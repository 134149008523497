type Props = {
  sameSize?: boolean;
  color?: string;
  size?: string | number;
  onClick?: any;
};
function GasStationIcon({ color, size = "24", onClick, sameSize }: Props) {

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.9165 18.3327V4.16602C2.9165 2.49935 4.03317 1.66602 5.4165 1.66602H12.0832C13.4665 1.66602 14.5832 2.49935 14.5832 4.16602V18.3327H2.9165Z"
        stroke={color ? color : "#101828"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.6665 18.334H15.8332"
        stroke={color ? color : "#101828"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.9915 8.3332H10.5165C11.3832 8.3332 12.0915 7.91653 12.0915 6.7582V5.7332C12.0915 4.57487 11.3832 4.1582 10.5165 4.1582H6.9915C6.12484 4.1582 5.4165 4.57487 5.4165 5.7332V6.7582C5.4165 7.91653 6.12484 8.3332 6.9915 8.3332Z"
        stroke={color ? color : "#101828"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.4165 10.834H7.9165"
        stroke={color ? color : "#101828"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5835 13.3417L18.3335 13.3333V8.33333L16.6668 7.5"
        stroke={color ? color : "#101828"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default GasStationIcon;

// fill={white ? "#fffff" : "#0F1322"}
