import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  sameSize?: boolean;
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function CreditCardIcon({ color, size, onClick, sameSize }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"}
      height={
        window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"
      }
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99609 8.5H11.4961"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99609 16.5H7.99609"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4961 16.5H14.4961"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.9961 12.03V16.11C21.9961 19.62 21.1061 20.5 17.5561 20.5H6.43609C2.88609 20.5 1.99609 19.62 1.99609 16.11V7.89C1.99609 4.38 2.88609 3.5 6.43609 3.5H14.4961"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.0764 4.12933L15.3664 7.83933C15.2264 7.97933 15.0864 8.25933 15.0564 8.45933L14.8564 9.87933C14.7864 10.3893 15.1464 10.7493 15.6564 10.6793L17.0764 10.4793C17.2764 10.4493 17.5564 10.3093 17.6964 10.1693L21.4064 6.45933C22.0464 5.81933 22.3464 5.07933 21.4064 4.13933C20.4564 3.18933 19.7164 3.48933 19.0764 4.12933Z"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5469 4.66016C18.8669 5.79016 19.7469 6.67016 20.8669 6.98016"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CreditCardIcon;

// fill={white ? "#fffff" : "#0F1322"}
