import React, { MouseEventHandler } from "react";
type Props = {
  color?: string;
  size?: string;
  onClick?: any;
};
function EmptyMessagesIcon({ color="#0F1322", size="48", onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill={color}
        d="M15.94 29.66A7.888 7.888 0 0010 27c-4.42 0-8 3.58-8 8 0 1.5.42 2.92 1.16 4.12.4.68.92 1.3 1.52 1.82A7.842 7.842 0 0010 43a7.94 7.94 0 006.84-3.88C17.58 37.92 18 36.5 18 35c0-2.04-.78-3.92-2.06-5.34zm-2.74 8.5c-.3.3-.68.44-1.06.44s-.76-.14-1.06-.44l-1.06-1.06-1.1 1.1c-.3.3-.68.44-1.06.44s-.76-.14-1.06-.44c-.58-.58-.58-1.54 0-2.12l1.1-1.1-1.06-1.06c-.58-.58-.58-1.54 0-2.12.58-.58 1.54-.58 2.12 0l1.06 1.06 1-1c.58-.58 1.54-.58 2.12 0 .58.58.58 1.54 0 2.12l-1 1 1.06 1.06c.58.58.58 1.52 0 2.12z"
      ></path>
      <path
        fill={color}
        d="M34.5 4.86h-19c-5.7 0-9.5 3.8-9.5 9.5v8.92c0 .7.72 1.2 1.4 1.02.84-.2 1.7-.3 2.6-.3 5.72 0 10.44 4.64 10.96 10.26.04.56.5 1 1.04 1h1.1l8.46 5.64c1.24.84 2.94-.08 2.94-1.6v-4.04c2.84 0 5.22-.96 6.88-2.6 1.66-1.68 2.62-4.06 2.62-6.9v-11.4c0-5.7-3.8-9.5-9.5-9.5zm-2.84 16.76H18.34c-.78 0-1.42-.64-1.42-1.42 0-.8.64-1.44 1.42-1.44h13.32c.78 0 1.42.64 1.42 1.44 0 .78-.64 1.42-1.42 1.42z"
      ></path>
    </svg>
  );
}

export default EmptyMessagesIcon;

// fill={white ? "#fffff" : "#0F1322"}
