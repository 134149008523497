import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  sameSize?: boolean;
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function ShirtFoldIcon({ color, size, onClick, sameSize }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"}
      height={
        window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"
      }
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 4.6875H16.7325L15.3975 3.3525C15.2921 3.24698 15.1491 3.18763 15 3.1875H9C8.85184 3.18837 8.71001 3.24767 8.60531 3.3525L7.2675 4.6875H5.25C4.9019 4.6875 4.56806 4.82578 4.32192 5.07192C4.07578 5.31806 3.9375 5.6519 3.9375 6V20.25C3.9375 20.5981 4.07578 20.9319 4.32192 21.1781C4.56806 21.4242 4.9019 21.5625 5.25 21.5625H18.75C19.0981 21.5625 19.4319 21.4242 19.6781 21.1781C19.9242 20.9319 20.0625 20.5981 20.0625 20.25V6C20.0625 5.6519 19.9242 5.31806 19.6781 5.07192C19.4319 4.82578 19.0981 4.6875 18.75 4.6875ZM15.9375 5.48344V10.5C15.9375 10.5353 15.9275 10.5699 15.9087 10.5998C15.8898 10.6297 15.863 10.6536 15.8311 10.6689C15.7993 10.6842 15.7638 10.6902 15.7287 10.6862C15.6936 10.6822 15.6604 10.6683 15.6328 10.6462L12.7434 8.14875L15.0872 4.63312L15.9375 5.48344ZM12 7.23563L10.0509 4.3125H13.9491L12 7.23563ZM8.91281 4.63312L11.2566 8.14875L8.36719 10.6444C8.33974 10.6664 8.30668 10.6802 8.27176 10.6842C8.23684 10.6883 8.20147 10.6825 8.1697 10.6675C8.13793 10.6524 8.11103 10.6287 8.09208 10.5991C8.07312 10.5695 8.06287 10.5352 8.0625 10.5V5.48344L8.91281 4.63312ZM5.0625 20.25V6C5.0625 5.95027 5.08225 5.90258 5.11742 5.86742C5.15258 5.83225 5.20027 5.8125 5.25 5.8125H6.9375V10.5C6.93596 10.7498 7.00641 10.9947 7.14041 11.2056C7.27441 11.4164 7.4663 11.5841 7.69312 11.6888C7.86754 11.77 8.05758 11.8122 8.25 11.8125C8.55861 11.814 8.85768 11.7056 9.09375 11.5069L9.10125 11.5003L11.445 9.47906V20.4375H5.25C5.20027 20.4375 5.15258 20.4177 5.11742 20.3826C5.08225 20.3474 5.0625 20.2997 5.0625 20.25ZM18.9375 20.25C18.9375 20.2997 18.9177 20.3474 18.8826 20.3826C18.8474 20.4177 18.7997 20.4375 18.75 20.4375H12.5625V9.47906L14.9062 11.5003L14.9137 11.5069C15.1479 11.704 15.444 11.8122 15.75 11.8125C15.9445 11.8123 16.1366 11.7691 16.3125 11.6859C16.5378 11.5807 16.7283 11.413 16.8612 11.2028C16.9941 10.9926 17.064 10.7487 17.0625 10.5V5.8125H18.75C18.7997 5.8125 18.8474 5.83225 18.8826 5.86742C18.9177 5.90258 18.9375 5.95027 18.9375 6V20.25Z"
        fill="#75808F"
      />
    </svg>
  );
}

export default ShirtFoldIcon;

// fill={white ? "#fffff" : "#0F1322"}
