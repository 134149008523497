import styles from "./DashboardPage.module.scss";
import { useDashboardPage } from "./hook";
import CommonTable from "../../components/templates/CommonTable";
import { localeButtons, useLocale } from "../../locales";

import { DashboardPageProps } from "./@types";
import ItemCard from "../../components/molecules/Side Stats copy";
import AddressBookIcon from "../../assets/Jsx/AddressBookIcon";
import UserCircleIcon from "../../assets/Jsx/UserCircleIcon";
import UserListIcon from "../../assets/Jsx/UserListIcon";
import BuildingIcon from "../../assets/Jsx/BuildingIcon";
import ChartComponent from "../../components/organisms/ChartComponent";
import NotificationsCard from "../../components/organisms/NotificationsCard";
import { BsGraphUp } from "react-icons/bs";

const DashboardPage = ({
  dashboardData,
  monthlyJoinedData,
  notifications,
}: DashboardPageProps) => {
  const {
    navigateToUserDetails,
    postData,
    barOptions,
    lineOptions,
  } = useDashboardPage(monthlyJoinedData);
  const { localeLinks, localeTitles } = useLocale();
  return (
    <div className="mt-4  md:mt-10">
      <div className={styles["dashboard-top"]}>
        <div className={styles["dashboard-top-left"]}>
          <ItemCard
            title={localeTitles.TITLE_TOTAL_USERS}
            value={dashboardData?.totalUser}
            icon={<AddressBookIcon />}
            // key={i}
          />
          <ItemCard
            title={localeTitles.TITLE_TOTAL_EMPLOYEES}
            value={dashboardData?.totalEmployee}
            icon={<UserCircleIcon />}
            // key={i}
          />
          <ItemCard
            title={localeTitles.TITLE_TOTAL_FREELANCERS}
            value={dashboardData?.totalFreelancer}
            icon={<UserListIcon />}
            // key={i}
          />
          <ItemCard
            title={localeTitles.TITLE_TOTAL_ORGANIZATIONS}
            value={dashboardData?.totalOrganization}
            icon={<BuildingIcon />}
            // key={i}
          />
          <ItemCard
            title={localeTitles.TITLE_PRODUCT_MONTHLY_GROWTH_RATE}
            // value={dashboardData?.totalOrganization}
            icon={<BsGraphUp size={48} />}
            rate={"26"}
            // key={i}
          />
        </div>
      </div>

      <div className={styles["dashboard-top"]}>
        <ChartComponent
          data={postData}
          type="bar"
          options={barOptions}
          heading={localeTitles.TITLE_TOTAL_JOINED_USERS}
        />

        <ChartComponent
          data={postData}
          type="line"
          options={lineOptions}
          heading={localeTitles.TITLE_USERS_INSIGHTS}
        />

        <NotificationsCard
          heading={localeTitles.TITLE_USERS_NOTIFICATIONS}
          // data={notifications?.slice(0, 6)}
          data={notifications}
        />
      </div>

      <CommonTable
        showTitle
        title={localeLinks.LINK_APPLICATION_STATUS}
        tableType="Application_status"
        loading={false}
        data={dashboardData?.latestFiveRecords || []}
        moreBtnPath="/dashboard/list"
        showMoreBtn={localeButtons.BUTTON_SEE_ALL_USER}
        navigateTo={navigateToUserDetails}
      />
    </div>
  );
};

export default DashboardPage;
