export default {
  LABEL_FAVORITES_ONLY: "Favorites only",
  LABEL_FILLED_SERVICES: "Filled services",
  LABEL_RECENTLY_VIEWED: "Recently viewed",
  LABEL_SHOW_BY_NAME: "Show by name",
  LABEL_SHOW_BY_POPULARITY: "Show by popularity",
  LABEL_SHOW_BY_DATE: "Show by date",
  LABEL_NEWEST: "Newest",
  LABEL_CLIENT_RATING: "Client rating",
  LABEL_DAYS_WITH_SCHEDULED_SERVICES: "Days with scheduled services",
  LABEL_ONLY_WITHIN_MY_FEATURES: "Only within my features",
  LABEL_URGENT_JOBS: "Urgent jobs",
  LABEL_FREELANCER: "Freelancer",
  LABEL_FACILITY: "Facility",
  LABEL_HOSPITALITY_INDUSTRY: "Hospitality industry",
  LABEL_LOGISTIC: "Logistic",
  LABEL_OTHER: "Other",
  LABEL_PROMOTION: "Promotion",
  LABEL_RETAIL: "Retail",
  LABEL_ACTIVE_JOBS: "Active jobs",

  LABEL_CARE: "Care",
  LABEL_SHOW_ALL: "Show All",
  LABEL_SORT_BY_NAME: "Sort by Name",
  LABEL_SORT_BY_POPULARITY: "Sort by Popularity",
  LABEL_SORT_BY_DATE: "Sort by Date",
  LABEL_PROMOTED: "Promoted",
  LABEL_URGENT_HIRING: "URGENT HIRING",
  LABEL_URGENT: "URGENT",
  LABEL_URGENT_LOWER: "Urgent",
  LABEL_REVIEWS: "Reviews",
  LABEL_FIND_JOBS: "Find Jobs",
  LABEL_POSTED_JOBS: "Posted Jobs",
  LABEL_FIND_WORKERS: "Find Workers",
  LABEL_HIRED_WORKERS: "Hired Workers",
  LABEL_INBOX: "Inbox",
  LABEL_COMPLETED: "Completed",
  LABEL_COMPLETE: "Complete",
  LABEL_PERSON: "Person",
  LABEL_PROPOSAL_SUBMITTED: "Proposal Submitted",
  LABEL_INVITED_BY: "Invited by",
  LABEL_DAY: "Day",
  LABEL_DATE: "Date",
  LABEL_WORKING_STATUS: "Working Status",
  LABEL_JOB_INFO: "Job Info",
  LABEL_JOB_CONTRACT: "Job Contract",
  LABEL_HOURS_WORKED: "Hours Worked",
  LABEL_START: "Start",
  LABEL_END: "End",
  LABEL_BREAK: "break",

  LABEL_RATE: "Rate",
  LABEL_TOTAL_TIME: "Total Time",
  LABEL_TOTAL_AMOUNT: "Total Amount",
  LABEL_FROM: "From",
  LABEL_TO: "To",
  LABEL_INVOICE_NUMBER: "Invoice Number",
  LABEL_DATE_OF_INVOICE: "Date of Invoice",
  LABEL_TOTAL_AMOUNT_EX_VAT: "Total Amount (ex. VAT)",
  LABEL_VAT: "VAT",
  LABEL_TOTAL_AMOUNT_INCL_VAT: "Total Amount (incl. VAT)",
  LABEL_DOWNLOAD: "Download",
  LABEL_PAYMENT: "Payment",
  LABEL_SATISFIED: "Satisfied",
  LABEL_AVAILABLE: "Available",

  completed: "Completed",
  rejected: "Rejected",
  approved: "Approved",
  declined: "Declined",
  inProgress: "Pending",

  LABEL_UNAVAILABLE: "Unavailable",
  LABEL_MR: "Mr",
  LABEL_DAY_SHIFT: "Day",
  LABEL_NIGHT_SHIFT: "Night",
  LABEL_ALL_DAY: "All Day",
  LABEL_MRS: "Mr's",
  LABEL_ENGLISH: "English",
  LABEL_DUTCH: "Dutch",
  LABEL_INVITATION_TO_SETTINGS: "Invitation to Settings",
  LABEL_INVITATION_OF_LIKED_SERVICES: "Invitation of Liked Services",
  LABEL_REMIDER_OF_JOBS: "Reminder of Jobs",
  LABEL_ALERT_OF_PROPOSAL: "Alert of Proposal Approval and Job Requesting",
  LABEL_SUN: "Sun",
  LABEL_MON: "Mon",
  LABEL_TUE: "Tue",
  LABEL_WED: "Wed",
  LABEL_THU: "Thu",
  LABEL_FRI: "Fri",
  LABEL_SAT: "Sat",
  LABEL_WEEK: "Week",
  LABEL_WEEKLY: "Weekly",
  LABEL_MONTHLY: "Monthly",
  LABEL_COMPANY_NAME: "Company name",
  LABEL_COMPANY_ADDRESS: "Company address",
  LABEL_ADDRESS: "Address",
  LABEL_LATEST_CONTRACT_START: "Latest Contract",
  LABEL_COPY_THE_PREVIOUS_WEEK_SCHEDULE: "Copy the previous week schedule",
  LABEL_AVAILABILITY_SELECTION: "Availability Selection",
  LABEL_REASON: "Reason",
  LABEL_TASK_NAME: "Task Name",
  LABEL_TASKS: "Tasks",
  LABEL_TASK_DESCRIPTION: "Task Description",
  LABEL_TIME_FRAME: "Time Frame",
  LABEL_AVAILABLE_ALL_DAY: "Available All Day",

  LABEL_NOT_AVAILABLE: "Not Available",
  LABEL_APPROVE: "Approve",
  LABEL_APPROVED: "Approved",

  LABEL_PARTIAL_AVAILABLE: "Partial Available",
  LABEL_PARTIAL: "Partial",
  LABEL_ALREADY_HIRED: "Already Hired",
  LABEL_OPEN_HIRING: "Open Hiring",
  LABEL_HIRED: "Hired",
  LABEL_SHIFT_NAME: "Shift Name:",
  LABEL_NEW_SHIFT: "New Shift",
  LABEL_SHIFT: "Shift",
  LABEL_DECLINED: "Declined",
  LABEL_PAUSED: "Paused",
  LABEL_DELETE: "Delete",
  LABEL_TOP_RATED: "Top Rated",
  LABEL_ORDINARY: "Ordinary",
  LABEL_SOON: "Soon",
  LABEL_FLEXIBLE: "Flexible",
  LABEL_JOINING_DATE: "Joining Date",
  LABEL_WORKERS: "Workers",
  LABEL_ORGANIZATION_BRANCH: "Organization Branch",
  LABEL_EMPLOYEE_NAME: "Name",
  LABEL_SCROLL_DOWN: "Scroll down",
  LABEL_JOB_COMPLETION_SCORE: "Job Completion score",
  LABEL_TOTAL_EARNINGS: "Total Earnings",
  LABEL_RECEIVED_INVITATIONS: "Received Invitations",
  LABEL_INVITE: "Invite",
  LABEL_JOB_SUCCESS: "Job Success",
  LABEL_OVERALL_RATING_START: "Overall Rating Start",
  LABEL_ORDERS_COMPLETED: "Orders Completed",
  LABEL_ORDERS: "orders",
  LABEL_SUBMITTED_PROPOSAL: "Submitted Proposal",
  LABEL_TOTAL_PROPOSAL: "Total proposals",
  LABEL_PLEASE_SELECT: "Please Select",
  LABEL_PLEASE_SELECT_AVAILABILITY: "Please Select Worker Availability",
  LABEL_PLEASE_SELECT_SHIFT: "Please Select shift",
  LABEL_EVERY_WEEK: "Every week",
  LABEL_EVERY_SECOND_WEEK: "Every second week",
  LABEL_EVERY_THIRD_WEEK: "Every third week",
  LABEL_EVERY_FOURTH_WEEK: "Every fourth week",
  LABEL_PLEASE_SELECT_A_JOB: "Please select a job",
  LABEL_SEARCH: "Search",
  LABEL_CHILD_DAY_CARE: "Child Day Care",
  LABEL_HOSPITALITY: "Hospitality",
  LABEL_HEALTH_CARE: "Health Care",
  LABEL_OFFICE: "Office",
  LABEL_SALES: "Sales",
  LABEL_CLEANING: "Cleaning",
  LABEL_CALL_CENTER: "Callcenter",
  LABEL_CONSTRUCTION: "Construction",
  LABEL_CONTACT_US: "Contact Us",
  LABEL_EMAIL: "Email",
  LABEL_PHONE: "Telephone",
  LABEL_WHATSAPP: "Whatsapp",
  LABEL_IT: "IT",
  LABEL_EDUCATION: "Education",
  LABEL_JOB_TITLE: "Job Title",
  LABEL_START_DATE: "Start Date",
  LABEL_END_DATE: "End Date",
  LABEL_DEGREE_TITLE: "Degree Title",
  LABEL_INSTITUE_NAME: "Institute Name",
  LABEL_LINKEDIN: "LinkedIn",
  LABEL_SKYPE: "Skype",
  LABEL_VIEW_PROFILE: "View Profile",
  LABEL_UNLINK: "Unlink",
  LABEL_FIVE_KM: "5 KM",
  LABEL_TEN_KM: "10 KM",
  LABEL_TWINTY_KM: "20 KM",
  LABEL_THIRITY_KM: "30 KM",
  LABEL_FOURTY_KM: "40 KM",
  LABEL_FIFTY_KM: "50 KM",
  LABEL_HOURLY_RATE: "Hourly rate",
  LABEL_WRITE_DESCRIPTION: "Write description",
  LABEL_WRITE_PERPOSAL: "Write perposal",
  LABEL_ADD_PORTFOLIO_LINK: "Add portfolio link",
  LABEL_AVAILABLE_A: "Available",
  LABEL_TASK: "Task = T",
  LABEL_TASK_BOLD: "Assigned Task = T with bold border",
  LABEL_SCHEDULE_S: "Schedule = S",
  LABEL_CLICK_HERE_TO_ADD_INDUSTRY:
    "Click here to add an industry or multiple industries.",
  LABEL_TIMELINE: "Timeline",

  LABEL_ASSIGNED: "Assigned",
  LABEL_ACCEPTED: "Accepted",
  LABEL_INPROGRESS: "In Progress",
  LABEL_REJECT: "Reject",
  LABEL_REJECTED: "Rejected",

  LABEL_LOADMORE: "Load More",

  LABEL_SUNDAY: "Sunday",
  LABEL_MONDAY: "Monday",
  LABEL_TUESDAY: "Tuesday",
  LABEL_WEDNESDAY: "Wednesday",
  LABEL_THURSDAY: "Thursday",
  LABEL_FRIDAY: "Friday",
  LABEL_SATURDAY: "Saturday",

  LABEL_FULLNAME: "Full name",
  LABEL_PW: "Password",

  LABEL_CPW: "Confirm Password",
  LABEL_NEW_PW: "New Password",
  LABEL_NEW_CPW: "Confirm New Password",
  LABEL_NO_DOT: "No.",
  LABEL_USER_NAME: "User Name",
  LABEL_WORKER_TYPE: "Worker Type",
  LABEL_VERIFCATION_STATUS: "Verification Status",
  LABEL_APPROVED_DATE: "Approved Date",
  LABEL_VIEW_MORE: "View More",

  LABEL_FREELANCER_NAME: "Name",
  LABEL_ORGANIZATION_NAME: "Organization Name",
  LABEL_PHONE_NUMBER: "Phone number",
  LABEL_INDUSTRY_CATEGORY: "Industry Category",
  LABEL_ASSOCIATED_ORGANIZATION: "Associated Organization",
  LABEL_DOB: "DOB",
  LABEL_SEE_MORE: "more",
  LABEL_NOTIFICATION: "Notification",
  LABEL_ALLOW_POST_JOB: "Allow To Post Job",
  LABEL_BUSINESS_BRANCH: "Business Branch",
  LABEL_INDUSTRY: "Industry",
  LABEL_IN_PROCESS: "In Process",
  LABEL_DATE_OF_BIRTH: "Date of birth",
  LABEL_ADDRESS_DETAILS: "Address Details",
  LABEL_BANK_ACCOUNT_DETAILS: "Bank Account Details",
  LABEL_KVK_NUMBER: "Kvk Number",
  LABEL_EMPLOYEE_ID: "Employee ID",
  LABEL_CERTIFICATE: "Certificate",
  LABEL_DIPLOMA: "Diploma",
  LABEL_SCAN_BY_CAMERA: "Scan by camera",
  LABEL_INSURANCE_DOCUMENT: "Insurance Document",
  LABEL_SEND_EMAIL_TO_EMPLOYEE: "Send Email to Employee",
  LABEL_VOG_OF_EMPLOYEE: "VOG Of Employee",
  LABEL_VOG_REGISTRATION_MSG:
    "All above details are verified, So we are goign to proceed to sent VOG registration link to Employee",
  LABEL_CURRENTLY_NO_VOG_AVAILABLE: "Currently, no VOG available here",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_ACCOUNT:
    "Are you sure you want to deactivate this account?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_ACTIVATE_ACCOUNT:
    "Are you sure you want to activate this account?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_BRANCH:
    "Are you sure you want to delete this branch?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SKILL:
    "Are you sure you want to delete this skill?",
  // message before delte Job, warning message before delete job
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JOB:
    "Are you sure you want to delete this job?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_CATEGORY:
    "Are you sure you want to delete this category?",
  LABEL_FRONT_SIDE: "Front Side",
  LABEL_BACK_SIDE: "Back Side",
  LABEL_NOTE: "Note",
  LABEL_VOG_IS_VALID_AND_APPROVED:
    "Employee VOG is valid. And We had approved it.",
  LABEL_VOG_IS_INVALID_AND_REJECTED:
    "Employee VOG is invalid. And We had rejected it.",
  LABEL_COMPANY_EMPLOYEES: "Company Employees:",
  LABEL_COMPANY_JOBS: "Company Jobs:",
  LABEL_SEE_ALL: "See All",

  LABEL_BRANCH_NAME: "Branch Name",
  LABEL_OPTIONS: "Options",
  LABEL_SEE_CATEGORIES: "See Categories",
  LABEL_CATEGORY_NAME_DUTCH: "Category Name(Dutch)",
  LABEL_CATEGORY_NAME_ENGLISH: "Category Name(English)",
  LABEL_SKILL_NAME_DUTCH: "Skill Name(Dutch)",
  LABEL_SKILL_NAME_ENGLISH: "Skill Name(English)",
  LABEL_BRANCH_NAME_ENGLISH: "Branch English Name",
  LABEL_BRANCH_NAME_DUTCH: "Branch Dutch Name",
  LABEL_SELECT_BRANCH: "Select Branch",
  LABEL_TURN_ON_PRICE_HIDE: "Turn on Price Hide",
  LABEL_TURN_ON_JOB_CHECK: "Turn on Job Posting Check",
  LABEL_TURN_OFF_JOB_CHECK: "Turn off Job Posting Check",
  LABEL_TURN_OFF_PRICE_HIDE: "Turn off Price Hide",

  LABEL_SEARCH_BY_NAME: "Search by name",
  LABEL_SEARCH_BY_EMAIL: "Search by email",
  LABEL_SEARCH_BY_CONTACT: "Search by contact",

  LABEL_ISSUE_DATE: "Issue Date",
  LABEL_EXPIRE_DATE: "Expire Date",
  LABEL_REPORT_TYPE: "Report Type",
  LABEL_REPORT_GROUP: "Report Group",
  LABEL_FREELANCER_PRICE: "Toggle Freelancer Price",

  CURRENT_LOCATION: "Current Location",

  LABEL_REGISTRATION_DATE:"Registration Date",
  LABEL_EMPLOYEE_NUMBER:"EN",
  LABEL_FREELANCER_NUMBER:"FN",


};
