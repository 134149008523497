import { Button } from "components/atoms/ButtonF";
import styles from "./HiredWorkersPage.module.scss";

import HiredWorkerCard from "../HiredWorkerCard";
import { IHiredWokers } from "types/schema";
import { localeButtons, useLocale } from "locales";
import Loader from "components/atoms/Loader";

const hireWordkerButtons = (buttons: typeof localeButtons) => [
  { label: buttons.BUTTON_ONGOING_JOBS, value: "hired" },
  { label: buttons.BUTTON_COMPLETED, value: "completed" },
  { label: buttons.BUTTON_PAUSED, value: "paused" },
  { label: buttons.BUTTON_CANCELED_JOBS, value: "cancelled" },
];

type HiredWorkersPageProps = {
  hiredWorkersList: IHiredWokers[];
  onClickCard?: (e: any) => void;
  isPage?: boolean;
  isLoading?: boolean;
  activeCategory?: string;
  hanCategoryChange?: (e: any) => void;
};
const HiredWorkersPage = ({
  hiredWorkersList,
  onClickCard,
  isPage = false,
  activeCategory = "hired",
  hanCategoryChange,
  isLoading,
}: HiredWorkersPageProps) => {
  const { localeButtons } = useLocale();


  return (
    <div className={styles["HiredWorkersPage"]}>
      {isPage && (
        <div className={styles["button-container"]}>
          {hireWordkerButtons(localeButtons)?.map(
            (category: { label: string; value: string }, index) => (
              <Button
                key={index}
                className={
                  activeCategory === category?.value
                    ? styles["primaryBtn"]
                    : styles["secondaryBtn"]
                }
                onClick={() => hanCategoryChange?.(category?.value)}
              >
                {category?.label}
              </Button>
            )
          )}
        </div>
      )}
      {isLoading ? (
        <div className={styles["HiredWorkersPage-main"]}>
          <Loader />
        </div>
      ) : (
        <div className={styles["HiredWorkersPage-main"]}>
          {hiredWorkersList?.map((job, jobIndex) => (
            <HiredWorkerCard
              data={job}
              key={jobIndex}
              onClickCard={onClickCard}
              className={styles["workerClass"]}
              midSectionClass={styles["midSectionClass"]}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HiredWorkersPage;
