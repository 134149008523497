import { localeButtons, localeLabels, localeTitles } from "locales";

import BothFrelancerAndEmployee from "assets/images/jobs/clarity_employee-group-line.svg";
// import ParkingIcon from "assets/images/jobs/freelancer.pn";

import employeeImg from "assets/images/jobs/Vector-4 (1).svg";
import freelancerImg from "assets/images/jobs/Group-3 (1).svg";
import GhostIcon from "assets/svgs/GhostIcon";

import { ViewMode } from "gantt-task-react";

export const googleAPIKey = "AIzaSyB7Jlc-Hyyf5llSQBoJYaFAoaoUIUo-FsU";

export const workingDatesStatusOrg = (localeLabel: typeof localeLabels) => [
  { value: "", label: localeLabel.LABEL_PLEASE_SELECT },
  {
    value: "approved",
    label: localeLabel.LABEL_APPROVE,
  },
  { value: "rejected", label: localeLabel.LABEL_REJECT },
];

export const profileShowOptions = (localeLabel: typeof localeLabels) => [
  // { value: "", label: localeLabel.LABEL_PLEASE_SELECT },

  { value: "available", label: localeLabel.LABEL_AVAILABLE, icon: GhostIcon },
  {
    value: "unavailable",
    label: localeLabel.LABEL_UNAVAILABLE,
    icon: GhostIcon,
  },
];

export const shiftOPtions = (localeLabel: typeof localeLabels) => [
  { value: "", label: localeLabel.LABEL_PLEASE_SELECT_SHIFT },
  { value: "day_shift", label: localeLabel.LABEL_DAY_SHIFT },
  {
    value: "night_shift",
    label: localeLabel.LABEL_NIGHT_SHIFT,
  },
];

export const availabilityScheduleSowOptions = (
  localeLabel: typeof localeLabels
) => [
  { value: "", label: localeLabel.LABEL_PLEASE_SELECT_AVAILABILITY },
  { value: "available", label: localeLabel.LABEL_AVAILABLE_ALL_DAY },
  {
    value: "unavailable",
    label: localeLabel.LABEL_NOT_AVAILABLE,
  },
  {
    value: "partial",
    label: localeLabel.LABEL_PARTIAL_AVAILABLE,
  },
];

export const ClanderViewOptions = (localeButton: typeof localeButtons) => [
  {
    value: ViewMode.Hour,
    label: localeButton.BUTTON_DAY,
    icon: employeeImg,
  },
  {
    value: ViewMode.Day,
    label: localeButton.BUTTON_WEEK,
  },
  {
    value: "day",
    label: localeButton.BUTTON_MONTH,
  },
];

// asasd
export const postJobOptionsFeatures = (localeTitle: typeof localeTitles) => [
  {
    value: "freelancer",
    label: localeTitle.TITLE_FREELANCER,
    icon: freelancerImg,
  },
  {
    value: "employee",
    label: localeTitle.TITLE_EMPLOYEE,
    icon: employeeImg,
  },
  {
    value: "both",
    label: localeTitle.TITLE_BOTH_EMPLOYEE_AND_FREELANCER,
    icon: BothFrelancerAndEmployee,
  },
];

// asdasd
export const jobTypeOptions = (localeLabel: typeof localeLabels) => [
  { value: "", label: localeLabel.LABEL_PLEASE_SELECT_A_JOB },
  {
    value: "soon",
    label: localeLabel.LABEL_SOON,
  },
  { value: "urgent", label: localeLabel.LABEL_URGENT_LOWER },
];
// aslkdjaslk
export const repeatWeekOptions = (localeLabel: typeof localeLabels) => [
  { value: "", label: localeLabel.LABEL_PLEASE_SELECT },
  {
    value: "everyWeek",
    label: localeLabel.LABEL_EVERY_WEEK,
  },
  {
    value: "everySecondWeek",
    label: localeLabel.LABEL_EVERY_SECOND_WEEK,
  },
  {
    value: "everyThirdWeek",
    label: localeLabel.LABEL_EVERY_THIRD_WEEK,
  },
  {
    value: "everyFourthWeek",
    label: localeLabel.LABEL_EVERY_FOURTH_WEEK,
  },
];
