import { useLocale } from "locales";
import styles from "./JobPostingSection.module.scss"
import { GrAddCircle } from "react-icons/gr";
import { Text } from "components/atoms/TextF";


export const AddNewLocation = ({
    onClick,
    label
  }: {
    onClick: () => void;
    label?: string
  }) => {
    const { localeTitles } = useLocale();
    return (
      <div
        onClick={onClick}
        className={styles["btn-add-action"]}
      >
        <GrAddCircle size={15} />
        <Text className={styles["btn-add-label"]}>
          {label?label:localeTitles.TITLE_NEW_LOCATION}
        </Text>
      </div>
    );
  };