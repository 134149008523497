import React, { useEffect, useState } from "react";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import styles from "./JobCard.module.scss";
import { JobCardProps } from "./@types";
import { localeButtons, useLocale } from "locales";
import { useNavigate } from "react-router-dom";
import LocationIcon from "../Icon/LocationIcon";

import ClockIcon from "../Icon/ClockIcon";
import ProfileIcon from "../Icon/ProfileIcon";
import CalendarTickIcon from "../Icon/CalendarTickIcon";
import TickCircle from "../Icon/TickCircle";
import logo from "assets/images/logo.png";
import { getLables, handleImagesPath } from "utils/hooks/helper";

import { formatDatePosted } from "utils/dayjs";
import dayjs from "dayjs";

const JobCard = ({
  data,
  className,
  onDeleteJob,
  onChangeFulfilledBy,
  organizationId
}: JobCardProps) => {
  const navigate = useNavigate();
  const { localeBasics, localeTitles, localeLabels, currentLocale } =
    useLocale();

  const navigateToInviteWorkers = () => {
    navigate(`/organization/${organizationId}/job/${data?._id}/invite`);
  };
  const navigateToJobDetails = () => {
    navigate(`/organization/${data._id}/details`, { state: data });
  };

  const formateDate = (startDate: string, endDate: string) => {
    if (data?.shift == "day") {
      return dayjs(startDate).format("DD MMM YYYY");
    }

    let isSameYear = dayjs(startDate).year() === dayjs(endDate).year();

    let isSameMonth = dayjs(startDate).month() === dayjs(endDate).month();

    let formate =
      isSameYear && isSameMonth ? "DD" : isSameYear ? "DD MMM" : "DD MMM YYYY";

    let formatedDate = endDate
      ? dayjs(startDate).format("DD MMM") +
        " - " +
        dayjs(endDate).format("DD MMM")
      : dayjs(startDate).format("DD MMM YYYY");

    return formatedDate;
  };

  return (
    <div className={`${styles["JobCard"]} ${className}`}>
      <div className={styles["JobCard-top"]}>
        <div className={styles["JobCard-top-left"]}>
          <LocationIcon />
          <Text className={styles["city"]}>{data?.address?.city},</Text>
          <Text className={styles["country"]}>{data?.address?.country}</Text>
        </div>

        {data?.status === "openForHiring" && (
          <SelectFullfilledBy
            onChange={(value) => onChangeFulfilledBy(value, data._id)}
            defaultValue={data?.fullfilledBy}
          />
        )}
      </div>
      <div className={styles["JobCard-mid"]}>
        <div className={styles["JobCard-mid-top"]}>
          {/* <Text className={styles["cateogory"]}>{data.category}</Text> */}
          {/* {data?.promoted && (  
            <Text className={styles["promoted"]}>
              {localeLabels.LABEL_PROMOTED}
            </Text>
          )} */}
          {data?.status === "completed" && (
            <Text className={styles["promoted"]}>
              {localeLabels.LABEL_COMPLETED}
            </Text>
          )}
          {data?.hiringUrgencyLevel === "urgent" && (
            <Text className={styles["urgent"]}>
              {localeLabels.LABEL_URGENT}
            </Text>
          )}
          {data?.branches?.length > 0 && (
            <Text className={styles["cateogory"]}>
              {getLables(data?.branches[0]?._id, currentLocale, "branches")}
            </Text>
          )}
        </div>

        <Text className={styles["title"]}>{data?.title}</Text>

        <div className={styles["JobCard-mid-bottom"]}>
          <div className={styles["JobCard-mid-details"]}>
            <ClockIcon color="#75808F" />
            <Text className={styles["details"]}>{`${
              dayjs(data.timeToStart).isValid()
                ? dayjs(data.timeToStart).format("HH:mm")
                : data.timeToStart
            } - ${
              dayjs(data.timeToEnd).isValid()
                ? dayjs(data.timeToEnd).format("HH:mm")
                : data.timeToEnd
            }`}</Text>
          </div>
          <div className={styles["JobCard-mid-details"]}>
            <ProfileIcon />
            <Text className={styles["details"]}>
              {data?.totalHiredWorkers} / {data?.workerNeeded}{" "}
              {localeLabels.LABEL_PERSON}
            </Text>
          </div>
        </div>
        <div className={styles["JobCard-mid-details"]}>
          <>
            <CalendarTickIcon />
            <Text className={styles["details"]}>
              {formateDate(data?.startDate, data?.endDate)}
            </Text>
          </>
          {data?.status === "applied" && (
            <>
              <TickCircle />
              <Text className={styles["proposalSubmitted"]}>
                {localeLabels.LABEL_PROPOSAL_SUBMITTED}
              </Text>
            </>
          )}
        </div>
      </div>
      <div className={styles["JobCard-bottom"]}>
        <div className={styles["JobCard-bottom-left"]}>
          <Img
            src={handleImagesPath(
              data?.organization?.logo ? data?.organization?.logo : logo
            )}
            className={styles["companyLogo"]}
          />
          <div>
            <Text className={styles["companyName"]}>
              {data?.organization?.companyName}
            </Text>
            <Text className={styles["details"]}>{`${formatDatePosted(
              data?.createdAt,
              localeBasics
            )}`}</Text>
            {/* <Text className={styles["details"]}>{`${getTimeDuration(
              data?.createdAt
            )} ${localeBasics.TEXT_HOURS_AGO}`}</Text> */}
          </div>
        </div>
        <div className={styles["JobCard-bottom-right"]}>
          {data?.workerType !== "employee" && (
            <div>
              <Text className={styles["rate"]}>
                €{" "}
                {`${(data as any)?.decidedRate || data?.wage} ${
                  localeTitles.TITLE_PER_HOUR
                }`}
              </Text>
            </div>
          )}
        </div>
      </div>

      <div className={styles["JobCard-bottom-btns"]}>
        {data?.status === "openForHiring" && (
          <Text
            className={styles["cancelJob-text"]}
            onClick={(e) => {
              e?.stopPropagation();
              onDeleteJob(data);
            }}
          >
            {localeBasics.TEXT_DELETE_JOB}
          </Text>
        )}
        <Text
          className={styles["repostJob-text"]}
          onClick={(e) => {
            e?.stopPropagation();
            navigateToInviteWorkers();
          }}
        >
          {localeButtons.BUTTON_INVITE}
        </Text>
        <Text
          className={styles["repostJob-text"]}
          onClick={(e) => {
            e?.stopPropagation();
            navigateToJobDetails();
          }}
        >
          {localeButtons.BUTTON_DETAIL}
        </Text>
      </div>
    </div>
  );
};

const SelectFullfilledBy = ({
  onChange,
  defaultValue,
}: {
  onChange: (value: "under_review" | "admin" | "users") => void;
  defaultValue: "under_review" | "admin" | "users";
}) => {
  const localeButtons = useLocale().localeButtons;

  const [fullfilledBy, setFullfilledBy] = useState<
    "under_review" | "admin" | "users"
  >(defaultValue || "under_review");

  const handleChange = (e) => {
    setFullfilledBy(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setFullfilledBy(defaultValue);
  }, [defaultValue]);

  return (
    <select
      onChange={handleChange}
      value={fullfilledBy}
      className={`${styles["select-primary"]} ${styles[fullfilledBy]}`}
    >
      <option value="under_review">
        {localeButtons.BUTTONS_JOB_IN_REVIEW}
      </option>
      <option value="admin">
        {localeButtons.BUTTONS_JOB_FULLFILLED_BY_ADMIN}
      </option>
      <option value="users">
        {localeButtons.BUTTONS_JOB_FULLFILLED_BY_USERS}
      </option>
    </select>
  );
};

export default JobCard;
