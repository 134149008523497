import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function CrownIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5254 14.2345H5.47539C5.16039 14.2345 4.80789 13.987 4.70289 13.687L1.59789 5.00203C1.15539 3.75703 1.67289 3.37453 2.73789 4.13953L5.66289 6.23203C6.15039 6.56953 6.70539 6.39703 6.91539 5.84953L8.23539 2.33203C8.65539 1.20703 9.35289 1.20703 9.77289 2.33203L11.0929 5.84953C11.3029 6.39703 11.8579 6.56953 12.3379 6.23203L15.0829 4.27453C16.2529 3.43453 16.8154 3.86203 16.3354 5.21953L13.3054 13.702C13.1929 13.987 12.8404 14.2345 12.5254 14.2345Z"
        stroke={color ? color : "white"}
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.875 16.5H13.125"
        stroke={color ? color : "white"}
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.125 10.5H10.875"
        stroke={color ? color : "white"}
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CrownIcon;

// fill={white ? "#fffff" : "#0F1322"}
