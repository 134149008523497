import AccountPage from "pages/AccountPage/AccountPage";
import AccountPageOrganization from "pages/AccountPageOrganization/AccountPageOrganization";
import { useAccountService } from "./hook";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AccountServiceComponent = () => {
  const { state } = useLocation();

  const userData = state?.userData;
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) navigate("/");
  }, [userData]);

  const { onSubmit, loading } = useAccountService(userData?.accountType);
  return (
    <>
      {userData?.accountType === "organization" ? (
        <AccountPageOrganization onSubmit={onSubmit} loading={loading} />
      ) : (
        <AccountPage
          onSubmit={onSubmit}
          loading={loading}
          employee={userData?.accountType === "employee" ? true : false}
          freelancer={userData?.accountType === "freelancer" ? true : false}
          organization={false}
        />
      )}
    </>
  );
};

// export default AccountService;

export default function UpdateAccount() {
  return <AccountServiceComponent />;
}
