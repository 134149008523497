import { Routes, Route } from "react-router-dom";
import { useLocale } from "../locales";
import Layout from "../components/templates/Layout";

import EmployeeServices from "../services/EmployeeServices";
import OrganizationServices from "../services/OrganizationServices";
import FreelancerServices from "../services/FreelancerServices";
import DetailsServices from "../services/DetailsServices";
import DashboardServices from "../services/DashboardServices";
import DashboardListServices from "../services/DashboardListServices";
import BranchesServices from "../services/BranchesServices";
import CategoriesServices from "../services/CategoriesServices";
import SkillsetServices from "../services/SkillsetServices";
import ReportsServices from "../services/Reports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { UsersContextConsumer } from "../store/AuthProvider";
import { useEffect } from "react";
import FinanceJobs from "pages/FinanceJobs";
import WorkingShift from "pages/FinancePage/workingShift";
import FinancePage from "pages/FinancePage/FinancePage";
import OrganizationJobsPage from "pages/OrganizationJobsPage/OrganizationJobsPage";
import JobDetail from "pages/OrganizationJobsPage/JobDetail";
import AllowedBranchesServices from "services/AllowedBranchesServices";
import AllJobs from "pages/OrganizationJobsPage/AllJobs";
import PostAJob from "services/PostAJob";
import EditAJob from "services/EditAJob";
import InviteWorkerPage from "services/InviteWorker/InviteWorker";
import WorkerDetailsService from "services/WorkerDetails";
import PersonalScheduleService from "services/PersonalSchedule";
import AccountService from "services/CreateAccount";
import UpdateAccount from "services/UpdateAccount";
import OrganizationSchedule from "pages/CompanySchedulePage";
import EmptyInbox from "components/organisms/EmptyInbox";
import GantChart from "components/cells/CompanyWorkersView/Gantt.Chart";
import WorkerPannelPage from "components/cells/CompanyWorkersView/WorkerPannel";
import NotificationService from "services/Notification";

const AdminRoutes = ({ user }: any) => {
  const { localeLinks, localeBasics } = useLocale();

  const { setAdminData } = UsersContextConsumer();

  useEffect(() => {
    if (user) {
      setAdminData(user.signInUserSession.idToken.jwtToken);
    }
  }, [user]);

  return (
    <>
      <Routes>
        {/* <Route path="/" element={<h1>M/ this is test</h1>} /> */}

        <Route
          path="/"
          element={
            <Layout
              headerPath={[localeLinks.LINK_DASHBOARD]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <DashboardServices />
            </Layout>
          }
        />
        <Route
          path="/dashboard/list"
          element={
            <Layout
              headerPath={[localeLinks.LINK_DASHBOARD]}
              isProtected={false}
              isAdmin={true}
              searchBar={true}
            >
              <DashboardListServices />
            </Layout>
          }
        />
        <Route
          path="/notifications"
          element={
            <Layout
              headerPath={[localeLinks.LINK_DASHBOARD]}
              isProtected={false}
              isAdmin={true}
              searchBar={true}
            >
              <NotificationService />
            </Layout>
          }
        />

        <Route
          path="/employees"
          element={
            <Layout
              headerPath={[localeLinks.LINK_EMPLOYEES_LIST]}
              isProtected={false}
              isAdmin={true}
              searchBar={true}
            >
              <EmployeeServices />
            </Layout>
          }
        />
        <Route
          path="/employees/:userId"
          element={
            <Layout
              // headerPath={[localeLinks.LINK_EMPLOYEES_LIST]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <DetailsServices />
            </Layout>
          }
        />

        <Route
          path="/organization"
          element={
            <Layout
              // headerPath={[localeLinks.LINK_EMPLOYEES_LIST]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <FinancePage />
            </Layout>
          }
        >
          <Route path=":hiredWorker/shift" element={<WorkingShift />} />
          <Route path=":organization/post" element={<PostAJob />} />
          <Route path=":organization/edit" element={<EditAJob />} />
          <Route path=":organization/worker" element={<FinanceJobs />} />
          {/* add  invite worker screen to invite worker */}
          <Route path=":organization/jobs" element={<OrganizationJobsPage />} />
          <Route
            path=":organization/allowed/branchs"
            element={<AllowedBranchesServices />}
          />
          <Route path="jobs/all" element={<AllJobs />} />
          <Route path=":jobId/details" element={<JobDetail />} />
          <Route
            path=":organization/job/:jobId/invite"
            element={<InviteWorkerPage />}
          />

          <Route
            path=":organization/worker/:userId/:jobId"
            element={<WorkerDetailsService />}
          />
          <Route
            path=":organization/schedule/:userId"
            element={<PersonalScheduleService />}
          />
          {/* add availability  or edit availability*/}
        </Route>

        <Route
          path="/freelancers"
          element={
            <Layout
              headerPath={[localeLinks.LINK_FREELANCER_LIST]}
              isProtected={false}
              isAdmin={true}
              searchBar={true}
            >
              <FreelancerServices />
            </Layout>
          }
        />
        <Route
          path="/freelancers/:userId"
          element={
            <Layout isProtected={false} isAdmin={true} searchBar={false}>
              <DetailsServices />
            </Layout>
          }
        />

        <Route
          path="/organizations"
          element={
            <Layout
              headerPath={[localeLinks.LINK_ORGANIZATION_LIST]}
              isProtected={false}
              isAdmin={true}
              searchBar={true}
            >
              <OrganizationServices />
            </Layout>
          }
        />

        <Route
          path="/create"
          element={
            <Layout isProtected={false} isAdmin={true} searchBar={false}>
              <AccountService />
            </Layout>
          }
        />
        <Route
          path="/update"
          element={
            <Layout isProtected={false} isAdmin={true} searchBar={false}>
              <UpdateAccount />
            </Layout>
          }
        />
        <Route
          path="/organizations/:userId"
          element={
            <Layout isProtected={false} isAdmin={true} searchBar={false}>
              <DetailsServices />
            </Layout>
          }
        />

        <Route
          path="/branches"
          element={
            <Layout
              headerPath={[localeLinks.LINK_ORGANIZATION_BRANCHES]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <BranchesServices />
            </Layout>
          }
        />
        <Route
          path="/branch/categories/:id"
          element={
            <Layout
              headerPath={[localeLinks.LINK_ORGANIZATION_BRANCHES]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <CategoriesServices />
            </Layout>
          }
        />

        <Route
          path="/categories"
          element={
            <Layout
              headerPath={[localeLinks.LINK_ORGANIZATION_CATEGORY]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <CategoriesServices />
            </Layout>
          }
        />

        <Route
          path="/skillset"
          element={
            <Layout
              headerPath={[localeLinks.LINK_EMPLOYEE_SKILLSET]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <SkillsetServices />
            </Layout>
          }
        />

        <Route
          path="/reports"
          element={
            <Layout
              headerPath={[localeLinks.LINK_REPORTS]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <ReportsServices />
            </Layout>
          }
        />

        <Route
          path="/schedule/:organization"
          element={
            <Layout
              headerPath={[localeLinks.LINK_REPORTS]}
              isProtected={false}
              isAdmin={true}
              searchBar={false}
            >
              <OrganizationSchedule />
            </Layout>
          }
        >
          <Route
            path=""
            element={
              <EmptyInbox
                title={localeBasics.TEXT_SELECT_WORKER}
                text={localeBasics.TEXT_SELECT_WORKER_MESSAGE}
              />
            }
          />
          <Route path=":hiredWorkerId" element={<GantChart />} />
          <Route path="worker/list" element={<WorkerPannelPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default withAuthenticator(AdminRoutes, {
  hideSignUp: true,
});
