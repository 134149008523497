import { useWidth } from '../utils/responsiveHook';
import { useEffect } from "react";
export const useDisableBodyScroll = () => {
  var layout = document.getElementById("mainBody");
  const {width} = useWidth()
  const scrollEnable = () => {
    if (layout && width <768) {
      layout.style.overflowY = "auto";
    }
  };
  const scrollDisable = () => {
    if (layout && width <768 ) {
      layout.style.overflowY = "hidden";
    }
  };

  return { scrollEnable, scrollDisable };
};
