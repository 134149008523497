import { useLocale } from "locales";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";

import { queryClient } from "utils/QueryClient";
import {
  editJobAPI,
} from "utils/globalAPI/OrganizationJobs";
import { JOB_FORMS } from "pages/JobPostingSection/@types";
// const jobsType = "cancelled" || "completed" || "inProgress";

export const useEditAJobPage = () => {
  const [loading, setLoading] = useState(false);
  const { localeSuccess, currentLocale } = useLocale();
  const toast = ToastConsumer();
  const { accessToken } = UsersContextConsumer();





  const location = useLocation();
  const navigate = useNavigate();

  const defaulValues: JOB_FORMS = {
    ...location?.state?.data,

    categories:
      location?.state?.data?.categories.map((category: any) => ({
        ...category,
        value: category._id,
        label: category.label?.[currentLocale] as string,
      })) || [],
    skills:
      location?.state?.data?.skills?.map((skill: any) => ({
        ...skill,
        value: skill._id,
        label: skill.label?.[currentLocale] as string,
      })) || [],
    branches:
      location?.state?.data?.branches.map((branch: any) => ({
        ...branch,
        value: branch._id,
        label: branch.label?.[currentLocale] as string,
      })) || [],
  };

  const onSubmit: SubmitHandler<JOB_FORMS> = async (data) => {
    console.log("onSubmit", data);
    const params = { ...data, jobId: location?.state?.data?._id };
    try {
      //
      setLoading(true);
      const response = await editJobAPI(params, accessToken);
      toast.success(localeSuccess.SUCESS_JOB_EDITED, "top-right");
          // Invalidate query
      queryClient.invalidateQueries([
        "jobsDetails",
        {
          jobId: location?.state?.data?._id,
        },
      ]);
      navigate(`/organization/${location?.state?.data?._id}/details`);
    } catch (error: any) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setLoading(false);
    }
  };
  // console.log("jobsData", jobsData);
  return {
    onSubmit,
    loading,
    defaulValues,
  };
};
