import React, { useState } from "react";
import type { selectProps } from "./@types";
import styles from "./Select.module.scss";

import { Controller } from "react-hook-form";
import { useLocale } from "../../../locales";
import { ErrorMessage } from "../../atoms/ErrorMessage";

const ControlSelect = ({
  list,
  control,
  name,
  Icon,
  className,
  placeholder,
  customError,
  selectTextClass,
  multiSelect,
  disabled
}: selectProps) => {
  const { localePlaceholders } = useLocale();



  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <>
          <div className={`${styles["select-container"]} ${className} ${(errors?.[name]?.message as string)?.length >0 &&styles["error-input"]}`}>
            <div className={styles["icon-container"]}>
              {Icon && Icon}
              {/* <Img src={icon} alt="Icon" className={styles["icon"]} /> */}
            </div>
            <select
            disabled={disabled}
              onChange={(v) => {
                if (multiSelect) {
                  if (value?.length < 3) {
                    if (value.includes(v.target.value)) {
                      onChange(value);
                    } else {
                      onChange([...value, v.target.value]);
                    }
                  }
                } else {
                  onChange(v.target.value);
                }
              }}
              value={value}
              // placeholder={placeholder}
              className={`${
                styles[Icon ? "select-less-width" : "select"]
              } ${selectTextClass} ${(errors?.[name]?.message as string)?.length >0 &&styles["error-title"]}`}
            >
              {list?.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={item.value}
                    className={styles["selectOptions"]}
                  >
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
          {(!!errors || customError)  && (
            <ErrorMessage errors={customError || errors?.[name]?.message as string} />
          )}
        </>
      )}
    />
  );
};

export default ControlSelect;
