export default {
  LINK_FORGOT_PW: "Forgot password",
  LINK_LOGIN: "Back to login page",
  LINK_DASHBOARD: "Dashboard",
  LINK_ORDERS: "List of Orders",
  LINK_EDIT_ACCOUNT: "Edit Account",
  LINK_LIST_OF_USERS: "List of users",
  LINK_USER_DETAILS: "User details",
  LINK_LIST_OF_BIKES: "List of bikes",
  LINK_CHARTS_SCREEN: "Charts screen",
  LINK_GOOD_PLAN: "Good plan",
  LINK_APARTMENT_AND_VILLA: "Apartment & Villa",
  LINK_HOSTEL_AND_CAMPING: "Hostel & Camping",
  LINK_EXPERIENCE: "Experience",
  LINK_LIST_OF_GOODPLAN: "List of Good Plan",
  LINK_APARTMENTS_AND_VILLA_ATTR: "List of Apartment and Villa Attributes",
  LINK_HOSTEL_AND_CAMPING_ATTR: "List of Hostel and Camping Attributes",
  LINK_EXPERIENCE_ATTR: "List of Experience Attributes",

  //
  LINK_EMPLOYEES_VIEW: "Employees View",
  LINK_FREELANCERS_VIEW: "Freelancers View",
  LINK_ORGANIZATIONS_VIEW: "Organizations View",
  LINK_APPLICATION_STATUS: "Application status",
  LINK_EMPLOYEES_LIST: "Employee’s List",
  LINK_FREELANCER_LIST: "Freelancer List",
  LINK_ORGANIZATION_LIST: "Organization List",
  LINK_ORGANIZATION_BRANCHES: "Organization Branches",
  LINK_ORGANIZATION_CATEGORY: "Organization Category",
  LINK_EMPLOYEE_SKILLSET: "Employee Skillset",
  LINK_REPORTS: "Reports",
  LINK_SEE_ALL_JOBS: "See All Jobs",

  LINK_EMPLOYEE: "Employee",
  LINK_FREELANCER: "Freelancer",
  LINK_ORGANIZATION: "Organization",
  LINK_CONTACT_INFO: "Contact info",
  LINK_DAYCARE: "Daycare",
  LINK_LOGISTICS: "Logistics",
  LINK_ADMINISTRATION: "Administration",
  LINK_CALLCENTER: "Call center",
  LINK_EDUCATION: "Education",
  LINK_HOW_IT_WORKS: "How it works",
  LINK_FIND_SHIFTS: "Find Shifts",
  LINK_FAQ: "FAQ",
  LINK_CONTACT_US: "Contact Us",
  LINK_TERMS_AND_CONDITIONS: "Terms & Conditions",
  LINK_PRIVACY_AND_POLICY: "Privacy & Policy",
  LINK_COOKIES_STATEMENT: "Cookies Statement",
  LINK_CREATE_ACCOUNT: " Create Account",
  LINK_FORGOT_PASSWORD: "Forgot password?",
  LINK_RESEND: "Resend",
  LINK_FIND_JOBS: "Find Jobs",
  LINK_INBOX: "Inbox",
  LINK_MY_JOBS: "My Jobs",
  LINK_FINANCE: "Finance",
  LINK_MY_STATS: "My Stats",
  LINK_PERSONAL_SCHEDULE: "Personal Schedule",
  LINK_FIND_WORKERS: "Find Workers",
  LINK_POST_A_JOB: "Post a Job",
  LINK_HIRED_WORKERS: "Hired Workers",
  LINK_SCHEDULE: "Schedule",
  LINK_NOTIFICATIONS: "Notifications",
  LINK_ACCOUNT: "Account",
  LINK_SETTINGS: "Settings",
  LINK_FAQS: "FAQ’s",
  LINK_DELETE_ACCOUNT: "Delete Account",
  LINK_LOG_OUT: "Log Out",
  LINK_BLOGS: "Blogs",
  LINK_ABOUT_US: "About Us",
  LINK_HEALTHCARE: "Health Care",
  LINK_OTHER: "Other",
  LINK_SALES: "Sales",
  // new one
  LINK_CHANGE_PASSWORD:"Change Password"

};
