import styles from "../index.module.scss";
export default {
  BASIC_BLOCKED: "You are blocked by this user or you blocked this user.",

  BASIC_STEP_1: "Steps to use Berenvriendjes",
  BASIC_STEP_2:
    "Register as an Employee or Freelancer person and create a profile.",
  BASIC_STEP_3:
    "Register as an Employee or Freelancer and discover immediately available services that match your expertise and preferences. We offera wide range of options so you can find the perfect job.",
  BASIC_STEP_4:
    "View all services in your location and sign up for the perfect job.",
  BASIC_STEP_5:
    "With our platform you have the opportunity to view all services in your immediate vicinity. Whether you are looking for a temporary job, a flexible assignment or a long-term work assignment, whether it concerns childcare, the healthcare sector or other branches, there is always a suitable work assignment for you.",
  BASIC_STEP_6: "Yes a match! Congratulations on your first work order",
  BASIC_STEP_7:
    "Once you have found a suitable match, you are ready to get started right away. This is an exciting moment and we congratulate you on reaching this milestone.We are confident that you will successfully complete your work assignment. Your commitment and skills will contribute to the success of the project and to the satisfaction of our client.",

  BASIC_STEP_1_Q:
    "Register as an employee or self-employed person and create a profile.",
  TEXT_ARE_YoU_SURE_DELETE_THIS_MESSAGE:
    "Deleting job will not affect ongoing job contract. If you want to cancel or complete job contract, please do it from Hired worker screen.",
  BASIC_UNMUTE_NOTIFICATION_CONFIRMATION:
    "Are you sure you want to unmute messages notification?",
  BASIC_STEP_1_A: (
    <>
      Register as an employee or self-employed person and discover immediately
      available positions services that match your expertise and preferences. We
      offer a wide range of options so you can get the perfect job assignment
      find.
      <br />
      <br />
      Tip: download the app to get a service anywhere!
    </>
  ),
  BASIC_STEP_2_Q:
    "View all services in your location and register for the perfect work assignment.",
  BASIC_STEP_2_A:
    "With our platform you have the opportunity to view all services in your immediate area. Whether you are looking for a temporary job, a flexible assignment or a long-term work assignment, whether it concerns childcare, the healthcare sector or other industries, there is always a suitable work assignment for you.",
  BASIC_STEP_3_Q: "Yes a match! Congratulations on your first work assignment",
  BASIC_STEP_3_A: (
    <>
      Once you have found a suitable match, you are ready to get started right
      away to get going. This is an exciting moment and we congratulate you on
      it achieving this milestone.
      <br />
      We are confident that you will be successful in your work assignment to
      accomplish. Your commitment and skills will contribute to success of the
      project and to the satisfaction of our client.
      <br />
      <br />
      Trust is the basis of everything we do! You are at Berenvriendjes you've
      come to the right place <br />
      For enthusiastic and well-instructed employees. <br />
      Employees who know how to tackle things in their own industry.
      <br />
      <br />
      How do you hire staff?
      <ol>
        <li>Register for free and create a profile</li>
        <li>Create a repeating, urgent or normal work order</li>
        <li>Choose your employee or freelancer you want to hire.</li>
        <li>Yes a Match. Your work assignment has been completed.</li>
      </ol>
    </>
  ),

  BASIC_URGENT_WARNING:
    "Job is accepted immediately by an employee without acceptance from the organization.",
  BASIC_SOON_WARNING:
    "Job is placed and must also be accepted by the employee and organization.",

  TEXT_CHANGE_DECIDED_RATE:
    "After changing the rate, the previous rate will be removed and the new rate will be applied to the future shifts.",
  TEXT_REPEAT_CONTRACT:
    "Select date to repeat the contract or you can make it repeating contract.",
  TEXT_HOME_HERO_DESCRIPTION: (
    <>
      Register for free as an employee or freelancer and start looking for a
      work assignment right away! <br />
      Register for free as an Organization and immediately find the perfect
      employee for your work assignment!
    </>
  ),
  TEXT_WITH_US_YOU_HAVE_THE_FREEDOME_MSG:
    "With us you have the freedom to determine where and when you work, both as an employee and as a freelancer. We offer options for both. You have the freedom to choose which work mode best suits your needs and preferences.",
  TEXT_REGISTER_AS_AN_EMPLOYEE_OR_FREELANCER:
    "Register as an Employee or Freelancer and discover immediately available services that match your expertise and preferences. We offer a wide range of options so you can find the perfect job.",
  TEXT_WITH_OUR_PLATFORM_YOU_HAVE_THE_OPPORTUNITY:
    "With our platform you have the opportunity to view all services in your immediate vicinity. Whether you are looking for a temporary job, a flexible assignment or a long-term work assignment, whether it concerns childcare, the healthcare sector or other branches, there is always a suitable work assignment for you.",
  TEXT_ONCE_YOU_HAVE_FOUND_A_SUITABLE_MATCH_MSG:
    "Once you have found a suitable match, you are ready to get started right away. This is an exciting moment and we congratulate you on reaching this milestone.We are confident that you will successfully complete your work assignment. Your commitment and skills will contribute to the success of the project and to the satisfaction of our client.",
  TEXT_REGISTER_FOR_FREE_CREATE_PROFILE_MSG:
    "Register for free and create a profile",
  TEXT_CREATE_A_REPEATING_URGENT_OR_NORMAL_MSG:
    "Create a repeating, urgent or normal work order",
  TEXT_CHOOSE_YOUR_EMPLOYEE_OR_FREELANCER_MSG:
    "Choose your employee or freelancer you want to hire.",
  TEXT_YES_A_MATCH_YOUR_WORK_ASSIGNEMNT_COMPLETED:
    "Yes a Match. Your work assignment has been completed.",
  TEXT_RATING: "rating",
  TEXT_AVERAGE_RATING: "average rating",
  TEXT_TOTAL_REVIEWS: "Totla Reviews",
  TEXT_EMPLOYEE_FREEDOM_MSG:
    "Empower your workforce by granting autonomy, enhancing job satisfaction, and boosting productivity. Explore the advantages of promoting ‘Employee Freedom’ in your organization.",
  TEXT_FREELANCER_FREEDOM_MSG:
    "Embrace the independence and flexibility of freelancing. Take control of your work, time, and life as you pursue your passions and projects on your terms.",
  TEXT_EMPLOYEE_FLEXIBILITY_MSG:
    "In a fast-paced world, flexibility is your competitive edge. Embrace change, pivot with ease, and seize new opportunities as they arise. Discover the strength of adaptability.",
  TEXT_FREELANCER_FLEXIBILITY_MSG:
    "Experience the freedom of freelance life. With the flexibility to choose your projects, set your own schedule, and work from anywhere, you’re in control of your professional journey. Discover the ultimate work-life balance",
  TEXT_NO_LONG_TERM_COMMITMENT_MSG:
    "The 'no strings attached' idea at Bearfriends means there are no long-term commitments for employees. In traditional employment agencies, employees can often be assigned to longer contracts with specific employers. At Berenvriendjes you determine where you put the emphasis for shorter jobs or long projects, so that you can experience more variation in your work.",
  TEXT_FOCUS_ON_SPECIFIC_SECTOR_MSG:
    "Achieve excellence by honing your expertise in chosen sectors. Specialization empowers you to provide tailored solutions, deepen client relationships, and stand out in competitive markets. Explore the benefits of industry focus and elevate your business to new heights.",
  TEXT_REGULATORY_EXPERTISE_MSG:
    "Navigating the complexities of regulations with precision. Gain a competitive edge through in-depth industry knowledge and compliance mastery.",
  TEXT_ATTENTION_TO_QUALITY_MSG:
    "Berenvriendjes focuses strongly on selecting qualified personnel with the right skills and experience for the specific needs of the childcare sector, care sector and other industries. This can lead to a higher quality of placed employees and better service to customers.",
  TEXT_ABOUT_US_DESCRIPTION_MSG:
    "Trust is the foundation of everything we do. This also applies to our employees and partners.We like to offer continuity and work on our relationships.",
  TEXT_CONTACT_US_DESCRIPTION_MSG:
    "Apply or do you have any questions? Please contact us via the options below!",
  TEXT_AVAILABLITY_MSG:
    "We are available from Monday to Friday during these hours:",
  TEXT_MONDAY: "Monday",
  TEXT_TUESDAY: "Tuesday",
  TEXT_WEDNESDAY: "Wednesday",
  TEXT_THURSDAY: "Thursday",
  TEXT_FRIDAY: "Friday",
  TEXT_WE_CAN_BE_REACHED_VIA_WHATSAPP_MSG:
    "* We can be reached via whatsapp during after hours and the weekend.",
  TEXT_ADDRESS: "Address",
  TEXT_NO_MESSAGE: "Empty Chats",
  TEXT_NO_CHAT_TITLE: "Currently, You have no chat to view.",
  TEXT_NO_TASK_TITLE: "Currently, You have no task to view.",
  TEXT_NO_CHAT_MESSAGES: (
    <>
      When you start communication on job,
      <br /> your message will be show here.
    </>
  ),
  TEXT_NO_TASKS: (
    <>
      When you start a job,
      <br /> your project tasks will be here.
    </>
  ),

  TEXT_EXPLORE_JOBS: "Explore Jobs",
  TEXT_EXPLORE_TALENT: "Explore Talent",
  TEXT_TELEPHONE: "Telephone",
  TEXT_EMAIL: "Email",
  TEXT_WHATSAPP: "Whatsapp",
  TEXT_HOURLY_WAGE: "Hourly Wage",
  TEXT_JOB_TITLE: "Job Title",
  TEXT_JOB_OFFER_CONFIRMATION: ({
    jobTitle,
    companyName,
  }: {
    jobTitle?: string;
    companyName?: string;
  }) => (
    <>
      Are you sure you want to accept the job offer for{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span>? This action is
      irreversible, and you will be officially joining the{" "}
      <span className={styles["jobTitle"]}>{companyName}</span> team.
      <br /> Click 'Confirm' to accept, or 'Cancel' to go back and reconsider.
    </>
  ),
  TEXT_JOB_OFFER_REJECTION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Are you sure you want to reject the job offer for{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span>? This action cannot
      be undone, and the offer will be declined.
      <br /> Click 'Confirm' to reject, or 'Cancel' to go back and reconsider.
    </>
  ),
  TEXT_JOB_OFFER_CANCELATION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Are you sure you want to cancel the job invitation for{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span>? This action will
      notify the invited person, and the invitation will be withdrawn.
      <br /> Click 'Confirm' to cancel, or 'Cancel' to go back and reconsider.
    </>
  ),

  TEXT_STAFF_MSG:
    "For our employees we look at your individual needs, what suits you best? In this way we invest in your personal growth and together we look at your work-life balance. We constantly evaluate and look with you at how we can improve our quality. Berenvriendjes works towards a long-term employment relationship.",
  TEXT_PARTNERS_MSG:
    "For our partners we are not a general recruitment and selection agency, temporary employment or secondment agency. That has its advantages. Due to our specialization in pedagogy and in other branches such as education, care, cleaning, retail and industry, we have a lot of know-how, experience and insight. This also means that our employees have the correct papers so that you do not have to worry.",
  TEXT_DO_NOT_HAVE_AN_ACCOUNT: "Do not have an account?",
  TEXT_REMEMBER_ME: "Remember me",
  TEXT_OR: "OR",
  TEXT_ONCE_YOUR_PROPOSAL_HAS_COUGHT_MSG:
    "Once your proposal has caught a client's attention, they may reach out to you through our messaging system.",
  TEXT_WELCOME_MSG:
    "We're thrilled to have you on board and thank you for choosing our platform to embark on your freelancing journey. Let's dive in!",
  TEXT_CREATE_YOUR_ACCOUNT_LIST:
    "Create Your Account: To begin, head over to our website and click on the 'Sign Up' button.",
  TEXT_SETUP_YOUR_PROFILE_LIST:
    "Set Up Your Profile: Once you've successfully created your account, it's time to set up your profile.",
  TEXT_EXPLORE_PROJECT_LIST:
    "Explore Project Listings: Our web app offers a vast range of projects across various categories. Browse through the project listings to find opportunities that match your skills and interests.",
  TEXT_SUBMIT_PROPOSALS_LIST:
    "Submit Proposals: When you come across a project that aligns with your expertise, craft a compelling proposal that showcases your abilities and explains how you can add value to the client's project.",
  TEXT_COMMUNICATE_WITH_CLIENTS_LIST:
    "Communicate with Clients: Once your proposal has caught a client's attention, they may reach out to you through our messaging system.",
  TEXT_SECURE_PAYMENT_SETUP_LIST:
    "Secure Payment Setup: To ensure a seamless payment process, set up your preferred payment method securely within your account settings. ",
  TEXT_WORK_ON_PROJECTS_LIST:
    "Work on Projects: Once you've secured a project and agreed on the terms with the client, it's time to get to work! ",
  TEXT_RECEIVE_FEEDBACK_AND_RATINGS_LIST:
    "Receive Feedback and Ratings: Client feedback and ratings play a crucial role in establishing your reputation as a freelancer.",
  TEXT_EXPLORE_ADDITIONAL_FEATURES_LIST:
    "Explore Additional Features: Beyond project listings, our web app offers additional features to enhance your freelancing experience. ",
  TEXT_STAY_ACTIVE_AND_ENGAGED_LIST:
    "Stay Active and Engaged: Consistency and proactivity are key to thriving in the freelancing world. ",
  TEXT_WE_ARE_THRILLED_TO_HAVE_YOU_ON_BOARD:
    "We're thrilled to have you on board and thank you for choosing our platform to embark on your freelancing journey. Let's dive in!",
  TEXT_PLEASE_ENTER_YOUR_DETAILS:
    "Please enter your details to setup new account.",
  TEXT_STEP: "Step",
  TEXT_OF: "of",
  TEXT_I_ACCEPT_THE_TERMS_AND_CONDITIONS: "I accept the terms and conditions.",
  TEXT_CHILD_DAY_CARE: "Child Day Care",
  TEXT_HEALTH_CARE: "Health Care",

  TEXT_OFFICES: "Offices",
  TEXT_LOGISTICS: "Logistics",
  TEXT_SALES: "Sales",
  TEXT_CALL_CENTER: "Call Center",
  TEXT_EDUCATION: "Education",
  TEXT_OTHERS: "Others",
  TEXT_IT: "IT",
  TEXT_CONSTRUCTION: "Construction",
  TEXT_HOSPITALITY: "Hospitality",
  TEXT_CLEANING: "Cleaning",

  TEXT_ID_MANDATORY: "ID (Mandatory)",
  TEXT_CERTIFICATES_OPTIONAL: "Certificate (Optional)",
  TEXT_DIPLOMA_OPTIONAL: "Diploma (Optional)",
  TEXT_INSURANCE_BLOCK_OPTIONAL: "Insurance block (Optional)",
  TEXT_SCAN_BY_CAMERA: "Scan by Camera",
  TEXT_THIS_ONBOARDING_GUILD_WILL_HELP_MSG:
    "This onboarding guide will help you get started and make the most of our web app.",
  TEXT_TERMS_AND_CONDITIONS: "You must agree to the terms and conditions",
  TEXT_PASSWORD_HINT:
    "The password must include at least one lowercase letter, one uppercase letter, one digit, one special character (e.g., !@#$%^&()-__+), and be a minimum of 8 characters long.",
  TEXT_PROVIDE_PERSNAL_DETAIL: "Provide personal information.",
  TEXT_PROVIDE_BUSINESS_DETAILS: "Provide business information.",
  TEXT_PROVIDE_BANK_DETAIL: "Provide bank information.",
  TEXT_SELECT_MAXIMUM_3_BRANCHES: "Select maximum 3 branches of your business.",
  TEXT_PROVIDE_DOCUMENTATION:
    "Provide documentation and front and back of your ID is mandatory.Size of files should be less than 2MB for each.",
  TEXT_PROVIDE_CONTACT_INFORMATION: "Provide contact information.",

  TEXT_LOCATION_TIP: "*person can only standard jobs nearby 30 km not more",

  TEXT_ADRESS_DETAIL: "Provide address information.",

  TEXT_DIDNT_RECEIVE: "Didn’t receive? ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK:
    "We have sent a verification link to this email ",
  TEXT_WE_HAVE_SENT_RESET_PASSWORD_LINK:
    "We have sent a forgot link to this email ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK_after:
    ", check your inbox and verify email to log in to your account.",
  TEXT_MARK_ALL_AS_READ: "Mark all as read",
  TEXT_SEE_ALL_NOTIFICATIONS: "See all notifications",
  TEXT_INCOMPLETE: "Incomplete",
  TEXT_POSTED_ON: "Posted On:",
  TEXT_PERSON: "Person",
  TEXT_AVATAR_REQUIREMENT: "Min 500 * 500px, .png or .jpeg",
  TEXT_HAVE_QUESTIONS_WE_ARE_HERE_TO_HELP:
    "Have questions? We are here to help",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT:
    "In the Availability Schedule, You can add his/her availability schedule. Please select the relevant date and set your availability that will show to the clients. Also you can add the task schedule here as well.",
  TEXT_INVOICE_TAGLINE:
    "Effortlessly generate invoices for completed tasks approved by organization and executed by workers.",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT_MOBILE:
    "In the Availability Schedule, You can add his/her availability schedule.",
  TEXT_STAY_ORGANIZED_ADD_YOUR_SCHEDULE:
    "Stay organized – add your schedule to keep track of important dates.",
  TEXT_DECLINE_JOB_NOTE:
    "Declining a job request will remove it from your shift of this jobs.",
  TEXT_REJECT_JOB_DONE_BY_WORKER:
    "Rejecting a job done by worker mean Worker has not completed the job, it will be removed from worker shift of this jobs.",
  TEXT_HANDLE_JOB_REQUEST: "Handle Job Request",
  TEXT_STAY_ORGANIZED_EDIT_YOUR_SCHEDULE:
    "Stay organized – Edit your schedule to keep track of important dates.",
  TEXT_HOURS_WORKED: "hours worked",
  TEXT_HOURS_AGO: "hours ago",
  TEXT_SEE_MORE: "See More...",
  TEXT_CANCEL_JOB: "Cancel Job",
  TEXT_DELETE_JOB: "Delete Job",
  TEXT_REPOST_JOB: "Repost Job",
  TEXT_NOTHING_HERE_YET: "Post a job to get started!",
  TEXT_FIND_JOB: "Find New Job!",
  TEXT_ASK_ORGANIZATION_TO_GENRATE_INVOICE:
    "Ask organization to genrate invoice",
  TEXT_SEE_ALL_APPLICATIONS: "See All Applications",

  TEXT_LAST_SEEN: "Last seen",
  TEXT_LAST_SEEN_JUST_NOW: "Last Seen Just Now",
  TEXT_MINUTE: "minute",
  TEXT_HOUR: "hour",
  TEXT_HOURS: "hours",
  TEXT_DAY: "day",
  TEXT_DAYS: "days",
  TEXT_AGO: "ago",
  TEXT_ONLINE: "Online",
  TEXT_TYPING: "Typing",
  TEXT_MESSAGES_LOADING: "Messages loading...",
  BASIC_HIRE_SOMEONE: "Hire someone for this branch",
  TEXT_STAY_ORGANIZED_MSG: "Stay organized msg",

  TEXT_HIRE_SOMEONE: "Hire someone!",

  TEXT_NO_JOBS: "Please try with different filters or check back later.",

  BASIC_BLOCK_CONFIRMATION: "Are you sure you want to block this user?",

  BASIC_MUTE_NOTIFICATION_CONFIRMATION:
    "Are you sure you want to mute messages notification?",

  BASIC_REPORT_CONFIRMATION: "Are you sure you want to report this user?",

  TEXT_SELECT_WORKER: "Select worker to check shifts of worker.",

  TEXT_SELECT_WORKER_MESSAGE: (
    <>
      Select worker to check shifts of worker.,
      <br /> Change filter to see different shifts.
    </>
  ),
  TEXT_WORKER_TASKS_EMPTY: "Currently, worker has no tasks.",

  TEXT_SELECT_WORKER_MESSAGE_EMPTY: (
    <>
      Worker have tasks.,
      <br /> you can try different dates filter
    </>
  ),

  BASIC_UNBLOCK_CONFIRMATION: "Are you sure you want to unblock this user?",

  TEXT_ID_BACK_MANDATORY: "ID Back(Mandatory)",
  BASIC_TODAY: "Today",

  BASIC_EMPTY_NOTIFICATION: "Meldingsvak is leeg.",
  TEXT_ATTENTION_TO_ORGANIZATION_MSG:
    "Berenvriendjes can take a personal approach and offer tailor-made solutions that meet the specific requirements of their customers in the childcare sector. This can lead to greater satisfaction for both employers and employees.",

  TEXT_WORKING_IN_CHILD_CARE: (
    <>
      Working in childcare Welcome to Berenvriendjes, where passion for the
      well-being and development of children goes hand in hand with finding the
      perfect match between talented people professionals and inspiring
      childcare centers. Understanding Bear Friends we believe that the right
      staff makes a difference in a child's growth, and That's why we are
      looking for enthusiastic individuals to make us dynamic team of sports
      coaches, pedagogical staff and teaching assistants to strengthen.
      <br />
      <br />
      As a temporary employment platform, we pride ourselves on bringing
      together qualified people professionals with child care centers who strive
      for excellence in their services. Whether you are an experienced sports
      coach who loves it encouraging a healthy lifestyle, an empathetic pedagogy
      employee who is driven to create a safe and stimulating environment
      create, or a teaching assistant who is committed to individual growth, at
      Bear Friends is there a place for you.
      <br />
      <br />
      We believe in the importance of personal and professional attention
      development. That's why we strive to not only meet the needs of childcare
      centers, but also the career goals of our support employees. Together we
      build a community in which both children and professionals can thrive and
      grow.
      <br />
      <br />
      Do you want to be part of our dedicated team and have a positive impact
      on... children's lives? Then join Berenvriendjes and discover the exciting
      possibilities waiting for you. Together we can find our way paving the way
      for flourishing professionals to guide them.
    </>
  ),
  TEXT_WORKING_IN_CHILD_CARE_ORG: (
    <div>
      <style>
        {`
        body { line-height:108%; font-family:Calibri; font-size:11pt }
        h3, p { margin:0pt 0pt 8pt }
        li { margin-top:0pt; margin-bottom:8pt }
        h3 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Calibri Light'; font-size:12pt; font-weight:normal; color:#1f4d78 }
        .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:11pt }
        .Kop3Char { font-family:'Calibri Light'; font-size:12pt; color:#1f4d78 }
      `}
      </style>
      <p>
        Berenvriendjes focuses entirely on compliance with laws and regulations,
        as monitored by the GGD. This means that the Certificate of Good Conduct
        and diplomas are manually checked by our HR department to ensure
        compliance with all regulations.
      </p>
      <p>
        Our process differs from other platforms because we send the connection
        requests from our HR department for the temporary workers deployed at
        your childcare organization.{" "}
      </p>
      <p>
        This guarantees that the process remains under control at
        Berenvriendjes, unlike other platforms where the responsibility lies
        with the self-employed person and the organization, which often leads to
        disputes.{" "}
      </p>
      <p>
        <strong>This makes Bear Friends UNIQUE!</strong>
      </p>
      <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li style={{ marginLeft: "31.35pt", paddingLeft: "4.65pt" }}>
          <strong>Vaste tarieven</strong>
          <ul
            style={{
              marginRight: "0pt",
              marginLeft: "0pt",
              paddingLeft: "0pt",
              listStyleType: "disc",
            }}
          >
            <li
              style={{
                marginLeft: "27.6pt",
                lineHeight: "108%",
                paddingLeft: "8.4pt",
                fontFamily: "serif",
                fontSize: "10pt",
              }}
            >
              <span
                style={{
                  lineHeight: "108%",
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                }}
              >
                Unlike other platforms, we do not charge an hourly fee from an
                organization, but we charge a fixed rate without additional
                costs.{" "}
              </span>
            </li>
          </ul>
        </li>
        <li style={{ marginLeft: "31.35pt", paddingLeft: "4.65pt" }}>
          <strong>Extensive service</strong>
        </li>
      </ol>
      <p>
        We offer the service and support of an employment agency as standard,
        which means:
      </p>
      <p>
        <strong>Persoonlijke planners</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            You determine the request together with our planners. You tell us
            how many employees you need and which diplomas/certificates they
            must have.{" "}
          </span>
        </li>
      </ul>
      <p>
        <strong>Flexible application options</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            Requests for services are scheduled within 24 hours of request.
            Requests can be submitted via our web app, WhatsApp, by telephone
            and by e-mail. We try to schedule the service as quickly as
            possible, depending on the means of communication that the
            organization finds most convenient.{" "}
          </span>
        </li>
      </ul>
      <p>
        <strong>24/7 Accessibility</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            For emergencies we are available 24/7 via WhatsApp!{" "}
          </span>
        </li>
      </ul>
      <p>
        <strong>Self-services</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            If you do not want to use the service, you can still make your
            service live via our platform, just like on other platforms, so that
            Berenvriendjes employees can fill in the shift.{" "}
          </span>
        </li>
      </ul>
      <p style={{ marginLeft: "72pt" }}>&#xa0;</p>
      <p>
        Berenvriendjes offers a reliable, efficient and user-friendly
        alternative to other similar platforms, with a strong focus on
        regulatory compliance and extensive support for childcare organizations.{" "}
      </p>
      <p>
        If you would like to receive more information about the method and
        process, do not hesitate to contact us.{" "}
      </p>
    </div>
  ),
};
