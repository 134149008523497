

type Props = {
  color?: string;
  size?: string | number;
  onClick?: any;
};
function LoadingTimerIcon({ color, size ="20" , onClick }: Props) {

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7002 1.66602H7.30019C4.16686 1.66602 3.92519 4.48268 5.61686 6.01602L14.3835 13.9827C16.0752 15.516 15.8335 18.3327 12.7002 18.3327H7.30019C4.16686 18.3327 3.92519 15.516 5.61686 13.9827L14.3835 6.01602C16.0752 4.48268 15.8335 1.66602 12.7002 1.66602Z"
        stroke="#101828"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default LoadingTimerIcon;

// fill={white ? "#fffff" : "#0F1322"}
