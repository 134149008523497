import styles from "../index.module.scss";
export default {
  BASIC_BLOCKED:
    "Je bent geblokkeerd door deze gebruiker of je hebt deze gebruiker geblokkeerd.",

  BASIC_STEP_1: "Steps to use Berenvriendjes",
  BASIC_STEP_2: "Meld je aan als werknemer of ZZP-er en creëer een profiel.",
  BASIC_STEP_3:
    "Meld u aan als werknemer of ZZP'er en ontdek direct beschikbare diensten die aansluiten bij uw expertise en voorkeuren. Wij bieden een breed scala aan mogelijkheden, zodat u de perfecte baan kunt vinden.",
  BASIC_STEP_4:
    "Bekijk alle diensten op jouw locatie en meld je aan voor de perfecte baan.",
  BASIC_STEP_5:
    "Bij ons platform heb je de mogelijkheid om alle diensten in jouw directe omgeving te bekijken. Of je nu op zoek bent naar een tijdelijke baan, een flexibele opdracht, Of het nu gaat om de kinderopvang, de zorgsector of andere branches, er is altijd een passende opdracht voor jou.",
  BASIC_STEP_6: "Yes een match! Gefeliciteerd jouw eerste dienst.",
  BASIC_STEP_7:
    "Zodra je een passende match hebt gevonden, ben je klaar om direct aan de slag te gaan. We hebben er alle vertrouwen in dat je je dienst succesvol zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het succes en aan de tevredenheid van onze opdrachtgever.",

  BASIC_STEP_1_Q: "Meld je aan als werknemer of ZZP-er en creëer een profiel.",
  BASIC_STEP_1_A: (
    <>
      Meld u aan als werknemer of ZZP'er en ontdek direct beschikbare diensten
      die aansluiten bij uw expertise en voorkeuren. Wij bieden een breed scala
      aan mogelijkheden, zodat u de perfecte dienst kunt vinden.
      <br />
      <br />
      Tip: download de app om overal waar je bent een dienst binnen te halen!
    </>
  ),
  BASIC_STEP_2_Q:
    "Bekijk alle diensten binnen jouw omgeving en meld je aan voor de perfecte dienst.",
  BASIC_STEP_2_A:
    "Bij ons platform heb je de mogelijkheid om alle diensten in jouw directe omgeving te bekijken. Of je nu op zoek bent naar een tijdelijke baan, een flexibele dienst of een langdurige opdracht, Of het nu gaat om de kinderopvang, de zorgsector of andere branches, er is altijd een passende opdracht voor jou.",
  BASIC_STEP_3_Q:
    "Yes een match! Gefeliciteerd jouw eerste dienst. Klaar om aan de slag te gaan?",
  BASIC_STEP_3_A: (
    <>
      Zodra je een passende match hebt gevonden, ben je klaar om direct aan de
      slag te gaan. We hebben er alle vertrouwen in dat je je dienst succesvol
      zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het
      succes en aan de tevredenheid van onze opdrachtgever.
      <br />
      <br />
      <br />
      <br />
      <br />
      <ol>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ol>
    </>
  ),

  BASIC_URGENT_WARNING:
    "dienst wordt per direct geaccepteerd door een medewerker zonder acceptatie van organisatie.",
  BASIC_SOON_WARNING:
    "dienst wordt onmiddellijk door een medewerker geaccepteerd zonder acceptatie van de organisatie.",
  TEXT_CHANGE_DECIDED_RATE:
    "Na wijziging van het tarief wordt het oude tarief verwijderd en wordt het nieuwe tarief toegepast op de toekomstige diensten.",
  TEXT_REPEAT_CONTRACT:
    "Selecteer de datum waarop u het contract wilt herhalen of u kunt er een herhalend contract van maken.",
  TEXT_HOME_HERO_DESCRIPTION: (
    <>
      Schrijf je gratis in als werknemer of ZZP-er en zoek direct een
      werkopdracht! <br /> Schrijf je gratis in als Organisatie en vindt direct
      de perfecte medewerker voor jouw werkopdracht!
    </>
  ),
  TEXT_WITH_US_YOU_HAVE_THE_FREEDOME_MSG:
    "Bij ons heb je de vrijheid om zelf te bepalen waar en wanneer je werkt, zowel in dienstverband als freelancer. Wij bieden mogelijkheden voor beide. Je hebt de vrijheid om te kiezen welke werkmodus het beste bij jouw behoeften en voorkeuren past.",
  TEXT_REGISTER_AS_AN_EMPLOYEE_OR_FREELANCER:
    "Schrijf u in als werknemer of ZZP'er en ontdek direct beschikbare diensten die aansluiten bij uw expertise en voorkeuren. Wij bieden een breed scala aan mogelijkheden, zodat u de perfecte werkopdracht kunt vinden.",
  TEXT_WITH_OUR_PLATFORM_YOU_HAVE_THE_OPPORTUNITY:
    "Bij ons platform heb je de mogelijkheid om alle diensten in jouw directe omgeving te bekijken. Of je nu op zoek bent naar een tijdelijke baan, een flexibele opdracht of een langdurige werkopdracht, Of het nu gaat om de kinderopvang, de zorgsector of andere branches, er is altijd een passende werkopdracht voor jou.",
  TEXT_ONCE_YOU_HAVE_FOUND_A_SUITABLE_MATCH_MSG:
    "Zodra je een passende match hebt gevonden, ben je klaar om direct aan de slag te gaan. We hebben er alle vertrouwen in dat je je dienst succesvol zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het succes en aan de tevredenheid van onze opdrachtgever. We hebben er alle vertrouwen in dat je je werkopdracht succesvol zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het succes van het project en aan de tevredenheid van onze opdrachtgever.",
  TEXT_REGISTER_FOR_FREE_CREATE_PROFILE_MSG:
    "Schrijf je gratis in en creëer een profiel",
  TEXT_CREATE_A_REPEATING_URGENT_OR_NORMAL_MSG:
    "Maak een herhalende, spoed of normale dienst aan",
  TEXT_CHOOSE_YOUR_EMPLOYEE_OR_FREELANCER_MSG:
    "Berenvriendjes zoekt naar de juiste gekwalificeerde medewerker",
  TEXT_YES_A_MATCH_YOUR_WORK_ASSIGNEMNT_COMPLETED:
    "Yes een Match. Je dienst is vervuld",
  TEXT_RATING: "beoordeling",
  TEXT_AVERAGE_RATING: "gemiddelde score",
  TEXT_TOTAL_REVIEWS: "Total Reviews",
  TEXT_EMPLOYEE_FREEDOM_MSG:
    "Geef uw personeel meer mogelijkheden door autonomie te verlenen, de werktevredenheid te vergroten en de productiviteit te verhogen. Ontdek de voordelen van het bevorderen van ‘Employee Freedom’ in uw organisatie.",
  TEXT_FREELANCER_FREEDOM_MSG:
    "Omarm de onafhankelijkheid en flexibiliteit van freelancen. Neem de controle over uw werk, tijd en leven terwijl u uw passies en projecten nastreeft op uw voorwaarden.,",
  TEXT_EMPLOYEE_FLEXIBILITY_MSG:
    "In een snel veranderende wereld is flexibiliteit uw concurrentievoordeel. Omarm veranderingen, draai met gemak en grijp nieuwe kansen wanneer ze zich voordoen. Ontdek de kracht van aanpassingsvermogen.,",
  TEXT_FREELANCER_FLEXIBILITY_MSG:
    "Bij Berenvriendjes is het zo dat werknemers meer vrijheid hebben om hun eigen klussen te kiezen. Hierdoor heb jij  meer controle over je eigen werkrooster en de projecten waar jij aan zou willen werken.",
  TEXT_NO_LONG_TERM_COMMITMENT_MSG:
    "Het idee dat je 'nergens aan vastzit' bij Berenvriendjes betekent dat er geen langdurige verplichtingen zijn voor werknemers. In traditionele uitzendbureaus kunnen werknemers vaak worden ingezet voor langere contracten bij specifieke werkgevers. Bij Berenvriendjes bepaal waar jij de nadruk opzet voor kortere klussen of lange projecten, waardoor jij meer variatie in jouw werk kan ervaren.",
  TEXT_FOCUS_ON_SPECIFIC_SECTOR_MSG:
    "Door zich te specialiseren in specifieke sectoren zoals de kinderopvang zorg schoonmaak etc, beschikt Berenvriendjes over veel knowhow, ervaring en inzicht in de behoeften en uitdagingen binnen die branches. Hierdoor kunnen zij beter begrijpen wat er vereist is voor een succesvolle match tussen werkgevers en werknemers",
  TEXT_REGULATORY_EXPERTISE_MSG:
    "Berenvriendjes is goed op de hoogte van de specifieke regelgeving en vereisten in de kinderopvangsector en zorgsector waar de standaarden hoog zijn, zoals certificeringseisen, VOG, diploma’s, PRK en koppelingen bij organisaties voor pedagogisch medewerkers. Dit stelt ons in staat om aan de wettelijke verplichtingen te voldoen en hun klanten te adviseren over naleving van de regelgeving..",
  TEXT_ATTENTION_TO_QUALITY_MSG:
    "Berenvriendjes richt sterk op het selecteren van gekwalificeerd personeel met de juiste vaardigheden en ervaring voor de specifieke behoeften van de kinderopvangsector, zorgsector en overige branches. Dit kan leiden tot een hogere kwaliteit van geplaatste medewerkers en betere dienstverlening aan klanten.",
  TEXT_ABOUT_US_DESCRIPTION_MSG:
    "“Vertrouwen is de basis van alles wat we doen. Dat geldt ook voor onze medewerkers en partners. Wij bieden graag continuïteit en werken aan onze relaties.”,",
  TEXT_CONTACT_US_DESCRIPTION_MSG:
    "Solliciteren of heb je nog vragen? Neem dan contact op via de onderstaande mogelijkheden!",
  TEXT_AVAILABLITY_MSG:
    " “Wij zijn bereikbaar van maandag tot en met vrijdag tijdens deze uren:”,",
  TEXT_MONDAY: "Maandag",
  TEXT_TUESDAY: "Dinsdag",
  TEXT_WEDNESDAY: "Woensdag",
  TEXT_THURSDAY: "Donderdag",
  TEXT_FRIDAY: "Vrijdag",
  TEXT_WE_CAN_BE_REACHED_VIA_WHATSAPP_MSG:
    "*Wij zijn buiten kantooruren en in het weekend bereikbaar via whatsapp.,",
  TEXT_ADDRESS: "Adres",
  TEXT_NO_MESSAGE: "Nog geen chat",

  TEXT_NO_CHAT_TITLE: "Momenteel heb je geen chat om te bekijken.",
  TEXT_NO_TASK_TITLE: "Momenteel heeft u geen taak om te bekijken.",

  TEXT_NO_CHAT_MESSAGES: (
    <>
      Wanneer u begint met communiceren op uw werk,
      <br /> berichten van uw organisatie worden hier weergegeven.
    </>
  ),
  TEXT_NO_TASKS: (
    <>
      Wanneer u met een baan begint,
      <br /> uw diensten zullen hier staan.
    </>
  ),
  TEXT_EXPLORE_JOBS: "Ontdek diensten",
  TEXT_EXPLORE_TALENT: "Ontdek talenten",

  TEXT_TELEPHONE: "Telefoon",
  TEXT_EMAIL: "E-mail",
  TEXT_WHATSAPP: "Whatsapp",
  TEXT_HOURLY_WAGE: "Uurloon",
  TEXT_JOB_TITLE: "Functie",
  TEXT_STAFF_MSG:
    "Bij Berenvriendjes richten we ons op jouw individuele behoeften en streven we ernaar maatwerkoplossingen te bieden die bij jou passen. We investeren in jouw persoonlijke groei door middel van trainingen en ontwikkelingsmogelijkheden, zodat jij je talenten optimaal kunt benutten. Daarnaast ondersteunen we je bij het vinden van een gezonde werk-privébalans, zodat je zowel op het werk als thuis goed functioneert. We evalueren regelmatig onze processen en werkwijzen en kijken samen naar mogelijke verbeterpunten. Jouw feedback is hierbij van groot belang, omdat we geloven in een cultuur van open communicatie en voortdurende verbetering. Door samen te werken aan kwaliteitsverbetering, streven we ernaar de best mogelijke werkomgeving te creëren. Ons doel is om een langdurige arbeidsrelatie op te bouwen waarin jij je thuis voelt en je kunt ontwikkelen. We willen datje met plezier en voldoening bij ons werkt, zowel nu als in de toekomst. Bij Berenvriendjes staat jouw welzijn en professionele groei centraal, en we werken er hard aan om dit te realiseren.",
  TEXT_PARTNERS_MSG:
    "Voor onze partners zijn wij geen uitzend- of detacheringsbureau. Dit biedt aanzienlijke voordelen. Dankzij onze specialisatie in pedagogiek en sectoren zoals onderwijs, zorg, schoonmaak, retail en industrie, hebben wij uitgebreide kennis, ervaring en inzicht. Onze gerichte aanpak stelt ons in staat om de specifieke behoeften en uitdagingen van deze branches effectief te matchen. Hierdoor kunnen wij snel en efficiënt de juiste kandidaten vinden die perfect passen bij de eisen en cultuur van uw organisatie. Bovendien beschikken onze medewerkers over de juiste kwalificaties en certificeringen. Dit betekent dat u zich geen zorgen hoeft te maken over hun bekwaamheid of geschiktheid. Wij zorgen ervoor dat u altijd kunt vertrouwen op competente en gekwalificeerde professionals, zodat u zich kunt concentreren op uw kernactiviteiten.",
  TEXT_DO_NOT_HAVE_AN_ACCOUNT: "Heb je geen account?",
  TEXT_REMEMBER_ME: "Onthoud me",
  TEXT_OR: "OF",
  TEXT_ONCE_YOUR_PROPOSAL_HAS_COUGHT_MSG:
    "Zodra uw voorstel de aandacht van een klant heeft getrokken, kunnen zij contact met u opnemen via ons berichtensysteem.,",
  TEXT_WELCOME_MSG:
    "We zijn blij je aan boord te hebben en bedanken je dat je ons platform hebt gekozen om aan je reis te beginnen. Laten we erin duiken!,",
  TEXT_CREATE_YOUR_ACCOUNT_LIST:
    "Maak uw account aan: ga om te beginnen naar onze website en klik op de knop 'Aanmelden'.,",
  TEXT_SETUP_YOUR_PROFILE_LIST:
    " 'Stel uw profiel in: zodra u uw account succesvol heeft aangemaakt, is het tijd om uw profiel in te stellen.',",
  TEXT_EXPLORE_PROJECT_LIST:
    "Verken projectoverzichten: onze webapp biedt een breed scala aan projecten in verschillende categorieën. Blader door het projectoverzicht om mogelijkheden te vinden die aansluiten bij uw vaardigheden en interesses.,",
  TEXT_SUBMIT_PROPOSALS_LIST:
    "'Dien voorstellen in: Wanneer u een dienst tegenkomt dat aansluit bij uw expertise, maak dan een overtuigend voorstel dat uw capaciteiten laat zien en uitlegt hoe u waarde kunt toevoegen aan het project van de klant.',",
  TEXT_COMMUNICATE_WITH_CLIENTS_LIST:
    "'Communiceer met klanten: zodra uw voorstel de aandacht van een klant heeft getrokken, kunnen zij contact met u opnemen via ons berichtensysteem.',",
  TEXT_SECURE_PAYMENT_SETUP_LIST:
    "Veilige betalingsinstellingen: Om een ​​naadloos betalingsproces te garanderen, stelt u de betaalmethode van uw voorkeur veilig in uw accountinstellingen in. ,",
  TEXT_WORK_ON_PROJECTS_LIST:
    "Werk aan de winkel: Zodra je een dienst hebt binnengehaald en de voorwaarden met de klant hebt afgesproken, is het tijd om aan de slag te gaan!,",
  TEXT_RECEIVE_FEEDBACK_AND_RATINGS_LIST:
    "Ontvang feedback en beoordelingen: feedback en beoordelingen van klanten spelen een cruciale rol bij het vestigen van uw reputatie als Berenvriendjes medewerker.,",
  TEXT_EXPLORE_ADDITIONAL_FEATURES_LIST:
    "Ontdek extra functies: naast projectoverzichten biedt onze webapp extra functies om uw freelance-ervaring te verbeteren. ,",
  TEXT_STAY_ACTIVE_AND_ENGAGED_LIST:
    " 'Blijf actief en betrokken: consistentie en proactiviteit zijn de sleutel tot succes.',",
  TEXT_WE_ARE_THRILLED_TO_HAVE_YOU_ON_BOARD:
    "We zijn blij je aan boord te hebben en bedanken je dat je ons platform hebt gekozen om aan je reis te beginnen. Laten we erin duiken!,",
  TEXT_PLEASE_ENTER_YOUR_DETAILS:
    "Vul uw gegevens in om een ​​nieuw account in te stellen.",
  TEXT_STEP: "Stap",
  TEXT_OF: "van",
  TEXT_I_ACCEPT_THE_TERMS_AND_CONDITIONS:
    "Ik accepteer de algemene voorwaarden.",
  TEXT_CHILD_DAY_CARE: "Kinderopvang",
  TEXT_HEALTH_CARE: "Gezondheidszorg",
  TEXT_OFFICES: "Kantoor",
  TEXT_IT: "IT",
  TEXT_CONSTRUCTION: "Bouw",
  TEXT_HOSPITALITY: "Hospitality",
  TEXT_CLEANING: "Schoonmaak",
  TEXT_LOGISTICS: "Logistiek",
  TEXT_SALES: "Verkoop",
  TEXT_CALL_CENTER: "Callcenter",
  TEXT_EDUCATION: "Onderwijs",
  TEXT_OTHERS: "Overig",
  TEXT_ID_MANDATORY: "ID (verplicht)",
  TEXT_CERTIFICATES_OPTIONAL: "Certificaat (optioneel)",
  TEXT_DIPLOMA_OPTIONAL: "Diploma (optioneel)",
  TEXT_INSURANCE_BLOCK_OPTIONAL: "Bedrijfsaansprakelijkheidsverzekering",
  TEXT_SCAN_BY_CAMERA: "Scannen met camera",
  TEXT_THIS_ONBOARDING_GUILD_WILL_HELP_MSG:
    "Deze handleiding helpt u om te beginnen en het maximale uit onze webapplicatie te halen",
  TEXT_DIDNT_RECEIVE: "Niet ontvangen? ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK:
    "We hebben een verificatielink naar dit e-mailadres gestuurd.",
  TEXT_WE_HAVE_SENT_RESET_PASSWORD_LINK:
    "We have sent a forgot link to this email ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK_after:
    "controleer uw inbox en verifieer uw e-mailadres om in te loggen op uw account.",
  TEXT_MARK_ALL_AS_READ: "Alles als gelezen markeren",
  TEXT_SEE_ALL_NOTIFICATIONS: "Bekijk alle meldingen",
  TEXT_INCOMPLETE: "Incompleet",
  TEXT_POSTED_ON: "Geplaatst op:",
  TEXT_PERSON: "Persoon",
  TEXT_AVATAR_REQUIREMENT: "Minimaal 500 * 500px, .png of .jpeg",
  TEXT_HAVE_QUESTIONS_WE_ARE_HERE_TO_HELP:
    "Heeft u vragen? Wij zijn er om u te helpen",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT:
    "In het Beschikbaarheids Rooster kan jij je beschikbare tijden toevoegen. Selecteer de relevante datum en stel uw beschikbaarheid in wanneer jij kan werken of niet kan werken.",
  TEXT_INVOICE_TAGLINE:
    "Genereer moeiteloos facturen voor voltooide taken die door de organisatie zijn goedgekeurd en door werknemers zijn uitgevoerd.",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT_MOBILE:
    "In het Beschikbaarheids Rooster kan jij je beschikbare tijden toevoegen. ",
  TEXT_STAY_ORGANIZED_ADD_YOUR_SCHEDULE:
    "Blijf georganiseerd – voeg uw planning toe om belangrijke data bij te houden.",
  TEXT_DECLINE_JOB_NOTE:
    "Als u een aanvraag afwijst, wordt deze dienst verwijderd.",
  TEXT_REJECT_JOB_DONE_BY_WORKER:
    "Het afwijzen van een dienst die door de werknemer is uitgevoerd, betekent dat de werknemer de dienst niet heeft voltooid. Deze wordt verwijderd.",
  TEXT_HANDLE_JOB_REQUEST: "Dienst aanvraag afhandelen",
  TEXT_STAY_ORGANIZED_EDIT_YOUR_SCHEDULE:
    "Blijf georganiseerd – bewerk uw planning om belangrijke data bij te houden.",
  TEXT_HOURS_WORKED: "Gewerkte uren",
  TEXT_HOURS_AGO: "Uren geleden",
  TEXT_SEE_MORE: "Bekijk meer...",
  TEXT_CANCEL_JOB: "Dienst annuleren",
  TEXT_DELETE_JOB: "Dienst verwijderen",
  TEXT_REPOST_JOB: "Dienst annuleren",
  TEXT_NOTHING_HERE_YET: "Hier is nog niets!",
  TEXT_FIND_JOB: "Vind een nieuwe dienst!",
  TEXT_ASK_ORGANIZATION_TO_GENRATE_INVOICE:
    "Vraag de organisatie om factuur te genereren",
  TEXT_SEE_ALL_APPLICATIONS: "See All Applications",

  TEXT_LAST_SEEN: "Laatst gezien",
  TEXT_LAST_SEEN_JUST_NOW: "Laatst gezien zojuist",
  TEXT_MINUTE: "minuut",
  TEXT_HOUR: "uur",
  TEXT_HOURS: "uren",
  TEXT_DAY: "dag",
  TEXT_DAYS: "dagen",
  TEXT_AGO: "geleden",
  TEXT_ONLINE: "Online",
  TEXT_TYPING: "Aan het typen",
  TEXT_TERMS_AND_CONDITIONS: "Je moet akkoord gaan met de algemene voorwaarden",
  TEXT_PASSWORD_HINT:
    "Het wachtwoord moet minimaal één kleine letter, één hoofdletter, één cijfer, één speciaal teken (bijv. !@#$%^&()-__+), en minimaal 8 tekens lang zijn.",
  TEXT_PROVIDE_PERSNAL_DETAIL: "Vul persoonlijke gegevens in.",
  TEXT_ADRESS_DETAIL: "Vul adresgegevens in.",

  TEXT_PROVIDE_BUSINESS_DETAILS: "Vul bedrijfsgegevens in.",
  TEXT_PROVIDE_BANK_DETAIL: "Vul bankgegevens in.",
  TEXT_SELECT_MAXIMUM_3_BRANCHES:
    "Selecteer maximaal 3 branches van uw bedrijf.",
  TEXT_PROVIDE_DOCUMENTATION:
    "Vul documentatie in en voor- en achterkant van uw ID is verplicht. De bestandsgrootte moet kleiner zijn dan 2 MB voor elk.",
  TEXT_PROVIDE_CONTACT_INFORMATION: "Vul contactgegevens in.",

  TEXT_LOCATION_TIP:
    "*persoon kan alleen diensten in de buurt van 30 km zien en niet meer",

  TEXT_MESSAGES_LOADING: "Berichten worden geladen...",

  BASIC_HIRE_SOMEONE: "Medewerker inhuren",
  TEXT_JOB_OFFER_REJECTION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Weet u zeker dat u het aanbod voor de functie van{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span> wilt afwijzen? Deze
      actie kan niet ongedaan worden gemaakt, en het aanbod zal worden
      afgewezen. Klik op 'Bevestigen' om af te wijzen, of 'Annuleren' om terug
      te gaan en te heroverwegen.
    </>
  ),
  TEXT_JOB_OFFER_CANCELATION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Weet u zeker dat u de uitnodiging voor de functie van{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span> wilt annuleren?
      Deze actie zal de genodigde op de hoogte stellen, en de uitnodiging zal
      worden ingetrokken. Klik op 'Bevestigen' om te annuleren, of 'Annuleren'
      om terug te gaan en te heroverwegen.
    </>
  ),
  TEXT_JOB_OFFER_CONFIRMATION: ({
    jobTitle,
    companyName,
  }: {
    jobTitle?: string;
    companyName?: string;
  }) => (
    <>
      Weet u zeker dat u het aanbod voor de functie van{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span> wilt accepteren?
      Deze actie is onomkeerbaar, en u zult officieel deel uitmaken van het team
      van <span className={styles["jobTitle"]}>{companyName}</span>. Klik op
      'Bevestigen' om te accepteren, of 'Annuleren' om terug te gaan en te
      heroverwegen.
    </>
  ),
  TEXT_STAY_ORGANIZED_MSG: "Blijf georganiseerd bericht",
  TEXT_HIRE_SOMEONE: "Medewerker inhuren!",
  TEXT_NO_JOBS: "Probeer het met andere filters of kom later nog eens terug.",

  BASIC_BLOCK_CONFIRMATION: "Weet u zeker dat u deze gebruiker wilt blokkeren?",
  BASIC_MUTE_NOTIFICATION_CONFIRMATION:
    "Weet u zeker dat u de meldingen van berichten wilt dempen?",
  BASIC_REPORT_CONFIRMATION:
    "Weet u zeker dat u deze gebruiker wilt rapporteren?",

  TEXT_SELECT_WORKER:
    "Selecteer werknemer om de diensten van de medewerker te controleren.",

  TEXT_SELECT_WORKER_MESSAGE: (
    <>
      Selecteer werknemer om de ploegendiensten van de werknemer te
      controleren.,
      <br /> Wijzig het filter om verschillende diensten te zien.
    </>
  ),

  TEXT_WORKER_TASKS_EMPTY: "Momenteel heeft de werknemer geen diensten.",

  TEXT_SELECT_WORKER_MESSAGE_EMPTY: (
    <>
      Werknemer heeft diensten,
      <br /> je kunt verschillende datum filters proberen
    </>
  ),

  TEXT_ARE_YoU_SURE_DELETE_THIS_MESSAGE:
    "Het verwijderen van een dienst heeft geen invloed op het lopende arbeidscontract. Als u het arbeidscontract wilt annuleren of voltooien, kunt u dit doen via het scherm Ingehuurde werknemer.",

  BASIC_UNBLOCK_CONFIRMATION:
    "Weet u zeker dat u deze gebruiker wilt deblokkeren?",

  BASIC_UNMUTE_NOTIFICATION_CONFIRMATION:
    "Weet je zeker dat je de melding van berichten wilt opheffen?",

  BASIC_TODAY: "Vandaag",

  TEXT_ID_BACK_MANDATORY: "ID achterkant(verplicht)",
  BASIC_EMPTY_NOTIFICATION: "Notification box is empty.",
  TEXT_ATTENTION_TO_ORGANIZATION_MSG:
    "Berenvriendjes kan een persoonlijke aanpak hanteren en maatwerkoplossingen bieden die aansluiten bij de specifieke eisen van hun klanten in de kinderopvangsector. Dit kan leiden tot meer tevredenheid bij zowel werkgevers als werknemers.",

  TEXT_WORKING_IN_CHILD_CARE: (
    <>
      Werken in de kinderopvang Welkom bij Berenvriendjes, waar passie voor het
      welzijn en de ontwikkeling van kinderen samengaat met het vinden van de
      perfecte match tussen getalenteerde professionals en inspirerende
      kinderopvangcentra. Bij Berenvriendjes begrijpen we dat het juiste
      personeel het verschil maakt in de groei van een kind, en daarom zijn we
      op zoek naar een enthousiaste individuen om ons dynamisch team van
      sportbegeleiders, pedagogisch medewerkers en onderwijsassistenten te
      versterken.
      <br />
      <br />
      Als uitzendplatform zijn we trots op het samenbrengen van gekwalificeerde
      professionals met kinderopvangcentra die streven naar excellentie in hun
      dienstverlening. Of je nu een ervaren sportbegeleider bent die dol is op
      het aanmoedigen van een gezonde levensstijl, een empathische pedagogisch
      medewerker die gedreven is om een veilige en stimulerende omgeving te
      creëren, of een onderwijsassistent die zich inzet voor individuele groei,
      bij Berenvriendjes is er een plek voor jou.
      <br />
      <br />
      Wij geloven in het belang van persoonlijke aandacht en professionele
      ontwikkeling. Daarom streven we ernaar om niet alleen de behoeften van de
      kinderopvangcentra te vervullen, maar ook de carrièredoelen van onze
      medewerkers te ondersteunen. Samen bouwen we aan een gemeenschap waarin
      zowel kinderen als professionals kunnen gedijen en groeien.
      <br />
      <br />
      Wil jij deel uitmaken van ons toegewijde team en een positieve impact
      hebben op het leven van kinderen? Sluit je dan aan bij Berenvriendjes en
      ontdek de opwindende mogelijkheden die op jou wachten. Samen kunnen we de
      weg effenen voor een bloeiende professionals die hen begeleiden.
    </>
  ),
  TEXT_WORKING_IN_CHILD_CARE_ORG: (
    <div>
      <style>
        {`
          body { line-height:108%; font-family:Calibri; font-size:11pt }
          h3, p { margin:0pt 0pt 8pt }
          li { margin-top:0pt; margin-bottom:8pt }
          h3 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Calibri Light'; font-size:12pt; font-weight:normal; color:#1f4d78 }
          .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:11pt }
          .Kop3Char { font-family:'Calibri Light'; font-size:12pt; color:#1f4d78 }
        `}
      </style>
    
      <p>
        Berenvriendjes richt zich volledig op de naleving van wet- en
        regelgeving, zoals gecontroleerd wordt door de GGD. Dit houdt in dat de
        VOG en diploma’s handmatig door onze HR-afdeling worden gecontroleerd om
        te voldoen aan alle voorschriften.
      </p>
      <p>
        Ons proces onderscheidt zich van andere platforms doordat wij de
        koppelverzoeken vanuit onze HR-afdeling versturen voor de
        uitzendkrachten die bij jullie kinderopvangorganisatie worden ingezet.
      </p>
      <p>
        Dit garandeert dat het proces onder controle blijft bij Berenvriendjes,
        in tegenstelling tot andere platforms waar de verantwoordelijkheid bij
        de ZZP'er en de organisatie ligt, wat vaak tot geschillen leidt.
      </p>
      <p>
        <strong>Dit maakt Berenvriendjes UNIEK! </strong>
      </p>
      <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li style={{ marginLeft: "31.35pt", paddingLeft: "4.65pt" }}>
          <strong>Vaste tarieven</strong>
          <ul
            style={{
              marginRight: "0pt",
              marginLeft: "0pt",
              paddingLeft: "0pt",
              listStyleType: "disc",
            }}
          >
            <li
              style={{
                marginLeft: "27.6pt",
                lineHeight: "108%",
                paddingLeft: "8.4pt",
                fontFamily: "serif",
                fontSize: "10pt",
              }}
            >
              <span
                style={{
                  lineHeight: "108%",
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                }}
              >
                In tegenstelling tot andere platforms, vragen wij geen fee per
                uur bij een organisatie maar hanteren wij een vast tarief zonder
                bijkomende kosten.
              </span>
            </li>
          </ul>
        </li>
        <li style={{ marginLeft: "31.35pt", paddingLeft: "4.65pt" }}>
          <strong>Uitgebreide service</strong>
        </li>
      </ol>
      <p>
        Wij bieden standaard de service en ondersteuning aan van een
        uitzendbureau, wat inhoudt:
      </p>
      <p>
        <strong>Persoonlijke planners</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            Samen met onze planners bepaal je de uitvraag. Je vertelt ons
            hoeveel medewerkers je nodig hebt en welke diploma’s/certificaten ze
            moeten bezitten.
          </span>
        </li>
      </ul>
      <p>
        <strong>Flexibele aanvraagmogelijkheden</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            De aanvragen van diensten worden binnen 24 uur na aanvraag
            ingepland. De aanvragen kunnen via onze webapp, WhatsApp,
            telefonisch en per e-mail worden ingediend. We proberen de dienst zo
            snel mogelijk in te plannen, afhankelijk van het communicatiemiddel
            dat de organisatie het handigst vindt.
          </span>
        </li>
      </ul>
      <p>
        <strong>24/7 Bereikbaarheid</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            Voor spoedgevallen zijn we 24/7 bereikbaar via WhatsApp!
          </span>
        </li>
      </ul>
      <p>
        <strong>Zelfdiensten</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            Indien je geen gebruik wilt maken van de service, kun je nog steeds
            zoals op andere platformen jouw dienst zelf live zetten via ons
            platform, waardoor werknemers van Berenvriendjes de shift kunnen
            invullen.
          </span>
        </li>
      </ul>
      <p style={{ marginLeft: "72pt" }}>&#xa0;</p>
      <p>
        Berenvriendjes biedt hiermee een betrouwbaar, efficiënt en
        gebruiksvriendelijk alternatief voor andere soortgelijke platforms, met
        een sterke focus op naleving van regelgeving en uitgebreide
        ondersteuning voor kinderopvangorganisaties.
      </p>
      <p>
        Mocht u meer informatie willen ontvangen over de werkwijze en proces,
        aarzel niet om contact met ons op te nemen.
      </p>
    </div>
  ),
};
