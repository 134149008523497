import React from "react";
import { colorBlack, colorGray, colorLabel } from "../../../styles/colors";
import { Text } from "../../atoms";
import Select from "../Select/Select";
import type { selectGroupProps } from "./@types";
import styles from "./SelectGroup.module.scss";
import { Controller } from "react-hook-form";

const SelectGroup = ({
  label,
  error,
  weight,
  list,
  name,
  validation,
  control,
  branchId,
}: selectGroupProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <div className={styles["input-group"]}>
          <div className={styles.label}>
            <Text
              textStyle={{
                color: colorLabel,
                fontWeight: "600",
                // fontFamily: "Montserrat-Bold",
              }}
              className={styles["input-group-text"]}
              weight={weight}
            >
              {label}
            </Text>
          </div>

          <Select
            name={name}
            branchId={branchId}
            validation={validation}
            list={list}
            value={value}
            onChange={onChange}
            // register={register}
          />
          {error && <Text color="alert">{error.message || ""}</Text>}
        </div>
      )}
    />
  );
};

export default SelectGroup;
