import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { INVITE_FORM } from "types/forms";
import { useLocale } from "locales";

export const useInviteWorkerModal = (defaulValues?: INVITE_FORM) => {
  const { localeErrors, } = useLocale();


  const validationSchema: any = yup.object().shape({
    // content: yup.string().required(),
    // perHourRate: yup.number().required(localeErrors.ERROR_WAGE_REQUIRED),
  });

  const { handleSubmit, control ,watch} = useForm<INVITE_FORM>({
    defaultValues: defaulValues,
    resolver: yupResolver(validationSchema) as any,
  });



  return {
    control,
    handleSubmit,
    watch 
  };
};
