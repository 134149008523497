import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { UsersContextConsumer } from "store/AuthProvider";
import {
  getOrganizationWorkers,
  getWorkerTasks,
} from "utils/globalAPI/OrganizationSchedule";

import { useWidth } from "utils/responsiveHook";

export const useOrganizationSchedule = () => {
  const [activeCategory, setActiveCategory] = useState<string[]>(["all"]);

  const { accessToken, fetchApi } = UsersContextConsumer();

  const navigate = useNavigate();

  const { control, watch, setValue } = useForm({
    defaultValues: {
      workerType: "both",
      date: new Date(),
      view: "day",
    },
  });

  const params = useParams();

  const organization = params["organization"];
  const hiredWorkerId = params["hiredWorkerId"];

  const {
    data: { tasksList  } = {
        data: {
          tasksList: [],
        },
    },
    isLoading: tasksLoading,

    refetch: refetchTasks,
  } = useQuery(
    ["workerTaskList", { hiredWorkerId }],
    () => getWorkerTasks(hiredWorkerId, accessToken),
    {
      enabled: fetchApi && hiredWorkerId ? true : false,
    }
  );

  const { width } = useWidth();

  const {
    data: {  workers } = {
        data: {
          workers: [],
        },
    },
    isLoading: workerLoading,
  } = useQuery(
    [
      "workersList",
      {
        organization,
        activeCategory,
        workerType: watch("workerType"),
      },
    ],
    () =>
      getOrganizationWorkers(
        activeCategory,
        watch("workerType"),
        organization,
        accessToken
      ),
    {
      enabled: fetchApi,
      onSuccess: (data) => {
        if (width < breakPoints.md) {
          navigate(`/schedule/${organization}/worker/list`);
        } else if (data?.workers?.length === 0) {
          navigate(`/schedule/${organization}`);
        } else
          navigate(`/schedule/${organization}/${data?.workers?.[0]?._id}`);
      },
    }
  );

  return {
    activeCategory,
    setActiveCategory,

    workers,
    tasksList,
    refetchTasks,
    control,
    workerType: watch("workerType"),
    watch,
    setValue,
    workerLoading,
  };
};
