import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string
};
function BreadCrumbIcon({ color="#101828", size="24", onClick,className }: Props) {

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7L11 12L7 17"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 7L17 12L13 17"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BreadCrumbIcon;

// fill={white ? "#fffff" : "#0F1322"}
