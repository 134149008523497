import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function AvailableDotIcon({ color="#26C700", size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
        fill={color}
        stroke="white"
        stroke-width="2"
      />
    </svg>
  );
}

export default AvailableDotIcon;

// fill={white ? "#fffff" : "#0F1322"}
