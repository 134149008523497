import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocale } from "../../../locales";
import {
  btnGrey,
  btnRed,
  colorPrimary,
  colorPrimaryText,
  colorWhite,
} from "../../../styles/colors";
import { Button, Text } from "../../atoms";
import SelectGroup from "../../molecules/Select Group";
import type { AppDataFormProps } from "./@types";
import styles from "./AppDataForm.module.scss";
import InputGroup from "../../molecules/InputGroup";
import { useAppDataForm } from "./hook";
import Loader from "../../atoms/Loader";

const AppDataForm = ({
  onSubmit,
  loading,
  onHide,
  type,
  control,
  handleSubmit,
  title,
  primaryBtnText,
  errors,
  placeholder,
  label1,
  label2,
  branchList,
  register,
  branchId,
}: AppDataFormProps) => {
  const { localeLabels, localeButtons, localePlaceholders, currentLocale } =
    useLocale();

  return (
    <div className={styles["main"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          textStyle={{
            fontWeight: "700",
            fontSize: "24px",
            marginBottom: "0.75rem",
            color: colorPrimaryText,
            textAlign: "center",
            // width: "max-content",
          }}
        >
          {title}
        </Text>
        {type === "Category" && (
          <SelectGroup
            register={register}
            list={
              branchList?.map((item) => ({
                ...item,
                label: item.label?.[currentLocale],
              })) as any
            }
            control={control}
            branchId={branchId}
            type={"text"}
            label={localeLabels.LABEL_SELECT_BRANCH}
            name={"branchId"}
            error={errors["branchId"]}
            // validation={field.validation}
            weight="600"
            fontFamily="Montserrat-SemiBold"
          />
        )}

        <InputGroup
          control={control}
          placeholder={placeholder}
          type={"text"}
          label={label1}
          name={"en"}
          error={errors["en"]}
          // validation={field.validation}
          weight="600"
          fontFamily="Montserrat-SemiBold"
        />
        <InputGroup
          control={control}
          placeholder={placeholder}
          type={"text"}
          label={label2}
          name={"du"}
          error={errors["du"]}
          // validation={field.validation}
          weight="600"
          fontFamily="Montserrat-SemiBold"
        />

        {/* {type === "Branches" && (
          <SelectGroup
            // register={register}
            list={[
              {
                label: localeLabels.LABEL_TURN_ON_JOB_CHECK,
                _id: true,
              },
              {
                label: localeLabels.LABEL_TURN_OFF_JOB_CHECK,
                _id: false,
              },
            ]}
            control={control}
            type={"text"}
            label={localeLabels.LABEL_SELECT_BRANCH}
            name={"jobPostingCheck"}
            error={errors["price"]}
            weight="600"
            fontFamily="Montserrat-SemiBold"
          />
        )} */}
        {type === "Category" && (
          <SelectGroup
            // register={register}
            list={[
              {
                label: localeLabels.LABEL_TURN_ON_PRICE_HIDE,
                _id: true,
              },
              {
                label: localeLabels.LABEL_TURN_OFF_PRICE_HIDE,
                _id: false,
              },
            ]}
            control={control}
            type={"text"}
            label={localeLabels.LABEL_SELECT_BRANCH}
            name={"price"}
            error={errors["price"]}
            weight="600"
            fontFamily="Montserrat-SemiBold"
          />
        )}

        <div className={styles["main-btn"]}>
          <Button
            type="submit"
            color={colorWhite}
            backgroundColor={btnRed}
            border={colorWhite}
            padding="8px"
            margin="1rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            // disabled={loading}
            onSubmit={handleSubmit(onSubmit)}
          >
            {!loading ? (
              primaryBtnText
            ) : (
              <Loader text={primaryBtnText} color="#fff" />
            )}
          </Button>
          <Button
            // disabled={loading}
            color={colorWhite}
            backgroundColor={btnGrey}
            border={colorWhite}
            padding="8px"
            margin="1rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            onClick={onHide}
          >
            {localeButtons.BUTTON_CANCEL}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AppDataForm;
