import React, { useEffect, useState } from "react";
import type { NotificationsCardProps } from "./@types";
import styles from "./NotificationsCard.module.scss";
import { useWidth } from "../../../utils/responsiveHook";
import { Text } from "../../atoms";
import { useLocale } from "../../../locales";
import Image from "../../atoms/Image";
import { handleImagesPath } from "../../../utils/hooks/helper";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import NotificationOptions from "../NotificationOptions";
import BellIcon from "../../../assets/Jsx/BellIcon";
import OptionIcon from "../../../assets/Jsx/OptionIcon";
import { useNavigate } from "react-router-dom";
import useComponentVisible from "../../../utils/useComposite";

const NotificationsCard = ({ data, heading }: NotificationsCardProps) => {
  const { width } = useWidth();
  const { localeTitles, localeLinks, localeDropdowns } = useLocale();
  const navigate = useNavigate();
  const { ref, setIsNotificationVisible, isNotificationVisible } =
    useComponentVisible(false);

  const DropDownOptions = (
    localeLink: typeof localeLinks,
    localeDropdown: typeof localeDropdowns
  ) => ({
    title: localeDropdown?.DROPDOWN_ALL,
    // Icon: <ContactIcon />,
    options: [
      {
        label: localeDropdown.DROPDOWN_ALL,
        // Icon: <BlogIcon />,
        path: "/contact",
      },
      {
        label: localeDropdown.DROPDOWN_UNREAD,
        // Icon: <PhoneIcon />,
        path: "/contact",
      },
      {
        label: localeDropdown.DROPDOWN_READ,
        // Icon: <AboutIcon />,
        path: "/about",
      },
    ],
  });

  dayjs.extend(relativeTime);
  const navigation = (accountType: string, link: string) => {
    console.log("navigation", link, accountType);
    const userId = link.split("/");

    const lastPart = userId[userId.length - 1];
    navigate(link);
    setIsNotificationVisible(false);
  };

  return (
    // <div className={styles["card"]}>
    <div className="mt-4  md:mt-10 flex flex-col  bg-white lg:bg-custom-foreground text-base w-full md:w-full lg:w-full xl:w-auto  xl-w-[380px] h-[420px] rounded-lg py-8 mb-6 px-[25px] ">
      <div className={styles["user-detail"]}>
        <Text className={styles["heading"]}>{heading}</Text>
        <NotificationOptions
          icon={<OptionIcon />}
          dropDownOptions={DropDownOptions(localeLinks, localeDropdowns)}
        />
      </div>

      <div className={styles["notifications"]}>
        {data?.map((notification, i) => (
          <div
            className={styles["notificationDiv"]}
            onClick={() =>
              navigation(notification?.user?.accountType, notification?.link)
            }
          >
            <Image
              className={styles["image"]}
              imagePath={handleImagesPath(notification?.user?.profileImage)}
            />
            <Text className={styles["title"]}> {notification?.content}</Text>
            <Text className={styles["time"]}>
            {dayjs(notification?.createdAt).format("HH:mm")}
              {/* {dayjs("1999-01-01").fromNow()} */}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationsCard;
