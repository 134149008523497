import type { NoteRequirementCardProps } from "./@types";
import styles from "./NoteRequirementCard.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";

import RequirementArrow from "./RequirementArrow";

const NoteRequirementCard = ({ data }: NoteRequirementCardProps) => {
  const { localeTitles } = useLocale();

  return (
    <div className={styles["NoteRequirementCard"]}>
      <Text className={styles["title"]}>{localeTitles.TITLE_NOTE}</Text>

      <div className={styles["requirements"]}>
        <div className={styles["requirement"]}>
          <RequirementArrow />
          <div className={styles["requirement-detail"]}>
            <Text className={styles["requiremnt-title"]}>
              {localeTitles.TITLE_CLOTING_REQUIREMENTS}
            </Text>
            <Text className={styles["requiremnt-value"]}>{data?.cloths}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteRequirementCard;
