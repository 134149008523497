import { useEffect, useState } from "react";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useInfiniteQuery } from "react-query";
import { ApiGetAllUsers } from "../../../utils/globalAPI/Users";

export const useDashboardListServices = () => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const { searchBy, searchKeyword } = UsersContextConsumer();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
  }, [searchBy, searchKeyword]);
  const { accessToken,fetchApi } = UsersContextConsumer();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: listLoading,
  } = useInfiniteQuery(
    [
      "workerList",
      {
        searchBy,
        keyword: searchKeyword,
      },
    ],
    async ({ pageParam = 0 }) =>
      ApiGetAllUsers("", pageParam, searchKeyword, searchBy,accessToken),
    {
      enabled: !!fetchApi,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (data) => data.nextPage,
    }
  );

  const handleOpenFilter = () => {
    setOpenFilterModal(true);
  };

  const handleCloseFilter = () => {
    setOpenFilterModal(false);
  };

  const onNextPage = () => {
    if (!hasNextPage) return;
    fetchNextPage();
    setOffset((prev) => prev + 1);
  };

  const onPreviousPage = () => {
    if (offset > 0) {
      setOffset((prev) => prev - 1);
    }
  };
  return {
    listLoading,
    openFilterModal,
    handleOpenFilter,
    handleCloseFilter,
    usersList: data?.pages?.[offset]?.usersList,
    hasMore: data?.pages?.[offset]?.hasMore,
    totalUsers: data?.pages?.[offset]?.totalUsers || 0,
    onNextPage,
    onPreviousPage,
    currentPage: offset + 1,
  };
};
