import { useFieldArray, useForm } from "react-hook-form";
import { ORG_ACCOUNT_FORMS } from "types/forms";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { useLocale } from "locales";
import {
  friendlyFormatIBAN,
  electronicFormatIBAN,
  isValidIBAN,
} from "ibantools";

import { useEffect, useRef } from "react";
import { getKvkNumber } from "utils/globalAPI/application";
import { useLocation } from "react-router-dom";

export interface UploadedImage {
  filePath: string;
  fileName: string;
  progress: number;
  status: string;
  file: File;
}

export const useAccountPageOrganization = () => {
  const { state } = useLocation();
  const userData = state?.userData

  const { localeErrors,currentLocale } = useLocale();
  const validationSchema: any = yup.object().shape({
    // accountHolderName: yup
    //   .string()
    //   .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    // IBAN: yup
    //   .string()
    //   .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS)
    //   .test("iban", localeErrors.ERROR_IBAN_FORMAT, (value) => {
    //     return isValidIBAN(electronicFormatIBAN(value) as string);
    //   }),
    // availabilityStatus: yup
    //   .string()
    //   .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    // title: yup.string().required(localeErrors.ERROR_TITLE_REQUIRED),
    // title must be in this given types account Tilte

    firstName: yup
      .string()
      .required(localeErrors.ERROR_FIRSTNAME_REQUIRED)
      .max(20, localeErrors.ERROR_FIRSTNAME_INVALID)
      .matches(/^[a-zA-Z ]*$/, localeErrors.ERROR_FIRSTNAME_INVALID),
    lastName: yup
      .string()
      .required(localeErrors.ERROR_LASTNAME_REQUIRED)
      .max(20, localeErrors.ERROR_LASTNAME_INVALID)
      .matches(/^[a-zA-Z ]*$/, localeErrors.ERROR_LASTNAME_INVALID),

    // phone: yup.string().required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    // bio: yup.string().required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),

    // employee: yup
    //   .number()
    //   .typeError(localeErrors.ERROR_EMPLOYEE_REQUIRED)
    //   .required(localeErrors.ERROR_EMPLOYEE_REQUIRED),
    // position: yup.string().required(localeErrors.ERROR_POSITION_REQUIRED),

    // address: yup.array().of(
    //   yup.object().shape({
    //     street: yup.string().required(localeErrors.ERROR_STREET_REQUIRED),
    //     city: yup.string().required(localeErrors.ERROR_CITY_REQUIRED),
    //     zip: yup.string().required(localeErrors.ERROR_ZIP_REQUIRED),
    //     country: yup.string().required(localeErrors.ERROR_COUNTRY_REQUIRED),
    //   })
    // ),
    // branches: yup.array().min(1, localeErrors.ERROR_CATEGORY_REQUIRED),
  });

  const {
    setValue,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ORG_ACCOUNT_FORMS>({
    defaultValues: {
      email: userData?.email || "",

      IBAN: friendlyFormatIBAN(
        userData?.organization?.bankDetails?.IBAN
      ) as string || "",
      accountHolderName: userData?.organization?.bankDetails?.accountHolderName || "",
      btw_nr: userData?.organization?.bankDetails?.btw_nr || "",

      availabilityStatus: userData?.availabilityStatus || "available",

      title: userData?.accountTitle || "Mr",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      bio: userData?.bio || "",
      password: "",
      profileImage: userData?.profileImage || "",
      phone: userData?.phone||"",
      heading: userData?.heading||"",

      address: userData?.address||[{}],

      categories: userData?.categories?.map((category)=>({
        ...category,
        value: category._id,
        label: category.label?.[currentLocale] as string,
      })) || [],
      skills: userData?.skills?.map((skill)=>({
        ...skill,
        value: skill._id,
        label: skill.label?.[currentLocale] as string,
      })) || [],
      branches: userData?.branches?.map((branch)=>({
        ...branch,
        value: branch._id,
        label: branch.label?.[currentLocale] as string,
      })) || [],

      // orgBranch: organization?.branch || [],

      deletedBranch: [],
      companyName: userData?.organization?.companyName||"",
      employee: userData?.organization?.employee||"",
      position: userData?.organization?.position||"",
      removedAddressIds: [],
    },
    resolver: yupResolver(validationSchema) as any,
  });

  const { fields, insert, remove } = useFieldArray({
    control: control,
    name: "address",
  });

  const sliderRef = useRef<any>(null);
  const insertAddress = (index: number) => {
    // new address can only add if he fill last address all fields
    const lastAddress = fields[fields.length - 1];
    //  ;
    if (lastAddress && 
      (!lastAddress?.street ||
      !lastAddress?.city ||
      !lastAddress?.zip ||
      !lastAddress?.country)
    ) {
      return;
    }

    insert(index, {
      street: "",
      city: "",
      zip: "",
      country: "",
      location: {
        type: "Point",
        coordinates: [],
      },
      address: "",
      addressTitle: "",
    });
    // navigate to last slide
    setTimeout(() => {
      sliderRef.current?.slideTo(index + 1);
    }, 100);
  };

  const removeAddress = (index: number) => {
    if (fields.length === 1) {
      return;
    }
    // if address is already saved in database then push it in removedAddressIds
    const address = fields[index];
    if (address?._id) {
      setValue("removedAddressIds", [
        ...(getValues().removedAddressIds || []),
        address._id,
      ]);
    }
    remove(index);
  };

  const handleIbanChange = (value: string) => {
    const iban = friendlyFormatIBAN(value);
    //  ;
    setValue("IBAN", iban + "");
  };

  const handlekvk = async (kvk: string) => {
    try {
      const data = await getKvkNumber(kvk);

      // console.log("data kvk " ,data);
      // set
      setValue(
        "companyName",
        data?.data?.naam
      );


    } catch (e) {}
  };

  useEffect(() => {
    if (watch("kvkNumber")?.length == 8) {
      handlekvk(watch("kvkNumber") as string);
    }
  }, [watch("kvkNumber")]);

  return {
    control,

    watch,
    handleSubmit,

    userData,
    setValue,
    getValues,
    handleIbanChange,

    fields,
    insertAddress,
    removeAddress,
    errors,
    sliderRef,
  };
};
