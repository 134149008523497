export default {
  invalid_email: "Invalid email address. Please enter a valid email.",
  already_invited: "You have invited this user already.",
  wrong_password: "Incorrect password. Please double-check your password.",
  invalid_Auth_with:
    "Authentication failed. Please try again with a different method.",
  create_a_new_account:
    "Account not found. Would you like to create a new account?",
  reset_password: "Password reset failed. Please try again later.",
  invalid_password:
    "Invalid password. Password must meet security requirements.",
  invalid_locale: "Invalid locale selected. Please choose a valid locale.",
  link_expired: "The link has expired. Please request a new one.",
  invalid_firstName: "Invalid first name. Please enter a valid first name.",
  invalid_lastName: "Invalid last name. Please enter a valid last name.",
  invalid_title: "Invalid title. Please enter a valid title.",
  invalid_dob: "Invalid date of birth. Please enter a valid date of birth.",
  invalid_street:
    "Invalid street address. Please enter a valid street address.",
  invalid_zip: "Invalid ZIP code. Please enter a valid ZIP code.",
  invalid_place: "Invalid place. Please enter a valid place.",
  invalid_categories:
    "Invalid categories selected. Please choose valid categories.",
  invalid_accountType:
    "Invalid account type. Please select a valid account type.",
  invalid_phone: "Invalid phone number. Please enter a valid phone number.",
  invalid_accountHolderName:
    "Invalid account holder name. Please enter a valid name.",
  invalid_accountNumber:
    "Invalid account number. Please enter a valid account number.",
  invalid_bankName: "Invalid bank name. Please enter a valid bank name.",
  invalid_certificate:
    "Invalid certificate. Please provide a valid certificate.",
  invalid_nic:
    "Invalid NIC (National Identification Card) number. Please enter a valid NIC.",
  invalid_diplomas: "Invalid diplomas. Please provide valid diplomas.",
  invalid_scannedImages:
    "Invalid scanned images. Please provide valid scanned images.",
  invalid_employee: "Invalid employee. Employee not found.",
  invalid_position: "Invalid position. Please select a valid position.",
  invalid_branch: "Invalid branch. Please select a valid branch.",
  invalid_token: "Invalid token. Authentication failed.",
  user_not_authorized: "User not authorized to perform this action.",
  invalid_request: "Invalid request. Please check your request and try again.",
  invalid_companyName: "Invalid company name.",
  invalid_availabilityStatus: "Invalid availability status.",
  invalid_skills: "Invalid skills.",
  invalid_job: "Invalid job.",
  invalid_education: "Invalid education.",
  invalid_profileImage: "Profile image is invalid.",
  invalid_socialHandle: "Invalid social handle.",
  invalid_hourlyRate: "Invalid hourly rate.",
  invalid_bio: "Invalid bio.",
  invalid_IBAN: "Invalid IBAN.",
  invalid_invitationToSetting: "Invalid invitation setting.",
  invalid_invitationOfLikedService: "Invalid invitation of liked service.",
  invalid_reminders: "Invalid reminders.",
  invalid_alerts: "Invalid alerts.",
  invalid_tokenType: "Invalid token type.",
  userId_Invalid: "User ID not found.",
  reportedUserId_Invalid: "Reported user ID is invalid.",
  reportReason_Invalid: "Report reason is invalid.",
  reportDescription_Invalid: "Report description is invalid.",

  notificationId_Invalid: "Notification ID is invalid.",
  workerType_Invalid: "Worker type is invalid.",
  urgencyLevel_Invalid: "Urgency level is invalid.",
  wage_Invalid: "Wage is invalid.",
  Address_Invalid: "Address is invalid.",
  cloths_Invalid: "Cloths information is invalid.",
  travelExpenses_Invalid: "Travel expenses are invalid.",
  workerNumber_Invalid: "Worker number is invalid.",
  breakTime_Invalid: "Break time is invalid.",
  title_Invalid: "Title is invalid.",
  jobDescription_Invalid: "Job description is invalid.",
  attachment_Invalid: "Attachment is invalid.",
  timeToEnd_Invalid: "End time is invalid.",
  timeToStart_Invalid: "Start time is invalid.",
  startDate_Invalid: "Starting date is invalid.",
  endDate_Invalid: "Ending date is invalid.",
  jobId_Invalid: "Job ID is invalid.",
  workerId_Invalid: "Worker ID is invalid.",
  perHourRate_Invalid: "Per hour rate is invalid.",
  content_required: "Content is required.",
  jobStatus_Invalid: "Job status is invalid.",
  invalid_jobStatus: "Invalid job status.",

  attachment_required: "Attachment is required.",
  response_Invalid: "Response is invalid.",
  invitationId_Invalid: "Invitation does not exist in the database.",
  ERROR_UNKOWN: "Something went wrong. Please try again later.",

  email_not_found: "Email not found.",
  email_already_exist: "Email already exists.",
  description_min_required_length_is_10:
    "Description must be at least 10 characters long.",
  user_not_active: "User is not active.",
  password_old_wrong: "Old password is incorrect.",
  invalid_category_type: "Invalid category type.",
  organization_not_found: "Organization not found.",
  job_not_found: "Job not found.",
  not_allowed_to_access_job: "Not allowed to access this job.",
  invalid_worker_type: "Invalid worker type.",
  task_not_found: "Task not found.",
  incorrect_start_date: "Incorrect start date.",
  incorrect_end_date: "Incorrect end date.",
  conversation_not_found: "Conversation not found.",
  user_is_blocked: "User is blocked.",
  user_not_found: "User not found.",
  cannot_send_empty_message: "Cannot send an empty message.",
  invitation_not_found: "Invitation not found.",
  password_wrong: "Password is wrong.",

  invalid_city: "Please enter a valid city.",
  invalid_country: "Please enter a valid country.",
  worker_not_found: "User not found.",

  invalid_date_format: "Invalid date format. Please use a valid date format.",
  Same_date_found: "A schedule for the same date already exists.",
  timeFrame_is_required: "Time frame is required for scheduling.",
  invalid_startTime_or_endTime: "Invalid start time or end time.",
  StartTime_cannot_be_same_as_ahead_of_endTime:
    "Start time cannot be the same as or ahead of end time.",
  TimeFrames_overlap_found: "Overlapping time frames found.",
  task_in_timeFrame_is_required: "Task in time frame is required.",
  invalid_availability: "Invalid availability selection.",
  day_shift: "Day shift is selected.",
  invalid_shift: "Invalid shift selection.",
  timeFrame_is_not_required: "Time frame is not required for this operation.",
  reason_in_timeFrame_is_required: "Reason in time frame is required.",
  Please_insert_reason_only_in_timeFrame:
    "Please insert reason only in the time frame.",
  reason_is_required: "Reason is required.",
  taskName_in_timeFrame_is_required: "Task name in time frame is required.",
  invalid_schedule_or_availability_Id: "Invalid schedule or availability ID.",
  invalid_timeFrame_id: "Invalid time frame ID.",
  invalid_endDate: "Invalid end date.",
  invalid_startDate: "Invalid start date.",
  organizatio_not_found: "Organization Account not found",
  invalid_date: "Invalid date.",
  schedule_not_found_for_the_given_date:
    "Schedule not found for the given date.",
  timeFrame_already_occupied_for_this_date:
    "Time frame is already occupied for this date.",
  availability_not_found_for_the_given_date:
    "Availability not found for the given date.",
  worker_is_not_available_on_this_date: "Worker is not available on this date.",
  worker_is_not_available_at_this_time: "Worker is not available at this time.",
  brach_id_not_exist: "Branch is not valid",
  invalid_address: "Invalid address",
  invalid_hiredWorker: "Invalid hired worker",
  reporteduser_Invalid: "Reported user is invalid",
  already_hired: "Already hired",
  invalid_job_id: "Invalid job id",
  worker_Invalid: "Invalid worker",
  hiredWorker_Invalid: "Invalid hired worker",
  start_Invalid: "Invalid start date",
  end_Invalid: "Invalid end date",
  name_Invalid: "Task name is invalid",
  task_Invalid: "Task is invalid",
  invalid_wage: "Wage must be a number",
  invalid_branch_id: "Invalid branch id",

  schedule_Invalid: "Invalid schedule",
  scheduleId_Invalid: "Invalid schedule",

  blocked_user_account: "You are not eligible to perform this action.",
  TASK_CAN_NOT_BE_UPDATED:
    "Task can not be updated because its completed or aproved.",
  financeReport_Invalid: "request is invalid",

  working_status_invalid: "Working status is invalid",
  not_authorised: "You are not authorised to perform this action.",
  shifts_not_found:
    "Can not have shifts to genrate invoice in given time frame.",
  job_conract_already_completed: "Job contract already completed.",
  mark_all_shifts_as_completed_before_completing_job:
    "Mark all shifts as completed before completing job.",
  rating_Invalid: "Rating is required",
  review_Invalid: "Review is required",

  branch_not_allowed:
    "Your organization is not allowed to post job for the branch you selected",
};
