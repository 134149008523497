import dayjs from "dayjs";
import { useLocale } from "locales";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useQuery } from "react-query";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";
import { SCHEDULE_FIELD } from "types/forms";
import {
  addAvailabilityAPI,
  addScheduleAPI,
  getAllSchedulesAPI,
} from "utils/globalAPI/ScheduleAndAvailability";
import {
  availabilityForm,
  availabilityTypes,
} from "components/cells/AvailabilitySchedule/@types";
import { useParams } from "react-router-dom";

export const usePersonalSchedulePage = () => {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());

  const { localeSuccess } = useLocale();
  const [availabilityScheduleModal, setAvailabilityScheduleModal] =
    useState(false);
  const { accessToken, fetchApi } = UsersContextConsumer();
  const { userId } = useParams();

  const [scheduleLoading, setScheduleLoading] = useState(false);

  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [addScheduleModal, setAddScheduleModal] = useState(false);
  const toast = ToastConsumer();
  const [view, setView] = useState<"day" | "week" | "month">("month");
  const [availability, setAvailability] = useState<availabilityForm>();

  const handleCloseAvailabilitySchedule = () => {
    setAvailabilityScheduleModal(false);
  };

  const { data: { data: eventsData } = {}, refetch: refetchSchedules } =
    useQuery(
      [
        "schedules",
        {
          userId,
          selectedDate: selectedDate?.format("YY MM"),
        },
      ],

      async () =>
        getAllSchedulesAPI(
          userId as string,
          view === "week"
            ? selectedDate.add(1, "day").format("YYYY-MM-DD")
            : selectedDate.format("YYYY-MM-DD"),
          view,
          accessToken
        ),

      { enabled: fetchApi, refetchOnMount: false, refetchOnWindowFocus: false }
    );

  const handleOpenAvailabilitySchedule = (dates: Date[]) => {
    setAvailabilityScheduleModal(true);

    const startDate = dayjs(dates[0]).add(1, "day");
    const endDate = dayjs(dates[dates.length - 1]).add(1, "day");

    const availabilitySchedule: availabilityTypes[] = [];

    for (let date = startDate; date <= endDate; date = date.add(1, "day")) {
      const availability = (eventsData as any)?.[date.format("YYYY-MM-DD")]
        ?.availability;

      availabilitySchedule.push({
        date: date.format("YYYY-MM-DD"),
        availability: availability?.availability || "",
        reason: availability?.reason,
        timeFrame: availability?.timeFrame || [],
        shift: availability?.shift || "",
      });
    }

    let ab = {
      availabilitySchedule: availabilitySchedule,
      date: dayjs(dates?.[0]).format("YYYY-MM-DD"),
    };

    setAvailability(ab);
  };

  const onConfirmAvailability: SubmitHandler<availabilityForm> = async (
    data
  ) => {
    console.log("onConfirmAvailabilitySchedule", data);
    const params = {
      availability: data?.availabilitySchedule.map((item) => ({
        date: dayjs(item?.date).format("YYYY-MM-DD"),
        shift: item?.shift,
        // shift: item?.availability === "available" ? item?.shift : undefined,
        timeFrame: item.availability == "partial" ? item.timeFrame : [],
        availability: item.availability,
        reason: item.reason,
      })),
    };

    console.log("onConfirmAvailabilitySchedule params", params);

    try {
      setAvailabilityLoading(true);
      const response = await addAvailabilityAPI(
        params as any,
        userId,
        accessToken
      );
      toast.success(localeSuccess.SUCESS_AVAILABILITY_ADDED, "top-right");
      // setTokenInCookies(response?.data.data);
      refetchSchedules();
      handleCloseAvailabilitySchedule();
      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setAvailabilityLoading(false);
    }
  };

  return {
    availabilityScheduleModal,
    handleOpenAvailabilitySchedule,
    handleCloseAvailabilitySchedule,

    onConfirmAvailability,
    availabilityLoading,

    selectedDate,
    setSelectedDate,
    eventsData,
    refetchSchedules,
    view,
    setView,
    availability,
  };
};
