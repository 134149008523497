import { socketContextTypes } from "./@types";
import { createCtx } from "../utils";
import useSocket from "./hook";
import { IsLocal } from "../../utils/axios";
import { useNotifications } from "components/organisms/NotificationDropDown/hook";
import { INotificaiton } from "types/schema";
export const [useSocketIo, SetSocketProvider] = createCtx<socketContextTypes>();

const useSocketIoCtx = (): socketContextTypes => {
  let isProduction = process.env.NODE_ENV === "production";
  let Notification: INotificaiton[] = [];
  const { socket, connected } = useSocket(
    (isProduction
      ? process.env.REACT_APP_SOCKETE_URL
      : IsLocal
      ? process.env.REACT_APP_SOCKETE_URL_DEV
      : process.env.REACT_APP_SOCKETE_URL) as string
  );

  const notificationAPi = useNotifications(Notification, socket, connected);

  return {
    sockets: socket,
    connected: connected,
    notificationAPi
  };
};
const SocketProvider = ({ children }: { children: JSX.Element }) => {
  const socketValue = useSocketIoCtx();
  return <SetSocketProvider value={socketValue}>{children}</SetSocketProvider>;
};
export const useSocketIO = () => {
  const socket = useSocketIo();
  return socket;
};
export default SocketProvider;
