type Props = {
  color?: string;
  size?: string|number;
  onClick?: any;
};
function TickCircle({ color, size = "20", onClick }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 1.66602C5.40866 1.66602 1.66699 5.40768 1.66699 9.99935C1.66699 14.591 5.40866 18.3327 10.0003 18.3327C14.592 18.3327 18.3337 14.591 18.3337 9.99935C18.3337 5.40768 14.592 1.66602 10.0003 1.66602ZM13.9837 8.08268L9.25866 12.8077C9.14199 12.9243 8.98366 12.991 8.81699 12.991C8.65033 12.991 8.49199 12.9243 8.37533 12.8077L6.01699 10.4493C5.77533 10.2077 5.77533 9.80768 6.01699 9.56602C6.25866 9.32435 6.65866 9.32435 6.90033 9.56602L8.81699 11.4827L13.1003 7.19935C13.342 6.95768 13.742 6.95768 13.9837 7.19935C14.2253 7.44102 14.2253 7.83268 13.9837 8.08268Z"
        fill="#05CF69"
      />
    </svg>
  );
}

export default TickCircle;

// fill={white ? "#fffff" : "#0F1322"}
