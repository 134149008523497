import React, { useEffect, useState } from "react";
import type { ChartComponentProps } from "./@types";
import styles from "./ChartComponent.module.scss";
import { useWidth } from "../../../utils/responsiveHook";
import Chart from "../../../components/organisms/Chart";
import { Text } from "../../../components/atoms";
import { useLocale } from "../../../locales";

const ChartComponent = ({
  data,
  heading,
  loading,
  type,
  options,
  getMonthlyData = (date, type) => {},
}: ChartComponentProps) => {
  const { width } = useWidth();
  const { localeTitles } = useLocale();
  return (
    <div className="mt-4  md:mt-10 flex flex-col justify-center bg-white  h-[420px] lg:bg-custom-foreground text-base w-[380px] rounded-lg py-8 mb-6 px-[12px]  ">
      <div className={styles["user-detail"]}>
        <Text className={styles["heading"]}>{heading}</Text>

        <Chart loading={loading} data={data} type={type} options={options} />
      </div>
    </div>
  );
};

export default ChartComponent;
