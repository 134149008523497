import ListPage from "../../pages/ListPage";
import { useFreelancerServices } from "./hook";
import Loader from "../../components/atoms/Loader";

export interface services {}

const FreelancerServices = ({}: services) => {
  const {
    listLoading,
    usersList,
    hasMore,
    totalUsers,
    onNextPage,
    onPreviousPage,
    currentPage,
    order,
    onChangeFilter,
    stausFilter,
    changeStausFilter,
  } = useFreelancerServices();
  if (listLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <ListPage
      type={"Freelancer"}
      stausFilter={stausFilter}
      changeStausFilter={changeStausFilter}
      list={usersList}
      hasMore={hasMore}
      totalUsers={totalUsers}
      loading={listLoading}
      showPagination={true}
      OnNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      currentPage={currentPage}
      filter={{
        order: order.order,
        column: order.column,
        onChange: onChangeFilter,
      }}
    />
  );
};

export default FreelancerServices;

// export default function AccountService() {
//   return (
//     <ProtectedRoute>
//       <AccountServiceComponent />
//     </ProtectedRoute>
//   );
// }
