import { useDashboardListPage } from "./hook";
import CommonTable from "../../components/templates/CommonTable";
import { useLocale } from "../../locales";

import { DashboardListPageProps } from "./@types";

const DashboardListPage = ({
  usersList,
  hasMore,
  currentPage,
  onPreviousPage,
  OnNextPage,
  totalUsers
}: DashboardListPageProps) => {
  const { navigateToUserDetails, onSubmit, onModalEdit } =
    useDashboardListPage();
  const { localeTitles } = useLocale();

  return (
    <div className="mt-4  md:mt-10">
      <CommonTable
        showTitle
        showPagination={true}
        hasMore={hasMore}
        currentPage={currentPage}
        OnNextPage={OnNextPage}
        onPreviousPage={onPreviousPage}
        title={`${localeTitles.TITLE_TOTAL} worker : ${totalUsers}`}
        tableType="Application_status"
        loading={false}
        data={usersList || []}
        onModalEdit={onModalEdit}
        onSubmit={onSubmit}
        navigateTo={navigateToUserDetails}
      />
    </div>
  );
};

export default DashboardListPage;
