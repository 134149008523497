import { useEffect, useState } from "react";
import { EventCalendarProps } from "./@types";
import DayView from "components/cells/DayView";
import WeekView from "components/cells/WeekView";
import MonthView from "components/cells/MonthView";
import dayjs from "dayjs";
import { Button } from "components/atoms/ButtonF";
import styles from "./EventCalendar.module.scss";

import { useLocale } from "locales";
import AddCircleIcon from "assets/svgs/AddCircleIcon";
import DatePicker, { DateObject } from "react-multi-date-picker";
import CalendarAddIcon from "assets/svgs/CalendarAddIcon";


const EventCalendar = ({
  selectedDate,
  setSelectedDate,
  eventsData,
  view,
  setView,

  addAvailability,
  refetchData,
}: EventCalendarProps) => {
  const { localeTitles, localeButtons } = useLocale();

  const [values, setValues] = useState<any>(
    view === "month"
      ? new DateObject()
      : [new DateObject(), new DateObject().add(1, "day")]
  );

  const generateWeeks = (startDate: dayjs.Dayjs) => {
    console.log("generateWeeks", startDate);
    const weeksArray = [];
    const currentMonth = startDate.month();
    let currentDate = startDate.add(1, "day").startOf("month");

    while (currentDate.month() === currentMonth) {
      weeksArray.push(currentDate);
      currentDate = currentDate.add(7, "days");
    }

    return weeksArray;
  };

  // const weeks = generateWeeks(); // Call the generateWeeks function

  const onSetValues = (data: DateObject[] | DateObject) => {
    console.log("onSetValues", data);
    setValues(data);

    if (Array.isArray(data) && data.length > 0) {
      const firstDataItem = data[0];
      const unixInSeconds = firstDataItem.unix * 1000; // Convert to milliseconds
      const startDate = dayjs(unixInSeconds);
      startDate
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);

      const updatedWeeks = generateWeeks(startDate);
      setValues([
        dayjs(startDate).startOf("week").add(1, "day").toDate(),
        dayjs(startDate).endOf("week").add(1, "day").toDate(),
      ]);
      setSelectedDate(startDate);
      return;
    }

    if (!Array.isArray(data) && (view === "month" || view === "day")) {
      setValues(data);
      const unixInSeconds = data?.unix * 1000;
      const startDate = dayjs(unixInSeconds);
      startDate
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      setSelectedDate(startDate);
      return;
    }
  };

  return (
    <div className="">
      <div className={styles["eventCalendar"]}>
        <div className={styles["eventCalendar-selection"]}>
          <div className={styles["col-row"]}>
            <div className={styles["calendarPicker"]}>
              <CalendarAddIcon />
              {view === "week" ? (
                <DatePicker
                  format={"DD MMM"}
                  weekStartDayIndex={1}
                  value={values}
                  dateSeparator="-"
                  onChange={onSetValues}
                  range
                  weekPicker
                  weekNumber="WN"
                  inputClass={styles["datePicker"]}
                />
              ) : view === "month" ? (
                <DatePicker
                  weekStartDayIndex={1}
                  format={"MMM YYYY"}
                  value={values}
                  onChange={onSetValues}
                  onlyMonthPicker
                  inputClass={styles["datePicker"]}
                />
              ) : (
                <DatePicker
                  format={"DD MMM YYYY"}
                  weekStartDayIndex={1}
                  value={values}
                  onChange={onSetValues}
                  multiple={false}
                  // onlyMonthPicker
                  inputClass={styles["datePicker"]}
                />
              )}
            </div>
            <div className={styles["eventCalendar-selection-buttons"]}>
              <Button
                className={styles[view === "day" ? "activeBtn" : "primaryBtn"]}
                onClick={() => {
                  setValues(dayjs().startOf("day").toDate());
                  setSelectedDate(dayjs().startOf("day"));
                  setView("day");
                }}
              >
                {localeButtons.BUTTON_DAY}
              </Button>
              <Button
                className={styles[view === "week" ? "activeBtn" : "primaryBtn"]}
                onClick={() => {
                  setValues([
                    dayjs().startOf("week").add(1, "day").toDate(),
                    dayjs().endOf("week").add(1, "day").toDate(),
                  ]);
                  setSelectedDate(dayjs().startOf("week").add(1, "day"));
                  setView("week");
                }}
              >
                {localeButtons.BUTTON_WEEK}
              </Button>
              <Button
                className={
                  styles[view === "month" ? "activeBtn" : "primaryBtn"]
                }
                onClick={() => {
                  setValues(
                    Array.isArray(values)
                      ? values[0]
                      : dayjs().startOf("month").toDate()
                  );
                  setSelectedDate(dayjs().startOf("month"));
                  setView("month");
                }}
              >
                {localeButtons.BUTTON_MONTH}
              </Button>
            </div>
          </div>

          {view === "day" && (
            <Button
              className={styles["activeBtn-main"]}
              leftIcon={<AddCircleIcon />}
              onClick={() =>
                addAvailability([
                  dayjs(selectedDate).startOf("week").add(1, "day").toDate(),
                  dayjs(selectedDate).endOf("week").add(1, "day").toDate(),
                ])
              }
            >
              {localeButtons.BUTTON_ADD_AVAILABILITY}
            </Button>
          )}
        </div>
        <div className={styles["calendar-body"]}>
          {view === "day" && (
            <DayView
              eventsData={eventsData}
              selectedDate={selectedDate}
              addAvailability={addAvailability}
              refetchData={refetchData}
            />
          )}
          {view === "week" && (
            <WeekView
              eventsData={eventsData}
              addAvailability={addAvailability}
              refetchData={refetchData}
              selectedDate={selectedDate}
            />
          )}
          {view === "month" && (
            <MonthView
              eventsData={eventsData}
              addAvailability={addAvailability}
              refetchData={refetchData}
              selectedDate={selectedDate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCalendar;
