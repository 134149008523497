import ListPage from "../../pages/ListPage";
import { useCategoriesServices } from "./hook";
import AddButton from "../../components/molecules/AddButton/AddButton";
import ModalComponent from "../../components/organisms/ModalComponent";
import AppDataForm from "../../components/organisms/AppDataForm/AppDataForm";
import ConfirmationModal from "../../components/organisms/Confirmation Modal";
import { useLocale } from "../../locales";
import Loader from "../../components/atoms/Loader";
import { useLocation } from "react-router-dom";

export interface services {}

const CategoriesServices = ({}: services) => {
  const { localeButtons, localeTitles, localeLabels, localePlaceholders } =
    useLocale();

  const {
    appDataLoading,
    dropdowns,
    handleOpenAddModal,
    handleCloseAddModal,
    addModal,
    onSubmit,
    control,
    handleSubmit,
    loading,
    deleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    deleteLoading,
    onConfirmDelete,
    handleOpenEditModal,
    onToggleCategroryPrice,
    handleCloseEditModal,
    editModal,
    editLoading,
    onSubmitEdit,
    errors,
    register,
    branchId,
    selectedBranch,
    categories,
  } = useCategoriesServices();

  if (appDataLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className="pt-10 pr-10 flex justify-end">
        <AddButton
          text={localeButtons.BUTTON_ADD_CATEGORIES}
          onClick={handleOpenAddModal}
        />
      </div>
      <ListPage
        onClickDelete={handleOpenDeleteModal}
        onClickEdit={handleOpenEditModal}
        type={"Categories"}
        list={categories}
        // hasMore={hasMore}
        totalUsers={categories?.length}
        loading={appDataLoading}
        onToggleCategroryPrice={onToggleCategroryPrice}
      />

      {/* Add Form */}
      <ModalComponent show={addModal}>
        <AppDataForm
          branchList={dropdowns?.branches}
          type="Category"
          handleSubmit={handleSubmit}
          register={register}
          control={control}
          errors={errors}
          onSubmit={onSubmit}
          loading={loading}
          onHide={handleCloseAddModal}
          title={localeTitles.TITLE_PLEASE_ADD_CATEGORY}
          primaryBtnText={localeButtons.BUTTON_ADD_CATEGORIES}
          placeholder={localePlaceholders.PLACEHOLDER_ADD_CATEOGRY_NAME}
          label1={localeLabels.LABEL_CATEGORY_NAME_ENGLISH}
          label2={localeLabels.LABEL_CATEGORY_NAME_DUTCH}
        />
      </ModalComponent>
      {/* Add Form */}

      {/* Edit Form */}
      <ModalComponent show={editModal}>
        <AppDataForm
          branchList={dropdowns?.branches}
          type="Category"
          handleSubmit={handleSubmit}
          branchId={branchId}
          control={control}
          register={register}
          errors={errors}
          onSubmit={onSubmitEdit}
          loading={editLoading}
          onHide={handleCloseEditModal}
          title={localeTitles.TITLE_EDIT_CATEGORY}
          primaryBtnText={localeButtons.BUTTON_EDIT}
          placeholder={localePlaceholders.PLACEHOLDER_ADD_CATEOGRY_NAME}
          label1={localeLabels.LABEL_CATEGORY_NAME_ENGLISH}
          label2={localeLabels.LABEL_CATEGORY_NAME_DUTCH}
        />
      </ModalComponent>
      {/* Edit Form */}

      {/* Delete Branch */}
      <ConfirmationModal
        show={deleteModal}
        title={localeLabels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_CATEGORY}
        onHide={handleCloseDeleteModal}
        onConfirm={onConfirmDelete}
        loading={deleteLoading}
      />
      {/* Delete Branch */}
    </>
  );
};

export default CategoriesServices;
