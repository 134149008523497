import Loader from "../Loader";
import { ButtonProps } from "./@types";
import styles from "./Button.module.scss";

function Button({
  borderRadius,
  children,
  onClick,
  disabled = false,
  type = `button`,
  backgroundColor = "#585EF2",
  opacity,
  className,
  size = `default`,
  style = {},
  height,
  width,
  color,
  border,
  padding,
  margin,
  fontWeight,
  fontSize,
  loading = false,
  ...props
}: ButtonProps) {
  return (
    <button
      // className={`${styles[`btn-${color}`]} ${className}`}
      className={`${styles[className]}   ${className}`}
      // className={`${styles[`btn-${color}`]}`}
      // className={`${styles[`btn-${color}`]}`}
      style={{
        backgroundColor,
        borderRadius,
        width,
        opacity,
        color,
        border,
        padding,
        margin,
        fontWeight,
        fontSize,
        ...style,
      }}
      // style={props.buttonStyle}
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={disabled}
      type={type}
      {...props}
    >
      {loading ? <Loader /> : children}
    </button>
  );
}

Button.defaultProps = {
  type: `button`,
  className: ``,
  disabled: false,
  backgroundColor: `#fff`,
  width: `100%`,
  opacity: 1,
  children: `button`,
  borderRadius: `6px`,
  color: `primary`,
  style: {},
};

export default Button;
