import React from "react";

import { LazyLoadImage } from 'react-lazy-load-image-component';
export type ImgProps =  
  Partial<{
    className: string;
    src: string;
    alt: string;
  }>

const Img: React.FC<React.PropsWithChildren<ImgProps>> = ({
  className,
  src = "defaultNoData.png",
  alt = "testImg",
  ...restProps
}) => {

  return (
    <LazyLoadImage
      className={className}
      src={src}
      alt={alt}
      placeholderSrc={src}
      loading={"lazy"}
      {...restProps}

    />
  );
};
export { Img };
