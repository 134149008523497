import dayjs from "dayjs";
import { useLocale } from "locales";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";

import { createJobAPI } from "utils/globalAPI/OrganizationJobs";
import { JOB_FORMS } from "pages/JobPostingSection/@types";
import { queryClient } from "utils/QueryClient";
import { JobData } from "types/Gloabal";
// const jobsType = "cancelled" || "completed" || "inProgress";

export const usePostAJobPage = () => {
  const [loading, setLoading] = useState(false);
  const { localeSuccess } = useLocale();
  const [inviteModal, setInviteModal] = useState(false);

  const toast = ToastConsumer();
  const { accessToken } = UsersContextConsumer();
  const navigate = useNavigate();
  const organization = useParams()?.organization;

  const onClickYes = () => {
    // organization/:jobId/invite/:organization
    navigate(`/organization/${job?.organization}/job/${job?._id}/invite`, {
      replace: true,
    });
  };
  const [job, setJob] = useState<JobData | null>(null);

  const closeInviteModal = () => {
    setInviteModal(false);
    // Invalidate query before going back
    navigate(`/organization/${organization}/jobs`);
  };

  const onSubmit: SubmitHandler<JOB_FORMS> = async (data) => {
    try {
       ;
      setLoading(true);
      const response = await createJobAPI(
        { ...data, organization } as any,
        accessToken
      );
      queryClient.invalidateQueries("jobsData");
      setJob(response.theJob as any);
      toast.success(localeSuccess.SUCESS_JOB_POSTED, "top-right");
      setInviteModal(true);
      // navigate("/jobs");
    } catch (error: any) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setLoading(false);
    }
  };

  return {
    onSubmit,
    loading,
    inviteModal,
    closeInviteModal,
    onClickYes,
  };
};
