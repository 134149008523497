import type { ContactUsCardProps } from "./@types";
import styles from "./ContactUsCard.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import CallIcon from "./CallIcon";

const ContactUsCard = ({ data }: ContactUsCardProps) => {
  const {  localeTitles } =
    useLocale();

  return (
    <div className={styles["ContactUsCard"]}>
      <Text className={styles["title"]}>{localeTitles.TITLE_CONTACT_US}</Text>
      <div className={styles["contactDiv"]}>
        <CallIcon size={20} />
        <Text className={styles["contactNumber"]}>{data}</Text>
      </div>
    </div>
  );
};

export default ContactUsCard;
