import styles from "./JobDetailsOrganization.module.scss";
import CardWithActionButtons from "./JobDetails/CardWithActionButtons";
import HiringManagerCard from "./JobDetails/HiringManagerCard";
import JobDescriptionCard from "./JobDetails/JobDescriptionCard";
import NoteRequirementCard from "./JobDetails/NoteRequirementCard";
import ContactUsCard from "./JobDetails/ContactUsCard";

import CompanyDetailsCard from "./JobDetails/CompanyDetailsCard";
import { breakPoints } from "utils/ResponsiveDesignBreakPoints";

import { JobDetailsOrganizationType } from "./types/indes";
import {} from "store/AuthProvider";

import { useLocale } from "locales";
import SkillAndExpertiseCard from "./JobDetails/SkillAndExpertiseCard";

import { useWidth } from "utils/responsiveHook";
import { MapInDetailPage } from "./JobDetails/JobDetails";

const JobDetailsOrganization = ({ jobData,onDeleteJob,onChangeJobPosting,OnEditJob }: JobDetailsOrganizationType) => {
  const { width } = useWidth();
  const { localeTitles } = useLocale();

  return (
    <div className={styles["JobDetailsOrganization"]}>
      {width > breakPoints.md && (
        <div className={styles["JobDetailsOrganization-left"]}>
          <CardWithActionButtons data={jobData} onDeleteJob={onDeleteJob} onChangeJobPosting={onChangeJobPosting} OnEditJob={OnEditJob} />
          <JobDescriptionCard description={jobData?.jobDescription} />

          {jobData && jobData?.branches?.length > 0 && (
            <SkillAndExpertiseCard
              title={localeTitles.TITLE_BRANCH}
              skills={jobData?.branches}
            />
          )}
          {jobData && jobData?.categories?.length > 0 && (
            <SkillAndExpertiseCard
              title={localeTitles.TITLE_CATEGORY}
              skills={jobData?.categories}
            />
          )}
          {jobData && jobData?.skills?.length > 0 && (
            <SkillAndExpertiseCard skills={jobData?.skills} />
          )}

          {/*Location  */}
          <MapInDetailPage
            coordinates={jobData?.address?.location?.coordinates}
            address={jobData?.address?.address + ""}
          />
          {/*Location  */}
          <CompanyDetailsCard
            data={jobData?.organization}
            bio={jobData?.user?.bio}
          />

          <div className="mb-8" />
        </div>
      )}

      {width > breakPoints.md && (
        <div className={styles["JobDetailsOrganization-right"]}>
          <HiringManagerCard data={jobData} />
          <NoteRequirementCard data={jobData} />

          <ContactUsCard data={jobData?.user?.phone} />
        </div>
      )}

      {width < breakPoints.md && (
        <>
          <CardWithActionButtons data={jobData} onDeleteJob={onDeleteJob} onChangeJobPosting={onChangeJobPosting} />
          <HiringManagerCard data={jobData} />
          <JobDescriptionCard description={jobData?.jobDescription} />

          <JobDescriptionCard description={jobData?.jobDescription} />

          {jobData && jobData?.branches?.length > 0 && (
            <SkillAndExpertiseCard
              title={localeTitles.TITLE_BRANCH}
              skills={jobData?.branches}
            />
          )}
          {jobData && jobData?.categories?.length > 0 && (
            <SkillAndExpertiseCard
              title={localeTitles.TITLE_CATEGORY}
              skills={jobData?.categories}
            />
          )}
          {jobData && jobData?.skills?.length > 0 && (
            <SkillAndExpertiseCard skills={jobData?.skills} />
          )}

          {/*Location  */}
          <MapInDetailPage
            coordinates={jobData?.address?.location?.coordinates}
            address={jobData?.address?.address + ""}
          />
          {/*Location  */}

          <NoteRequirementCard data={jobData} />
          <ContactUsCard data={jobData?.user?.phone} />

          <CompanyDetailsCard
            data={jobData?.organization}
            bio={jobData?.user?.bio}
          />
        </>
      )}
    </div>
  );
};

export default JobDetailsOrganization;
