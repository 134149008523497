import { CompanyScheduleProps } from "./@types";

import { Button } from "components/atoms/ButtonF";
import styles from "./CompanySchedule.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { GoScreenFull } from "react-icons/go";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

import CompanyWorkersView from "components/cells/CompanyWorkersView";
import {
  ClanderViewOptions,
  postJobOptionsFeatures,
} from "constants/constantData";

import { ReactControlSelect } from "components/atoms/ReactControlSelect";
import BreadCrumbIcon from "assets/svgs/BreadCrumbIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

import DatePicker, { DateObject } from "react-multi-date-picker";
import { dutch, english } from "locales/MultiDatePicker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import "./index.css";
import dayjs from "dayjs";
import { ITask } from "types/schema";
import { useWidth } from "utils/responsiveHook";
const CompanySchedule = ({
  tasksList,
  control,
  refetchTasks,
  setActiveCategory,
  activeCategory,
  workerLoading,
  workers,
  setValue,
  watch,
  organizationAddress,
}: CompanyScheduleProps) => {
  const { localeTitles, localeButtons, currentLocale, localePlaceholders } =
    useLocale();
  const { width } = useWidth();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [view, setView] = useState<string>(watch("view"));
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const filterTasks = (tasksList: ITask[]) => {
    // if view is day means we have to show all tasks of the current month
    let date = watch("date");
     ;
    if (view?.toString() === "day") {
      let firstDay = dayjs(date).startOf("month").unix();
      let lastDay = dayjs(date).endOf("month").unix();

      return tasksList.filter(
        (task) =>
          dayjs(task.start).unix() >= firstDay &&
          dayjs(task.start).unix() <= lastDay
      );
    } else if (view?.toString() === "Day") {
      let firstDay = dayjs(date).startOf("week").unix();
      let lastDay = dayjs(date).endOf("week").unix();

      return tasksList.filter(
        (task) =>
          dayjs(task.start).unix() >= firstDay &&
          dayjs(task.start).unix() <= lastDay
      );
    } else if (view?.toString() === "Hour") {
      let firstDay = dayjs(date).startOf("day").unix();
      let lastDay = dayjs(date).endOf("day").unix();

      return tasksList.filter(
        (task) =>
          dayjs(task.start).unix() >= firstDay &&
          dayjs(task.start).unix() <= lastDay
      );
    } else {
      return tasksList;
    }
  };

  const handleAdress = (adressId: string[]) => {
    if (adressId.length == 0) {
      setActiveCategory(["all"]);
      return;
    }
    // if
    const newaddress = new Set([...activeCategory, ...adressId]);
    // convert newaddress to array
    let newArray = Array.from(newaddress).filter((item) => item != "all");
    setActiveCategory(newArray);
  };

  const handleButton = (address: string) => {
    if (address == "all") {
      setActiveCategory(["all"]);
      return;
    }
    if (activeCategory.includes(address)) {
    } else {
      handleAdress([address]);
    }
  };

  console.log("organizationAddress",organizationAddress)

  return (
    <div className={styles["CompanySchedule-main"]}>
      <div className={styles["CompanySchedule-categories"]}>
        <Button
          className={
            activeCategory?.includes("all")
              ? styles["primaryBtn"]
              : styles["secondaryBtn"]
          }
          onClick={() => handleButton("all")}
        >
          {localeButtons.BUTTON_SHOW_ALL}
        </Button>

        <div className={styles["control-select"]}>
          <ReactControlSelect
            onChange={handleAdress as any}
            placeholder={localePlaceholders.PLACEHOLDERS_ADDRESS}
            name="branches"
            unControlled
            maxHeight={38}
            isMulti={true}
            options={(organizationAddress || [])?.map((item) => {
              return {
                label:
                  item.addressTitle || item?.street + " " + item?.city || "",
                value: item?._id || "",
              };
            })}
          />
        </div>
      </div>
      <div className={styles["CompanySchedule"]}>
        {width < breakPoints.md ? (
          <div className={styles["CompanySchedule-header"]}>
            <div className={styles["CompanySchedule-header-left"]}>
              <Text className={styles["title"]}>
                {pathname !== "/schedule/worker/list" && (
                  <span
                    className={styles["backbutton"]}
                    onClick={() => navigate("/schedule/worker/list")}
                  >
                    <BreadCrumbIcon
                      className={styles["backbutton-svg"]}
                      color="#fff"
                    />
                  </span>
                )}
                {localeTitles.TITLE_COMPANY_SCHEDULE}
              </Text>
            </div>
          </div>
        ) : (
          <div className={styles["CompanySchedule-header"]}>
            <div className={styles["CompanySchedule-header-left"]}>
              <Text className={styles["title"]}>
                {localeTitles.TITLE_COMPANY_SCHEDULE}
              </Text>

              <div className={styles["CompanySchedule-selection-buttons"]}>
                <ReactControlSelect
                  options={postJobOptionsFeatures(localeTitles)}
                  defaultValue={postJobOptionsFeatures(localeTitles)?.[2]}
                  control={control}
                  name={"workerType"}
                />
              </div>

              <div className={styles["CompanySchedule-selection-buttons"]}>
                <ReactControlSelect
                  options={ClanderViewOptions(localeButtons)}
                  defaultValue={ClanderViewOptions(localeButtons)?.[2]}
                  unControlled={true}
                  onChange={(value) => {
                    setView(value as any);
                    setValue("view", value);
                  }}
                />
              </div>
              <div className={styles["CompanySchedule-selection-buttons"]}>
                {watch("view") == "Day" ? (
                  <DatePicker
                    // format={"DD MMM"}
                    weekStartDayIndex={1}
                    value={watch("date")}
                    dateSeparator="-"
                    onChange={(newDates: DateObject) => {
                      setValue("date", newDates.toDate());
                    }}
                    displayWeekNumbers
                    // range
                    // weekPicker={true}
                    locale={currentLocale === "en" ? english : dutch}
                    weekNumber="WN"
                    inputClass={styles["datePicker"]}
                  />
                ) : (
                  <DatePicker
                    value={watch("date")}
                    weekStartDayIndex={1}
                    locale={currentLocale === "en" ? english : dutch}
                    onChange={(newDates: DateObject) => {
                      setValue("date", newDates.toDate());
                    }}
                    type="week"
                    inputClass={styles["datePicker"]}
                    render={<InputIcon />}
                    onlyMonthPicker={view.toString() == "day"}
                    weekPicker={view.toString() == "Day"}
                  />
                )}
              </div>
            </div>
            <Button
              className={
                fullScreen ? styles["primaryBtn"] : styles["secondaryBtn"]
              }
              onClick={() => setFullScreen(!fullScreen)}
              leftIcon={
                <GoScreenFull size={25} color={fullScreen ? "#fff" : "#000"} />
              }
            ></Button>
          </div>
        )}

        <div className={styles["calendar-body"]}>
          <CompanyWorkersView
            tasksList={filterTasks(tasksList)}
            // tasksList={tasksList}
            workerLoading={workerLoading}
            workers={workers}
            refetchTasks={refetchTasks}
            view={(view === "day" ? "Day" : view) as any}
            haveTasks={tasksList.length > 0}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanySchedule;
