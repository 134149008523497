import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { ToastConsumer } from "../../../store/ToastProvider";
import { useLocale } from "../../../locales";
import {
  ApiGetUserDetails,
  ApiSentVOGLink,
  ApiToggleAccountStatus,
  ApiToggleWorkStatus,
} from "../../../utils/globalAPI/Users";
import { useNavigate, useParams } from "react-router-dom";
import { UsersContextConsumer } from "../../../store/AuthProvider";
// import { ACCOUNT_FORMS } from "types/forms";

export const useDetailsServices = () => {
  const { reset } = useForm<{
    search: string;
    place: string;
  }>({
    defaultValues: {},
  });
  const toast = ToastConsumer();
  const { localeSuccess } = useLocale();
  const [toggleLoading, setToggleLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [vogLoading, setVogLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [declineLoading, setDelcineLoading] = useState(false);
  const onClickHide = () => {
    setShowConfirmation(false);
  };
  const onToggleAccount = () => {
    setShowConfirmation(true);
  };

  const params = useParams();
  const userId = params?.userId;

  // console.log("params", userId);
  const { accessToken, fetchApi } = UsersContextConsumer();

  const {
    data,
    isLoading: userDetailsListLoading,
    refetch: refetchUserDetails,
  } = useQuery(
    [
      "userDetails",
      {
        userId,
      },
    ],
    async () => ApiGetUserDetails(userId, accessToken),
    {
      enabled: !!fetchApi,
      //  refetchOnMount: false, refetchOnWindowFocus: false
    }
  );

  const onConfirmAccountToggle = async () => {
    try {
      //
      setToggleLoading(true);
      const response = await ApiToggleAccountStatus(userId, accessToken);
      toast.success(localeSuccess.SUCCESS_ACCOUNT_ACTIVE, "top-right");
      console.log("response", response);
      refetchUserDetails();
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setToggleLoading(false);
      onClickHide();
    }
  };

  const onSubmitForm: SubmitHandler<{ link: string }> = async (data) => {
    console.log("onSubmit", data);
    const params = {
      user: userId,
      link: data?.link,
    };
    try {
      //
      setVogLoading(true);
      const response = await ApiSentVOGLink(params, accessToken);
      toast.success(localeSuccess.SUCCESS_SENT_LINK, "top-right");
      // setTokenInCookies(response?.data.data);
      // setJobPosting(false);
      refetchUserDetails();
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setVogLoading(false);
    }
  };

  const onClickVerified = async () => {
    try {
      setVerifyLoading(true);
      const response = await ApiToggleWorkStatus(
        userId,
        "verified",
        accessToken
      );
      toast.success(localeSuccess.SUCCESS_EMPLOYEE_VERIFIED, "top-right");

      console.log("response", response);
      refetchUserDetails();
    } catch (error) {
      console.log(error);
    } finally {
      setVerifyLoading(false);
    }
  };

  const onClickDeclined = async () => {
    try {
      setDelcineLoading(true);
      const response = await ApiToggleWorkStatus(
        userId,
        "unverified",
        accessToken
      );
      toast.success(localeSuccess.SUCCESS_EMPLOYEE_DECLINED, "top-right");

      console.log("response", response);
      refetchUserDetails();
    } catch (error) {
      console.log(error);
    } finally {
      setDelcineLoading(false);
    }
  };

  const navigate = useNavigate();

  const onUserUpdate=()=>{
    navigate("/update",{
      state:{
        userData:data.usersDetails
      }
    })
  }

  return {
    reset,
    userDetails: data?.usersDetails,
    organization: data?.organization,
    latestContract:data?.latestContract,
    onToggleAccount,
    onUserUpdate,
    onClickHide,
    onConfirmAccountToggle,
    showConfirmation,
    toggleLoading,
    userDetailsListLoading,
    onClickVerified,
    onClickDeclined,
    onSubmitForm,
    verifyLoading,
    vogLoading,
    declineLoading,
  };
};
