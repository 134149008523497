
import { useFieldArray, useForm } from "react-hook-form";
import { useRef, useState } from "react"
import { IBranches } from "types/Gloabal";
import { useLocale } from "locales";
import { IAddress } from "types/ApiResponse";
import { IOrganization, ISkills } from "types/schema";


export const useUserDetailsPage = (branches: IBranches[], address: IAddress[], organization: IOrganization[]) => {
  const { currentLocale } = useLocale()
  const {
    handleSubmit: handleSubmitForm,
    control,
    formState: { errors },
  } = useForm<{
    link: string;
    branches: IBranches[];
    organization: ISkills[];
    address: IAddress[]
  }>({
    defaultValues: {
      link: "",
      address: address || [],
      organization: organization?.map((item) => {
        return {
          _id: item._id,
          label: item.companyName,
          value: item._id,
        };
      }),
      branches: branches?.map((branch) => ({
        ...branch,
        value: branch._id,
        label: branch.label?.[currentLocale] as string,
      })) || [],
    },
  });

  const { fields, } = useFieldArray({
    control: control,
    name: "address",
  });

  const sliderRef = useRef<any>(null);

  const [previewFile, setPreviewFile] = useState<{
    show: boolean,
    file: string[],
  }>({
    show: false,
    file: [],
  });
  const onPreviewFile = (file: string) => {

    setPreviewFile({
      show: true,
      file: [file]
    })
  }
  const onClosePreviewFile = () => setPreviewFile({
    show: false,
    file: []
  })

  return {
    control,
    handleSubmitForm,
    onPreviewFile,
    previewFile,
    onClosePreviewFile,
    fields,
    sliderRef

  };
};


