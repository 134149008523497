import Text from "../../atoms/Text";
import { useLocale } from "../../../locales";
import { DeactivateButtonProps } from "./@types";
import { title } from "process";
import styles from "./DeactivateButton.module.scss";
import CancleCircleIcon from "../../../assets/Jsx/CancleCircleIcon";

const DeactivateButton = ({
  title,
  icon,
  onClickDeactivateButton,
}: DeactivateButtonProps) => {
  const { localeLabels } = useLocale();
  return (
    <div className={styles["actionBtn"]}>
      {icon}
     
      <Text
        size={"s"}
        className={styles["text"]}
        onClick={onClickDeactivateButton}
      >
        {title}
      </Text>
    </div>
  );
};

export default DeactivateButton;
