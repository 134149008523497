import { useReportsServices } from "./hook";
import AddButton from "../../components/molecules/AddButton/AddButton";
import { useLocale } from "../../locales";

import "./report.scss";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { ReactControlSelect } from "../../components/atoms/ReactControlSelect";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "../../components/atoms/ErrorMessage";
import ListPage from "../../pages/ListPage";
import Loader from "../../components/atoms/Loader";

export interface services {}

const ReportsServices = ({}: services) => {
  // const { userData } = UserAuthContext();
  const { localeButtons } = useLocale();
  const {
    appDataLoading,
    control,
    watch,
    handleOrganizationFetch,
    handleAddressFetch,
    handleWorkerFetch,
    addressOptions,
    Submit,
    onPreviouse,
    onNextPage,
    reports,
    isLoading,
    onClickDownload,
    loading
  } = useReportsServices();

  if (appDataLoading || isLoading ) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className="filter-container">
        <Controller
          name={"dates"}
          control={control}
          render={({ field: { onChange }, formState: { errors } }) => (
            <>
              <DatePicker
                range
                format="DD MMM"
                dateSeparator="-"
                // className={styles.datePicker}
                inputClass={"datePicker"}
                editable={false}
                plugins={[<DatePanel />]}
                placeholder="Select Dates"
                onChange={(dates) => {
                  onChange(dates);
                }}
              />
              <ErrorMessage errors={`${errors?.dates?.message}`} />
            </>
          )}
        />

        <ReactControlSelect
          name="reportType"
          placeholder="Select Report Type"
          control={control}
          defaultValue={{
            label: "Schedule",
            value: "schedule",
          }}
          options={[
            {
              label: "Schedule",
              value: "schedule",
            },
            {
              label: "Hour Registration",
              value: "hourRegistration",
            },
          ]}
        />

        <ReactControlSelect
          name="reportGroup"
          placeholder="Select Report Group"
          control={control}
          options={[
            {
              label: "All Organization",
              value: "allOrganization",
            },
            {
              label: "All Employee",
              value: "allEmployee",
            },
            {
              label: "All Freelancer",
              value: "allFreelancer",
            },

            {
              label: "Specific Organization",
              value: "specificOrganization",
            },
            {
              label: "Specific Worker",
              value: "specificWorker",
            },
          ]}
        />

        {watch("reportGroup") === "specificOrganization" && (
          <ReactControlSelect
            placeholder="Select Organization"
            name="organization"
            control={control}
            isAsync
            options={[]}
            loadOptions={handleOrganizationFetch}
          />
        )}
        {watch("reportGroup") === "specificOrganization" && (
          <ReactControlSelect
            placeholder="Select Address"
            name="address"
            control={control}
            // isAsync
            options={addressOptions}
            loadOptions={handleAddressFetch}
          />
        )}
        {watch("reportGroup") === "specificWorker" && (
          <ReactControlSelect
            placeholder="Enter Worker Name"
            name="worker"
            control={control}
            isAsync
            options={[]}
            loadOptions={handleWorkerFetch}
          />
        )}

        <AddButton
          text={localeButtons.BUTTON_GENRATE_REPORT}
          onClick={Submit}
          loading={loading}
        />
      </div>
      <ListPage
        type={"reports"}
        list={reports?.reports || []}
        currentPage={reports?.nextPage || 1}
        loading={isLoading}
        totalUsers={reports?.totalReports || 0}
        showPagination
        OnNextPage={onNextPage}
        onPreviousPage={onPreviouse}
        onClickEdit={onClickDownload}
      />
    </>
  );
};

export default ReportsServices;
