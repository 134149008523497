import { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useParams } from "react-router-dom";
import { UsersContextConsumer } from "store/AuthProvider";
import {
  getWorkerJobsShiftApi,
} from "utils/globalAPI/Finance";

export const useFinancePage = () => {
  const { accessToken, fetchApi } = UsersContextConsumer();

  const hiredWorker = useParams()?.hiredWorker;
  const {
    data: jobs,
    isLoading: loading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [
      "workerShiftPage",
      {
        hiredWorker,
      },
    ],

    async ({ pageParam = 0 }) => {
      const data = await getWorkerJobsShiftApi(
        {
          hiredWorker: hiredWorker as string,
        },
        pageParam,
        accessToken
      );
      return data;
    },
    {
      enabled: fetchApi,
      getNextPageParam: (lastPage) => lastPage?.data?.nextPage || undefined,
    }
  );

  const [showInvoiceGenrator, setShowInvoiceGenrator] = useState(false);

  const toggleInvoceGenrator = () => {
    setShowInvoiceGenrator(!showInvoiceGenrator);
  };
  console.log("jobs ",jobs)
  return {
    jobs,
    loading,
    hasNextPage,
    fetchNextPage,
    showInvoiceGenrator,
    toggleInvoceGenrator,
    job: jobs?.pages?.[0]?.jobs?.[0]?.jobId,
  };
};
