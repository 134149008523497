import styles from "./JobDetails.module.scss";
import { useLocale } from "locales";
import { Text } from "components/atoms/TextF";
import GoogleMap from "./GoogleMap";
import GpsIcon from "./GpsIcon";
// import GpsIcon from "assets/svgs/GpsIcon";


export const MapInDetailPage = ({
  coordinates,
  address
}: {
   coordinates: [number, number];
   address:string
}) => {
  const { localeTitles } = useLocale();
  return (
    <div className={styles["JobDetails-location"]}>
      <Text className={styles["section-title"]}>
        {localeTitles.TITLE_LOCATION}
      </Text>

      <Text className={styles["section-text"]}>
        <GpsIcon /> {address}
      </Text>

      <div className={styles["JobDetails-section-map"]}>
        <GoogleMap
          lat={coordinates?.[1]}
          lng={coordinates?.[0]}
        />
      </div>
    </div>
  );
};

