import ListPage from "../../pages/ListPage";
import { useBranchesServices } from "./hook";
import Loader from "../../components/atoms/Loader";


const AllowedBranchesServices = () => {
  const { appDataLoading, dropdowns, onToggleCategroryPrice,allowedBranches } =
    useBranchesServices();

  if (appDataLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ListPage
        type={"Allowed Branches"}
        list={dropdowns?.branches}
        totalUsers={dropdowns?.branches?.length}
        loading={appDataLoading}
        onToggleCategroryPrice={onToggleCategroryPrice}
        allowedBranches={allowedBranches as any}
      />
    </>
  );
};

export default AllowedBranchesServices;
