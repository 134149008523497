import React from "react";
import { ErrorMessage } from "../ErrorMessage";
import { Control, Controller, FieldValues } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";

import { googleAPIKey } from "constants/constantData";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import styles from "./Input.module.scss";

// Define a generic InputProps type that accepts the Control type and automatically extracts the name prop.
type InputProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "size" | "prefix" | "onChange"
> & {
  control: Control<any>;
  name: keyof TFieldValues;
  wrapClassName?: string;
  className?: string;
  label?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onClickSuffix?: () => void;
  color?: string;
  value?: string;
  onChangeCoordinates?: (
    coordinates: [number, number],
    formatedAddress?: string,
    postal_code?: string,
    strret?: string,
    city?: string,
    country?: string
  ) => void;
  preDefinedWrapClassName?: "inputField-wrap-password" | "inputField-wrap";
  preDefinedClassName?: "inputField";
};

const ControlAutoCompleteInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapClassName = "",
      className = "",
      label = "",
      prefix,
      suffix,
      onClickSuffix,
      color = "",
      control,
      name = "",
      value,
      onChangeCoordinates,
      preDefinedClassName = "inputField",
      preDefinedWrapClassName = "inputField-wrap",
      defaultChecked,
      ...restProps
    },
    ref
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          console.log("value", errors);
          return (
            <div className="flex flex-col mb-2 w-full">
              <div
                className={`${
                  wrapClassName?.length > 0
                    ? wrapClassName
                    : styles[preDefinedWrapClassName]
                }`}
              >
                {!!label && label}
                {!!prefix && prefix}
                <Autocomplete
                  inputAutocompleteValue={value}
                  apiKey={googleAPIKey}
                  disabled={restProps?.disabled}
                  className={`${
                    styles["autoComplete"]
                  } bg-transparent border-0 ${
                    className?.length > 0
                      ? className
                      : styles[preDefinedClassName]
                  }`}
                  onPlaceSelected={(place: any) => {
                    const addressComponents = place.address_components;
                    // console.log("addressComponent", addressComponents);
                    if (addressComponents) {
                      // setValue("address", place.formatted_address);
                      onChange(place.formatted_address);
                      let postal_code = "";
                      let street = "";
                      let city = "";
                      let country = "";
                      let streetNumber = "";

                      for (let i = 0; i < addressComponents.length; i++) {
                        const component = addressComponents[i];
                        console.log("addres commponent", component);
                        if (component.types.includes("postal_code")) {
                          postal_code = component.long_name;
                        }
                        if (component.types.includes("route")) {
                          street = component.long_name;
                        }

                        if (component.types.includes("street_number")) {
                          streetNumber = component.long_name;
                        }

                        // now
                        if (component.types.includes("locality")) {
                          city = component.long_name;
                        }
                        if (component.types.includes("country")) {
                          country = component.long_name;
                        }
                      }

                      geocodeByAddress(place.formatted_address)
                        .then((results) => getLatLng(results[0]))
                        .then(({ lat, lng }) => {
                          onChangeCoordinates?.(
                            [lng, lat],
                            place?.formatted_address,
                            postal_code,
                            street + " " + streetNumber,
                            city,
                            country
                          );
                        });
                    }
                  }}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                  options={{
                    fields: [
                      "address_components",
                      "formatted_address",
                      "geometry",
                    ],
                    types: ["address"],
                  }}
                  placeholder={restProps.placeholder}
                  defaultValue={value}
                />

                {!!suffix && (
                  <div role="button" className="pr-2" onClick={onClickSuffix}>
                    {suffix}
                  </div>
                )}
              </div>
              {!!errors && (
                <ErrorMessage errors={errors?.[name]?.message as string} />
              )}
            </div>
          );
        }}
      />
    );
  }
);

export { ControlAutoCompleteInput };
