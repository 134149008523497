import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import type { headerProps } from "./@types";
import { localeLabels, useLocale } from "../../../locales";
import DropDown from "../../molecules/DropDown";
import styles from "./Header.module.scss";
import { SlArrowDown } from "react-icons/sl";
import { IoArrowForward, IoMenuOutline } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";
import { Button, Text } from "../../../components/atoms";
import { colorBgGray, colorBlack, colorWhite } from "../../../styles/colors";
import useComponentVisibleDropdown from "../../../utils/useCompositeDropdown";
import { useWidth } from "../../../utils/responsiveHook";
import { useDisableBodyScroll } from "../../../utils/usePreventScroll";
import Input from "../../atoms/Input";
import SearchIcon from "../../../assets/Jsx/SearchIcon";
import LanguageDropDown from "../LanguageDropDown";
import NotificationDropDown from "../NotificationDropDown";
import BellIcon from "../../../assets/Jsx/BellIcon";
import { SubmitHandler, useForm } from "react-hook-form";
import ControlSelect from "../../molecules/ControlSelect";

export const filterShowOptions = (localeLabel: typeof localeLabels) => [
  { value: "name", label: localeLabel.LABEL_SEARCH_BY_NAME },
  { value: "email", label: localeLabel.LABEL_SEARCH_BY_EMAIL },
  { value: "phone", label: localeLabel.LABEL_SEARCH_BY_CONTACT },
];

const Header = ({
  isAdmin = false,

  backArrow = false,
  headerPath = [],
  dashboard = "/",
  onEditAccount,
  onUpdatePassword,
  signOut,
  notifications,
  searchBar,
  onSubmitSearch,
  control,
  handleSubmit,
}: headerProps) => {
  const {
    localeButtons,
    localeLabels,
    localeLinks,
    localeTitles,
    localePlaceholders,
  } = useLocale();

  const { register } = useForm<any>({
    defaultValues: {},
  });
  // const name = window.location.pathname.replaceAll("/", " ");
  const name = "Dashboard";

  const navigate = useNavigate();
  const { width } = useWidth();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { scrollEnable, scrollDisable } = useDisableBodyScroll();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisibleDropdown(false);
  const toggle = () => {
    if (isComponentVisible == true) {
      setIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
    }
  };
  useEffect(() => {
    if (isComponentVisible) {
      scrollDisable();
    } else {
      scrollEnable();
    }
  }, [isComponentVisible]);

  const titleType = () => {
    if (
      location.pathname.includes("dashboard") ||
      (location.pathname.length === 1 && location.pathname.startsWith("/"))
    )
      return localeLinks.LINK_DASHBOARD;
  };

  return (
    <div>
      <div className={styles["header"]}>
        <div className=" block lg:hidden " style={{ fontSize: "0.8rem" }}>
          <div
            onClick={() =>
              !location.pathname.includes("order-detail")
                ? navigate(dashboard)
                : navigate(-1)
            }
          >
            <div className="flex flex-col ">
              <div className="flex items-center">
                {backArrow && (
                  <FiChevronLeft
                    className="mr-2"
                    size={18}
                    color={width <= 991 ? "white" : "black"}
                  />
                )}
                {/* <Text
                  size={"xs"}
                  weight="700"
                  color={width <= 991 ? "white" : "black"}
                >
                  {localeTitles.TITLE_SERVICE}
                </Text> */}
              </div>
              <div className=" font-semibold">
                <Text
                  size={"10"}
                  weight="700"
                  color={width <= 991 ? "white" : "black"}
                >
                  {titleType()}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden items-center space-x-3 font-medium lg:flex">
          {headerPath.length > 0 ? (
            <div className="flex items-center space-x-3">
              {headerPath.map((item, index) => {
                return (
                  <div
                    className="flex items-start hover:font-bold"
                    onClick={() => {
                      if (headerPath.length - 1 === index) {
                        return;
                      }
                      navigate(-(headerPath.length - index - 1));
                    }}
                  >
                    <div className="HeaderLink">
                      <Text size={"m"}>{item}</Text>
                    </div>
                    {/* <SlArrowDown
                      className="-rotate-90"
                      size={16}
                      style={{ marginTop: 5 }}
                    /> */}
                  </div>
                );
              })}
            </div>
          ) : name ? (
            <div className="flex items-center space-x-3">
              <div>{name}</div>
              {/* <MdArrowForwardIos /> */}
              <IoArrowForward />
            </div>
          ) : null}
        </div>
        {searchBar && (
          <form
            onSubmit={handleSubmit(onSubmitSearch)}
            className={styles["detail-field"]}
          >
            <div className={styles["inputWrap"]}>
              <SearchIcon onClick={handleSubmit(onSubmitSearch)} />

              <ControlSelect
                name={`by`}
                control={control}
                list={filterShowOptions(localeLabels)}
                // className={styles["selectInputAvailability"]}
              />
              <Input
                control={control}
                // register={register}
                placeholder={localePlaceholders.PLACEHOLDER_SEARCH}
                name={"search"}
                width="auto"
                className={styles["searchInput"]}
              />
            </div>
          </form>
        )}

        {/* <div>

</div> */}
        <div className="flex space-x-5 items-center">
          <LanguageDropDown />

          <NotificationDropDown
            icon={<BellIcon />}
            notifications={notifications}
          />
          <DropDown
            isAdmin={isAdmin}
            onEditAccount={onEditAccount}
            onUpdatePassword={onUpdatePassword}
            signOut={signOut}
          />
          <div className="block lg:hidden">
            {!isComponentVisible && (
              <IoMenuOutline
                color="white"
                size={32}
                onClick={() => {
                  isComponentVisible == false
                    ? setIsComponentVisible(true)
                    : setIsComponentVisible(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {isComponentVisible && width < 1024 ? (
        <div
          ref={ref}
          className="bg-black text-white w-full z-50 fixed top-0 right-0 bg-opacity-100 mt-[100px]"
        >
          <div className=" flex flex-col w-full text-center ">
            <>
              <NavLink
                to={"/"}
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2  h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer  pl-8 w-[100%] text-left  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={location.pathname === "/" ? "black" : "white"}
                  weight={location.pathname === "/" ? "700" : "400"}
                  size={"xs"}
                >
                  {localeLinks.LINK_DASHBOARD}
                </Text>
              </NavLink>

              <NavLink
                to="/employees"
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/employees") ? "black" : "white"
                  }
                  weight={
                    location.pathname.includes("/employees") ? "700" : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_EMPLOYEES_VIEW}
                </Text>
              </NavLink>

              <NavLink
                to={"/freelancers"}
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/freelancers")
                      ? "black"
                      : "white"
                  }
                  weight={
                    location.pathname.includes("/freelancers") ? "700" : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_FREELANCERS_VIEW}
                </Text>
              </NavLink>

              <NavLink
                to={"/organizations"}
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/organizations")
                      ? "black"
                      : "white"
                  }
                  weight={
                    location.pathname.includes("/organizations") ? "700" : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_ORGANIZATIONS_VIEW}
                </Text>
              </NavLink>

              <NavLink
                to={"/branches"}
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8   font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/branches") ? "black" : "white"
                  }
                  weight={
                    location.pathname.includes("/branches") ? "700" : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_ORGANIZATION_BRANCHES}
                </Text>
              </NavLink>

              <NavLink
                to={"/categories"}
                className={({ isActive }) =>
                  isActive ||
                  location.pathname.includes("/categories") ||
                  location.pathname.includes("/branch/categories/")
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/categories") ||
                    location.pathname.includes("/branch/categories/")
                      ? "black"
                      : "white"
                  }
                  weight={
                    location.pathname.includes("/categories") ||
                    location.pathname.includes("/branch/categories/")
                      ? "700"
                      : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_ORGANIZATION_CATEGORY}
                </Text>
              </NavLink>

              <NavLink
                to={"/skillset"}
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/skillset") ? "black" : "white"
                  }
                  weight={
                    location.pathname.includes("/skillset") ? "700" : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_EMPLOYEE_SKILLSET}
                </Text>
              </NavLink>
              <NavLink
                to={"/reports"}
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/reports") ? "black" : "white"
                  }
                  weight={
                    location.pathname.includes("/reports") ? "700" : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_REPORTS}
                </Text>
              </NavLink>
              <NavLink
                to={"/organization/jobs/all"}
                className={({ isActive }) =>
                  isActive
                    ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                    : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
                }
              >
                <Text
                  color={
                    location.pathname.includes("/organization/jobs/all") ? "black" : "white"
                  }
                  weight={
                    location.pathname.includes("/organization/jobs/all") ? "700" : "400"
                  }
                  size={"xs"}
                >
                  {localeLinks.LINK_SEE_ALL_JOBS}
                </Text>
              </NavLink>
            </>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
