import { useState } from "react";
import { Text } from "components/atoms/TextF";
import styles from "./WeekView.module.scss";
import { WeekViewProps } from "./@types";
import { useLocale } from "locales";
import dayjs from "dayjs";
import LabelIndicator from "assets/svgs/LabelIndicator";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

import { Pannel } from "../DayView/Panel";
import { IAvailabilties } from "../ScheduleModal/@types";
import { IHiredWokers, ITask, JobData } from "types/schema";
import ModalComponent from "components/molecules/ModalF/Modal";
import EditAvailability from "../EditAvailability";
import { usePersonalSchedulePage } from "../DayView/panel.hook";
import AddCircleIcon from "assets/svgs/AddCircleIcon";
import { BsPlus } from "react-icons/bs";

import MothStyles from "components/cells/MonthView/MonthView.module.scss";
import { useSearchParams } from "react-router-dom";
import { useWidth } from "utils/responsiveHook";

const generateWeekViewData = (selectedDate: dayjs.Dayjs) => {
  const week = [];
  let currentDay = selectedDate.startOf("week").add(1, "day");

  for (let i = 0; i < 7; i++) {
    week.push(currentDay);
    currentDay = currentDay.add(1, "day");
  }

  return [week]; // Return an array with a single week
};

function getWeekNumber(dateString: string): number {
  const date = new Date(dateString);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  const yearStart = new Date(date.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(
    ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  return weekNumber;
}

const WeekView = ({
  selectedDate,
  addAvailability,
  refetchData,
  eventsData,
  isClander = true,
}: // weeks,
WeekViewProps) => {
  const weeks = generateWeekViewData(selectedDate);
  const { localeLabels } = useLocale();

  const { width } = useWidth();

  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const [currentSelectedDate, setCurrentSelectedDate] = useState(
    selectedDate?.[0]
  );
  const [params, setParamas] = useSearchParams({
    date: selectedDate.format("YYYY-MM-DD"),
  });
  const openPopover = (date: dayjs.Dayjs, jobId?: string) => {
    if (jobId) {
      setParamas({ job: jobId, date: date.format("YYYY-MM-DD") });
    } else {
      setParamas({ date: date.format("YYYY-MM-DD") });
    }

    setCurrentSelectedDate(date.toDate());
    setPopoverOpen(true);
  };

  return (
    <>
      <div
        className={
          isClander ? MothStyles["month-view-main"] : styles["month-view-main"]
        }
      >
        <table
          className={
            isPopoverOpen
              ? MothStyles["month-view"]
              : MothStyles["month-view-full"]
          }
        >
          <thead className={MothStyles["datesContainer-head"]}>
            <tr
              className={
                isClander
                  ? MothStyles["datesContainer-1"]
                  : styles["datesContainer-1"]
              }
            >
              <td
                className={`${MothStyles["dayLabel-day"]} ${MothStyles["dayLabel-day-none"]}`}
              >
                <Text className={MothStyles["dayLabel"]}>
                  {localeLabels.LABEL_DAY}
                </Text>
              </td>
              <td className={MothStyles["dayLabel-day"]}>
                {localeLabels.LABEL_MON.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={MothStyles["dayLabel-day"]}>
                {localeLabels.LABEL_TUE.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={MothStyles["dayLabel-day"]}>
                {localeLabels.LABEL_WED.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={MothStyles["dayLabel-day"]}>
                {localeLabels.LABEL_THU.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={MothStyles["dayLabel-day"]}>
                {localeLabels.LABEL_FRI.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={MothStyles["dayLabel-day"]}>
                {localeLabels.LABEL_SAT.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={MothStyles["dayLabel-day"]}>
                {localeLabels.LABEL_SUN.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              {isClander && <td className={MothStyles["dayLabel-day"]}></td>}
            </tr>
          </thead>
          <tbody>
            {weeks?.map((week, weekIndex: number) => (
              <tr
                className={
                  isClander
                    ? MothStyles["datesContainer"]
                    : styles["datesContainer"]
                }
                key={weekIndex}
              >
                <td className={MothStyles["dateContainer-week"]}>
                  <Text className={MothStyles["dayLabel"]}>
                    {`${localeLabels.LABEL_WEEK} ${getWeekNumber(
                      week[0].format("YYYY-MM-DD")
                    )}`}
                  </Text>
                </td>
                {week?.map((date, dateIndex: number) => {
                  const availabilityEvent =
                    eventsData?.[dayjs(date).format("YYYY-MM-DD")]
                      ?.availability;
                  const jobs =
                    eventsData?.[dayjs(date).format("YYYY-MM-DD")]?.hiredWorker;

                  return (
                    <TableCell
                      availabilityEvent={availabilityEvent}
                      jobs={jobs}
                      dateIndex={dateIndex}
                      openPopover={openPopover}
                      date={date}
                      refetchData={refetchData}
                      isClander={isClander}
                    />
                  );
                })}

                {isClander && (
                  <td className={MothStyles["dateContainer"]}>
                    <Text className={MothStyles["addAvailability"]}>
                      <BsPlus
                        onClick={() =>
                          addAvailability([
                            dayjs(week[0]).startOf("week").toDate(),
                            dayjs(week[0]).endOf("week").toDate(),
                          ])
                        }
                        color={"white"}
                        size={width > breakPoints.lg ? 40 : 40}
                      />
                    </Text>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {isPopoverOpen && isClander && (
          <Pannel
            setPopoverOpen={setPopoverOpen}
            isPopoverOpen={isPopoverOpen}
            selectedDate={currentSelectedDate}
            jobs={
              eventsData?.[dayjs(currentSelectedDate).format("YYYY-MM-DD")]
                ?.hiredWorker
            }
            availability={
              eventsData?.[dayjs(currentSelectedDate).format("YYYY-MM-DD")]
                ?.availability
            }
            addAvailability={() =>
              addAvailability([
                dayjs(currentSelectedDate).startOf("week").toDate(),
                dayjs(currentSelectedDate).endOf("week").toDate(),
              ])
            }
            refetchData={refetchData}
          />
        )}
      </div>
      <div className={styles["datesContainer-bottom"]}>
        <div className={styles["indicators"]}>
        <div className={styles["each-indicator"]}>
            <LabelIndicator color="#181203" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_HIRED}
            </Text>
          </div>
          {isClander && <>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="#DA011C" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_REJECTED}
            </Text>
          </div>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="rgb(228, 174, 38)" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_DECLINED}
            </Text>
          </div>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="#819bf7" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_APPROVED}
            </Text>
          </div>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="#C9D5FF" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_COMPLETED}
            </Text>
          </div>
          </>}
        </div>

        {width < breakPoints.md && (
          <div className={styles["indicators"]}>
            <div className={styles["each-indicator"]}>
              <LabelIndicator color="#009633" />
              <Text className={styles["labelText"]}>
                {localeLabels.LABEL_AVAILABLE}
              </Text>
            </div>
            <div className={styles["each-indicator"]}>
              <LabelIndicator color="#DA011C" />
              <Text className={styles["labelText"]}>
                {localeLabels.LABEL_UNAVAILABLE}
              </Text>
            </div>
            <div className={styles["each-indicator"]}>
              <LabelIndicator color="#B5B8C3" />
              <Text className={styles["labelText"]}>
                {localeLabels.LABEL_PARTIAL_AVAILABLE}
              </Text>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

interface CellProps {
  date: dayjs.Dayjs;
  dateIndex: number;
  availabilityEvent: IAvailabilties;
  openPopover: (date: dayjs.Dayjs, jobId?: string) => void;
  jobs: {
    job: JobData;
  } & IHiredWokers[];
  refetchData: () => void;
  isClander: boolean;
}

export const TableCell = (props: CellProps) => {
  const [focus, setFocus] = useState(false);

  const {
    modals,
    toggleEditAvailabilityModal,
    onConfirmAvailabilityEdit,
    loading,
    openAvailabiltySchedule,
    availability,
  } = usePersonalSchedulePage(props.refetchData);
  const { width } = useWidth();

  const { localeLabels } = useLocale();

  const returnLabel = () => {
    if (props.availabilityEvent?.availability === "available") {
      return localeLabels.LABEL_AVAILABLE;
    } else if (props.availabilityEvent?.availability === "partial") {
      return localeLabels.LABEL_PARTIAL_AVAILABLE;
    } else if (props.availabilityEvent?.availability === "unavailable") {
      return localeLabels.LABEL_NOT_AVAILABLE;
    }
  };

  const onBlur = () => {
    if (props.availabilityEvent || props.jobs?.length > 0) {
      setFocus(false);
    } else {
      setFocus(true);
    }
  };

  return (
    <td
      onMouseEnter={onBlur}
      onMouseLeave={() => setFocus(false)}
      onBlur={() => setFocus(false)}
      key={props?.dateIndex}
      className={`${styles["clander-cell"]} ${
        focus && !props.availabilityEvent && styles["focus"]
      }`}
      onClick={(e) => {
        e.stopPropagation();
        if (!focus) {
          props?.openPopover(props?.date);
        }
      }}
    >
      <span className={styles["availability-cell"]}>
        <div className={styles["dateLabel"]}>{props?.date.format("D")}</div>
        <span>
          {props?.availabilityEvent && (
            <span
              className={
                styles[`availability-${props?.availabilityEvent?.availability}`]
              }
            >
              {returnLabel()}
            </span>
          )}
        </span>
      </span>

      { props?.jobs?.map((job) => {
            return (
              <TasksItem
                key={job?._id}
                job={job?.job}
                hiredWorker={job}
                isClander={props.isClander}
                openPopover={(jobId) => props.openPopover(props.date, jobId)}
                cellDate={props.date}
              />
            );
          })}
      {focus && !props.availabilityEvent && props.isClander && (
        <span className={styles["add-icon"]}>
          <AddCircleIcon
            size={"40"}
            color={"#000000"}
            onClick={(event) => {
              event.stopPropagation();
              setFocus(false);
              openAvailabiltySchedule({
                date: props.date?.format("YYYY-MM-DD"),
                availability: "available",
                reason: "",
                timeFrame: [],
                _id: "",
                shift: "day_shift",
              });
            }}
          />
        </span>
      )}

      {modals.editAvailability && (
        <ModalComponent
          open={modals.editAvailability}
          onClose={toggleEditAvailabilityModal}
        >
          <EditAvailability
            selectedEvent={availability}
            loading={loading}
            onConfirm={onConfirmAvailabilityEdit}
            closeModal={toggleEditAvailabilityModal}
          />
        </ModalComponent>
      )}
    </td>
  );
};

const TasksItem = ({
  job,
  hiredWorker,
  isClander = true,
  openPopover,
  cellDate
}: {
  job: JobData;
  hiredWorker: IHiredWokers;
  isClander: boolean;
  openPopover: (jobId?: string) => void;
  cellDate: dayjs.Dayjs;
}) => {
  const { localeLabels } = useLocale();

  const isWorkingDateExist = hiredWorker?.workingDates?.find(
    (day) => cellDate.format("YYYY-MM-DD") === dayjs(day.date).format("YYYY-MM-DD")
  );
const {width} = useWidth()
  return (
    <span
      className={`${styles["task-item"]} ${
        styles[`task-${isClander ? isWorkingDateExist?.status : "hired"}`]
      }`}
      onClick={(e) => {
        e.stopPropagation();
        openPopover(job._id);
      }}
    >
     {width > breakPoints.md ? <>
      <span
        className={`${styles["task-stick"]} ${
          styles[`task-stick-${isClander ? isWorkingDateExist?.status : "hired"}`]
        }`}
      />
      <span className={styles["task-info"]}>
        <Text className={styles["task-name"]}>
          {isClander ? job?.title : localeLabels.LABEL_ALREADY_HIRED}
        </Text>
        <Text className={styles["task-time"]}>
          {dayjs(job?.timeToStart).format("hh:mm")}-{" "}
          {dayjs(job?.timeToEnd).format("hh:mm")}
        </Text>
      </span>
      </>
      : 
        <Text className={styles["task-name"]}>
          {isClander ? job?.title.substring(0,2) : localeLabels.LABEL_ALREADY_HIRED.substring(0,2)}
        </Text>
      
    }
    </span>
  );
};

export default WeekView;
