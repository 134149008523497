
import { AxiosResponse } from "axios";

import apiRequest from "utils/axios";
import {
  GetOrganizationJobsResponse,
  GetJobDetailResponse,
  jobApplicantsApiResponse,
  workingDatesResponse,
} from "./types";
import { JOB_FORMS } from "pages/JobPostingSection/@types";

// import { CreateOrganizationJob } from "types/schema";

//  /Get all organization jobs


//  Create organization job

export const createJobAPI = async (
  data: JOB_FORMS,
  token: string
): Promise<GetJobDetailResponse> => {
  console.log("createJobAPI", data, "token", token);

  const response = await apiRequest({
    method: "post",
    url: `organization/job/admin`,
    data,
    token,
  });
  return response;
};

//  Edit organization job

export const editJobAPI = async (
  data: JOB_FORMS,
  token: string
): Promise<GetOrganizationJobsResponse> => {
  console.log("editJobAPI", data, "token", token);

  const response = await apiRequest({
    method: "put",
    url: `organization/job/admin`,
    data,
    token,
  });
  return response;
};

//  Delete organization job

export const deleteJobAPI = async (
  jobId: string,
  token: string
): Promise<AxiosResponse<GetOrganizationJobsResponse, any>> => {
  console.log("createJobAPI", jobId, "token", token);

  const response = await apiRequest({
    method: "delete",
    url: `organization/job/${jobId}`,

    token,
  });
  return response;
};

