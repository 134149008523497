import styles from "./WorkerDetails.module.scss";
import { useLocale } from "locales";

import { useWorkerDetailsPage } from "./hook";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

import WorkerCardLarge from "components/organisms/WorkerCardLarge";
import SkillAndExpertiseCard from "components/organisms/SkillAndExpertiseCard";
import JobsCompletedSection from "components/organisms/JobsCompletedSection";
import EmployeeRatingAndReview from "components/organisms/EmployeeRatingAndReview";
import CompanyCard from "components/organisms/CompanyCard";
import { WorkerDetailsProps } from "./@types";

import ModalComponent from "components/molecules/ModalF/Modal";
import InviteWorkerModal from "components/cells/InviteWorkerModal";
import JobDescriptionCard from "components/organisms/JobDescriptionCard";
import WeeklyAvailability from "components/organisms/WorkerWeeklyAvailablity";

import { useWidth } from "utils/responsiveHook";
import Loader from "components/atoms/Loader";
import { MapInDetailPage } from "pages/OrganizationJobsPage/JobDetail/JobDetailsOrganization/JobDetails/JobDetails";
import ConfirmationModal from "components/molecules/ConfirmationModal";
import CheckCircleIcon from "assets/svgs/CheckCircleIcon";

const WorkerDetails = ({
  workerData,
  loading,
  inviteLoading,
  onClickInvite,
  inviteModal,
  closeInviteModal,
  onConfirmInvite,
  refetch,
}: WorkerDetailsProps) => {
  const {
    selectedCompanyData,
    getData,
    onNavigate,
    onCloseHireModal,
    onHireWorker,
    onClickHire,
    hireLoading,
    hireModal
  } = useWorkerDetailsPage(refetch);
  const { width } = useWidth();
  const { localeTitles, localeButtons } = useLocale();

  if (loading) {
    return (
      <div className={"loadingScreen"}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles["WorkerDetails"]}>
      <WorkerCardLarge
        data={workerData?.user}
        // getWorkerData={getWorkerData}
        onHireWorker={() => onClickHire(workerData?.user?._id as string)}
        onClickInvite={() => onClickInvite(workerData?.user?._id as string)}
      />
      <div className="flex flex-row">
        <div className={styles["WorkerDetails-main"]}>
          {width >= breakPoints.lg && (
            <div
              className={
                styles[
                  selectedCompanyData
                    ? "WorkerDetails-left-reduced"
                    : "WorkerDetails-left"
                ]
              }
            >
              {workerData && workerData?.user?.skills?.length > 0 && (
                <SkillAndExpertiseCard skills={workerData?.user?.skills} />
              )}

              {workerData && workerData?.user?.categories?.length > 0 && (
                <SkillAndExpertiseCard
                  title={localeTitles.TITLE_CATEGORY}
                  skills={workerData?.user?.categories}
                />
              )}
              {workerData && workerData?.user?.branches?.length > 0 && (
                <SkillAndExpertiseCard
                  title={localeTitles.TITLE_BRANCH}
                  skills={workerData?.user?.branches}
                />
              )}

              {workerData?.user?.bio && workerData?.user?.bio?.length > 0 && (
                <JobDescriptionCard
                  title={localeTitles.TITLE_BIO}
                  description={workerData?.user?.bio}
                />
              )}

              <MapInDetailPage
                coordinates={workerData?.user?.address?.location?.coordinates}
                address={workerData?.user?.address?.address + ""}
              />

              {workerData && workerData?.availability && (
                <WeeklyAvailability
                  data={workerData?.availability}
                  title={localeTitles.TITLE_AVAILABILITY_SCHEDULE}
                  showDetaile
                  onNavigate={onNavigate}
                />
              )}
              {workerData?.completedJobs &&
                workerData?.completedJobs?.length > 0 && (
                  <JobsCompletedSection
                    data={workerData?.completedJobs}
                    getData={getData}
                  />
                )}
              {workerData && workerData?.Ratings?.length > 0 && (
                <EmployeeRatingAndReview data={workerData?.Ratings} />
              )}
            </div>
          )}
          {width < breakPoints.lg && (
            <>
              {workerData && workerData?.user?.skills?.length > 0 && (
                <SkillAndExpertiseCard skills={workerData?.user?.skills} />
              )}
              {workerData && workerData?.user?.categories?.length > 0 && (
                <SkillAndExpertiseCard
                  title={localeTitles.TITLE_CATEGORY}
                  skills={workerData?.user?.categories}
                />
              )}
              {workerData && workerData?.user?.branches?.length > 0 && (
                <SkillAndExpertiseCard
                  title={localeTitles.TITLE_BRANCH}
                  skills={workerData?.user?.branches}
                />
              )}

              {/*Location  */}

              {workerData?.user?.bio && workerData?.user?.bio?.length > 0 && (
                <JobDescriptionCard
                  title={localeTitles.TITLE_BIO}
                  description={workerData?.user?.bio}
                />
              )}

              <MapInDetailPage
                coordinates={workerData?.user?.address?.location?.coordinates}
                address={workerData?.user?.address?.address + ""}
              />

              {workerData && workerData?.availability && (
                <WeeklyAvailability
                  data={workerData?.availability}
                  title={localeTitles.TITLE_AVAILABILITY_SCHEDULE}
                  showDetaile
                  onNavigate={onNavigate}
                />
              )}

              {width < breakPoints.lg && selectedCompanyData && (
                <CompanyCard data={selectedCompanyData} />
              )}
              {workerData?.completedJobs && (
                <JobsCompletedSection
                  data={workerData?.completedJobs}
                  getData={getData}
                />
              )}
              {workerData && workerData?.Ratings?.length > 0 && (
                <EmployeeRatingAndReview data={workerData?.Ratings} />
              )}
            </>
          )}
          <ModalComponent open={inviteModal} onClose={closeInviteModal}>
            <InviteWorkerModal
              onConfirm={onConfirmInvite}
              closeModal={closeInviteModal}
              loading={inviteLoading}
            />
          </ModalComponent>
          <ModalComponent open={hireModal} onClose={onCloseHireModal}>
            <ConfirmationModal
              cancelButtonText={localeButtons?.BUTTON_NO_CANCEL}
              confirmButtonText={localeButtons?.BUTTON_YES}
              icon={<CheckCircleIcon />}
              onConfirm={onHireWorker}
              subTitle={
                <>
                  {localeTitles.TITLE_JOB_Hire}
                  <br />
                  {localeTitles.TITLE_JOB_HIRING_MESSAGE}
                </>
              }
              closeModal={onCloseHireModal}
              loading={hireLoading}
            />
          </ModalComponent>
        </div>
        {width >= breakPoints.lg && selectedCompanyData && (
          <div className={styles["WorkerDetails-right"]}>
            <CompanyCard data={selectedCompanyData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkerDetails;
