import React from "react";
type Props = { white?: boolean; size?: number };
function CancleCircleIcon({ white, size=30 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.625 20.875C9.85417 21.1042 10.1458 21.2188 10.5 21.2188C10.8542 21.2188 11.1458 21.1042 11.375 20.875L15 17.25L18.6562 20.9062C18.8854 21.1354 19.1721 21.2446 19.5163 21.2337C19.8596 21.2237 20.1458 21.1042 20.375 20.875C20.6042 20.6458 20.7188 20.3542 20.7188 20C20.7188 19.6458 20.6042 19.3542 20.375 19.125L16.75 15.5L20.4062 11.8438C20.6354 11.6146 20.7446 11.3279 20.7337 10.9838C20.7237 10.6404 20.6042 10.3542 20.375 10.125C20.1458 9.89583 19.8542 9.78125 19.5 9.78125C19.1458 9.78125 18.8542 9.89583 18.625 10.125L15 13.75L11.3438 10.0938C11.1146 9.86458 10.8283 9.755 10.485 9.765C10.1408 9.77583 9.85417 9.89583 9.625 10.125C9.39583 10.3542 9.28125 10.6458 9.28125 11C9.28125 11.3542 9.39583 11.6458 9.625 11.875L13.25 15.5L9.59375 19.1562C9.36458 19.3854 9.25542 19.6717 9.26625 20.015C9.27625 20.3592 9.39583 20.6458 9.625 20.875ZM15 28C13.2708 28 11.6458 27.6717 10.125 27.015C8.60417 26.3592 7.28125 25.4688 6.15625 24.3438C5.03125 23.2188 4.14083 21.8958 3.485 20.375C2.82833 18.8542 2.5 17.2292 2.5 15.5C2.5 13.7708 2.82833 12.1458 3.485 10.625C4.14083 9.10417 5.03125 7.78125 6.15625 6.65625C7.28125 5.53125 8.60417 4.64042 10.125 3.98375C11.6458 3.32792 13.2708 3 15 3C16.7292 3 18.3542 3.32792 19.875 3.98375C21.3958 4.64042 22.7188 5.53125 23.8438 6.65625C24.9688 7.78125 25.8592 9.10417 26.515 10.625C27.1717 12.1458 27.5 13.7708 27.5 15.5C27.5 17.2292 27.1717 18.8542 26.515 20.375C25.8592 21.8958 24.9688 23.2188 23.8438 24.3438C22.7188 25.4688 21.3958 26.3592 19.875 27.015C18.3542 27.6717 16.7292 28 15 28Z"
        fill="#101828"
      />
    </svg>
  );
}

export default CancleCircleIcon;
