import React from "react";
import { colorWhite } from "../../../styles/colors";
import { Text } from "../../atoms";
import type { AddButtonProps } from "./@types";
import styles from "./AddButton.module.scss";
import Loader from "../Loading";

const AddButton = ({ text, onClick, loading, ...props }: AddButtonProps) => {
  return (
    <div className={styles["icon-button"]} onClick={onClick}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.6667 13.3333H13.3333V18.6667H10.6667V13.3333H5.33333V10.6667H10.6667V5.33333H13.3333V10.6667H18.6667M21.3333 0H2.66667C1.18667 0 0 1.18667 0 2.66667V21.3333C0 22.0406 0.280951 22.7189 0.781048 23.219C1.28115 23.719 1.95942 24 2.66667 24H21.3333C22.0406 24 22.7189 23.719 23.219 23.219C23.719 22.7189 24 22.0406 24 21.3333V2.66667C24 1.95942 23.719 1.28115 23.219 0.781048C22.7189 0.280951 22.0406 0 21.3333 0Z"
          fill="white"
        />
      </svg>
      {loading ? (
        <Loader />
      ) : (
        <Text
          textStyle={{ color: colorWhite, fontWeight: "600", fontSize: "16px" }}
        >
          {text}
        </Text>
      )}
    </div>
  );
};

export default AddButton;
