import dayjs from "dayjs";
import { electronicFormatIBAN } from "ibantools";
import { useLocale } from "locales";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";
import { ACCOUNT_FORMS } from "types/forms";
import { updateUser } from "utils/globalAPI/Auth";
import { uploadFile } from "utils/hooks/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { queryClient } from "utils/QueryClient";


export const useAccountService = (accountType:string) => {

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { accessToken,  } =
  UsersContextConsumer();
  const toast = ToastConsumer();
  const { localeSuccess } = useLocale();
  const currentDate = dayjs();
  const formattedDate = currentDate.format("YYYY-MM-DD HH:mm:ss");

  const handleOpenFilter = () => {
    setOpenFilterModal(true);
  };

  const handleCloseFilter = () => {
    setOpenFilterModal(false);
  };

  const uploadImageToS3 = async (file: File, index: number) => {
    try {
      //  ;
      const uploadedFile = await uploadFile(
        file,
        `${formattedDate}-${accountType}-${index}-${file.name}`
      );
      console.log("uploadedFile", uploadedFile);
      return uploadedFile.fileKey;
    } catch (err: any) {
      toast.serverError(err, "top-right");
      console.log("error on", err);
      throw err;
    }
  };

  const upploadFiles = async (files: File[] | string) => {
    let uploadedFiles: string[] = [];
    //
    try {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (typeof file === "string") {
          uploadedFiles.push(file);
          continue;
        } else {
          const uploadedFile = await uploadImageToS3(file, index);
          uploadedFiles.push(uploadedFile);
        }
      }
      return uploadedFiles;
    } catch (err) {
      console.log("error on", err);
      throw err;
    }
  };
  const {state} = useLocation();

  const navigate = useNavigate()
  const userData = state?.userData;

  const onSubmit: SubmitHandler<ACCOUNT_FORMS> = async (data) => {
    try {
      setLoading(true); // Set loading to true before uploading images

      const updatedFields = { ...data,_id:userData?._id };
      updatedFields.IBAN = electronicFormatIBAN(updatedFields.IBAN) + "";
      if (accountType !== "organization") {
        if (updatedFields?.certificate) {
          updatedFields.certificate = await upploadFiles(
            updatedFields?.certificate as File[]
          );
        }
        if (updatedFields?.diplomas) {
          updatedFields.diplomas = await upploadFiles(
            updatedFields?.diplomas as File[]
          );
        }

        if (updatedFields?.insuranceBlockDocuments) {
          updatedFields.insuranceBlockDocuments = await upploadFiles(
            updatedFields?.insuranceBlockDocuments as File[]
          );
        }
        if (updatedFields?.idBack) {
          //
          updatedFields.idBack = await upploadFiles(
            updatedFields?.idBack as File[]
          );
        }
        if (updatedFields?.idFront) {
          //
          updatedFields.idFront = await upploadFiles(
            updatedFields?.idFront as File[]
          );
        }

        if (updatedFields?.scannedImages) {
          updatedFields.scannedImages = await upploadFiles(
            updatedFields?.scannedImages as File[]
          );
        }
      }
       
      if (typeof updatedFields?.profileImage !== "string") {
        const profileImage = await uploadImageToS3(
          updatedFields?.profileImage as File,
          1
        );
        updatedFields.profileImage = profileImage;
      }

      const response = await updateUser(
        updatedFields as any,
        accessToken
      );

      console.log("profile updated", response);
      queryClient.invalidateQueries( [
        "userDetails",
        {
          userId:userData?._id,
        },
      ],)

      toast.success(localeSuccess.SUCESS_PROFILE_UPDATED, "top-right");
      navigate(-1)
    } catch (err) {
      console.log("error on update", err);
      toast.serverError(err as any, "top-right");
    } finally {
      setLoading(false);
    }
  };

  return {
    onSubmit,
    openFilterModal,
    handleOpenFilter,
    handleCloseFilter,
    loading,
  };
};
