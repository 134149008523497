import { AxiosResponse } from "axios";
import apiRequest from "utils/axios";
import { ILoginResponse } from "./types";

export const createNewUser = async (
  data: any,
  token: string
): Promise<AxiosResponse<ILoginResponse, any>> => {
  const response = await apiRequest({
    method: "post",
    url: "/create/user/admin",
    data,
    token,
  });
  return response;
};
export const updateUser = async (
  data: any,
  token: string
): Promise<AxiosResponse<ILoginResponse, any>> => {
  const response = await apiRequest({
    method: "post",
    url: "/update/user/admin",
    data,
    token,
  });
  return response;
};
