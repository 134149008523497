import React from "react";
type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function AlertDot({ white, size }: Props) {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.71638 7.81619C5.7198 7.81619 7.34389 6.10353 7.34389 3.99086C7.34389 1.87819 5.7198 0.165527 3.71638 0.165527C1.71296 0.165527 0.0888672 1.87819 0.0888672 3.99086C0.0888672 6.10353 1.71296 7.81619 3.71638 7.81619Z"
        fill="#EB5757"
      />
    </svg>
  );
}

export default AlertDot;
