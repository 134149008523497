import React from "react";
import { useLocale } from "../../../locales";
import styles from "./ItemCard.module.scss";
import { ItemCardProps } from "./@types";
import { Text } from "../../atoms";
import { useWidth } from "../../../utils/responsiveHook";

const ItemCard = ({ title, value, icon, rate }: ItemCardProps) => {
  const { localeLabels, localeButtons } = useLocale();
  const { width } = useWidth();

  return (
    <div className={styles["itemCard"]}>
      <Text className="cardHeading" size={width <= 767 ? "xxs" : "s"}>
        {title}
      </Text>
      <div className={styles["itemCard-main"]}>
        <div className={styles["itemCard-left"]}>
          <Text className={styles["value"]} size={width <= 767 ? "xxs" : "m"}>
            {rate ? `${rate}%` : value}
          </Text>
        </div>
        <div className={styles["itemCard-right"]}>{icon}</div>
      </div>
    </div>
  );
};

export default ItemCard;
