import { useLocation, useNavigate } from "react-router-dom";
import type { SkillAndExpertiseCardProps } from "./@types";
import styles from "./SkillAndExpertiseCard.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { useState } from "react";

const SkillAndExpertiseCard = ({ skills,title }: SkillAndExpertiseCardProps) => {

  const { localeBasics, localeTitles, currentLocale } = useLocale();
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  const itemsToShow = showAll ? skills?.length : 16;

  return (
    <div className={styles["SkillAndExpertiseCard"]}>
      <Text className={styles["title"]}>
        {title || localeTitles.TITLE_SKILLS_AND_EXPERTISE}
      </Text>

      <div className={styles["SkillAndExpertiseCard-skills"]}>
        {skills?.slice(0, itemsToShow)?.map((skill, index: number) => (
          <Text key={index} className={styles["skill"]}>
            {skill?.label?.[currentLocale]}
          </Text>
        ))}
      </div>
      {!showAll && skills?.length > 16 && (
        <Text className={styles["see-more"]} onClick={toggleShowAll}>
          {localeBasics.TEXT_SEE_MORE}
        </Text>
      )}
    </div>
  );
};

export default SkillAndExpertiseCard;
