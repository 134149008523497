import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  className?: string;
  size?: string;
  onClick?: (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void | React.MouseEventHandler<SVGSVGElement>;
};
function AddCircleIcon({ color, size, onClick, className }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      className={className}
      onClick={onClick}
      width={size ? size : "24"}
      height={size ? size : "24"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z"
        stroke={color ? color : "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12.5H16"
        stroke={color ? color : "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 16.5V8.5"
        stroke={color ? color : "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AddCircleIcon;

// fill={white ? "#fffff" : "#0F1322"}
