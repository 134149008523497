import React, { ReactNode, useState } from "react";
import { Button, Modal } from "@mui/material";
import styles from "./Modal.module.scss"; // Import the SCSS file

interface ModalComponentProps {
  open: boolean;
  onClose: (e?:any) => void;
  children: ReactNode;
  modaolContentClass?:string;
  modalClassName?:string;
  className?: string;
  heightClassName?: "min-height-80vh"|""
  //  | "min-height-60vh" | "min-height-40vh";
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  open,
  onClose,
  children,
  modalClassName=styles["custom-modal"],
  modaolContentClass=styles["modal-content"],
  className,
  heightClassName="",
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description" // Apply the custom SCSS class
      className={`${className?`${styles["custom-modal"]} ${className}`:modalClassName}`} // Apply the custom SCSS class
    >
      <div className={`${modaolContentClass} ${styles[heightClassName+""]}`}>{children}</div>
    </Modal>
  );
};

export default ModalComponent;
