import type { CompanyDetailsCardProps } from "./@types";
import styles from "./CompanyDetailsCard.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { Img } from "components/atoms/Img";
import ReviewIcon from "../../ReviewIcon";
import RatingCard from "./RatingCard";
import { handleImagesPath } from "utils/hooks/helper";

const CompanyDetailsCard = ({ data,bio }: CompanyDetailsCardProps) => {
  const { localeTitles, localeLabels } = useLocale();

  return (
    <div className={styles["CompanyDetailsCard"]}>
      <div className={styles["CompanyDetailsCard-head"]}>
        <div className={styles["CompanyDetailsCard-head-left"]}>
          <Img
            src={handleImagesPath(data?.logo)}
            className={styles["companyLogo"]}
          />
          <div className={styles["company-details-text"]}>
            <Text className={styles["companyName"]}>{data?.companyName}</Text>
            <Text className={styles["reviews"]}>
              <ReviewIcon fill={true} />
              <Text className={styles["reviewText"]}>
                {data?.reviews} {localeLabels.LABEL_REVIEWS}
              </Text>
            </Text>
          </div>
        </div>
        <div className={styles["CompanyDetailsCard-head-right"]}>
          <RatingCard
            rating={data?.rating || 0}
            reviews={0}
            className={styles["ratingCard"]}
            ratingText={styles["ratingText"]}
            googleText={styles["googleText"]}
            logoClass={styles["logoClass"]}
            starClass={styles["starClass"]}
            subTitleClass={styles["subTitleClass"]}
          />
        </div>
      </div>

      {bio && (
        <div className={styles["CompanyDetailsCard-about"]}>
          <Text className={styles["title"]}>
            {localeTitles.TITLE_ABOUT_COMPANY}
          </Text>
          <Text className={styles["companyDetails"]}>{bio}</Text>
        </div>
      )}
    </div>
  );
};

export default CompanyDetailsCard;
