import { Task, ViewMode } from "gantt-task-react";
import React, { useEffect } from "react";

import { initTasks } from "../helper";
import { ITask } from "types/schema";
import dayjs from "dayjs";

// import dayjs plugin relativeTime
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));

export const useGantt = (
  tasksList: ITask[],
  view: ViewMode,
) => {

  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [task, setTask] = React.useState<ITask>();

  const [displayTaskDetaile, setTaskDeataile] = React.useState<boolean>(false);

  const [isChecked, setIsChecked] = React.useState(false);


  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 200;
  } else if (view === ViewMode.Month) {
    columnWidth = 100;
  } else if (view === ViewMode.Week) {
    columnWidth = 75;
  }

  const handleDblClick = (task: Task) => {
    setTask(task as any);
    toggleDisplayDetaileModal();
  };



  useEffect(() => {
    console.log("tasksList", tasksList);
    setTasks([
      ...tasksList
        ?.filter(
          (a) =>
            dayjs(a?.start).isValid() &&
            dayjs(a?.end).isValid() &&
            dayjs(a?.start).isBefore(dayjs(a?.end))
        )
        .map((t, index) => ({
          ...t,
          id: t?.id?.toString(),
          start: dayjs(t?.start).toDate(),
          end: dayjs(t?.end).toDate(),
        })),
    ]);
  }, [tasksList]);


  const toggleDisplayDetaileModal = () => {
    setTaskDeataile(!displayTaskDetaile);
  };

  return {
    columnWidth,
    isChecked,
    setIsChecked,
    tasks,
    setTasks,
    handleDblClick,
    task,
    displayTaskDetaile,
    toggleDisplayDetaileModal,
  };
};
