import { ToastConsumer } from "../../../store/ToastProvider";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useLocale } from "../../../locales";
import {
  getOrganizartionAllowedBaranches,
  ApiToggleOrganizationJobPOsting,
} from "../../../utils/globalAPI/applicationData";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

export type AddFormField = {
  en: String;
  du: String;
  jobPostingCheck?: boolean;
};

export const useBranchesServices = () => {
  const { dropdowns, appDataLoading, accessToken, fetchApi } =
    UsersContextConsumer();
  const toast = ToastConsumer();
  const { localeSuccess } = useLocale();

  const organization = useParams()?.organization;

  const { data, refetch } = useQuery(
    [
      "Allowed Branches",
      {
        organization,
      },
    ],
    async () => getOrganizartionAllowedBaranches(organization, accessToken),
    { enabled: fetchApi, refetchOnMount: false, refetchOnWindowFocus: false }
  );
  const onToggleCategroryPrice = async (data) => {
    try {
      const response = await ApiToggleOrganizationJobPOsting(
        data,
        organization,
        accessToken
      );
      refetch();
      toast.success(localeSuccess.SUCCESS_BRANCH_UPDATED, "top-right");
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
    }
  };

  return {
    appDataLoading,
    dropdowns,
    onToggleCategroryPrice,
    allowedBranches: data?.branches,
  };
};
