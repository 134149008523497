import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: boolean;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function UploadFileIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7474 6.17058L12.7019 0.2475C12.6217 0.168948 12.5264 0.106659 12.4216 0.0641925C12.3167 0.0217257 12.2044 -8.71419e-05 12.0909 2.61629e-07H1.72727C1.26917 2.61629e-07 0.829833 0.178296 0.505906 0.495666C0.18198 0.813035 0 1.24348 0 1.69231V20.3077C0 20.7565 0.18198 21.187 0.505906 21.5043C0.829833 21.8217 1.26917 22 1.72727 22H17.2727C17.7308 22 18.1702 21.8217 18.4941 21.5043C18.818 21.187 19 20.7565 19 20.3077V6.76923C19.0001 6.65808 18.9778 6.548 18.9345 6.44528C18.8911 6.34256 18.8276 6.24922 18.7474 6.17058ZM12.9545 2.88856L16.0518 5.92308H12.9545V2.88856ZM17.2727 20.3077H1.72727V1.69231H11.2273V6.76923C11.2273 6.99364 11.3183 7.20887 11.4802 7.36755C11.6422 7.52624 11.8619 7.61538 12.0909 7.61538H17.2727V20.3077ZM12.7019 12.0937C12.7822 12.1723 12.8458 12.2656 12.8892 12.3683C12.9327 12.471 12.955 12.5811 12.955 12.6923C12.955 12.8035 12.9327 12.9136 12.8892 13.0163C12.8458 13.119 12.7822 13.2123 12.7019 13.291C12.6217 13.3696 12.5264 13.4319 12.4216 13.4745C12.3168 13.517 12.2044 13.5389 12.0909 13.5389C11.9774 13.5389 11.8651 13.517 11.7602 13.4745C11.6554 13.4319 11.5601 13.3696 11.4799 13.291L10.3636 12.1962V16.9231C10.3636 17.1475 10.2726 17.3627 10.1107 17.5214C9.94872 17.6801 9.72905 17.7692 9.5 17.7692C9.27095 17.7692 9.05128 17.6801 8.88932 17.5214C8.72735 17.3627 8.63636 17.1475 8.63636 16.9231V12.1962L7.52011 13.291C7.43987 13.3696 7.34461 13.4319 7.23977 13.4745C7.13493 13.517 7.02257 13.5389 6.90909 13.5389C6.79561 13.5389 6.68325 13.517 6.57841 13.4745C6.47357 13.4319 6.37831 13.3696 6.29807 13.291C6.21783 13.2123 6.15418 13.119 6.11075 13.0163C6.06733 12.9136 6.04497 12.8035 6.04497 12.6923C6.04497 12.5811 6.06733 12.471 6.11075 12.3683C6.15418 12.2656 6.21783 12.1723 6.29807 12.0937L8.88898 9.55519C8.96919 9.47652 9.06444 9.41411 9.16928 9.37153C9.27412 9.32894 9.38651 9.30703 9.5 9.30703C9.61349 9.30703 9.72588 9.32894 9.83072 9.37153C9.93556 9.41411 10.0308 9.47652 10.111 9.55519L12.7019 12.0937Z"
        fill="#858D98"
      />
    </svg>
  );
}

export default UploadFileIcon;

// fill={white ? "#fffff" : "#0F1322"}
