export default {
  TITLE_UNBLOCK_USER: "Deblokkeer gebruiker",

  TITLE_HOME_HERO:
    "Uitzend en ZZP Platform voor vast en flex werk in de kinderopvang, zorg en meer!",
  TITLE_LOCATION: "Plaats",
  TITLE_REMOVE_LOCATION: "Verwijder locatie",
  TITLE_NEW_LOCATION: "Nieuwe locatie toevoegen",
  TITLE_EMPLOYEES: "Werknemers",
  TITLE_FREELANCER: "Freelancer",
  TITLE_OPEN_JOBS: "Openstaande diensten",
  TITLE_HOW_TO_GET_WORK: "Hoe u aan werk kunt komen",
  TITLE_THIS_IS_HOW_THE_PLATFORM_WORK:
    "Zo werkt het Berenvriendjes platform.",
  TITLE_REGISTER_AS_AN_EMPLOYEE_OR_FREELANCER:
    "Meld je aan als werknemer of ZZP-er en creëer een profiel.",
  TITLE_VIEW_ALL_SERVICES:
    "Bekijk alle diensten in jouw locatie en meld je aan voor de perfecte dienst.",
  TITLE_YES_A_MATCH: "Yes een match! Gefeliciteerd jouw eerste dienst via Berenvriendjes",
  TITLE_WHAT_TYPE_OF_PEOPLE_YOU_FIND:
    "In overleg met uw organisatie kiest Berenvriendjes de juiste persoon voor uw KDV of BSO dienst.",
  TITLE_JOB_CATEGOREIS: "Overzicht van onze vakgebieden",
  TITLE_HOW_YOU_CAN_HIRE: "Hoe huur je personeel in?",
  TITLE_HOW_DO_YOU_HIRE_STAFF: "Hoe huur je personeel in?",
  TITLE_BEARFRIENDS_PARTNERS: "Berenvriendjes partners",
  TITLE_BEARFRIENDS_APP_ON_STORE:
    "Download de Berenvriendjes app via Google Play of via de Appstore",
  TITLE_FREQUENTLY_ASKED_QUESTIONS: "Veelgestelde Vragen",
  TITLE_FOR_EMPLOYEES: "Voor werknemers",
  TITLE_FOR_FREELANCERS: "Voor ZZP'ers",
  TITLE_FOR_ORGANIZATIONS: "Voor organisaties",
  TITLE_WORKER_POLICY:
  "Werken in de kinderopvang",
  TITLE_WORKER_POLICY_ORG:
  "Method: compliance and service",
  TITLE_REGISTER_FOR_FREE:
    "Meld u aan als werknemer en ontdek direct beschikbare diensten die aansluiten bij uw expertise en voorkeuren. Wij bieden een breed scala aan mogelijkheden, zodat u de perfecte baan kunt vinden.",
  TITLE_REGISTER_FOR_FREE_ORG:
    "Schrijf je gratis in als Organisatie en vindt direct de perfecte medewerker voor jouw dienst!",
  TITLE_REGISTER_FOR_FREE_FREELANCER:
    "Meld u aan als ZZP’er en kies ervoor om verschillende opdrachten aan te nemen binnen je vakgebied, waardoor je je vaardigheden kunt uitbreiden en jezelf kunt blijven ontwikkelen.",
  TITLE_EMPLOYEE_FREEDOM: "Werknemers vrijheid",
  TITLE_FREELANCER_FREEDOM: "Freelance vrijheid",
  TITLE_NO_LONG_TERM_COMMITMENTS: "Geen langdurige verplichtingen",
  TITLE_FLEXIBILITY: "Flexibiliteit",
  TITLE_FOCUS_ON_SPECIFIC_SECTORS: "Focus op specifieke sectoren",
  TITLE_ATTENTION_TO_QUALITY: "Aandacht voor kwaliteit",
  TITLE_REGULATORU_EXPERTISE: "Regelgevende expertise",
  TITLE_ABOUT_US: "Over ons",
  TITLE_CONTACT_US: "Neem contact met ons op",
  TITLE_LOGIN_WELCOME_TITLE: "Leuk je weer te zien! Log in bij Berenvriendjes",
  TITLE_STAFF: "Medewerkers",
  TITLE_PARTNERS: "Partners",
  TITLE_COMMUNICATE_WITH_CLIENTS: "Communiceren met klanten",
  TITLE_ITS_NICE_TO_SEE_YOU_AGAIN: "Fijn om je weer te zien!",
  TITLE_LOGIN_WITH_BERENVIENDJES: "Inloggen bij Berenvriendjes",
  TITLE_WELCOME_HAND: "Welkom! 👋",
  TITLE_HELLO_HAND: "Hallo 👋",
  TITLE_WELCOME: " Welkom ",
  TITLE_PLEASE_INTRODUCE_YOURSELF: "Stel jezelf even voor.",
  TITLE_EMPLOYEE: "Medewerker",
  TITLE_ORGANIZATION: "Organisatie",
  TITLE_YOUR_EMAIL: "Jouw e-mail",
  TITLE_VERIFY_EMAIL: "Verifieer e-mail",
  TITLE_FORGOT_PASSWORD: "Wachtwoord vergeten",
  TITLE_YOUR_PASSWORD: "Je wachtwoord",
  TITLE_RESET_PASSWORD: "Wachtwoord opnieuw instellen",
  TITLE_BASIC_INFO: "Jouw gegevens",
  TITLE_YOUR_ADDRESS: "Jouw adres",
  TITLE_KAMER_VAN_KOOPHANDEL: "Kamer van Koophandel",
  TITLE_YOUR_PHONE_NUMBER: "Jouw telefoonnummer",
  TITLE_YOUR_BANK_DETAILS: "Uw bankgegevens",
  TITLE_INDUSTRY_CATEGORY: "Vakgebied",
  TITLE_UPLOAD_DOCUMENTS: "Documenten uploaden",
  TITLE_TERMS_AND_CONDITION: "Algemene voorwaarden",
  TITLE_BUSINESS_DETAILS: "Bedrijfsgegevens",
  TITLE_UPLOAD: "Uploaden",
  TITLE_TURN_ON: "Aanzetten",
  TITLE_NOTIFICATIONS: "Meldingen",
  TITLE_PROFILE_STATUS: "Profielstatus",
  TITLE_CATEGORY_STATUS: "Categoriestatus",
  TITLE_DOCUMENT_STATUS: "Documentstatus",
  TITLE_PERSONAL_INFO_STATUS: "Persoonlijke informatiestatus",
  TITLE_MOST_RECENT: "Meest recente",
  TITLE_ENTER_DETAILS: "Gegevens invoeren",
  TITLE_EARLIER: "Eerder",
  TITLE_FILTERS: "Filters",
  TITLE_SHOW: "Toon",
  TITLE_FEATURES: "Functies",
  TITLE_MAXIMUM_DISTANCE: "Maximale afstand",
  TITLE_PLACE: "Plaats",
  TITLE_DATE: "Datum",
  TITLE_PER_HOUR: "per uur",
  TITLE_HIRING_MANAGER: "Contactpersoon",
  TITLE_BEGIN_TIME: "Begintijd",
  TITLE_END_TIME: "Eindtijd",
  TITLE_BREAK_TIME: "Pauze",
  TITLE_TRAVEL_EXPENSE: "Reiskosten",
  TITLE_JOB_REPETATION: "Opnieuw dienst aanbieden",
  TITLE_JOB_REPEAT_WHEN: "Herhaal iedere",
  TITLE_JOB_REPEAT_END: "Einddatum",

  TITLE_HEAD_COUNT: "Aantal personen binnen het bedrijf",
  TITLE_JOB_TYPE: "Soort Dienst",
  TITLE_JOB_OVERVIEW: "Dienst overzicht",
  TITLE_NOTE: "Opmerking!",
  TITLE_CLOTING_REQUIREMENTS: "Kledingvereisten",
  TITLE_SHOE_REQUIREMENTS: "Schoenvereisten",
  TITLE_ABOUT_COMPANY: "Over het bedrijf,",
  TITLE_APPLY_FOR: "Dienst aanvragen",
  TITLE_INBOX: "Inbox",
  TITLE_PERSONAL_INFORMATION: "Persoonlijke informatie",
  TITLE_PERSONAL_INFO: "Persoonlijke informatie",
  TITLE_BUSINESS_INFO: "Bedrijfsinformatie",
  TITLE_BUSINESS_INFORMATION: "Bedrijfsinformatie",
  TITLE_JOB_AND_EDUCATION: "Werk & Opleiding",
  TITLE_BRANCHES_AND_CATEGORIES: "Branche & Categorieën",
  TITLE_CATEGORIES: "Categorieën",
  TITLE_SKILL_SET_AND_INTERESTS: "Vaardigheden en interesses",
  TITLE_BANK_DETAILS: "Bankgegevens",
  TITLE_DOCUMENTS: "Documenten",
  TITLE_CONTRACT: "Dienst",
  TITLE_ACCOUNT: "Profiel",
  TITLE_AVATAR: "Avatar",
  TITLE_SETTINGS: "Instellingen",
  TITLE_PREFERENCES: "Voorkeuren",
  TITLE_EMAIL_SETTINGS: "E-mail instellingen",
  TITLE_FAQS: "Veelgestelde vragen",
  TITLE_ARE_YoU_SURE_DELETE_YOUR_ACCOUNT:
    "Weet u zeker dat u uw account wilt verwijderen?",
  TITLE_ARE_YoU_SURE_LOGOUT_YOUR_ACCOUNT:
    "Weet u zeker dat u zich wilt afmelden bij uw account?",
  TITLE_ARE_YoU_SURE_DELETE_THIS:
    "Weet je zeker dat je dit wilt verwijderen?",
  TITLE_ARE_YoU_SURE_COMPLETE_JOB_CONTRACT:
    "Weet je zeker dat je deze dienst wilt voltooien?",
  TITLE_ARE_YoU_SURE_CANCEL_JOB_CONTRACT:
    "Weet je zeker dat je deze dienst wilt annuleren?",
  TITLE_ARE_YoU_SURE_PAUSE_JOB_CONTRACT:
    "Weet je zeker dat je deze dienst wilt pauzeren?",
  TITLE_ARE_YoU_SURE_RESUME_JOB_CONTRACT:
    "Weet je zeker dat je deze dienst wilt hervatten?",
  TITLE_DO_YOU_WANT_RATE_THIS_WORKER: "Wil je deze werknemer beoordelen?",
  TITLE_CAN_NATO_COMPLETE_JOB_CONTRACT:
    "Kan deze dienst niet voltooien. Markeer alstublieft alle voltooide diensten die zijn goedgekeurd of afgewezen.",

  TITLE_ARE_YoU_SURE_HIRE_THIS:
    "Weet je zeker dat je deze dienst wilt aannemen?",
  TITLE_JOB_Hire: "Wil je deze medewerker inhuren?",
  TITLE_JOB_HIRING_MESSAGE: "De medewerker wordt aangenomen en ontvangt een melding.",
  TITLE_JOB_POSTED_SUCCESSFULLY: "Uw dienst is succesvol geplaatst.",
  TITLE_WANT_TO_SEND_INVITES: "Wil je uitnodigingen aan medewerkers versturen?",
  TITLE_MY_SCHEDULE: "Mijn rooster",
  TITLE_COMPANY_SCHEDULE: "Rooster",
  TITLE_DATE_SELECTION: "Datumselectie",
  TITLE_INVOICE_INTERVAL: "Kies datum",
  TITLE_AVAILABILITY_SCHEDULE: "Beschikbaarheids rooster",
  TITLE_GENRATE_INVOICE: "Genereer factuur",
  TITLE_AVAILABLE: "Beschikbaar",
  TITLE_UNAVAILABLE: "Niet beschikbaar",
  TITLE_HIRED: "Ingehuurd",
  TITLE_ADD_SCHEDULE: "Beschikbaarheid toevoegen",
  TITLE_JOB_STATUS: "Status",
  TITLE_RATING: "Beoordeel",
  TITLE_REVIEW: "Review",
  TITLE_WORKER_RATING: "Review & beoordeel medewerker",
  TITLE_ADD_ORGANIZATION_SCHEDULE: "Organisatie rooster toevoegen",
  TITLE_ORGANIZATION_SCHEDULE_DETAILE: "Organisatie rooster detail",
  TITLE_DETAIL_SCHEDULE: "Detail rooster",
  TITLE_DETAIL_AVAILABILITY: "Detail beschikbaarheid",
  TITLE_NO_AVAILABILITY:
    "U heeft nog geen beschikbaarheid ingesteld. Voeg beschikbaarheid toe.",
  TITLE_NO_TASK: "Er is momenteel geen taak voor u.",
  TITLE_TOP_RATED: "Best beoordeeld",
  TITLE_SKILLS_AND_EXPERTISE: "Vaardigheden & Expertise",
  TITLE_REPEATED_JOB: "Herhaalde diensten",
  TITLE_DESCRIPTION: "Beschrijving",
  TITLE_JOBS_COMPLETED: "Diensten voltooid",
  TITLE_COMPLETED: "Voltooid",
  TITLE_ONGOING: "Bezig",
  TITLE_RATING_AND_REVIEWS: "Beoordeling & Recensies",
  TITLE_POST_A_NEW_JOB: "Plaats een nieuwe dienst",
  TITLE_EDIT_JOB: "Dienst bewerken",
  TITLE_BOTH_EMPLOYEE_AND_FREELANCER: "Beide",
  TITLE_POSITION: "Functie",
  TITLE_COPYRIGHT_2023: "@copyright 2024",
  TITLE_MY_STATS: "Mijn statistieken",
  TITLE_CLIENT_SATISFACTION: "Klanttevredenheid",
  TITLE_ANALYTICS: "Analyses",
  TITLE_YEARLY: "Jaarlijks",
  TITLE_MONTHLY: "Maandelijks",
  TITLE_NO_NOTIFICATIONS: "Geen notificaties",
  TITLE_WORKING_VERIFICATION_STATUS: "Verificatie status",
  TITLE_WORKING_VERIFICATION_STATUS_IS_VERIFEID:
    "Uw werkverificatiestatus is geverifieerd.",
  TITLE_DELETE_ACCOUNT: "Account verwijderen",
  TITLE_INVITE_TO_FREELANCER_OR_EMPLOYEE: "Uitnodigen voor freelancer of werknemer",
  TITLE_JOB_APPLICANTS: "Potentiële medewerkers",
  TITLE_GO_TO_WORKER_LIST: "Ga naar de medewerkerslijst",
  TITLE_ADD_JOB: "Voeg uw werkervaring toe",
  TITLE_ADD_EDUCATION: "Voeg uw opleiding toe",
  TITLE_EDIT_EDUCATION: "Wijzig opleiding",
  TITLE_JOB: "Werkervaring",
  TITLE_EDUCATION: "Opleiding",
  TITLE_WORKER_DETAILS: "Medewerker details",
  TITLE_BRANCH_AND_CATEGORIES: "Branche- en categorieën details",
  TITLE_SKILL_DETAILS: "Vaardigheden Details",
  TITLE_INVITE_WORKER: "Medewerker uitnodigen",
  TITLE_REPEAT_CONTRACT: "Herhaal dienst",
  TITLE_APPLY_FOR_JOB: "Solliciteer voor deze dienst",
  TITLE_FREE_PARKING: "Gratis parkeren",
  TITLE_WORKING_SHIFT: "Dienst gewerkt",
  TITLE_REPEATED_JOBS: "Herhaald",
  TITLE_JOB_INVITATION: "Uitnodiging voor een dienst",
  TITLE_JOB_OFFER: "Dienst",
  TITLE_BLOCK_USER: "Blokkeer gebruiker",
  TITLE_MUTE_NOTIFICATION: "Melding dempen",
  TITLE_REPORT_USER: "Rapporteer gebruiker",
  TITLE_ASSIGN_WORKER_TASKS: "Taak toewijzen",
  TITLE_BIO: "Biografie",
  TITLE_BRANCH: "Branches",
  TITLE_CATEGORY: "Categorieën",
  TITLE_CONTRACT_TYPE: "Dienst Type",

  TITLE_LOGIN_ADMIN: "Inloggen voor beheerder",

  LABEL_TASKS: "Opdrachtnaam",
  TITLE_PW_RESET: "Wachtwoord opnieuw instellen",
  TITLE_SENT_PW_RESET: "E-mail is verzonden",
  TITLE_CHANGE_PW: "Wachtwoord wijzigen",
  TITLE_CREATE_ORDER: "Maak een dienst aan",

  TITLE_FILTER: "Filteren",
  TITLE_ORDER_STATUS_DELIVERED: "Bestellingsstatus wijzigen in afgeleverd?",
  TITLE_ORDER_STATUS_SHIPPED: "Bestellingsstatus wijzigen in verzonden?",
  TITLE_ORDER_STATUS_ISSUED: "Bestellingsstatus wijzigen in Uitgegeven bestelling?",
  TITLE_ORDER_STATUS_CANCEL: "Bestellingsstatus wijzigen om te annuleren?",
  TITLE_YEAR_MONTH: "{YYYY}/{MM}",
  TITLE_LOGOUT: "Uitloggen",
  TITLE_LOGIN: "Inloggen",
  TITLE_NO_DATA: "Geen gegevens gevonden",
  TITLE_STATUS_CHANGE: "Status wijzigen",
  TITLE_EDIT_ACCOUNT: "Account bewerken",
  TITLE_UPDATE_PW: "Wachtwoord bijwerken",
  TITLE_REGISTRATION: "Registratieformulier",
  TITLE_TOTAL_FEEDS: "Totaal aantal feeds per categorie voor de maand",
  TITLE_MAINTENANCE_BLOG: "Onderhoudsblog per categorie voor de maand",
  TITLE_TOTAL_TALKROOM: "Totale gespreksruimte per categorie voor de maand",
  TITLE_TOTAL_TOURINGS: "Totaal aantal toeren voor de maand",
  TITLE_ADD_BIKE: "Fiets toevoegen",
  TITLE_DEACTIVATE_ACCOUNT: "Deactiveer dit account",
  TITLE_ALREADY_INACTIVE: "Gebruiker al inactief",
  TITLE_SAFR: "SAFR-beheerder",
  TITLE_ADD_GOODPLAN: "Goed plan toevoegen",
  TITLE_EDIT_GOODPLAN: "Bewerk een goed plan",
  TITLE_ADD_INSIDE: "Binnenkant toevoegen",
  TITLE_ADD_OUTSIDE: "Buitenkant toevoegen",
  TITLE_ADD_MODE: "Modus toevoegen",
  TITLE_ADD_EXPERIENCE_TYPE: "Voeg ervaringstype toe",
  TITLE_INSIDE_OPTIONS: "Inside-opties",
  TITLE_OUTSIDE_OPTIONS: "Externe opties",
  TITLE_MODE_OPTIONS: "Modusopties",
  TITLE_UPLOAD_PHOTO: "Foto uploaden",
  TITLE_PRODUCT_MONTHLY_GROWTH_RATE: "Maandelijks groeipercentage product ",
  TITLE_TOTAL_JOINED_USERS: "Totaal aantal aangesloten gebruikers",
  TITLE_USERS_INSIGHTS: "Gebruikersinzichten",
  TITLE_USERS_NOTIFICATIONS: "Gebruikersmelding",
  TITLE_TOTAL_USERS: "Totaal aantal gebruikers",
  TITLE_TOTAL_EMPLOYEES: "Totaal aantal werknemers",
  TITLE_TOTAL_FREELANCERS: "Totaal freelancers",
  TITLE_TOTAL_ORGANIZATIONS: "Totaal aantal organisaties",
  TITLE_TOTAL: "Totaal",

  TITLE_ALL: "Alles",
  TITLE_READ: "Lezen",
  TITLE_UNREAD: "Ongelezen",
  TITLE_PLEASE_ADD_BRANCH: "Voeg branche toe",
  TITLE_PLEASE_ADD_CATEGORY: "Voeg categorie toe",
  TITLE_ADD_SKILLS: "Vaardigheden toevoegen",
  TITLE_EDIT_BRANCH: "Branche bewerken",
  TITLE_EDIT_CATEGORY: "Categorie bewerken",
  TITLE_EDIT_SKILLS: "Vaardigheden bewerken",

  TITLE_UNMUTE_NOTIFICATION: "Meldingen opheffen",

  TITLE_CULTURE: "CULTUUR",

  TITLE_CONTRACT_START_DATE:"startdatum",
  TITLE_CONTRACT_END_DATE:"einddatum",

  TITLE_ORGANIZATION_CULUTRE:"Maatwerkoplossingen",


}