import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { UsersContextConsumer } from "store/AuthProvider";
import {
  getOrganizationJobByIdAPI,
  deleteJobApi,
  onChecngeJobPostingCheck,
} from "utils/globalAPI/Dashboard";
import { JobData } from "types/schema";
import { useState } from "react";
import { useLocale } from "locales";
import { ToastConsumer } from "store/ToastProvider";

export const usePostJobDetailsService = () => {
  // const location = useLocation();
  const { accessToken, fetchApi } = UsersContextConsumer();
  const { localeSuccess } = useLocale();
  const toast = ToastConsumer();

  const params = useParams();
  const jobId = params?.["jobId"];

  const {
    data,
    isLoading: jobDetailsLoading,
    refetch,
  } = useQuery(
    [
      "jobsDetails",
      {
        jobId,
      },
    ],

    async () => getOrganizationJobByIdAPI(jobId as string, accessToken),
    { enabled: fetchApi, refetchOnMount: false, refetchOnWindowFocus: false }
  );
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setModal(!modal);
  };
  const onDeleteJob = (data: JobData | null) => {
    toggleModal();
  };

  const OnEditJob = (data: JobData | null) => {
    navigate(`/organization/${data?.organization}/edit`, {
      state: {
        data,
      },
    });
  };

  const onConfirmDeleteJob = async () => {
    try {
      setLoading(true);
      await deleteJobApi(jobId as string, accessToken);
      toast.success(localeSuccess.SUCCESS_DELETING_JOB);
      navigate(-1);
    } catch (error) {
      console.log("Error in deleting job", error);
      toast.error(localeSuccess.ERROR_DELETING_JOB);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  const onChangeJobPosting = async (
    fullfilledBy: "under_review" | "admin" | "users"
  ) => {
    try {
      setLoading(true);
      await onChecngeJobPostingCheck(
        jobId as string,
        fullfilledBy,
        accessToken
      );
      toast.success(localeSuccess.SUCCESS_JOB_UPDATED);
      refetch();
    } catch (error) {
      console.log("Error in Updating job", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    jobDetailsLoading,
    job: data?.theJob as JobData,
    modal,
    toggleModal,
    onDeleteJob,
    loading,
    onConfirmDeleteJob,
    onChangeJobPosting,
    OnEditJob,
  };
};
