import styles from "./OrganizationJobsPage.module.scss";

import { useNavigate } from "react-router-dom";
import { localeButtons, useLocale } from "locales";

import JobCard from "./JobCard/JobCard";

import { Button } from "components/atoms/ButtonF";
import { Loader } from "@aws-amplify/ui-react";
import { useJobService } from "./hook";
import ConfirmationModal from "components/organisms/Confirmation Modal";
import { ReactControlSelect } from "components/atoms/ReactControlSelect";

export const jobCategoryTypes = (localeButton: typeof localeButtons) => [
  { label: localeButton.BUTTON_ONGOING_JOBS, value: "openForHiring" },
  { label: localeButton.BUTTON_COMPLETED, value: "completed" },
  { label: localeButton.BUTTON_CANCELED_JOBS, value: "cancelled" },
  { label: localeButton.BUTTON_DELETED_JOBS, value: "deleted" },
];

const OrganizationJobsPage = () => {
  const {
    activeCategory,
    jobsData,
    jobsDataLoading,
    getCategoryName,
    onConfirmDeleteJob,
    loading,
    modal,
    toggleModal,
    onDeleteJob,
    organizationId,
    address,
    handleAdress
  } = useJobService();

  const { localeButtons, localeLabels,localePlaceholders } = useLocale();

  return (
    <div className={styles["OrganizationJobsPage"]}>
      <div className={styles["OrganizationJobsPage-main"]}>
        <div className={styles["OrganizationJobsPage-top"]}>
          <div className={styles["OrganizationJobsPage-top-left"]}>
            {jobCategoryTypes(localeButtons)?.map(
              (category: { label: string; value: string }, index) => (
                <Button
                  key={index}
                  className={
                    activeCategory === category?.value
                      ? styles["primaryBtn"]
                      : styles["secondaryBtn"]
                  }
                  onClick={() => getCategoryName(category?.value)}
                >
                  {category?.label}
                </Button>
              )
            )}

            {address && (
              <div className={styles["control-select"]}>
                <ReactControlSelect
                  onChange={handleAdress as any}
                  placeholder={localePlaceholders.PLACEHOLDERS_ADDRESS}
                  name="branches"
                  unControlled
                  maxHeight={20}
                  isMulti={true}
                  options={address?.map((item) => {
                    return {
                      label:
                        item.addressTitle ||
                        item?.street + " " + item?.city ||
                        "",
                      value: item?._id || "",
                    };
                  })}
                />
              </div>
            )}
          </div>
        </div>

        {jobsDataLoading ? (
          <div className={"loadingScreen"}>
            <Loader />
          </div>
        ) : (
          <div className={styles["OrganizationJobsPage-jobs"]}>
            {(jobsData || [])?.map((job, jobIndex: number) => (
              <JobCard
                cardType="organization"
                data={job}
                key={jobIndex}
                onDeleteJob={onDeleteJob}
                organizationId={organizationId}
              />
            ))}
          </div>
        )}
      </div>

      <ConfirmationModal
        show={modal}
        title={localeLabels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JOB}
        onHide={toggleModal}
        onConfirm={onConfirmDeleteJob}
        loading={loading}
      />
    </div>
  );
};

export default OrganizationJobsPage;
