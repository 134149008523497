import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import styles from "./GoogleMap.module.scss";
import { GoogleMapProps } from "./@types";
import { UseFormSetValue } from "react-hook-form";
import { ACCOUNT_FORMS } from "types/forms";
import { geocodeByLatLng } from "react-google-places-autocomplete";

interface IMapProps {
  lng: number | undefined;
  lat: number | undefined;
  setValue?: UseFormSetValue<ACCOUNT_FORMS>;
  index:number;
}

const containerStyle = {
  width: "100%",
  height: "400px"
};
const GoogleMapComponent: React.FC<IMapProps> = ({ lat, lng, setValue,index }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB7Jlc-Hyyf5llSQBoJYaFAoaoUIUo-FsU",
  });
  const center = {
    lat: lat ? lat : 0,
    lng: lng ? lng : 0,
  };

  const [map, setMap] = React.useState<any>();

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const getCoords = (data?: any) => {
    const coordinates = [data.latLng?.lng(), data.latLng?.lat()];
    setValue?.(`address.${index}.location.coordinates`, coordinates);

    geocodeByLatLng({ lat: data.latLng?.lat(), lng: data.latLng?.lng() })
      .then((results: any) => {
        if (results && results[0]) {
          const addressComponents = results[0].address_components;
          if (addressComponents) {
            for (let i = 0; i < addressComponents.length; i++) {
              const component = addressComponents[i];

              if (component.types.includes("postal_code")) {
                setValue?.(`address.${index}.zip`, component.long_name);
              }
              if (component.types.includes("route")) {
                setValue?.(`address.${index}.street`, component.long_name);
              }
              if (component.types.includes("locality")) {
                setValue?.(`address.${index}.city`, component.long_name);
              }
              if (component.types.includes("country")) {
                setValue?.(`address.${index}.country`, component.long_name);
              }

            }
            setValue?.(`address.${index}.address`, results[0].formatted_address);
          
        }
      }})
      .catch((error) => console.error("error", error));
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      
      onLoad={onLoad}
      onUnmount={onUnmount}
      
    >
      <Marker position={center} draggable onDrag={getCoords} />
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMapComponent);
