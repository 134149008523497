import type { selectProps } from "./@types";
import styles from "./Select.module.scss";
import { useLocale } from "locales";
import { ErrorMessage } from "components/atoms/ErrorMessage";
import { Controller } from "react-hook-form";

import Select, { CSSObjectWithLabel, components } from "react-select";
import SortDropDown from "components/cells/SortDropDown";
import { useEffect, useState } from "react";
import { ISkills } from "types/schema";

const CategorySelect = ({
  list,
  control,
  name,
  Icon,
  className,
  placeholder,
  selectTextClass,
  multiSelect,
  maxLimit,
  disablesorting = false,
  disabled,

  groupedOptions,
}: selectProps) => {
  const { currentLocale } = useLocale();

  const [options, setOptions] = useState<ISkills[]>([]);

  const [filterBy, setFilterBy] = useState<
    "A_Z" | "Z_A" | "popularity_d" | "popularity"
  >("A_Z");

  const onChangeFilter = (
    value: "A_Z" | "Z_A" | "popularity_d" | "popularity"
  ) => {
    let sortList = (list || [])?.map((item: any) => ({
      ...item,
      value: item._id,
      label: item.label?.[currentLocale],
    }));

    setFilterBy(value);

    if (disablesorting) {
      setOptions(sortList)
      return
    }

    //  sort a-z
    if (value === "A_Z") {
      setOptions(
        sortList?.sort((a: any, b: any) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
    } else if (value === "Z_A") {
      setOptions(
        sortList?.sort((a: any, b: any) => {
          if (a.label < b.label) {
            return 1;
          }
          if (a.label > b.label) {
            return -1;
          }
          return 0;
        })
      );
    } else if (value === "popularity") {
      setOptions(
        sortList?.sort((a: any, b: any) => {
          if (a.popularity < b.popularity) {
            return -1;
          }
          if (a.popularity > b.popularity) {
            return 1;
          }
          return 0;
        })
      );
    } else if (value === "popularity_d") {
      setOptions(
        sortList?.sort((a: any, b: any) => {
          if (a.popularity < b.popularity) {
            return 1;
          }
          if (a.popularity > b.popularity) {
            return -1;
          }
          return 0;
        })
      );
    }
  };

  useEffect(() => {
    onChangeFilter(filterBy);
  }, [list, currentLocale]);

  const formatGroupLabel = (data: any) => (
    <div className={styles["groupStyles"]}>
      <span className={styles["groupLabel"]}>{data.label}</span>
      <span className={styles["groupBadge"]}>{data?.options?.length}</span>
    </div>
  );

  const selectStyle = {
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: 60,
      borderWidth: 0,
      height: "100%",
      paddingLeft: "30px",
    }),
    indicatorSeparator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: "#212121",
      fontSize: "14px",
      fontWeight: "500",
    }),

    indicatorsContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      marginRight: "10px",
    }),
    menu: (base: CSSObjectWithLabel) => ({
      ...base,
      zIndex: 1000,
    }),
    option: (provided: CSSObjectWithLabel) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "black",
      padding: 10,
    }),
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    data,
    ...rest
  }: any) => {
    // styles
    /*  let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF"; */

    const style = {
      alignItems: "center",
      backgroundColor: "#fff",
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      // onMouseDown,
      // // isActive,
      // onMouseUp,
      // onMouseLeave,
      style,
    };

    return (
      <components.Option
        isDisabled={isDisabled}
        data={data}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
        {...rest}
      >
        <div className="flex gap-2 items-center">
          <input type="checkbox" checked={isSelected} />
          <div>{children}</div>
        </div>
      </components.Option>
    );
  };
  // const sortedOptions = (LIST: any) => {
  //   let sortList = LIST?.map((item: any) => ({
  //     ...item,
  //     value: item._id,
  //     label: item.label?.[currentLocale],
  //   }));

  //   //  sort a-z

  //   return sortList?.sort((a: any, b: any) => {
  //     if (a.label < b.label) {
  //       return -1;
  //     }
  //     if (a.label > b.label) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <div className={styles["eachFieldWithCategory"]}>
          <div className={styles["selectDiv"]}>
            <div className={`${styles["select-container"]} ${className}`}>
              <div className={styles["icon-container"]}>
                {Icon && Icon}
                {/* <Img src={icon} alt="Icon" className={styles["icon"]} /> */}
              </div>
              <Select
                isClearable
                isDisabled={disabled}
                isMulti={true}
                styles={selectStyle}
                closeMenuOnSelect={false}
                value={value}
                hideSelectedOptions={false}
                components={{ Option: InputOption }}
                className={`${
                  styles[Icon ? "select-less-width" : "select"]
                } ${selectTextClass}`}
                onChange={(v) => {
                  onChange(v);
                }}
                options={groupedOptions ? (groupedOptions as any) : options}
                formatGroupLabel={
                  groupedOptions ? formatGroupLabel : () => <></>
                }
              />

              {!disablesorting && (
                <SortDropDown changeFilter={onChangeFilter} value={filterBy} />
              )}
            </div>
            {!!errors && (
              <ErrorMessage errors={errors?.[name]?.message as string} />
            )}
          </div>
        </div>
      )}
    />
  );
};

export default CategorySelect;
