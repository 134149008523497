import { workingDatesFormTypes } from "components/cells/WorkingApprovalForm/@types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UsersContextConsumer } from "store/AuthProvider";


export const useTaskCard = (workingDayId: string, refetch: () => void) => {
  const { accessToken } = UsersContextConsumer();

  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const toggleModal = (
    status?:
      | "approved"
      | "declined"
      | "inProgress"
      | "completed"
      | "rejected"
      | "close"
  ) => {
    if (status !== "close") {
      if (true) {
        return;
      }
    }
    setModal(!modal);
  };

  const hiredWorkerId = useParams().hiredWorkerId;

  const onConfirm = async (data: workingDatesFormTypes) => {
    try {
      setModalLoading(true);
      const DATA = {
        ...data,
        status: data.status,
        breakTimeInMin: data.breakTimeInMin,
        startDateTime: data.date[0],
        endDateTime: data.date[1],
        hiredWorkerId,
      };
      // const d = await updateWorkingDay(workingDayId, DATA, accessToken);
      // toast.success(localeSuccess.JOB_SHIFT_UPDATED,"top-right");
      toggleModal("close");
      refetch();
    } catch (error: any) {
      // toast.ServerError(error,"top-right")
    } finally {
      setModalLoading(false);
    }
  };

  return {
    onConfirm,
    toggleModal,
    modal,
    modalLoading,
  };
};
