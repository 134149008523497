import { Text } from "components/atoms/TextF";
import styles from "./AddJobModal.module.scss";
import { AddJobModalProps } from "./@types";
import {  useLocale } from "locales";
import { useNavigate } from "react-router-dom";
import { ControlInput } from "components/atoms/ControlledInput/Input";
import { Button } from "components/atoms/ButtonF";
import { useForm } from "react-hook-form";
import {  Job } from "types/forms";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs, { Dayjs } from "dayjs";

const AddJobModal = ({
  closeModal,
  onConfirm,
  data,
  onEdit,
}: AddJobModalProps) => {
  const { localeErrors } = useLocale();
  const validationSchema: any = yup.object().shape({
    positionName: yup
      .string()
      .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    organization: yup
      .string()
      .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    startDate: yup
      .date()
      .typeError(localeErrors.ERROR_STARTING_DATE_REQUIRED)
      .required(localeErrors.ERROR_STARTING_DATE_REQUIRED),
    endDate: yup
      .date()
      .typeError(localeErrors.ERROR_ENDING_DATE_REQUIRED)
      .required(localeErrors.ERROR_ENDING_DATE_REQUIRED)
      .min(
        yup.ref("startDate"),
        localeErrors.ERROR_ENDING_DATE_BEFORE_STARTING_DATE
      ),
  });
  // const { register } = useFormContext();
  const { handleSubmit, control } = useForm<Job>({
    defaultValues: {
      ...data,
      startDate: dayjs(data?.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(data?.endDate).format("YYYY-MM-DD"),
    },
    resolver: yupResolver(validationSchema) as any,
  });

  const navigate = useNavigate();
  const {
    localeBasics,
    localeTitles,
    localePlaceholders,
    localeButtons,
    localeLabels,
  } = useLocale();

  return (
    <div className={styles["AddJobModal"]}>
      <div className={styles["AddJobModal-main"]}>
        <div className={styles["AddJobModal-head"]} onClick={closeModal}>
          <Text className={styles["backText"]}>
            {data ? localeTitles.TITLE_EDIT_JOB : localeTitles.TITLE_ADD_JOB}
          </Text>
        </div>
        <div className={styles["AddJobModal-sections"]}>
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_JOB_TITLE}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                borderClass=""

                name="positionName"
                placeholder={localePlaceholders.PLACEHOLDERS_JOB_TTTLE}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="text"
              />
            </div>
          </div>
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_COMPANY_NAME}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                borderClass=""

                name="organization"
                placeholder={localePlaceholders.PLACEHOLDERS_COMPANY_NAME}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="text"
              />
            </div>
          </div>
          
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_START_DATE}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                borderClass=""
                
                name="startDate"
                placeholder={localePlaceholders.PLACEHOLDERS_STARTING_DATE}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="date"
              />
            </div>
          </div>
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_END_DATE}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                name="endDate"
                borderClass=""

                placeholder={localePlaceholders.PLACEHOLDERS_ENDING_DATE}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="date"
              />
            </div>
          </div>
        </div>

        <div className={styles["submitButton"]}>
          <Button
            className={"secondary-active"}
            onClick={data ? handleSubmit(onEdit) : handleSubmit(onConfirm)}
          >
            {data ? localeTitles.TITLE_EDIT_JOB : localeTitles.TITLE_ADD_JOB}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddJobModal;
