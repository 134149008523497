import { loadavg } from "os";
import Modal from "../../molecules/Modal";
import FilterForm from "../Filter Form";
import type { FilterModalProps } from "./@types";
import styles from "./FilterModal.module.scss";

const FilterModal = ({
  show,
  
  onSubmit,
  loading,
  onClickClearFilter,
  filterType,
}: FilterModalProps) => {
  return (
    <div className={styles["auth-form"]}>
      <Modal show={show}>
        
        <FilterForm
          type={filterType}
          onSubmit={onSubmit}
          loading={loading}
          onClickClearFilter={onClickClearFilter}
        />
      </Modal>
    </div>
  );
};

export default FilterModal;
