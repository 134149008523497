import ListPage from "../../pages/ListPage";
import { useOrganizationServices } from "./hook";
import Loader from "../../components/atoms/Loader";

export interface services {}

const OrganizationServices = ({}: services) => {
  const {
    listLoading,
    usersList,
    hasMore,
    totalUsers,
    onNextPage,
    onPreviousPage,
    currentPage,
    order,
    onChangeFilter,
  } = useOrganizationServices();

  if (listLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <ListPage
      type={"Organization"}
      list={usersList}
      hasMore={hasMore}
      totalUsers={totalUsers}
      loading={listLoading}
      showPagination={true}
      OnNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      currentPage={currentPage}
      filter={{
        onChange: onChangeFilter,
        order: order.order,
        column: order.column,
      }}
    />
  );
};

export default OrganizationServices;

// export default function AccountService() {
//   return (
//     <ProtectedRoute>
//       <AccountServiceComponent />
//     </ProtectedRoute>
//   );
// }
