import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";
import { ApiGetDashboard } from "../../../utils/globalAPI/Dashboard";
import { UsersContextConsumer } from "../../../store/AuthProvider";

export const useDashboardServices = () => {
  const { accessToken, fetchApi } = UsersContextConsumer();
  const {
    data: { dashboardData, monthlyJoinedData, notifications } = {},
    isLoading: dashboardLoading,
  } = useQuery(
    [
      "dashboard",
      {
        accessToken,
      },
    ],
    async () => ApiGetDashboard(accessToken),
    { enabled: !!fetchApi, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const sortedNotifications = notifications?.sort((a, b) => {
    const timeA = dayjs(a.createdAt);
    const timeB = dayjs(b.createdAt);
    return timeB.diff(timeA);
  });

  return {
    loading: dashboardLoading,
    dashboardData,
    monthlyJoinedData,
    sortedNotifications,
  };
};
