import en from "./english";
import du from "./dutch";

export type KeyOfSubjectTranslation = keyof typeof en;
export const getSubject = (locale: "en" | "du", key: KeyOfSubjectTranslation) => (locale === "en"? en?.[key]:du?.[key]) || key ;


export const NOTIFICATION = {
  en,
  du,
};
