import { AxiosResponse } from "axios";
import apiRequest from "../../../utils/axios";
import {
  getUserDetailsApiResponse,
  getUserListApiResponse,
  IAddress,
  UserDetails,
  getUserDetailsApiResponse1,
} from "../../../types/ApiResponse";

export const ApiGetAllUsers = async (
  type: string,
  offset: number | string,
  keyword?: string,
  by?: string,
  token?: string,
  status?: string
): Promise<getUserListApiResponse> => {
  let url = `/Admin/userList?keyword=${keyword ? keyword : ""}${
    by && `&searchBy=${by}`
  }&accountType=${type}&offset=${offset}`;

  if (status) url += `&status=${status}`;

  const response = await apiRequest({
    method: "get",
    url: url,
    token,
  });

  return response;
};

export const ApiGetAddress = async (
  id: string,
  token: string
): Promise<IAddress> => {
  console.log("ApiGetUserDetails", id, token);

  const response = await apiRequest({
    method: "get",
    url: `/Admin/address?orgnization=${id}`,
    token,
  });

  return response;
};
export const ApiGetUserDetails = async (
  id: string,
  token: string
): Promise<getUserDetailsApiResponse1> => {
  console.log("ApiGetUserDetails", id);
  const response = await apiRequest({
    method: "get",
    url: `/Admin/userDetails?user=${id}`,
    token,
  });

  return response;
};

export const ApiSentVOGLink = async (
  data: {
    user: string;
    link: string;
  },
  token: string
): Promise<getUserDetailsApiResponse> => {
  console.log("ApiSentVOGLink", data);

  const response = await apiRequest({
    method: "post",
    url: `/Admin/sendVOGLink`,
    data,
    token,
  });

  return response;
};

export const ApiToggleAccountStatus = async (
  user: string,
  token: string
): Promise<getUserDetailsApiResponse> => {
  console.log("ApiToggleAccountStatus", user);

  const response = await apiRequest({
    method: "get",
    url: `Admin/toggleAccountStatus?user=${user}`,
    token,
  });

  return response;
};

export const ApiToggleWorkStatus = async (
  id: string,
  status: string,
  token: string
): Promise<getUserDetailsApiResponse> => {
  console.log("ApiToggleWorkStatus", status);

  const response = await apiRequest({
    method: "put",
    url: `/Admin/allowWorker/${id}/${status}`,
    token,
  });

  return response;
};
export const ApiToggleOrganizationJobPOsting = async (
  data: any,
  token: string
): Promise<getUserDetailsApiResponse> => {
  const response = await apiRequest({
    method: "put",
    url: `/Admin/jobPostingCheck`,
    token,
    data,
  });

  return response;
};
