import { useLocation, useNavigate } from "react-router-dom";

import type { NotificationDropDownProps } from "./@types";
import styles from "./NotificationDropDown.module.scss";
import { useLocale } from "../../../locales";

import useComponentVisible from "../../../utils/useComposite";
import { Text } from "../../atoms";
import AlertDot from "../../../assets/Jsx/AlertDot";
import Image from "../../atoms/Image";
import { handleImagesPath } from "../../../utils/hooks/helper";
import dayjs from "dayjs";
import { useSocketIO } from "store/SocketConsumer";
import { getSubject } from "locales/NOTIFICATION";

const NotificationDropDown = ({
  dropDownOptions,
  className,
  icon,
}: // notifications,
NotificationDropDownProps) => {
  const { localeTitles, localeBasics,currentLocale } = useLocale();
  const navigate = useNavigate();
  // const { ref, setIsNotificationVisible, isNotificationVisible } =
  //   useComponentVisible(false);
  // const toggle = () => {
  //   if (isNotificationVisible == true) {
  //     setIsNotificationVisible(false);
  //   } else {
  //     setIsNotificationVisible(true);
  //   }
  // };

  // const navigation = (accountType: string, link: string) => {
  //   navigate(link);
  //   setIsNotificationVisible(false);
  // };

  const {
    notificationAPi: {
      ref,
      navigation,
      toggle,
      isNotificationVisible,

      notifications,
      updateNotificationReadAbility,
      markAllAsRead,
      unreadCount,
    },
  } = useSocketIO();

  return (
    <div className={className} ref={ref}>
      <div className={styles["actionButton-postListing-icon"]} onClick={toggle}>
        <div className={styles["notification-icon"]}>
          {icon}
          {unreadCount ? (
            <div className={styles["notification-alert"]}>
              <AlertDot />
            </div>
          ) : null}
        </div>
      </div>

      {isNotificationVisible ? (
        <div className="font-bold">
          <div className="bg-white p-2 mt-3 bg-white-A700 space-y-3 flex flex-col rounded-lg font-bold  top-13 right-30 absolute border-2 border-black w-[322px] z-20 right-[15%]">
            <div className={styles["dropdown-option-top"]}>
              <Text className={styles["title"]}>
                {localeTitles.TITLE_NOTIFICATIONS}
              </Text>
              <Text className={styles["markRead"]} onClick={markAllAsRead}>
                {localeBasics.TEXT_MARK_ALL_AS_READ}
              </Text>
            </div>
            <div className={styles["dropdown-option-mid"]}>
              <div className={styles["notifications"]}>
                {notifications?.map((notification, i) => (
                  <div
                    className={styles["notificationDiv"]}
                    onClick={() => {
                      updateNotificationReadAbility(
                        notification?.link,
                        notification?._id,
                        navigate
                      );
                    }}
                  >
                    <Image
                      className={styles["image"]}
                      imagePath={handleImagesPath(
                        notification?.user?.profileImage
                      )}
                    />
                    <Text className={styles["title"]}>
                      {" "}
                      {getSubject(currentLocale,notification?.content)}
                    </Text>
                    <Text className={styles["time"]}>
                      {dayjs(notification?.createdAt).format("HH:mm")}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles["dropdown-option-bottom"]}>
            <Text
                className={styles["seeAllNotifications"]}
                onClick={() => navigate("/notifications")}
              >
                {localeBasics.TEXT_SEE_ALL_NOTIFICATIONS}
              </Text> 
            </div>
          </div>
        </div>
      ) : null}

    </div>
  );
};

export default NotificationDropDown;
