import type { RatingStarProps } from "./@types";
import styles from "./RatingStar.module.scss";

const RatingStar = ({ filled, starClass ,onClick}: RatingStarProps) => {
  return (
    <span
      className={`${styles.star} ${filled ? styles.filled : ""} ${starClass}`}
      onClick={onClick}
    >
      ★
    </span>
  );
};

export default RatingStar;
