import { Tab, Tabs } from "@mui/material";
import styles from "./InviteWorker.module.scss";
import { useInviteWorkerPage } from "./hook";
import { Text } from "components/atoms/TextF";
import { Button } from "components/atoms/ButtonF";
import { useLocale } from "locales";
import { colorPrimary } from "assets/styles/colors";
import { ControlInput } from "components/atoms/ControlledInput/Input";
import SearchIcon from "assets/svgs/SearchIcon";
import InviteWorkerCard from "components/organisms/InviteWorkerCard";
import ModalComponent from "components/molecules/ModalF/Modal";
import InviteWorkerModal from "components/cells/InviteWorkerModal";
import ConfirmationModal from "components/molecules/ConfirmationModal";

import CheckCircleIcon from "assets/svgs/CheckCircleIcon";

const InviteWorkerPage = () => {
  const {
    control,
    selectedOption,
    handleOptionChange,
    onHireWorker,
    inviteModal,
    onConfirmInvite,
    onClickInvite,
    closeInviteModal,
    inviteLoading,

    tabValue,
    handleTabChange,
    jobId,
    workerId,
    accountTyp,
    data,
    hasMore,
    fetchNextPage,
    isFetchingNextPage,
    navigateToWorkerDetails,
    onClickHire,
    hireModal,
    onCloseHireModal,
    hireLoading
  } = useInviteWorkerPage();
  const {
    localeTitles,
    localeLabels,
    localePlaceholders,
    localeButtons: localeButtons,
  } = useLocale();

  return (
    <div className={styles["InviteWorker"]}>
      <Text className={styles["title"]}>
        {localeTitles.TITLE_INVITE_TO_FREELANCER_OR_EMPLOYEE}
      </Text>
      <div className={styles["InviteWorker-main"]}>
        <div className={styles["InviteWorker-main-header"]}>
          <div className={styles["header-top"]}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              className={styles.tabs}
              aria-label="icon position tabs example"
              sx={{
                "& button": { borderRadius: 16 },
              }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                value={0}
                className={styles["Hometabs-tab"]}
                label={localeLabels.LABEL_SEARCH}
                sx={{
                  textTransform: "inherit",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  minHeight: "48px",
                  paddingRight: "10px",
                  paddingLeft: "0px",
                  marginRight: "5px",
                  position: "relative",
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    right: "10%",
                    transform: "translateX(-50%)",
                    width: "30%",
                    height: "3px",
                    backgroundColor: colorPrimary,
                    borderRadius: "0 5px 5px 0",
                    visibility: tabValue === 0 ? "visible" : "hidden",
                  },
                }}
              />
              <Tab
                value={1}
                sx={{
                  textTransform: "inherit",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  minHeight: "48px",
                  paddingRight: "10px",
                  paddingLeft: "0px",
                  marginRight: "5px",
                  position: "relative",
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    right: "10%",
                    transform: "translateX(-50%)",
                    width: "30%",
                    height: "3px",
                    backgroundColor: colorPrimary,
                    borderRadius: "0 5px 5px 0",
                    visibility: tabValue === 1 ? "visible" : "hidden",
                  },
                }}
                label={localeLabels.LABEL_ALREADY_HIRED}
              />
            </Tabs>
          </div>
          <div className={styles["header-bottom"]}>
            <div className={styles["header-bottom-left"]}>
              <ControlInput
                control={control}
                name="search"
                placeholder={localePlaceholders.PLACEHOLDERS_STREET}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="search"
                prefix={<SearchIcon />}
                borderClass=""
              />
            </div>
            <div className={styles["header-bottom-left"]}>
              <div className={styles["toggleTabs"]}>
                <button
                  value="freelancer"
                  aria-label="Freelancer"
                  className={
                    selectedOption === "freelancer"
                      ? styles["activeButton"]
                      : styles["inactiveButton"]
                  }
                  onClick={(event) => {
                    handleOptionChange(event, "freelancer");
                  }}
                >
                  {localeButtons.BUTTON_FREELANCERS}
                </button>
                <button
                  value="employee"
                  aria-label="Employee"
                  className={
                    selectedOption === "employee"
                      ? styles["activeButton"]
                      : styles["inactiveButton"]
                  }
                  onClick={(event) => {
                    handleOptionChange(event, "employee");
                  }}
                >
                  {localeButtons.BUTTON_EMPLOYEE}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["InviteWorker-main-workers"]}>
          {data?.pages?.map((page) => {
            return page.workers.map((worker, index) => (
              <InviteWorkerCard
                key={worker?._id}
                data={worker}
                cardType="job-applicants"
                isHired={worker?.isHired}
                isInvited={worker?.isInvited}
                navigateToWorkerDetails={navigateToWorkerDetails}
                onCLickHireDirectly={(id) => onClickHire(id)}
                onClickInvite={(id) => onClickInvite(id, worker?.accountType)}
              />
            ));
          })}
        </div>

        {hasMore && (
          <div className="flex justify-center w-full self-center mt-4 mb-4">
            <Button
              loading={isFetchingNextPage}
              onClick={fetchNextPage}
              className="primary-active-fullWidth"
              type="button"
            >
              {localeLabels.LABEL_LOADMORE}
            </Button>
          </div>
        )}
      </div>

      <ModalComponent open={hireModal} onClose={onCloseHireModal}>
        <ConfirmationModal
          cancelButtonText={localeButtons?.BUTTON_NO_CANCEL}
          confirmButtonText={localeButtons?.BUTTON_YES}
          icon={<CheckCircleIcon />}
          onConfirm={onHireWorker}
          subTitle={
            <>
              {localeTitles.TITLE_JOB_Hire}
              <br />
              {localeTitles.TITLE_JOB_HIRING_MESSAGE}
            </>
          }
          closeModal={onCloseHireModal}
          loading={hireLoading}
        />
      </ModalComponent>

      <ModalComponent open={inviteModal} onClose={closeInviteModal}>
        <InviteWorkerModal
          onConfirm={onConfirmInvite}
          closeModal={closeInviteModal}
          loading={inviteLoading}
          disableWorkerFetcing={true}
          isEmployee={accountTyp === "employee"}
          defaultValues={{
            jobId: jobId as string,
            worker: workerId as string,
            perHourRate: 0,
            content: "",
          }}
        />
      </ModalComponent>
    </div>
  );
};

export default InviteWorkerPage;
