import React from "react";
import styles from "./FinancePage.module.scss";
import { Outlet } from "react-router-dom";

const FinancePage: React.FC = () => {


  return (
    <div className={styles["FinancePage"]}>
      <Outlet />
    </div>
  );
};

export default FinancePage;
