import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: string;
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
};
function CloseCircleIcon({ color, size, onClick, className }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      className={className}
      width={size ? size : "24"}
      height={size ? size : "24"}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16992 14.8299L14.8299 9.16992"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8299 14.8299L9.16992 9.16992"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CloseCircleIcon;

// fill={white ? "#fffff" : "#0F1322"}
