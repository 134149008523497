// import { useLocale } from "locales";
import React from "react";
import { useDashboardListServices } from "./hook";
import DashboardListPage from "../../pages/DashboardListPage/DashboardListPage";
import Loader from "../../components/atoms/Loader";

export interface services {}

const DashboardListServices = ({}: services) => {
  // const { userData } = UserAuthContext();
  const {
    usersList,
    listLoading,
    hasMore,
    totalUsers,
    onNextPage,
    onPreviousPage,
    currentPage,
  } = useDashboardListServices();

  if (listLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <DashboardListPage
      usersList={usersList}
      hasMore={hasMore}
      totalUsers={totalUsers}
      OnNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      currentPage={currentPage}
    />
  );
};

export default DashboardListServices;

// export default function AccountService() {
//   return (
//     <ProtectedRoute>
//       <AccountServiceComponent />
//     </ProtectedRoute>
//   );
// }
