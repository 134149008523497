import { useLocation, useNavigate } from "react-router-dom";
import type { EmployeeRatingAndReviewProps } from "./@types";
import styles from "./EmployeeRatingAndReview.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { useState } from "react";
import EmployeeRatingAndReviewCard from "../EmployeeRatingAndReviewCard";

const EmployeeRatingAndReview = ({ data }: EmployeeRatingAndReviewProps) => {
  const navigation = (link?: string) => {};
  const { localeBasics, localeTitles } = useLocale();
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  const itemsToShow = showAll ? data?.length : 2;

  return (
    <div className={styles["EmployeeRatingAndReview"]}>
      <Text className={styles["title"]}>
      {localeTitles.TITLE_RATING_AND_REVIEWS}
      </Text>

      <div className={styles["EmployeeRatingAndReview-main"]}>
        {data?.slice(0, itemsToShow).map((job, index) => (
          <EmployeeRatingAndReviewCard key={index} data={job} />
        ))}

        {!showAll && data?.length > 2 && (
          <Text className={styles["see-more"]} onClick={toggleShowAll}>
            {localeBasics.TEXT_SEE_MORE}
          </Text>
        )}
      </div>
    </div>
  );
};

export default EmployeeRatingAndReview;
