import { AxiosResponse } from "axios";

import apiRequest from "utils/axios";
import {
  AddScheduleForm,
  AvailabilityParams,
  GetAvailabilitiesResponse,
  GetOrganizationWorkerResponse,
  GetAlreadyAddedAvailabilitiesResponse,
} from "./types";
import { availabilityTypes } from "components/cells/EditAvailability/@types";
// import { CreateOrganizationJob } from "types/schema";

//  /Add Schedule
export const addScheduleAPI = async (
  data: AddScheduleForm,
  token: string
): Promise<GetOrganizationWorkerResponse> => {
  console.log("addScheduleAPI", data, "token", token);

  const response = await apiRequest({
    method: "post",
    url: `/Worker/Schedule/addSchedule`,
    token,
    data,
  });
  return response;
};

//  /Delete Schedule
export const deleteScheduleAPI = async (
  scheduleId: string,
  token: string
): Promise<GetOrganizationWorkerResponse> => {
   ;

  const response = await apiRequest({
    method: "delete",
    url: `/Worker/Schedule/deleteSchedules/${scheduleId}`,
    token,
  });
  return response;
};

//  /Edit Schedule
export const editScheduleAPI = async (
  data: {
    date: string;
    startTime: string;
    endTime: string;
    task: string;
  },
  id: string,
  token: string
): Promise<GetOrganizationWorkerResponse> => {
  const response = await apiRequest({
    method: "put",
    url: `/Worker/Schedule/updatesSchedule?scheduleId=${id}`,
    token,
    data,
  });
  return response;
};

//  /Add Availability

export const addAvailabilityAPI = async (
  data: AvailabilityParams,
  userId: string,
  token: string
): Promise<GetOrganizationWorkerResponse> => {

  const response = await apiRequest({
    method: "post",
    url: `/Worker/Availability/addAvailability/admin?userId=${userId}`,
    token,
    data,
  });
  return response;
};

//  /Delete Availability
export const deleteAvailabilityAPI = async (
  availabilityId: string,
  token: string
): Promise<GetOrganizationWorkerResponse> => {
  const response = await apiRequest({
    method: "delete",
    url: `/Worker/Availability/deleteAvailability/${availabilityId}/admin`,
    token,
  });
  return response;
};

//  /Edit Availability
export const editAvailabilityAPI = async (
  data: availabilityTypes,
  userId: string,
  availabilityId: string,
  token: string
): Promise<GetOrganizationWorkerResponse> => {
  let url = `/Worker/Availability/updatesAvailability/admin?availabilityId=${availabilityId}&userId=${userId}`;
  const response = await apiRequest({
    method: "put",
    url: url,
    token,
    data,
  });
  return response;
};

//  /Get Availabilities

export const getAlreadyAvailAvailability = async (
  startDate: string,
  endDate: string,
  token: string
): Promise<GetAlreadyAddedAvailabilitiesResponse> => {
   ;
  const response = await apiRequest({
    method: "get",
    url: `Worker/Availability/getExistingAvailability?startDate=${startDate}&endDate=${endDate}`,
    token,
  });

  return response;
};
export const getPreviosWeekAvailability = async (
  date: string,
  token: string
): Promise<GetAlreadyAddedAvailabilitiesResponse> => {
  const response = await apiRequest({
    method: "get",
    url: `Worker/Availability/previousWeekAvailability?date=${date}`,
    token,
  });

  return response;
};

export const getAllAvailabilitiesAPI = async (
  user: string,
  date: string,
  token: string
): Promise<GetAvailabilitiesResponse> => {
  console.log("getAllAvailabilitiesAPI", user, "date", date, "token", token);

  const response = await apiRequest({
    method: "get",
    url: `/Worker/Availability/getAvailability?user=${user}&date=${date}`,
    token,
  });
  return response;
};
//  /Get Schedules

export const getAllSchedulesAPI = async (
  user: string,
  date: string,
  view: string,
  token: string
): Promise<GetAvailabilitiesResponse> => {
  const response = await apiRequest({
    method: "get",
    url: `/Worker/Schedule/getSchedule/admin?userId=${user}&date=${date}&view=${view}`,
    token,
  });
  //
  return response;
};
