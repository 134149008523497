export default {
  TITLE_HOME_HERO:
    "Employee and Freelancer Platform for permanent and flexible work in childcare, healthcare and more!",
  TITLE_LOCATION: "Location",
  TITLE_REMOVE_LOCATION: "Remove Location",
  TITLE_NEW_LOCATION: "Add new location",
  TITLE_EMPLOYEES: "Employees",
  TITLE_FREELANCER: "Freelancer",
  TITLE_OPEN_JOBS: "Open Jobs",
  TITLE_HOW_TO_GET_WORK: "How to Get Work",
  TITLE_THIS_IS_HOW_THE_PLATFORM_WORK:
    "This is how the Berenvriendjes platform works.",
  TITLE_WORKER_POLICY:
    "Working in childcare",
  TITLE_WORKER_POLICY_ORG:
    "Werkwijze: naleving en service",
  TITLE_REGISTER_AS_AN_EMPLOYEE_OR_FREELANCER:
    "Register as an Employee or Freelancer person and create a profile.",
  TITLE_VIEW_ALL_SERVICES:
    "View all services in your location and sign up for the perfect job.",
  TITLE_YES_A_MATCH: "Yes a match! Congratulations on your first work order",
  TITLE_WHAT_TYPE_OF_PEOPLE_YOU_FIND:
    "What type of people you can find\n for Recruitment",
  TITLE_JOB_CATEGOREIS: "Job Categories",
  TITLE_HOW_YOU_CAN_HIRE: "How you can Hire?",
  TITLE_HOW_DO_YOU_HIRE_STAFF: "How do you hire Staff?",
  TITLE_BEARFRIENDS_PARTNERS: "BearFriends Partners",
  TITLE_BEARFRIENDS_APP_ON_STORE:
    "Downlaod BearFriends App on 🎉 Google Play or Appstore",
  TITLE_FREQUENTLY_ASKED_QUESTIONS: "Frequently asked Questions",
  TITLE_FOR_EMPLOYEES: "For Employees",
  TITLE_FOR_FREELANCERS: "For Freelancers",
  TITLE_FOR_ORGANIZATIONS: "For Organizations",
  TITLE_REGISTER_FOR_FREE:
    "Register for free as an Employee or Freelancer and start looking for a work assignment right away!",
    TITLE_REGISTER_FOR_FREE_FREELANCER:
    "Register for free as an Freelancer and start looking for a work assignment right away!",
    TITLE_REGISTER_FOR_FREE_ORG:
    "Register as an Organization for free and immediately find the perfect employee for your service!",
  TITLE_EMPLOYEE_FREEDOM: "Employee Freedom",
  TITLE_FREELANCER_FREEDOM: "Freelancer Freedom",
  TITLE_NO_LONG_TERM_COMMITMENTS: "No Long-Term Commitments",
  TITLE_FLEXIBILITY: "Flexibility",
  TITLE_FOCUS_ON_SPECIFIC_SECTORS: "Focus on Specific Sectors",
  TITLE_ATTENTION_TO_QUALITY: "Attention to Quality",
  TITLE_REGULATORU_EXPERTISE: "Regulatory Expertise",
  TITLE_ABOUT_US: "About Us",
  TITLE_CONTACT_US: "Contact Us",
  TITLE_LOGIN_WELCOME_TITLE:
    "It’s nice to see you again! Log In to Berenvriendjes",
  TITLE_STAFF: "Staff",
  TITLE_PARTNERS: "Partners",
  TITLE_COMMUNICATE_WITH_CLIENTS: "Communicate with Clients",
  TITLE_ITS_NICE_TO_SEE_YOU_AGAIN: "It’s nice to see you again!",
  TITLE_LOGIN_WITH_BERENVIENDJES: "Log In to Berenvriendjes",
  TITLE_WELCOME_HAND: "Welcome! 👋",
  TITLE_HELLO_HAND: "Hello 👋",
  TITLE_WELCOME: " Welcome ",
  TITLE_PLEASE_INTRODUCE_YOURSELF: "Please Introduce Yourself.",
  TITLE_EMPLOYEE: "Employee",
  TITLE_ORGANIZATION: "Organization",
  TITLE_YOUR_EMAIL: "Your Email",
  TITLE_VERIFY_EMAIL: "Verify Email",
  TITLE_FORGOT_PASSWORD: "Forgot Password",
  TITLE_YOUR_PASSWORD: "Your Password",
  TITLE_RESET_PASSWORD: "Reset Password",
  TITLE_BASIC_INFO: "Basic Info",
  TITLE_YOUR_ADDRESS: "Your Address",
  TITLE_KAMER_VAN_KOOPHANDEL: "Kamer van Koophandel",
  TITLE_YOUR_PHONE_NUMBER: "Your Phone Number",
  TITLE_YOUR_BANK_DETAILS: "Your Bank Details",
  TITLE_INDUSTRY_CATEGORY: "Industry Category",
  TITLE_UPLOAD_DOCUMENTS: "Upload Documents",
  TITLE_TERMS_AND_CONDITION: "Terms & Conditions",
  TITLE_BUSINESS_DETAILS: "Business Details",
  TITLE_UPLOAD: "Upload",
  TITLE_TURN_ON: "Turn on",
  TITLE_NOTIFICATIONS: "Notifications",
  TITLE_PROFILE_STATUS: "Profile Status",
  TITLE_CATEGORY_STATUS: "Category Status",
  TITLE_DOCUMENT_STATUS: "Document Status",
  TITLE_PERSONAL_INFO_STATUS: "Personal Info Status",
  TITLE_MOST_RECENT: "Most Recent",
  TITLE_ENTER_DETAILS: "Enter Details",
  TITLE_EARLIER: "Earlier",
  TITLE_FILTERS: "Filters",
  TITLE_SHOW: "Show",
  TITLE_FEATURES: "Features",
  TITLE_MAXIMUM_DISTANCE: "Maximum Distance",
  TITLE_PLACE: "Place",
  TITLE_DATE: "Date",
  TITLE_PER_HOUR: "a hour",
  TITLE_HIRING_MANAGER: "Hiring Manager",
  TITLE_BEGIN_TIME: "Begin Time",
  TITLE_END_TIME: "End Time",
  TITLE_BREAK_TIME: "Break Time",
  TITLE_TRAVEL_EXPENSE: "Travel Expense",
  TITLE_JOB_REPETATION: "Job Repetation",
  TITLE_JOB_REPEAT_WHEN: "Interval",
  TITLE_JOB_REPEAT_END: "End",
  TITLE_HEAD_COUNT: "Head Count",
  TITLE_JOB_TYPE: "Job Type",
  TITLE_JOB_OVERVIEW: "Job Overview",
  TITLE_NOTE: "Note!",
  TITLE_CLOTING_REQUIREMENTS: "Clothing Requirements",
  TITLE_SHOE_REQUIREMENTS: "Shoe Requirements",
  TITLE_ABOUT_COMPANY: "About Company",
  TITLE_APPLY_FOR: "Apply for ",
  TITLE_INBOX: "Inbox",
  TITLE_PERSONAL_INFORMATION: "Personal Information",
  TITLE_PERSONAL_INFO: "Personal Info",
  TITLE_BUSINESS_INFO: "Business Info",
  TITLE_BUSINESS_INFORMATION: "Business Information",
  TITLE_JOB_AND_EDUCATION: "Job & Education",
  TITLE_BRANCHES_AND_CATEGORIES: "Branches & Categories",
  TITLE_CATEGORIES: "Categories",
  TITLE_SKILL_SET_AND_INTERESTS: "Skill Set & Interests",
  TITLE_BANK_DETAILS: "Bank Details",
  TITLE_DOCUMENTS: "Documents",
  TITLE_CONTRACT: "Contract",
  TITLE_ACCOUNT: "Account",
  TITLE_AVATAR: "Avatar",
  TITLE_SETTINGS: "Settings",
  TITLE_PREFERENCES: "Preferences",
  TITLE_EMAIL_SETTINGS: "Email Settings",
  TITLE_FAQS: "FAQs",
  TITLE_ARE_YoU_SURE_DELETE_YOUR_ACCOUNT:
    "Are you sure you want to delete your account?",
  TITLE_ARE_YoU_SURE_LOGOUT_YOUR_ACCOUNT:
    "Are you sure you want to log out from your account?",
  TITLE_ARE_YoU_SURE_DELETE_THIS: "Are you sure you want to delete this?",

  TITLE_ARE_YoU_SURE_COMPLETE_JOB_CONTRACT:
    "Are you sure you want to complete this job contract?",
  TITLE_ARE_YoU_SURE_CANCEL_JOB_CONTRACT:
    "Are you sure you want to cancel this job contract?",
  TITLE_ARE_YoU_SURE_PAUSE_JOB_CONTRACT:
    "Are you sure you want to pause this job contract?",
  TITLE_ARE_YoU_SURE_RESUME_JOB_CONTRACT:
    "Are you sure you want to resume this job contract?",
  TITLE_CAN_NATO_COMPLETE_JOB_CONTRACT:
    "Can not complete this job contract. Please mark all the services completed that are approved or rejected.",
  TITLE_DO_YOU_WANT_RATE_THIS_WORKER: "Do you want to rate this worker?",

  TITLE_ARE_YoU_SURE_HIRE_THIS: "Are you sure you want to hire this person?",

  TITLE_JOB_POSTED_SUCCESSFULLY: "Your job successfully posted.",
  TITLE_JOB_Hire: "Do you want to hire this user?",
  TITLE_JOB_HIRING_MESSAGE: "Worker will be hired and recived notification.",
  TITLE_WANT_TO_SEND_INVITES: "Do you want to send invites?",
  TITLE_MY_SCHEDULE: "My Schedule",
  TITLE_COMPANY_SCHEDULE: "Company Schedule",
  TITLE_DATE_SELECTION: "Date Selection",
  TITLE_INVOICE_INTERVAL: "Invoice Interval",
  TITLE_AVAILABLE: "Available",

  TITLE_AVAILABILITY_SCHEDULE: "Availability Schedule",
  TITLE_GENRATE_INVOICE: "Genrate Invoice",
  TITLE_UNAVAILABLE: "Unavailable",

  TITLE_HIRED: "Hired",
  TITLE_ADD_SCHEDULE: "Add Schedule",
  TITLE_JOB_STATUS: "Job Status",
  TITLE_RATING: "Rating",
  TITLE_REVIEW: "Review",
  TITLE_WORKER_RATING: "Review & Rate Worker",
  TITLE_ADD_ORGANIZATION_SCHEDULE: "Add Organization Schedule",
  TITLE_ORGANIZATION_SCHEDULE_DETAILE: "Organization Schedule Detail",
  TITLE_DETAIL_SCHEDULE: "Detail Scheule",
  TITLE_DETAIL_AVAILABILITY: "Detail Availability",
  TITLE_NO_AVAILABILITY:
    "You have not set any availability yet. Please add availability.",
  TITLE_NO_TASK: "There is no task for you at the moment.",
  TITLE_TOP_RATED: "Top Rated",
  TITLE_SKILLS_AND_EXPERTISE: "Skills & Expertise",
  TITLE_REPEATED_JOB: "Repeated Jobs",
  TITLE_DESCRIPTION: "Description",
  TITLE_JOBS_COMPLETED: "Jobs Completed",
  TITLE_COMPLETED: "Completed",
  TITLE_ONGOING: "Ongoing",
  TITLE_RATING_AND_REVIEWS: "Rating & Reviews",
  TITLE_POST_A_NEW_JOB: "Post a New Job",
  TITLE_EDIT_JOB: "Edit Job",
  TITLE_BOTH_EMPLOYEE_AND_FREELANCER: "Both",
  TITLE_POSITION: "Position",
  TITLE_COPYRIGHT_2023: "@copyright 2023",
  TITLE_MY_STATS: "My Stats",
  TITLE_CLIENT_SATISFACTION: "Client satisfaction",
  TITLE_ANALYTICS: "Analytics",
  TITLE_YEARLY: "Yearly",
  TITLE_MONTHLY: "Monthly",
  TITLE_NO_NOTIFICATIONS: "No Notifications",
  TITLE_WORKING_VERIFICATION_STATUS: "Working Verification Status",
  TITLE_WORKING_VERIFICATION_STATUS_IS_VERIFEID: "You are verified.",

  TITLE_DELETE_ACCOUNT: "Delete Account",
  TITLE_INVITE_TO_FREELANCER_OR_EMPLOYEE: "Invite to Freelancer or Employee",
  TITLE_JOB_APPLICANTS: "Job Applicants",
  TITLE_GO_TO_WORKER_LIST: "Go to Worker List",
  TITLE_ADD_JOB: "Add Job",
  TITLE_ADD_EDUCATION: "Add Education",
  TITLE_EDIT_EDUCATION: "Edit Education",
  TITLE_JOB: "Job",
  TITLE_EDUCATION: "Education",
  TITLE_WORKER_DETAILS: "Worker Details",
  TITLE_BRANCH_AND_CATEGORIES: "Branch and Categories Details",
  TITLE_SKILL_DETAILS: "Skill Details",
  TITLE_INVITE_WORKER: "Invite Worker",
  TITLE_REPEAT_CONTRACT: "Repeat Contract",
  TITLE_APPLY_FOR_JOB: "Apply for job",
  TITLE_FREE_PARKING: "Free Parking",
  TITLE_WORKING_SHIFT: "Working Shift",
  TITLE_BRANCH: "Branches",
  TITLE_CATEGORY: "Categories",
  TITLE_CONTRACT_TYPE: "Contract Type",
  TITLE_REPEATED_JOBS: "Repeated",
  TITLE_JOB_INVITATION: "Job Invitation",
  TITLE_JOB_OFFER: "Job Offer",
  TITLE_BLOCK_USER: "Block user",
  TITLE_MUTE_NOTIFICATION: "Mute notification",
  TITLE_REPORT_USER: "Report user",
  TITLE_ASSIGN_WORKER_TASKS: "Assign worker task",
  TITLE_BIO: "Bio",

  TITLE_LOGIN_ADMIN: "Login for Admin",

  TITLE_PW_RESET: "Reset Password",
  TITLE_SENT_PW_RESET: "Email is sent",
  TITLE_CHANGE_PW: "Change Password",
  TITLE_CREATE_ORDER: "Create an Order",

  TITLE_FILTER: "Filter",
  TITLE_ORDER_STATUS_DELIVERED: "Change order status to delivered?",
  TITLE_ORDER_STATUS_SHIPPED: "Change order status to shipped?",
  TITLE_ORDER_STATUS_ISSUED: "Change order status to Issued order?",
  TITLE_ORDER_STATUS_CANCEL: "Change order status to cancel?",
  TITLE_YEAR_MONTH: "{YYYY}/{MM}",
  TITLE_LOGOUT: "Logout",
  TITLE_LOGIN: "Login",
  TITLE_NO_DATA: "No Data Found",
  TITLE_STATUS_CHANGE: "Change Status",
  TITLE_EDIT_ACCOUNT: "Edit Account",
  TITLE_UPDATE_PW: "Update Password",
  TITLE_REGISTRATION: "Registration Form",
  TITLE_TOTAL_FEEDS: "Total feeds by category for the month",
  TITLE_MAINTENANCE_BLOG: "Maintenance blog by category for the month",
  TITLE_TOTAL_TALKROOM: "Total talkroom by category for the month",
  TITLE_TOTAL_TOURINGS: "Total tourings for the month",
  TITLE_ADD_BIKE: "Add bike",
  TITLE_DEACTIVATE_ACCOUNT: "Deactivate this account",
  TITLE_ALREADY_INACTIVE: "User already inactive",
  TITLE_SAFR: "SAFR Admin",
  TITLE_ADD_GOODPLAN: "Add Good plan",
  TITLE_EDIT_GOODPLAN: "Edit Good plan",
  TITLE_ADD_INSIDE: "Add Inside",
  TITLE_ADD_OUTSIDE: "Add Outside",
  TITLE_ADD_MODE: "Add Mode",
  TITLE_ADD_EXPERIENCE_TYPE: "Add Experience Type",
  TITLE_INSIDE_OPTIONS: "Inside Options",
  TITLE_OUTSIDE_OPTIONS: "Outside Options",
  TITLE_MODE_OPTIONS: "Mode Options",
  TITLE_UPLOAD_PHOTO: "Upload Photo",
  TITLE_PRODUCT_MONTHLY_GROWTH_RATE: "Product Monthly growth rate ",
  TITLE_TOTAL_JOINED_USERS: "Total Joined Users",
  TITLE_USERS_INSIGHTS: "Users Insights",
  TITLE_USERS_NOTIFICATIONS: "User’s Notification",
  TITLE_TOTAL_USERS: "Total users",
  TITLE_TOTAL_EMPLOYEES: "Total Employees",
  TITLE_TOTAL_FREELANCERS: "Total Freelancers",
  TITLE_TOTAL_ORGANIZATIONS: "Total Organizations",
  TITLE_TOTAL: "Total",
  TITLE_ALL: "All",
  TITLE_READ: "Read",
  TITLE_UNREAD: "Unread",
  TITLE_PLEASE_ADD_BRANCH: "Please add branch",
  TITLE_PLEASE_ADD_CATEGORY: "Please add category",
  TITLE_ADD_SKILLS: "Add Skills",
  TITLE_EDIT_BRANCH: "Edit branch",
  TITLE_EDIT_CATEGORY: "Edit category",
  TITLE_EDIT_SKILLS: "Edit Skills",

  LABEL_TASKS: "Tasks",

  TITLE_UNBLOCK_USER: "Unblock user",
  TITLE_UNMUTE_NOTIFICATION: "Unmute notification",
  TITLE_CULTURE: "Culture",

  TITLE_CONTRACT_START_DATE:"Start Date",
  TITLE_CONTRACT_END_DATE:"End Date",
  TITLE_ORGANIZATION_CULUTRE:"Custom solutions",



}