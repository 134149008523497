import React from "react";
type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function BellIcon({ white, size }: Props) {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6443 18.0254C22.9447 17.481 22.3322 16.8568 21.8233 16.1698C21.2677 15.2214 20.9347 14.1857 20.8439 13.1234V9.99476C20.8487 8.32631 20.1554 6.71376 18.8942 5.46008C17.6331 4.20639 15.891 3.39787 13.9951 3.18643V2.36943C13.9951 2.14519 13.8931 1.93013 13.7114 1.77157C13.5298 1.61301 13.2834 1.52393 13.0266 1.52393C12.7697 1.52393 12.5233 1.61301 12.3417 1.77157C12.1601 1.93013 12.058 2.14519 12.058 2.36943V3.19909C10.1792 3.42578 8.45806 4.23919 7.2135 5.48866C5.96895 6.73813 5.28526 8.339 5.28909 9.99476V13.1234C5.19822 14.1857 4.86523 15.2214 4.30966 16.1698C3.8097 16.8552 3.20704 17.4793 2.51767 18.0254C2.44028 18.0848 2.37826 18.1578 2.33572 18.2397C2.29319 18.3216 2.27113 18.4105 2.271 18.5004V19.3618C2.271 19.5297 2.34743 19.6908 2.48349 19.8096C2.61955 19.9284 2.80408 19.9951 2.9965 19.9951H23.1655C23.3579 19.9951 23.5424 19.9284 23.6785 19.8096C23.8145 19.6908 23.891 19.5297 23.891 19.3618V18.5004C23.8908 18.4105 23.8688 18.3216 23.8263 18.2397C23.7837 18.1578 23.7217 18.0848 23.6443 18.0254ZM3.78004 18.7284C4.45506 18.1592 5.04938 17.5213 5.55027 16.8284C6.2501 15.683 6.65842 14.4192 6.74735 13.1234V9.99476C6.71857 9.25252 6.8612 8.51281 7.16672 7.81968C7.47225 7.12656 7.93442 6.49419 8.52573 5.96024C9.11704 5.42628 9.8254 5.00167 10.6086 4.71167C11.3918 4.42167 12.2339 4.27222 13.0846 4.27222C13.9354 4.27222 14.7774 4.42167 15.5606 4.71167C16.3438 5.00167 17.0522 5.42628 17.6435 5.96024C18.2348 6.49419 18.697 7.12656 19.0025 7.81968C19.308 8.51281 19.4507 9.25252 19.4219 9.99476V13.1234C19.5108 14.4192 19.9191 15.683 20.619 16.8284C21.1198 17.5213 21.7142 18.1592 22.3892 18.7284H3.78004Z"
        fill="#FFA412"
      />
      <path
        d="M13.1172 22.1105C13.5742 22.1013 14.0128 21.9513 14.3553 21.6871C14.6979 21.4228 14.9223 21.0613 14.989 20.6665H11.1729C11.2414 21.072 11.4763 21.442 11.834 21.7076C12.1917 21.9733 12.6477 22.1164 13.1172 22.1105Z"
        fill="#FFA412"
      />
    </svg>
  );
}

export default BellIcon;
