import { useLocation, useNavigate } from "react-router-dom";

import type { NotificationOptionsProps } from "./@types";
import styles from "./NotificationOptions.module.scss";
import { useLocale } from "../../../locales";

import React from "react";
import useComponentVisible from "../../../utils/useComposite";
import { Text } from "../../atoms";
import AlertDot from "../../../assets/Jsx/AlertDot";
import Image from "../../atoms/Image";
import { handleImagesPath } from "../../../utils/hooks/helper";
import dayjs from "dayjs";

const NotificationOptions = ({
  dropDownOptions,
  className,
  icon,
}: NotificationOptionsProps) => {
  const { localeTitles } = useLocale();
  const navigate = useNavigate();
  const { ref, setIsNotificationVisible, isNotificationVisible } =
    useComponentVisible(false);
  const toggle = () => {
    if (isNotificationVisible == true) {
      setIsNotificationVisible(false);
    } else {
      setIsNotificationVisible(true);
    }
  };

  const navigation = (link: string) => {
    navigate(link);
    setIsNotificationVisible(false);
  };

  return (
    <div className={className} ref={ref}>
      {/* <div className={styles["actionButton-postListing-icon"]} onClick={toggle}> */}
      <div className={styles["notification-icon"]} onClick={toggle}>
        {icon}
        {/* <div className={styles["notification-alert"]}>
            <AlertDot />
          </div> */}
      </div>
      {/* </div> */}

      {isNotificationVisible ? (
        <div className="font-bold">
          <div className="bg-white p-2 mt-3 bg-white-A700 space-y-3 flex flex-col rounded-lg font-bold  top-5  absolute border-2 border-black w-[160px] z-20 right-[0%]">
            <div className={styles["dropdown-option-top"]}>
              {dropDownOptions?.options.map((item) => (
                <div
                  className={styles["dropdown-option"]}
                  // onClick={() => navigation(item.path)}
                >
                  {item.Icon}
                  <Text className={styles["dropdown-menu"]}>{item.label}</Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationOptions;
