import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

interface IMapProps {
  lng: number | undefined;
  lat: number | undefined;
}

const containerStyle = {
  width: "100%",
  height: "400px",
};
const GoogleMapComponent = ({ lat, lng }:IMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB7Jlc-Hyyf5llSQBoJYaFAoaoUIUo-FsU",
  });
  const center = {
    lat: lat ? lat : 0,
    lng: lng ? lng : 0,
  };

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
      <Marker position={center} />
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMapComponent);
