import type { ExpandableFieldProps } from "./@types";
import styles from "./ExpandableField.module.scss";
import { Text } from "components/atoms/TextF";

import { ControlInput } from "components/atoms/ControlledInput/Input";
import { Collapse } from "@mui/material";
import ChevronDownIcon from "assets/svgs/ChevronDownIcon";
import { useLocale } from "locales";
import { useState } from "react";
import dayjs from "dayjs";
import PencilIcon from "assets/svgs/PencilIcon";
import { Education, Job } from "types/forms";
import TrashIcon from "assets/svgs/TrashIcon";

const ExpandableField = ({
  control,
  name,
  prefix,
  fieldDataEducation,
  fieldDataJob,
  category,
  editEducationField,
  editJobField,
  removeEducationField,
  removeJobField,
  index,
}: ExpandableFieldProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const { localeLabels } = useLocale();
  // console.log("ImageData", ImageData);
  return (
    <div className={styles["expandable"]}>
      <ControlInput
        readOnly
        control={control}
        name={name}
        placeholder={name}
        className={styles["inputField"]}
        onClick={handleExpandClick}
        wrapClassName={styles["inputField-wrap"]}
        type="text"
        prefix={prefix}
        suffix={
          <ChevronDownIcon color={"#101828"} onClick={handleExpandClick} />
        }
      />
      <Collapse in={isExpanded} className="w-full">
        <div className={styles["attributeLarge"]}>
          <div className={styles["AddJobModal-sections"]}>
            {category === "EDUCATION" ? (
              <div className={styles["eachSection"]}>
                <Text className={styles["heading"]}>
                  {localeLabels.LABEL_DEGREE_TITLE}:
                </Text>
                <div className={styles["each div"]}>
                  <Text className={styles["heading"]}>
                    {fieldDataEducation?.degreeName}
                  </Text>
                </div>
              </div>
            ) : (
              <div className={styles["eachSection"]}>
                <Text className={styles["heading"]}>
                  {localeLabels.LABEL_JOB_TITLE}:
                </Text>
                <div className={styles["each div"]}>
                  <Text className={styles["heading"]}>
                    {fieldDataJob?.positionName}
                  </Text>
                </div>
              </div>
            )}

            {category === "EDUCATION" ? (
              <div className={styles["eachSection"]}>
                <Text className={styles["heading"]}>
                  {localeLabels.LABEL_INSTITUE_NAME}:
                </Text>
                <div className={styles["each div"]}>
                  <Text className={styles["heading"]}>
                    {fieldDataEducation?.school}
                  </Text>
                </div>
              </div>
            ) : (
              <div className={styles["eachSection"]}>
                <Text className={styles["heading"]}>
                  {localeLabels.LABEL_COMPANY_NAME}:
                </Text>
                <div className={styles["each div"]}>
                  <Text className={styles["heading"]}>
                    {fieldDataJob?.organization}
                  </Text>
                </div>
              </div>
            )}
            <div className={styles["eachSection-date"]}>
              {category === "EDUCATION" ? (
                <div className={styles["eachSection"]}>
                  <Text className={styles["heading"]}>
                    {localeLabels.LABEL_START_DATE}:
                  </Text>
                  <div className={styles["each div"]}>
                    <Text className={styles["heading"]}>
                      {dayjs(fieldDataEducation?.startDate).format(
                        "DD-MM-YYYY"
                      )}
                    </Text>
                  </div>
                </div>
              ) : (
                <div className={styles["eachSection"]}>
                  <Text className={styles["heading"]}>
                    {localeLabels.LABEL_START_DATE}:
                  </Text>
                  <div className={styles["each div"]}>
                    <Text className={styles["heading"]}>
                      {dayjs(fieldDataJob?.startDate).format("DD-MM-YYYY")}
                    </Text>
                  </div>
                </div>
              )}
              {category === "EDUCATION" ? (
                <div className={styles["eachSection"]}>
                  <Text className={styles["heading"]}>
                    {localeLabels.LABEL_END_DATE}:
                  </Text>
                  <div className={styles["each div"]}>
                    <Text className={styles["heading"]}>
                      {dayjs(fieldDataEducation?.endDate).format("DD-MM-YYYY")}
                    </Text>
                  </div>
                </div>
              ) : (
                <div className={styles["eachSection"]}>
                  <Text className={styles["heading"]}>
                    {localeLabels.LABEL_END_DATE}:
                  </Text>
                  <div className={styles["each div"]}>
                    <Text className={styles["heading"]}>
                      {dayjs(fieldDataJob?.endDate).format("DD-MM-YYYY")}
                    </Text>
                  </div>
                </div>
              )}
            </div>

            <div className={styles["eachSection-date"]}>
              <div className={styles["edit"]}>
                <PencilIcon
                  color="white"
                  onClick={() =>
                    category === "EDUCATION"
                      ? editEducationField &&
                        editEducationField(
                          fieldDataEducation as Education,
                          index
                        )
                      : editJobField && editJobField(fieldDataJob as Job, index)
                  }
                />
              </div>

              <div className={styles["remove"]}>
                <TrashIcon
                  color="white"
                  onClick={() =>
                    category === "EDUCATION"
                      ? removeEducationField && removeEducationField(index)
                      : removeJobField && removeJobField(index)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Collapse>{" "}
    </div>
  );
};

export default ExpandableField;
