import React from "react";
import styles from "./PersonalSchedulePage.module.scss";

import { usePersonalSchedulePage } from "./hook";
import ModalComponent from "components/molecules/ModalF/Modal";

import EventCalendar from "components/organisms/EventCalendar";

import AvailabilitySchedule from "components/cells/AvailabilitySchedule";

const PersonalSchedulePage: React.FC = () => {
  const {
    availabilityScheduleModal,
    handleOpenAvailabilitySchedule,
    handleCloseAvailabilitySchedule,
    onConfirmAvailability,
    availabilityLoading,
    selectedDate,
    setSelectedDate,
    eventsData,
    refetchSchedules,
    view,
    setView,
    availability,
  } = usePersonalSchedulePage();

  return (
    <div className={styles["PersonalSchedulePage"]}>
      <EventCalendar
        eventsData={eventsData}
        addAvailability={handleOpenAvailabilitySchedule}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        refetchData={refetchSchedules}
        view={view}
        setView={setView}
      />

      <ModalComponent
        open={availabilityScheduleModal}
        onClose={handleCloseAvailabilitySchedule}
      >
        <AvailabilitySchedule
          loading={availabilityLoading}
          onConfirm={onConfirmAvailability}
          closeModal={handleCloseAvailabilitySchedule}
          defaultValues={availability}
        />
      </ModalComponent>
    </div>
  );
};

export default PersonalSchedulePage;
