import { Text } from "components/atoms/TextF";
import styles from "./DayView.module.scss";
import { DayViewProps } from "./@types";
import { useLocale } from "locales";
import dayjs from "dayjs";
import LabelIndicator from "assets/svgs/LabelIndicator";
import EventCard from "components/molecules/EventCard";

import { useEffect, useState } from "react";
import { Pannel } from "./Panel";

import monthStyles from "../MonthView/MonthView.module.scss"
import { useSearchParams } from "react-router-dom";

const DayView = ({
  selectedDate,
  eventsData,
  addAvailability,
  refetchData,
}: DayViewProps) => {
  const { localeLabels } = useLocale();
  const availability =
    eventsData?.[dayjs(selectedDate).format("YYYY-MM-DD")]?.availability;
  const jobs =
    eventsData?.[dayjs(selectedDate).format("YYYY-MM-DD")]?.hiredWorker;





  const calculateEventWidth = (
    startTime: string,
    endTime: string,
    shift?: "day_shift" | "night_shift"
  ) => {
    const startTimeObj = dayjs(`2000-01-01T${startTime}`);
    const endTimeObj = dayjs(`2000-01-01T${endTime}`);

    if (shift == "night_shift") {
      const minuteDifference = endTimeObj
        .add(1, "day")
        .diff(startTimeObj, "minute");

      const baseWidth = 155;
      const widthPixels = (minuteDifference / 60) * baseWidth;
      return `${widthPixels}px`;
    }

    const minuteDifference = endTimeObj.diff(startTimeObj, "minute");

    const baseWidth = 155;
    const widthPixels = (minuteDifference / 60) * baseWidth;

    return `${widthPixels}px`;
  };

  const calculateEventLeft = (startTime: string) => {
    const startTimeObj = dayjs(`2000-01-01T${startTime}`);
    const minuteDifference = startTimeObj.diff(
      dayjs("2000-01-01T00:00"),
      "minute"
    );
    const baseLeft = 155;
    const leftPixels = (minuteDifference / 60) * baseLeft;

    return `${leftPixels}px`;
  };

  const [isPopoverOpen, setPopoverOpen] = useState(false);


  const [params, setParamas] = useSearchParams();
  const openPopover = (jobId?: string) => {
    if (jobId) {
      setParamas({ job: jobId, date: selectedDate.format("YYYY-MM-DD") });
    } else {
      setParamas({ date: selectedDate.format("YYYY-MM-DD") });
    }

    setPopoverOpen(!isPopoverOpen);
  };

  const startTime = () => {
    if (availability?.availability === "available") {
      if (availability?.shift === "night_shift") {
        return "18:00";
      } else {
        return "06:00";
      }
    }

    return "00:00";
  };
  const endTime = () => {
    if (availability?.availability === "available") {
      if (availability?.shift === "night_shift") {
        return "06:00";
      } else {
        return "18:00";
      }
    }
    return "23:59";
  };


  return (
    <>
      <div className={styles["day-view"]}>
        <div className={styles["day-view-left"]}>
          <div className={styles["weekLabel"]}>
            <Text className={styles["dayLabel"]}>
              {localeLabels.LABEL_DATE}
            </Text>
          </div>
          <div className={styles["weekLabel"]}>
            <Text className={styles["dayLabel"]}>
              {selectedDate?.format(
                availability?.shift === "night_shift" ? "DD" : "DD-MMM"
              )}{" "}
              {availability?.availability === "available" &&
                availability?.shift === "night_shift" &&
                " - " + selectedDate?.add(1, "day").format("DD-MMM")}
            </Text>
          </div>
          {jobs?.length > 0 && <div className={styles["weekLabel"]}>
            <Text className={styles["dayLabel"]}>
              {localeLabels.LABEL_TASKS}
            </Text>
          </div>}
        </div>
        <div
          className={
            isPopoverOpen
              ? styles["day-view-main"]
              : styles["day-view-main-full"]
          }
        >
          <div className={styles["datesContainer-head"]}>
            <div className={styles["datesContainer"]}>
              {Array.from({ length: 24 }, (_, hour) => (
                <Text key={hour} className={styles["dayLabel-day"]}>
                  {hour.toString().padStart(2, "0")}:00
                </Text>
              ))}
              {(availability?.availability === "available" && availability?.shift === "night_shift" )
              || jobs?.some(item=>item?.job?.shift == "night" ) &&
                Array.from({ length: 8 }, (_, hour) => (
                  <Text key={hour} className={styles["dayLabel-day"]}>
                    {hour.toString().padStart(2, "0")}:00
                  </Text>
                ))}
            </div>
          </div>
          <div className={styles["weekContainer"]}>
            <div className={styles["datesContainer-events"]}>
              <div
                className={`${styles["dateContainer"]} cursor-pointer`}
                onClick={(e)=>{
                  e.stopPropagation();
                  openPopover();
                }}
              >
                <div className={`${styles["eventsContainer"]}`}>
                  {availability && availability?.availability !== "partial" && (
                    <EventCard
                      left={calculateEventLeft(startTime())}
                      width={calculateEventWidth(
                        startTime(),
                        endTime(),
                        availability?.shift as any
                      )}
                      shift={availability?.shift}
                      availability={
                        availability?.availability
                      }
                      startTime={startTime()}
                      endTime={endTime()}
                      isTask={false}
                    />
                  )}
                  {availability?.availability === "partial" &&
                  (
                    <EventCard
                          left={calculateEventLeft(dayjs(availability?.timeFrame?.[0]).format("HH:mm"))}
                          width={calculateEventWidth(
                            dayjs(availability?.timeFrame?.[0]).format("HH:mm"),
                            dayjs(availability?.timeFrame?.[1]).format("HH:mm"),
                            availability?.shift as any
                          )}
                          isTask={false}
                          shift={availability?.shift}
                          availability={
                            availability?.availability
                          }
                          startTime={dayjs(availability?.timeFrame?.[0]).format("HH:mm")}
                        />
                  )
                    }
                </div>
                {jobs?.map((item) => (
                  <div className={`${styles["eventsContainer"]}`} 
                  onClick={(e)=>{
                    e.stopPropagation();
                    openPopover(item?.job?._id);
                  }}
                  >
                    <EventCard
                      isTask={true}
                      left={calculateEventLeft(
                        dayjs(item?.job?.timeToStart).format("HH:mm")
                      )}
                      width={calculateEventWidth(
                        dayjs(item?.job?.timeToStart).format("HH:mm"),
                        dayjs(item?.job?.timeToEnd).format("HH:mm"),
                        item?.job?.shift=="day"?"day_shift":"night_shift"
                      )}
                      availability={item?.status as any}
                      shift={item?.job?.title}
                      startTime={dayjs(item?.job?.timeToStart).format("HH:mm")}
                      endTime={dayjs(item?.job?.timeToEnd).format("HH:mm")}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {isPopoverOpen && (
          <Pannel
            setPopoverOpen={setPopoverOpen}
            isPopoverOpen={isPopoverOpen}
            selectedDate={selectedDate}
            jobs={jobs}
            availability={availability}
            addAvailability={() => {
              addAvailability([
                dayjs(selectedDate)?.startOf("week").toDate(),
                dayjs(selectedDate)?.endOf("week").toDate(),
              ]);
            }}
            refetchData={refetchData}
          />
        )}
      </div>

      <div className={monthStyles["datesContainer-bottom"]}>
        <div className={monthStyles["indicators"]}>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#181203" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_HIRED}
            </Text>
          </div>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#DA011C" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_REJECTED}
            </Text>
          </div>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="rgb(228, 174, 38)" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_DECLINED}
            </Text>
          </div>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#819bf7" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_APPROVED}
            </Text>
          </div>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#C9D5FF" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_COMPLETED}
            </Text>
          </div>
        </div>
        <div className={monthStyles["indicators"]}>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#009633" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_AVAILABLE}
            </Text>
          </div>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#DA011C" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_UNAVAILABLE}
            </Text>
          </div>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#B5B8C3" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_PARTIAL_AVAILABLE}
            </Text>
          </div>
          <div className={monthStyles["each-indicator"]}>
            <LabelIndicator color="#C9D5FF" />
            <Text className={monthStyles["labelText"]}>
              {localeLabels.LABEL_ALREADY_HIRED}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default DayView;
