import React, { useState } from "react";
import type { selectProps } from "./@types";
import styles from "./Select.module.scss";
import { useLocale } from "../../../locales";

const Select = ({
  name,
  list,
  register,
  validation,
  branchId,
  ...props
}: selectProps & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) => {
  console.log("selectedId", name);
  const { localePlaceholders } = useLocale();
  return (
    <div>
      <select
        defaultValue={branchId}
        // value={branchId}
        // name={name}
        placeholder={localePlaceholders.PLACEHOLDER_SELECT}
        className="border-black border-2 p-2 focus:outline-0 w-full rounded-md placeholder:text-black"
        {...props}
        {...register?.(name, validation)}
      >
        {list?.map((item, index) => {
          return (
            <option key={index} value={item._id}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
