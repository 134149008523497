import dayjs from "dayjs";
import { useLocale } from "locales";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";
import {
  deleteAvailabilityAPI,
  editAvailabilityAPI,
} from "utils/globalAPI/ScheduleAndAvailability";

import { availabilityTypes } from "components/cells/EditAvailability/@types";
import { IAvailabilties, ISchedule } from "../ScheduleModal/@types";
import { useParams } from "react-router-dom";

export const usePersonalSchedulePage = (refetchSchedules: () => void) => {
  const { localeSuccess } = useLocale();

  const { accessToken } = UsersContextConsumer();
  const toast = ToastConsumer();

  const [availability, setAvailability] = useState<IAvailabilties>();
  const [loading, setLoading] = useState<boolean>(false);

  const [modals, setModals] = useState({
    deleteAvailability: false,

    editAvailability: false,

    acceptTask: false,
    declineTask: false,
    compeleteTask: false,
  });

  const toggleDeleteAvailabilityModal = () => {
    setModals((prev) => ({
      ...prev,
      deleteAvailability: !prev.deleteAvailability,
    }));
  };
  const toggleEditAvailabilityModal = () => {
    setModals((prev) => ({
      ...prev,
      editAvailability: !prev.editAvailability,
    }));
  };

  const { userId } = useParams();

  const onConfirmAvailabilityEdit: SubmitHandler<availabilityTypes> = async (
    data
  ) => {
    const params = {
      availability: {
        date: data?.date,
        availability: data?.availability,
        shift: data?.shift,
        timeFrame: data?.timeFrame,
        reason: data?.reason,
      },
    };
    try {
      setLoading(true);
      const response = await editAvailabilityAPI(
        params as any,
        userId,
        data?._id as string,
        accessToken
      );
      toast.success(localeSuccess.SUCESS_AVAILABILITY_UPDATED, "top-right");
      refetchSchedules();
      toggleEditAvailabilityModal();
      // console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setLoading(false);
    }
  };

  const onDeleteAvailability = async () => {
    try {
      //
      setLoading(true);

      const response = await deleteAvailabilityAPI(
        availability?._id as string,
        accessToken
      );
      toast.success(localeSuccess.SUCESS_SCHEDULE_DELETED, "top-right");
      toggleDeleteAvailabilityModal();
      refetchSchedules();
      // console.log("response", response);
    } catch (error: any) {
      toast.serverError(error, "top-right");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteAvailability = (data: IAvailabilties) => {
    setAvailability(data);
    toggleDeleteAvailabilityModal();
  };
  const openAvailabiltySchedule = (data: IAvailabilties) => {
    setAvailability(data);
    toggleEditAvailabilityModal();
  };

  const clockColor = (status: string) => {
    if (status === "available") {
      return "#16911b";
    } else if (status === "unavailable") {
      return "#da011c";
    } else {
      return "#181203";
    }
  };

  return {
    modals,
    toggleEditAvailabilityModal,
    onConfirmAvailabilityEdit,
    onDeleteAvailability,
    openDeleteAvailability,
    loading,
    toggleDeleteAvailabilityModal,
    openAvailabiltySchedule,
    clockColor,
    availability,
  };
};
