import { Controller } from "react-hook-form";
import styles from "./ReactControlSelect.module.scss";

import Select, { components } from "react-select";
import { ErrorMessage } from "../ErrorMessage";
import AsyncSelect from "react-select/async";
export const ReactControlSelect = ({
  name = "",
  control,
  defaultValue,
  options,
  placeholder,
  unControlled = false, 
  isAsync = false,
  onChange,
  isMulti = false,
  maxHeight = 40,
  loadOptions,
}: {
  name?: string;
  control?: any;
  defaultValue?: {
    value: string;
    label: string;
  };
  options: {
    value: string;
    label: string;
  }[];
  placeholder?: string;
  unControlled?: boolean;
  isAsync?: boolean;
  onChange?: (value: string) => void;
  loadOptions?: (
    inputValue: string
  ) => Promise<{ value: string; label: string }[]>;
  isMulti?: boolean;
  maxHeight?: number;
}) => {
  if (unControlled) {
    return (
    
        <SelectComponent
          onChange={onChange as any}
          defaultValue={defaultValue as any}
          options={options}
          placeholder={placeholder}
          isMulti={isMulti}
          maxHeight={maxHeight}
        />
    
    );
  }
  if (isAsync)
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange }, formState: { errors } }) => (
          <>
          <SelectAsyncComponent
            onChange={onChange}
            defaultValue={defaultValue as any}
            loadOptions={loadOptions}
            options={options}
            placeholder={placeholder}
          />
          <ErrorMessage errors={`${errors?.[name]?.message}`} />
        </>
      )}
    />
  );
  return (
 
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange }, formState: { errors } }) => (
          <>
            <SelectComponent
              onChange={onChange}
              defaultValue={defaultValue as any}
              options={options}
              placeholder={placeholder}
              isMulti={isMulti}
              maxHeight={maxHeight}
            />
            <ErrorMessage errors={`${errors?.[name]?.message}`} />
          </>
        )}
      />
   
  );
};

const SelectComponent = ({
  defaultValue,
  options,
  placeholder,
  onChange,
  isMulti,
  maxHeight,
}: {
  defaultValue: {
    value: string;
    label: string;
  };
  options: {
    value: string;
    label: string;
  }[];
  placeholder?: string;
  onChange: (value: string) => void;
  isMulti?: boolean;
  maxHeight: number;
}) => {
  const MoreSelectedBadge = (props: any) => {
    return <components.MultiValue {...props} />;
  };
  const MultiValue = ({ index, getValue, ...props }: any) => {
    const maxToShow = 0;
    const overflow = () =>
      getValue()
        .slice(maxToShow)
        .map((x: any) => x.label);

    return index < maxToShow ? (
      <components.MultiValue getValue={() => {}} {...props} />
    ) : index === maxToShow ? (
      <div className="flex flex-row">
        <MoreSelectedBadge getValue={overflow} index={index} {...props} />

        {/* show +2 or + so on  */}

        {`+${getValue().length - 1}`}
      </div>
    ) : null;
  };
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    data,
    ...rest
  }: any) => {
    const style = {
      alignItems: "center",
      backgroundColor: "#fff",
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      style,
    };

    return (
      <components.Option
        isDisabled={isDisabled}
        data={data}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
        {...rest}
      >
        <div className={styles["height-options"]}>
          <input type="checkbox" checked={isSelected} />
          <div>{children}</div>
        </div>
      </components.Option>
    );
  };
  return (
    <Select
      onChange={(value) =>
        onChange(
          isMulti && Array.isArray(value)
            ? value?.map((item) => item.value)
            : (value as any)?.value
        )
      }
      defaultValue={defaultValue}
      options={options}
      isSearchable={true}
      isMulti={isMulti}
      placeholder={placeholder}
      components={{MultiValue,Option:InputOption}}
      // className={styles["selectInputAvailability"]}
      styles={{
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? "#fff" : "#000",
          backgroundColor: state.isSelected ? "#8BA3F5" : "#fff",
          padding: "10px !important",
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "inherit",
          cursor: "pointer",
          height: 40,
          // maxHeight: maxHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 5,

          
        }),
        menuList: (provided) => ({
          ...provided,
          padding: "5px",
        }),
        menu: (provided) => ({
          ...provided,
          padding: "0px",
        }),
        container: (provided) => ({
          ...provided,
          // width: "170px",
        }),
        input: (provided) => ({
          ...provided,
          fontSize: 12,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: 12,
          fontWeight: 500,
          fontFamily: "inherit",
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: 12,
          fontWeight: 500,
          fontFamily: "inherit",
          textAlign: "left",
          width: "100%",
        }),
      }}
    />
  );
};


const SelectAsyncComponent = ({
  defaultValue,
  placeholder,
  onChange,
  loadOptions,
  options
}: {
  defaultValue: {
    value: string;
    label: string;
  };
  options: {
    value: string;
    label: string;
  }[];
  placeholder?: string;
  onChange: (value: string) => void;
  loadOptions: (
    inputValue: string
  ) => Promise<{ value: string; label: string }[]>;
}) => {
  return (
    <AsyncSelect
      onChange={(value) => onChange(value?.value as string)}
      defaultValue={defaultValue}
      loadOptions={loadOptions}
      cacheOptions={true}
      defaultOptions={true}
      options={options}
      isSearchable={true}
      placeholder={placeholder}
      // className={styles["selectInputAvailability"]}
      styles={{
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? "#fff" : "#000",
          backgroundColor: state.isSelected ? "#8BA3F5" : "#fff",
          padding: "10px !important",
          fontSize: 12,
          fontWeight: 500,
          fontFamily: "inherit",
          cursor: "pointer",
          height: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 5,
        }),
        menuList: (provided) => ({
          ...provided,
          padding: "5px",
        }),
        menu: (provided) => ({
          ...provided,
          padding: "0px",
        }),
        container: (provided) => ({
          ...provided,
          // width: "170px",
        }),
        input: (provided) => ({
          ...provided,
          fontSize: 12,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: 12,
          fontWeight: 500,
          fontFamily: "inherit",
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: 12,
          fontWeight: 500,
          fontFamily: "inherit",
          textAlign: "left",
          width: "100%",
        }),
      }}
    />
  );
};

