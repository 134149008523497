import React, { useEffect } from "react";
import styles from "./OrganizationSchedule.module.scss";
import { useOrganizationSchedule } from "./hook";

import CompanySchedule from "components/organisms/CompanySchedule";
import { useLocation,useNavigate } from "react-router-dom";
import { UsersContextConsumer } from "store/AuthProvider";


const OrganizationSchedule: React.FC = () => {
  const {
    activeCategory,
    setActiveCategory,
    setValue,
    watch,
    workers,
    tasksList,
    refetchTasks,
    control,
    workerType,
    workerLoading,
  } = useOrganizationSchedule();

  const {organizationAddress } = UsersContextConsumer()
  const navigate = useNavigate()



  useEffect(() => {
    if(!organizationAddress)
      navigate('/')
  },[organizationAddress])


  return (
    <div className={styles["OrganizationSchedule"]}>
      <CompanySchedule
        tasksList={tasksList || []}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        workers={workers || []}
        workerType={workerType}
        control={control}
        workerLoading={workerLoading}
        refetchTasks={refetchTasks as any}
        setValue={setValue}
        watch={watch}
        organizationAddress={organizationAddress}
      />
    </div>
  );
};

export default OrganizationSchedule;
