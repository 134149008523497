import React from "react";
import type { OrderStatusChangeBtnProps } from "./@types";
import styles from "./TableButton.module.scss";

const OrderStatusChangeBtn = ({
  cursor = "cursor-pointer",
  backgroundColor = "#FAFAFA",
  ...props
}: OrderStatusChangeBtnProps) => {
  return (
    <div
      className={"flex items-center  justify-around ml-3 mr-3 mb-2 " + cursor}
      style={{
        ...props.buttonStyle,
        ...{
          backgroundColor: backgroundColor,
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          padding: "3px 0px",
          margin: "6px 3px 0 ",
          width: "78px",
          height: "43px",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
          fontWeight: "700",
        },
      }}
      {...props}
    >
      {props.children}
      <span className={`${props.extraClass} font-bold text-xs `}>
        {props.text}
      </span>
    </div>
  );
};

export default OrderStatusChangeBtn;
