import { TextProps } from "./@types";
import styles from "./Text.module.scss";
import { forwardRef } from "react";

const Text = forwardRef(
  (
    {
      children,
      size = "20px",
      color = "black",
      weight,
      linearGradient = false,
      preDefinedClassName = "title-main",
      onClick,
      ...props
    }: TextProps,
    ref
  ) => {
    return (
      <div
        ref={ref as any}
        onClick={onClick}
        className={`${styles[`text`]} ${styles[preDefinedClassName]} ${
          styles[`text-${weight}`]
        } ${styles[`text-${color}`]} ${styles[`text-${size}`]} ${
          styles[`text-${linearGradient && "linear-gradient"}`]
        }`}
        style={props.textStyle}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export { Text };
