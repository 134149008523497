import React from "react";
type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function ChevronDownIcon({ white, size }: Props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3379 15.0002L12.3371 15.0002C12.2713 15.0006 12.2061 14.988 12.1452 14.9631C12.0845 14.9383 12.0293 14.9018 11.9827 14.8558C11.9825 14.8556 11.9823 14.8554 11.9821 14.8552L7.98358 10.8566C7.88905 10.7621 7.83594 10.6339 7.83594 10.5002C7.83594 10.3665 7.88905 10.2383 7.98358 10.1437C8.07812 10.0492 8.20634 9.99609 8.34003 9.99609C8.47362 9.99609 8.60174 10.0491 8.69626 10.1435C8.69633 10.1436 8.6964 10.1437 8.69648 10.1437L11.9859 13.4432L12.3395 13.7978L12.6936 13.4437L15.9676 10.1697C16.0614 10.0945 16.1793 10.0554 16.2998 10.0601C16.4256 10.065 16.545 10.1171 16.634 10.2062C16.7231 10.2952 16.7753 10.4146 16.7801 10.5404C16.7848 10.6609 16.7457 10.7789 16.6705 10.8726L12.6879 14.8552C12.6877 14.8554 12.6875 14.8556 12.6873 14.8558C12.5943 14.9478 12.4688 14.9996 12.3379 15.0002Z"
        fill="#A098AE"
        stroke="#A098AE"
      />
    </svg>
  );
}

export default ChevronDownIcon;
