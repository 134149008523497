import { loadavg } from "os";
import Modal from "../../molecules/Modal";
import FilterForm from "../Filter Form";
import type { ModalComponentProps } from "./@types";
import styles from "./ModalComponent.module.scss";

const ModalComponent = ({
  show,
  children,
}: ModalComponentProps) => {
  return (
    <div className={styles["auth-form"]}>
      <Modal show={show}>
        {children}
        {/* <FilterForm
          type={filterType}
          onSubmit={onSubmit}
          loading={loading}
          onClickClearFilter={onClickClearFilter}
        /> */}
      </Modal>
    </div>
  );
};

export default ModalComponent;
