import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: boolean;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function TrashImgIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 9.96615C29.45 9.41615 23.8667 9.13281 18.3 9.13281C15 9.13281 11.7 9.29948 8.4 9.63281L5 9.96615"
        stroke="#101828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.168 8.28398L14.5346 6.10065C14.8013 4.51732 15.0013 3.33398 17.818 3.33398H22.1846C25.0013 3.33398 25.218 4.58398 25.468 6.11732L25.8346 8.28398"
        stroke="#101828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.4154 15.2324L30.332 32.0158C30.1487 34.6324 29.9987 36.6658 25.3487 36.6658H14.6487C9.9987 36.6658 9.8487 34.6324 9.66536 32.0158L8.58203 15.2324"
        stroke="#101828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.2148 27.5H22.7648"
        stroke="#101828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.832 20.834H24.1654"
        stroke="#101828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default TrashImgIcon;

// fill={white ? "#fffff" : "#0F1322"}
