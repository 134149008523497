import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
  sameSize?: boolean;
};
function CakeIcon({ color, size, onClick, sameSize }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={window.innerWidth < breakPoints.md - 1 && !sameSize ?  "15":  "24"}
      height={window.innerWidth < breakPoints.md - 1 && !sameSize ?  "15":  "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22H22"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.10986 22V13C3.10986 11.34 4.59986 10 6.43986 10H17.5499C19.3899 10 20.8799 11.34 20.8799 13V22"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.56006 10V7.17C5.56006 5.97 6.64006 5 7.98006 5H16.0301C17.3601 5 18.4401 5.97 18.4401 7.17V10"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.52979 13.9805L3.89979 13.9905C4.63979 14.0005 5.22979 14.6005 5.22979 15.3405V15.6705C5.22979 16.4105 5.82979 17.0205 6.57979 17.0205C7.31979 17.0205 7.92978 16.4205 7.92978 15.6705V15.3605C7.92978 14.6205 8.52979 14.0105 9.27979 14.0105C10.0198 14.0105 10.6298 14.6105 10.6298 15.3605V15.6705C10.6298 16.4105 11.2298 17.0205 11.9798 17.0205C12.7198 17.0205 13.3298 16.4205 13.3298 15.6705V15.3605C13.3298 14.6205 13.9298 14.0105 14.6798 14.0105C15.4198 14.0105 16.0298 14.6105 16.0298 15.3605V15.6705C16.0298 16.4105 16.6298 17.0205 17.3798 17.0205C18.1198 17.0205 18.7298 16.4205 18.7298 15.6705V15.3605C18.7298 14.6205 19.3298 14.0105 20.0798 14.0105H20.5298"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 5V3"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 5V3"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 5V2"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CakeIcon;

// fill={white ? "#fffff" : "#0F1322"}
