import { useNavigate } from "react-router-dom";
import type { WorkerCardLargeProps } from "./@types";
import styles from "./WorkerCardLarge.module.scss";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";
import LocationIcon from "assets/svgs/LocationIcon";
import CrownIcon from "assets/svgs/CrownIcon";

import AvailableDotIcon from "assets/svgs/AvailableDotIcon";
import RatingStartIcon from "assets/svgs/RatingStartIcon";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import { handleImagesPath } from "utils/hooks/helper";
import { useWidth } from "utils/responsiveHook";

export const WorkerCardLarge = ({
  data,
  onClickInvite,
  onHireWorker
}: WorkerCardLargeProps) => {
  const { localeButtons, localeLabels, localeTitles } = useLocale();

  const { width } = useWidth();

  return (
    <div className={styles["WorkerCardLarge"]}>
      <div className={styles["WorkerCardLarge-left"]}>
        <div className={styles["left-image"]}>
          <Img
            className={styles["companyLogo"]}
            src={handleImagesPath(data?.profileImage)}
          />
          <div className={styles["available-dot"]}>
            {data?.availabilityStatus && <AvailableDotIcon />}
          </div>
        </div>
        <div className={styles["left-details"]}>
          <div className={styles["left-details-top"]}>
            <div className={styles["left-details-top-main"]}>
              <Text
                className={styles["title"]}
              >{`${data?.firstName} ${data?.lastName}`}</Text>
              <div className={styles["left-details-top-rating"]}>
                <RatingStartIcon fill="#1C53F4" stroke="#1C53F4" />{" "}
                <Text className={styles["rating-text"]}>{`4/5.0`}</Text>
                {/* >{`${data?.userRating}/5.0`}</Text> */}
              </div>
            </div>
            <Text className={styles["companyName"]}>{data?.heading}</Text>
          </div>

          <div className={styles["left-details-bottom"]}>
            {width > breakPoints.md && (
              <div className={styles["left-details-iconText"]}>
                <LocationIcon size={16} />
                <Text className={styles["description"]}>
                  {localeLabels.LABEL_FROM}
                </Text>
                <Text className={styles["city"]}>{data?.address?.city},</Text>
                <Text className={styles["country"]}>
                  {data?.address?.country}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles["WorkerCardLarge-mid"]}>
        {data?.accountType === "freelancer" && data?.hourlyRate && (
          <Text
            className={styles["rate"]}
          >{`€${data?.hourlyRate} ${localeTitles.TITLE_PER_HOUR}`}</Text>
        )}

        <div className={styles["availableDiv"]}>
          {data?.availabilityStatus && (
            <div className={styles["available"]}>
              <Text className={styles["available-text"]}>
                {localeLabels.LABEL_AVAILABLE}
              </Text>
            </div>
          )}
          {data?.ranking && data?.ranking > 100 && (
            <div className={styles["topRated"]}>
              <CrownIcon />
              <Text className={styles["topRated-text"]}>
                {localeLabels.LABEL_TOP_RATED}
              </Text>
            </div>
          )}
        </div>

        {width < breakPoints.md && (
          <div className={styles["left-details-iconText"]}>
            <LocationIcon size={16} />
            <Text className={styles["description"]}>
              {localeLabels.LABEL_FROM}
            </Text>
            {/* <Text className={styles["city"]}>{data?.stree},</Text> */}
            <Text className={styles["description"]}>
              {data?.address?.address}
            </Text>
          </div>
        )}
        <div className={styles["WorkerCardLarge-right-below"]}></div>
      </div>

      <div className={styles["WorkerCardLarge-right"]}>
        <div className={styles["WorkerCardLarge-right-buttons"]}>
          <Button
            className={"secondary-active"}
            onClick={() => onClickInvite && onClickInvite()}
          >
            {localeButtons.BUTTON_INVITE_WORKER}
          </Button>
          <Button className={"primary-active"} 
            onClick={() => onHireWorker && onHireWorker()}

            >
              {localeButtons.BUTTON_HIRE_DIRECTLY}
            </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkerCardLarge;
