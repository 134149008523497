import { Link } from "react-router-dom";

import type { OrganizationDetailsCardProps } from "./@types";
import styles from "./OrganizationDetailsCard.module.scss";
import { useLocale } from "../../../locales";
import { Text } from "../../atoms";
import Image from "../../atoms/Image";
import { handleImagesPath } from "../../../utils/hooks/helper";
import dayjs from "dayjs";
import { dayjsDutuch } from "locales/MultiDatePicker";

const OrganizationDetailsCard = ({
  userDetails,
}: OrganizationDetailsCardProps) => {
  const { localeLabels,currentLocale } = useLocale();

  return (
    <div className={styles["OrganizationDetailsCard"]}>
      <div className={styles["OrganizationDetailsCard-top"]}>
        <Image
          imagePath={handleImagesPath(userDetails?.profileImage)}
          className={styles["userImg"]}
          alt=""
        />
      </div>
      <div className={styles["OrganizationDetailsCard-mid"]}>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_ORGANIZATION_NAME}:
          </Text>

          <Text className={styles["labelValue"]}>
            {`${userDetails?.organization?.companyName}`}
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_PHONE_NUMBER}:
          </Text>
          <Text className={styles["labelValue"]}>{userDetails?.phone}</Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_EMAIL}:
          </Text>
          <Text className={styles["labelValue"]}>{userDetails?.email}</Text>
        </div>
        {/* <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_INDUSTRY_CATEGORY}:
          </Text>
          <select className={styles["labelValue"]}>
            {userDetails?.branches?.map((category, index) => (
              <option key={index}>
                {getLables(category, currentLocale, "branches")}
              </option>
            ))}
          </select>
        </div> */}
        {/* <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_ADDRESS_DETAILS}:
          </Text>

          <Text className={styles["labelValue-address"]}>
            {userDetails?.address?.length > 0 && (
              <select
              className={styles['address-select']}
              >
                {userDetails?.address?.map((val, index) => (
                  <option key={index}>{val.address}</option>
                ))}
              </select>
            )}
          </Text>
        </div> */}
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_REGISTRATION_DATE}
          </Text>
          <Text className={styles["labelValue"]}>
            {dayjs(userDetails?.createdAt).locale(currentLocale==="en"?"en":dayjsDutuch).format("DD MMM YYYY")}
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_BANK_ACCOUNT_DETAILS}
          </Text>
          <Text className={styles["labelValue"]}>
            {userDetails?.organization?.bankDetails?.IBAN}
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_KVK_NUMBER}
          </Text>
          <Text className={styles["labelValue"]}>
            {userDetails?.organization?.kvkNumber}
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_COMPANY_EMPLOYEES}
          </Text>
          <Text className={styles["labelValue"]}>
            <Link to={`/organization/${userDetails?.organization?._id}/worker`}>
              {localeLabels.LABEL_SEE_ALL}
            </Link>
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_COMPANY_JOBS}
          </Text>
          <Text className={styles["labelValue"]}>
            <Link to={`/organization/${userDetails?.organization?._id}/jobs`}>
              {localeLabels.LABEL_SEE_ALL}
            </Link>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetailsCard;
