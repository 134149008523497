import React, { useEffect, useRef, useState } from "react";
import type { TableItemsListProps } from "./@types";
import styles from "./TableItemsList.module.scss";
import { Text } from "../../atoms";
import { localeButtons, localeLabels, useLocale } from "../../../locales";
import BellIcon from "../../../assets/Jsx/BellIcon";
import AlertDot from "../../../assets/Jsx/AlertDot";
import ChevronRightIcon from "../../../assets/Jsx/ChevronRightIcon";
import dayjs from "dayjs";

import TableButton from "../../atoms/TableStatusButtonAtom";
import { CancelBtnIcon, DeliverBtnIcon } from "../../../assets/Jsx";
import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";

const TableItemsList = ({
  itemData,
  tableType = "Application_status",
  navigateTo = () => {},
  onModalEdit,
  number,
  onClickDelete,
  onClickEdit,
  onToggleCategroryPrice,
  isAllowed,
}: TableItemsListProps) => {
  const targetRef = useRef<any>(null);
  // const {currentLocale} =  useLocale()
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<any>(null); // Ref for the popover content
  const handleImageClick = () => {
    console.log("handleImageClick");
    setShowPopover(!showPopover);
  };
  const navigate = useNavigate();
  const { currentLocale } = useLocale();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        targetRef.current &&
        popoverRef.current &&
        !targetRef.current.contains(event.target) &&
        !popoverRef.current.contains(event.target) // Check if the clicked target is not inside the popover
      ) {
        setShowPopover(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  console.log("itemData", itemData);
  switch (tableType) {
    case "Application_status":
      return (
        <tr className=" text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2">
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            {" "}
            <Text size={"xxs"} weight="700">
              {number + 1}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {`${itemData?.firstName} ${itemData?.lastName}`}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.accountType}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            {itemData?.worker?.verificationStatus === "verified" && (
              <Text size={"xxs"} weight="700" className={styles["verified"]}>
                {itemData?.worker?.verificationStatus === "verified" &&
                  localeLabels.LABEL_APPROVED}
              </Text>
            )}
            {itemData?.worker?.verificationStatus === "unverified" && (
              <Text size={"xxs"} weight="700" className={styles["rejected"]}>
                {itemData?.worker?.verificationStatus === "unverified" &&
                  localeLabels.LABEL_REJECTED}
              </Text>
            )}
            {itemData?.worker?.verificationStatus === "inProcess" && (
              <Text size={"xxs"} weight="700" className={styles["inProcess"]}>
                {itemData?.worker?.verificationStatus === "inProcess" &&
                  localeLabels.LABEL_IN_PROCESS}
              </Text>
            )}
          </td>
          {/* <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.approvedDate}
            </Text>
          </td> */}

          <td className={styles["tableItem-last"]}>
            <div
              className={styles["seeDetails"]}
              onClick={() => navigateTo(itemData?._id, itemData?.accountType)}
            >
              <Text className={styles["seeDetails-text"]}>
                {localeButtons.BUTTON_SEE_DETAILS}
              </Text>
            </div>
            {/* <BsPencil
              className="cursor-pointer"
              onClick={() => onModalEdit && onModalEdit(itemData)}
            /> */}
          </td>
        </tr>
      );

    case "Employee":
      return (
        <tr
          className={`${
            itemData?.status !== "active" ? styles["item-background"] : ""
          } text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2`}
        >
          <td className={styles["tableItem"]}>
            <Text
              size={"xxs"}
              weight="700"
              className={
                itemData?.verificationStatus !== "verified"
                  ? styles[itemData?.verificationStatus]
                  : ""
              }
            >
              {`${itemData?.worker?.employeeNumber || 1}`}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            {" "}
            <Text size={"xxs"} weight="700">
              {`${itemData?.firstName} ${itemData?.lastName}`}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.email}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {dayjs(itemData?.createdAt).format("DD/MM/YYYY")}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.phone}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.branches?.slice(0, 3)?.map((category, index) => (
                <React.Fragment key={index}>
                  {category?.label?.[currentLocale]}
                  {index < itemData.branches.length - 1 && ", "}
                </React.Fragment>
              ))}
              {itemData?.branches.length > 3 &&
                ` +${itemData?.branches.length - 3}`}
            </Text>
          </td>
          {/* <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {dayjs(itemData?.dob).format("YYYY-MM-DD")}
            </Text>
          </td> */}
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <div
              className={styles["notification-icon"]}
              onClick={() => navigate("/notifications")}
            >
              <BellIcon />
              {itemData?.haveNotification && (
                <div className={styles["notification-alert"]}>
                  <AlertDot />
                </div>
              )}
            </div>
          </td>

          <td className={styles["tableItem-last"]}>
            <div
              className={styles["seeMore"]}
              onClick={() => navigateTo(itemData?._id)}
            >
              {/* <Text className={styles["seeDetails-text"]}>
                {localeButtons.BUTTON_SEE_DETAILS}
              </Text> */}
              <ChevronRightIcon />
            </div>
            {/* <BsPencil
              className="cursor-pointer"
              onClick={() => onModalEdit && onModalEdit(itemData)}
            /> */}
          </td>
        </tr>
      );

    case "Freelancer":
      return (
        <tr
          className={`${
            itemData?.status !== "active" ? styles["item-background"] : ""
          } text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2`}
        >
          <td className={styles["tableItem"]}>
            <Text
              size={"xxs"}
              weight="700"
              className={
                itemData?.verificationStatus !== "verified"
                  ? styles[itemData?.verificationStatus]
                  : ""
              }
            >
              {`${itemData?.worker?.employeeNumber || 1}`}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            <Text size={"xxs"} weight="700">
              {`${itemData?.firstName} ${itemData?.lastName}`}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.email}
            </Text>
          </td>
          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {dayjs(itemData?.createdAt).format("DD/MM/YYYY")}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.phone}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.branches?.slice(0, 3)?.map((category, index) => (
                <React.Fragment key={index}>
                  {category?.label?.[currentLocale]}
                  {index < itemData.branches.length - 1 && ", "}
                </React.Fragment>
              ))}
              {itemData?.branches.length > 3 &&
                ` +${itemData?.branches.length - 3}`}
            </Text>
          </td>
          {/* <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {dayjs(itemData?.dob).format("YYYY-MM-DD")}
            </Text>
          </td> */}
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <div
              className={styles["notification-icon"]}
              onClick={() => navigate("/notifications")}
            >
              <BellIcon />
              {itemData?.haveNotification && (
                <div className={styles["notification-alert"]}>
                  <AlertDot />
                </div>
              )}
            </div>
          </td>

          <td className={styles["tableItem-last"]}>
            <div
              className={styles["seeMore"]}
              onClick={() => navigateTo(itemData?._id)}
            >
              {/* <Text className={styles["seeDetails-text"]}>
                {localeButtons.BUTTON_SEE_DETAILS}
              </Text> */}
              <ChevronRightIcon />
            </div>
            {/* <BsPencil
              className="cursor-pointer"
              onClick={() => onModalEdit && onModalEdit(itemData)}
            /> */}
          </td>
        </tr>
      );

    case "Organization":
      return (
        <tr
          className={`${
            itemData?.status !== "active" ? styles["item-background"] : ""
          } text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2`}
        >
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            {" "}
            <Text size={"xxs"} weight="700">
              {`${itemData?.organization?.[0]?.companyName}`}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.email}
            </Text>
          </td>
          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {dayjs(itemData?.createdAt).format("DD/MM/YYYY")}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.phone}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {Array.isArray(itemData?.address) && (
                <>
                  {itemData?.address?.slice(0, 3)?.map((branch, index) => (
                    <React.Fragment key={index}>
                      {branch?.city}
                      {index < itemData?.address.length - 1 && ", "}
                    </React.Fragment>
                  ))}
                  {itemData?.address.length > 3 &&
                    ` +${itemData?.address.length - 3}`}
                </>
              )}
            </Text>
          </td>
          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <Text size={"xxs"} weight="700">
              {itemData?.branches?.slice(0, 3)?.map((category, index) => (
                <React.Fragment key={index}>
                  {category?.label?.[currentLocale]}
                  {index < itemData.branches.length - 1 && ", "}
                </React.Fragment>
              ))}
              {itemData?.branches?.length > 3 &&
                ` +${itemData?.branches?.length - 3}`}
            </Text>
          </td>

          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <div
              className={styles["notification-icon"]}
              onClick={() => navigate("/notifications")}
            >
              <BellIcon />
              {itemData?.haveNotification && (
                <div className={styles["notification-alert"]}>
                  <AlertDot />
                </div>
              )}
            </div>
          </td>

          <td className={styles["tableItem-last"]}>
            <div
              className={styles["seeMore"]}
              onClick={() => navigateTo(itemData?._id)}
            >
              <ChevronRightIcon />
            </div>
            {/* <BsPencil
              className="cursor-pointer"
              onClick={() => onModalEdit && onModalEdit(itemData)}
            /> */}
          </td>
        </tr>
      );

    case "Branches":
      return (
        <tr className=" text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2">
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            {" "}
            <Text size={"xxs"} weight="700">
              {`${itemData?.label?.du} `}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.label?.en}
            </Text>
          </td>

          <td className={styles["tableItem-options"]}>
            <TableButton
              backgroundColor={"rgba(224,224, 224,0.8)"}
              onClick={() => {
                onClickEdit && onClickEdit(itemData);
              }}
              extraClass={"text-[#00A510]"}
              text={localeButtons.BUTTON_EDIT}
            >
              <DeliverBtnIcon />
            </TableButton>
            <TableButton
              backgroundColor={"rgba(224,224, 224,0.8)"}
              onClick={() => {
                onClickDelete && onClickDelete(itemData?._id);
              }}
              extraClass={"text-[#000000]"}
              text={localeButtons.BUTTON_DELETE}
            >
              <CancelBtnIcon />
            </TableButton>
          </td>

          <td className={styles["tableItem-last"]}>
            <div
              className={styles["seeMore"]}
              onClick={() =>
                navigate(`/branch/categories/${itemData?._id}`, {
                  state: { selectedBranch: itemData?._id },
                })
              }
            >
              <ChevronRightIcon />
            </div>
          </td>
        </tr>
      );

    case "Allowed Branches":
      return (
        <tr className=" text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2">
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            {" "}
            <Text size={"xxs"} weight="700">
              {`${itemData?.label?.du} `}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.label?.en}
            </Text>
          </td>

          <td
            className={styles["tableItem"]}
            // onClick={navigateTo}
          >
            <HandleCategoryToggle
              Price={isAllowed}
              id={itemData?._id}
              onClickEdit={onToggleCategroryPrice}
            />
          </td>

          <td className={styles["tableItem-last"]}>
            <div
              className={styles["seeMore"]}
              onClick={() =>
                navigate(`/branch/categories/${itemData?._id}`, {
                  state: { selectedBranch: itemData?._id },
                })
              }
            >
              <ChevronRightIcon />
            </div>
          </td>
        </tr>
      );

    case "Categories":
      return (
        <tr className=" text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2">
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            {" "}
            <Text size={"xxs"} weight="700">
              {`${itemData?.label?.du} `}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.label?.en}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <HandleCategoryToggle
              Price={itemData?.price}
              id={itemData?._id}
              onClickEdit={onToggleCategroryPrice}
            />
          </td>

          <td className={styles["tableItem-last"]}>
            <td className={styles["tableItem-options"]}>
              <TableButton
                backgroundColor={"rgba(224,224, 224,0.8)"}
                onClick={() => {
                  onClickEdit && onClickEdit(itemData);
                }}
                extraClass={"text-[#00A510]"}
                text={localeButtons.BUTTON_EDIT}
              >
                <DeliverBtnIcon />
              </TableButton>
              <TableButton
                backgroundColor={"rgba(224,224, 224,0.8)"}
                onClick={() => {
                  onClickDelete && onClickDelete(itemData?._id);
                }}
                extraClass={"text-[#000000]"}
                text={localeButtons.BUTTON_DELETE}
              >
                <CancelBtnIcon />
              </TableButton>
            </td>
            {/* <div
              className={styles["seeMore"]}
              onClick={() => navigateTo(itemData?._id)}
            >
              <ChevronRightIcon />
            </div> */}
          </td>
        </tr>
      );

    case "SkillSet":
      return (
        <tr className=" text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2">
          <td
            className={styles["tableItem"]}
            onClick={handleImageClick}
            ref={targetRef}
          >
            {" "}
            <Text size={"xxs"} weight="700">
              {`${itemData?.label?.du} `}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.label?.en}
            </Text>
          </td>

          <td className={styles["tableItem-last"]}>
            <td className={styles["tableItem-options"]}>
              <TableButton
                backgroundColor={"rgba(224,224, 224,0.8)"}
                onClick={() => {
                  onClickEdit && onClickEdit(itemData);
                }}
                extraClass={"text-[#00A510]"}
                text={localeButtons.BUTTON_EDIT}
              >
                <DeliverBtnIcon />
              </TableButton>
              <TableButton
                backgroundColor={"rgba(224,224, 224,0.8)"}
                onClick={() => {
                  onClickDelete && onClickDelete(itemData?._id);
                }}
                extraClass={"text-[#000000]"}
                text={localeButtons.BUTTON_DELETE}
              >
                <CancelBtnIcon />
              </TableButton>
            </td>
          </td>
        </tr>
      );

    case "reports":
      return (
        <tr className=" text-md px-0 lg:px-2 h-16 border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2">
          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {`${itemData?.reportType} `}
            </Text>
          </td>
          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {`${dayjs(itemData?.createdAt).format("YYYY-MM-DD")} `}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {`${dayjs(itemData?.expireAt)
                .add(30, "day")
                .format("YYYY-MM-DD")} `}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {`${itemData?.reportGroup} `}
            </Text>
          </td>

          <td className={styles["tableItem"]}>
            <Text size={"xxs"} weight="700">
              {itemData?.label?.en}
            </Text>
          </td>

          <td className={styles["tableItem-last"]}>
            <div className={styles["tableItem-options"]}>
              <TableButton
                backgroundColor={"rgba(224,224, 224,0.8)"}
                onClick={() => {
                  onClickEdit && onClickEdit(itemData);
                }}
                extraClass={"text-[#00A510]"}
                text={localeButtons.BUTTON_DOWNLOAD}
              >
                <DeliverBtnIcon />
              </TableButton>
            </div>
          </td>
        </tr>
      );

    default:
      return null;
  }
};

const HandleCategoryToggle = ({ Price, id, onClickEdit }: any) => {
  const [price, setPrice] = useState(Price || false);
  useEffect(() => {
    setPrice(Price || false);
  }, [Price]);
  return (
    <ToggleButton
      value={price || false}
      onToggle={(value) => {
        setPrice(!value);
        onClickEdit &&
          onClickEdit?.({
            price: !value,
            id: id,
          } as any);
      }}
    />
  );
};

export default TableItemsList;
