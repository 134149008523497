import {  useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastConsumer } from "../../../store/ToastProvider";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useLocale } from "../../../locales";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  APIAddSkill,
  APIDeleteSkill,
  APIEditSkill,
} from "../../../utils/globalAPI/applicationData";

export type AddFormField = {
  en: String;
  du: String;
};

export const useSkillsetServices = () => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillId, setSkillId] = useState<string>("");
  const {  dropdowns, appDataLoading, refetchAppAData,accessToken } =
    UsersContextConsumer();
  const toast = ToastConsumer();
  const { localeSuccess, localeErrors } = useLocale();

  const validationSchema: any = yup.object().shape({
    en: yup.string().required(localeErrors.ERROR_INVALID_SKILLSET).min(2),
    du: yup.string().required(localeErrors.ERROR_INVALID_SKILLSET).min(2),
  });

  const [dData, setDData] = useState(dropdowns.skills);

  useEffect(() => {
    refetchAppAData();
  }, []);

  useEffect(() => {
    setDData(dropdowns.skills);
  }, [dropdowns?.skills]);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm<AddFormField>({
    resolver: yupResolver(validationSchema) as any,
  });

  const handleOpenAddModal = () => {
    setAddModal(true);
  };

  const handleCloseAddModal = () => {
    setAddModal(false);
  };

  const handleOpenEditModal = (data: any) => {
    setEditModal(true);
    // setBranchData(data);
    setValue("en", data?.label.en);
    setValue("du", data?.label.du);
    setSkillId(data?._id);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    reset();
    setSkillId(null);
  };

  const handleOpenDeleteModal = (id: string) => {
    // console.log("handleDeleteModal");
    setSkillId(id);
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onSubmit: SubmitHandler<AddFormField> = async (data) => {
    const params = {
      en: data?.en,
      du: data?.du,
    };
    try {
      //
      setLoading(true);
      const response = await APIAddSkill(params,accessToken);
      toast.success(localeSuccess.SUCCESS_SKILL_ADDED, "top-right");
      refetchAppAData();
      reset();
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error,"top-right")
    } finally {
      setLoading(false);
      handleCloseAddModal();
    }
  };

  const onSubmitEdit: SubmitHandler<AddFormField> = async (data) => {
    const params = {
      en: data?.en,
      du: data?.du,
    };
    try {
      //
      setEditLoading(true);
      const response = await APIEditSkill(skillId, params,accessToken);
      toast.success(localeSuccess.SUCCESS_SKILL_UPDATED, "top-right");
      // refetchAppAData();

      setDData(
        dData.map((item) =>{
          if(item._id === skillId){
            return{
              ...item,
              label:{
                en: data?.en,
                du: data?.du,
              }
            }
          }
          return item;
        })
      )
      reset();
      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setEditLoading(false);
      handleCloseEditModal();
    }
  };

  const onConfirmDelete = async () => {
    try {
      //
      setDeleteLoading(true);
      const response = await APIDeleteSkill(skillId,accessToken);
      toast.success(localeSuccess.SUCCESS_SKILL_DELETED, "top-right");
      refetchAppAData();
      setSkillId("");
      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteModal();
    }
  };

  return {
    handleOpenAddModal,
    handleCloseAddModal,
    addModal,
    reset,
    loading,
    appDataLoading,
    refetchAppAData,
    dropdowns,
    onSubmit,
    register,
    control,
    handleSubmit,
    deleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    deleteLoading,
    onConfirmDelete,
    handleOpenEditModal,
    handleCloseEditModal,
    editModal,
    editLoading,
    onSubmitEdit,
    errors,
    skills:dData
  };
};
