import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: boolean;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function CheckCircleIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.7016 23.0484C40.8759 23.2226 41.0142 23.4294 41.1086 23.657C41.2029 23.8846 41.2515 24.1286 41.2515 24.375C41.2515 24.6214 41.2029 24.8654 41.1086 25.093C41.0142 25.3206 40.8759 25.5274 40.7016 25.7016L27.5766 38.8266C27.4024 39.0009 27.1956 39.1392 26.968 39.2335C26.7404 39.3279 26.4964 39.3765 26.25 39.3765C26.0036 39.3765 25.7596 39.3279 25.532 39.2335C25.3044 39.1392 25.0976 39.0009 24.9234 38.8266L19.2984 33.2016C18.9466 32.8497 18.749 32.3726 18.749 31.875C18.749 31.3774 18.9466 30.9003 19.2984 30.5484C19.6503 30.1966 20.1275 29.999 20.625 29.999C21.1226 29.999 21.5997 30.1966 21.9516 30.5484L26.25 34.8492L38.0484 23.0484C38.2226 22.8741 38.4294 22.7358 38.657 22.6414C38.8846 22.5471 39.1286 22.4985 39.375 22.4985C39.6214 22.4985 39.8654 22.5471 40.093 22.6414C40.3206 22.7358 40.5274 22.8741 40.7016 23.0484ZM54.375 30C54.375 34.8209 52.9454 39.5336 50.2671 43.542C47.5887 47.5505 43.7819 50.6747 39.3279 52.5196C34.874 54.3644 29.973 54.8472 25.2447 53.9066C20.5164 52.9661 16.1732 50.6446 12.7643 47.2357C9.35538 43.8268 7.03388 39.4836 6.09337 34.7553C5.15286 30.027 5.63556 25.126 7.48045 20.6721C9.32533 16.2181 12.4495 12.4113 16.458 9.73293C20.4664 7.05457 25.1791 5.625 30 5.625C36.4626 5.63182 42.6585 8.20209 47.2282 12.7718C51.7979 17.3415 54.3682 23.5374 54.375 30ZM50.625 30C50.625 25.9208 49.4154 21.9331 47.1491 18.5414C44.8828 15.1496 41.6616 12.506 37.8929 10.945C34.1241 9.38393 29.9771 8.97548 25.9763 9.7713C21.9754 10.5671 18.3004 12.5315 15.4159 15.4159C12.5315 18.3004 10.5671 21.9754 9.77131 25.9763C8.97549 29.9771 9.38394 34.1241 10.945 37.8928C12.5061 41.6616 15.1496 44.8828 18.5414 47.1491C21.9331 49.4154 25.9208 50.625 30 50.625C35.4682 50.6188 40.7106 48.4438 44.5772 44.5772C48.4438 40.7106 50.6188 35.4682 50.625 30Z"
        fill="black"
      />
    </svg>
  );
}

export default CheckCircleIcon;

// fill={white ? "#fffff" : "#0F1322"}
