export default {
  BUTTON_GET_STARTED_FOR_EMPLOYEE: "Get Started for employee",
  BUTTON_GET_STARTED_FOR_FREELANCER: "Get Started for freelancer",
  BUTTON_GET_STARTED_FOR_ORGANIZATION: "Get Started for organization",
  BUTTON_LOGIN: "Log In",
  BUTTON_SIGNUP: "Sign Up",
  BUTTON_LOGIN_WITH_GOOGLE: "Log In with Google",
  BUTTON_LOGIN_WITH_FACEBOOK: "  Log In with Facebook",
  BUTTON_LOGIN_WITH_APPLE: "Log In with Apple",
  BUTTON_GO_BACK: "Go Back",
  BUTTON_CONTINUE: "Continue",
  BUTTON_CREATE: "Create Account",
  BUTTON_UPLOADING: "Uploading ...",
  BUTTON_CREATING: "Creating ...",
  BUTTON_CHECK_EMAIL: "Check Email",
  BUTTON_CLEAR_ALL: "Clear all",
  BUTTON_SAVE: "Save",
  BUTTON_EDIT_TASK: "Edit Schedule",
  BUTTON_DELETE_TASK: "Delete Schedule",
  BUTTON_SAVED: "Saved",
  BUTTON_APPLY: "Apply",
  BUTTON_SUBMIT: "Submit",
  BUTTON_CANCEL: "Cancel",
  BUTTON_MUTE: "Mute",
  BUTTON_CANCELED: "Cancelled",
  BUTTON_REPORT: "Report",
  BUTTON_DELETED_JOBS: "Deleted",

  BUTTON_RATE_LATTER: "Rate Latter",
  BUTTON_CLOSE: "Close",
  BUTTON_NO_CANCEL: "No, Cancel",
  BUTTON_FAVOURITE_JOBS: "Favourite Jobs",
  BUTTON_RECEIVED_INVITATIONS: "Received Invitations",
  BUTTON_SUBMITTED: "Submitted",
  BUTTON_CANCELED_JOBS: "cancelled",
  BUTTON_COMPLETED_JOBS: "Completed Jobs",
  BUTTON_WORK_SHIFT: "Work Shift",
  BUTTON_RATE_OF_JOBS: "Rate of Jobs",
  BUTTON_INVOICES: "Invoices",
  BUTTON_SAVE_CHANGES: "Save Changes",
  BUTTON_YES_HIRE: "Yes, Hire",
  BUTTON_YES_DELETE: "Yes, Delete",
  BUTTON_YES_COMPLETE: "Yes, Complete",
  BUTTON_YES_CANCEL: "Yes, Cancel",
  BUTTON_YES_Pause: "Yes, Pause",
  BUTTON_YES_Resume: "Yes, Resume",
  BUTTON_SHFIT_PAGE: "Shift Page",
  BUTTON_RATE: "Rate",
  BUTTON_YES_LOGOUT: "Yes, Log out",
  BUTTON_YES: "Yes",
  BUTTON_SCHEDULE: "Schedule",
  BUTTON_ADD_AVAILABILITY: "Add Availability",
  BUTTON_ADD_NEW_LOCATION: "Add New Location",
  BUTTON_SELECT_EXISTING_LOCATION: "Select Existing Location",

  BUTTON_ADD_SCHEDULE: "Add Schedule",
  BUTTON_AVAILABILITY: "Availability",
  BUTTON_TASKS: "Tasks",
  BUTTON_FREELANCERS: "Freelancers",
  BUTTON_EMPLOYEE: "Employee",
  BUTTON_ONGOING_JOBS: "Ongoing",
  BUTTON_UNDER_REVIEW: "Under Review",
  BUTTON_FILLED_BY_ADMIN: "Filled by Admin",
  BUTTON_FILLED_BY_USERS: "Filled by Users",

  BUTTON_COMPLETED: "Completed",
  BUTTON_PAUSED: "Paused",
  BUTTON_DECLINE: "Decline",
  BUTTON_DECLINED: "Declined",
  BUTTON_COMPLETE: "Complete",

  BUTTON_PART_TIME: "Part Time",
  BUTTON_FULL_TIME: "Full Time",
  BUTTON_POST_NEW_JOB: "Post New Job",
  BUTTON_POST_FIND_NEW_JOB: "Find New Jobs",
  BUTTON_POST_NOW: "Post Now",
  BUTTON_EDIT_NOW: "Edit Now",
  BUTTON_ADD_NEW: "Add New",
  BUTTON_ACCEPT: "Accept",
  BUTTON_GENRATE_INVOICE: "Genrate Invoice",
  BUTTON_FOR_MORE_INFO: "For more information",
  BUTTON_DAY: "Day",
  BUTTON_WEEK: "Week",
  BUTTON_MONTH: "Month",

  BUTTON_ACCEPTED: "Accepted",
  BUTTON_CONFIRM: "Confirm",
  BUTTON_BLOCK: "Block",
  BUTTON_REJECT: "Reject",
  BUTTON_REJECTED: "Rejected",
  BUTTON_JOB_DETAILS: "Job detail",

  BUTTON_SHOW_ALL: "Show All",
  BUTTON_DELETE: "Delete",
  // Job Fullfilled by Users, button which will be shown on the job detail page and have purpose that admin can not fullfill the job mean admin do not have any worker which can fullfill the job
  BUTTONS_JOB_FULLFILLED_BY_USERS: "Job Fullfilled by Users",
  BUTTONS_JOB_IN_REVIEW: "In Review",
  BUTTONS_JOB_FULLFILLED_BY_ADMIN: "Job Fullfilled by Admin",
  BUTTONS_BY_ADMIN: "By Admin",
  BUTTONS_BY_USERS: "By Users",
  BUTTONS_ALL: "All",
  BUTTONS_ACTIVE: "verified",
  BUTTONS_INACTIVE: "unverified",
  BUTTON_EDIT_DETAILS: "Edit Details",
  BUTTON_INVITE_WORKER: "Invite Worker",
  BUTTON_HIRE_DIRECTLY: "Hire",
  BUTTON_WORKER_DETAIL: "Details",
  BUTTON_ADD_JOB: "Add Job",
  BUTTON_ADD_EDUCATION: "Add Education",
  BUTTON_INVITE: "Invite",
  BUTTON_INVITED: "Invited",
  BUTTON_HIRED: "Hired",
  BUTTON_CHANGE_RATE: "Change Rate",
  BUTTON_REPEAT_CONTRACT: "Repeat Contract",

  BUTTON_FAVOURITE: "Favourite",
  BUTTON_APPLIED: "Applied",
  BUTTON_ALREADY_APPLIED: "Already Applied",
  BUTTON_DAY_SHIFT: "Day",
  BUTTON_NIGHT_SHIFT: "Night",
  BUTTON_NO: "No",
  BUTTON_DELETE_SHIFT: "Delete Shift",
  BUTTON_ADD_MORE_SHIFT: "Add More Shift",
  BUTTON_ADD_MORE_TASK: "Add More Task",

  BUTTON_LOGOUT: "Logout",
  BUTTON_SEND_EMAIL: "Send Email",
  BUTTON_TO_LOGIN: "Back to login page",
  BUTTON_CHANGE_PW: "Change Password",
  BUTTON_PREV_MONTH: "Prev Month",
  BUTTON_NEXT_MONTH: "Next Month",
  BUTTON_SHIPPED: "Shipped",
  BUTTON_DELIVERED: "Delivered",
  BUTTON_ORDER_ISSUED: "Issue Occured",
  BUTTON_DETAIL: "Detaile",
  BUTTON_ORDER: "Create an Order",
  BUTTON_NEXT: "Next",
  BUTTON_CONFIRM_ORDER: "Confirm",
  BUTTON_CONFIRM_DEACTIVATE: "Deactivate",
  BUTTON_CONFIRM_ACTIVATE: "Activate",
  BUTTON_APPLY_FILTER: "Apply Filter",
  BUTTON_CLEAR_FILTER: "Clear Filter",
  BUTTON_IN_TRANSIT: "In transit",
  BUTTON_DELIVERY_COMPLETE: "Delivery completed",
  BUTTON_PREVIOUS: "Previous",
  BUTTON_REGISTER: "Register",
  BUTTON_LOADING: "Loading",
  BUTTON_SEARCH_ZIPCODE: "Search Address",
  BUTTON_BACK: "Back",
  BUTTON_ORDER_CANCEL: "Cancel",
  BUTTON_ACTIVATE_ACCOUNT: "Activate this account",
  BUTTON_ALLOWED_BRANCHES: "Allowed Branches",
  BUTTON_POST_JOB: "Post Job",

  BUTTON_DEACTIVATE_ACCOUNT: "Deactivate this account",
  BUTTON_SEE_DETAILS: "See details",
  BUTTON_SEE_ALL_USER: "See All User >> ",
  BUTTON_SEE_ALL_AVAILABILITY: "See All Availability",
  // BUTTON_SEE_ALL_USER: "See All User >> ",
  BUTTON_ADD_BRANCHES: "Add Branches",
  BUTTON_ADD_BRANCH: "Add Branch",
  BUTTON_ADD_CATEGORIES: "Add Categories",
  BUTTON_ADD_SKILL_NAME: "Add Skill Name",
  BUTTON_GENRATE_REPORT: "Genrate report",
  BUTTON_EDIT: "Edit",
  BUTTON_DOWNLOAD: "Download",

  BUTTON_ALLOW_EMPLOYEE_TO_WORK: "Allow the Employee to work",
  BUTTON_DECLINE_EMPLOYEE_CERTIFICATE: "Decline the Employee certificate",

  BUTTON_UNMUTE: "Unmute",

  BUTTON_UNBLOCK: "Unblock",

  BUTTON_FULL_SCREEN: "Full Screen",

  // new One

  BUTTON_CHANGE_PASSWORD: "Change Password",

  BUTTON_UPDATE_USER:"Update User",
  BUTTON_ORGANIZATION_SCHEDULE:"Organization Schedule",
  
};
