import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastConsumer } from "../../../store/ToastProvider";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useQuery } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { S3_BUCKET, myBucket } from "../../../store/utils/Aws";
import { Progress } from "aws-sdk/lib/request";
import { useLocale } from "../../../locales";

export const useListPage = (type: string) => {
  const toast = ToastConsumer();
  const navigate = useNavigate();
  const { adminData } = UsersContextConsumer();
  // const [showFilter, setShowFilter] = useState(false);

  const {
    setValue,
    watch,
    getValues,
    register,
    handleSubmit: handleSubmitForm,
    formState: { errors },
  } = useForm<{
    country: any;
    city: any;
  }>({
    defaultValues: {
      country: "",
      city: "",
    },
  });

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [currentData, setCurrentData] = useState<any>(null);
  const [addGoodplanLoading, setAddGoodplanLoading] = useState(false);

  let country = watch("country");
  let city = watch("city");

  const { localeErrors, localeSuccess } = useLocale();
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const handleCountryChange = (value: any) => {
    // console.log("handleCountryChange", value);
    setValue("country", value);
  };

  const handleCityChange = (value: any) => {
    setValue("city", value);
  };

  useEffect(() => {
    setValue("city", currentData?.cityName);
    setValue("country", currentData?.countryName);

    setSelectedImage([currentData?.image]);
  }, [currentData]);

  const handleImageChange = (acceptedFiles: File[]) => {
    const uploadedFilesData = [] as any; // Array to store the data for all uploaded files

    console.log("acceptedFiles", acceptedFiles);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const filePath = reader.result as string;
        const fileName = file.name;
        const progress = 0;
        const status = "";
        uploadedFilesData.push({ filePath, fileName, progress, status, file });
        setSelectedImage(uploadedFilesData);
      };
    });
  };

  // const {
  //   data: goodplans,
  //   isLoading: goodplansFetchLoading,
  //   refetch: refetchGoodplans,
  //   error: errorGoodplans,
  // } = useQuery(
  //   "goodplan",

  //   async () => {
  //     if (adminData) {
  //       return ApiGetAllGoodplan(adminData?.accessToken)
  //         .then((res) => res?.cities)
  //         .catch((error) => {
  //           toast.error(error.message, "top-right");
  //           throw error;
  //         });
  //     }
  //   },
  //   {
  //     enabled: !!adminData,
  //   }
  // );

  const handleUpload = (paramsData: any) => {
    // console.log("paramsData", paramsData);
    const file = selectedImage[0].file;
    const fileName = selectedImage[0].fileName;
    let fileData;
    const date = Date.now();

    myBucket
      .upload({
        Bucket: "safrspace",
        ACL: "public-read",
        Key: adminData?._id + "$$$" + date + "$$$" + fileName,
        Body: file,
      })
      .on("httpUploadProgress", (evt: Progress) => {
        //  ;
        const progressVal = Math.round((evt.loaded / evt.total) * 100);
        selectedImage[0].progress = progressVal;
        selectedImage[0].status = "Uploaded";
      })
      .send(async (err: string, data: any) => {
        if (err) {
          console.log("uploadError", err);
        } else if (data) {
          fileData = {
            filePath: data.Location,
            fileKey: data.Key,
          };

          const updatedParamsData = {
            ...paramsData,
            image: fileData,
          };
          callUploadApi(updatedParamsData);
        }
      });
  };
  const handleUploadEdit = (paramsData: any) => {
    // console.log("paramsData", paramsData);
    const file = selectedImage[0].file;
    const fileName = selectedImage[0].fileName;
    let fileData;
    const date = Date.now();

    myBucket
      .upload({
        Bucket: "safrspace",
        ACL: "public-read",
        Key: "123Abc" + "$$$" + date + "$$$" + fileName,
        // Key: userData?.userObject?._id + "$$$" + date + "$$$" + fileName,
        Body: file,
      })
      .on("httpUploadProgress", (evt: Progress) => {
        //  ;
        const progressVal = Math.round((evt.loaded / evt.total) * 100);
        selectedImage[0].progress = progressVal;
        selectedImage[0].status = "Uploaded";
      })
      .send(async (err: string, data: any) => {
        if (err) {
          console.log("uploadError", err);
        } else if (data) {
          fileData = {
            filePath: data.Location,
            fileKey: data.Key,
          };

          const updatedParamsData = {
            ...paramsData,
            image: fileData,
          };
          callEditApi(updatedParamsData);
        }
      });
  };

  const callUploadApi = async (params: any) => {
    // console.log("paramsData", params);
    // try {
    //   setAddGoodplanLoading(true);
    //   const result = await ApiAddGoodplan(params, adminData?.accessToken);
    //   toast.success(localeSuccess.SUCCESS_GOODPLAN_ADD, "top-right");
    //   refetchGoodplans();
    //   console.log("addGoodplan", result);
    //   // refetchProfle();
    //   return Promise.resolve(result);
    // } catch (err: any) {
    //   console.log("goodplanError", err);
    //   toast.error(err?.response?.data?.errorMessage, "top-right");
    //   toast.error(localeErrors.ERROR_UNKNOWN, "top-right");
    //   return Promise.reject(err);
    // } finally {
    //   setAddGoodplanLoading(false);
    //   onModal();
    // }
  };

  const callEditApi = async (params: any) => {
    // console.log("paramsData", params);
    // try {
    //   setEditGoodplanLoading(true);
    //   const result = await ApiUpdateGoodplan(
    //     params,
    //     currentData?._id,
    //     adminData?.accessToken
    //   );
    //   toast.success(localeSuccess.SUCCESS_GOODPLAN_EDIT, "top-right");
    //   refetchGoodplans();
    //   console.log("editGoodplan", result);
    //   // refetchProfle();
    //   return Promise.resolve(result);
    // } catch (err: any) {
    //   console.log("goodplanError", err);
    //   toast.error(err?.response?.data?.errorMessage, "top-right");
    //   toast.error(localeErrors.ERROR_UNKNOWN, "top-right");
    //   return Promise.reject(err);
    // } finally {
    //   setEditGoodplanLoading(false);
    //   onModalEdit();
    // }
  };

  const onModal = () => {
    setShow(!show);
  };
  const onModalEdit = (data?: any) => {
    setCurrentData(data);
    setShowEdit(!showEdit);
  };

  // console.log("adminData", adminData);

  const navigateToUserDetails = (userId: string) => {
    navigate(`/${type.toLocaleLowerCase()}s/` + userId);
  };

  // const togleShowModalFilter = () => {
  //   setShowFilter(!showFilter);
  // };

  const onSubmit: SubmitHandler<any> = async (data) => {
    // console.log("submit data", data);
  };
  const onAddGoodplan: SubmitHandler<any> = async (data) => {
    // console.log("onsubmit goodplan", data);

    const params = {
      countryName: getValues("country").name,
      cityName: getValues("city").name,
    };

    handleUpload(params);
  };
  const onEditGoodplan: SubmitHandler<any> = async (data) => {
    // console.log("onsubmit goodplan", data);

    const params = {
      countryName: getValues("country").name,
      cityName: getValues("city").name,
      image: selectedImage[0],
    };

    if (selectedImage[0]?.progress === 0) {
      handleUploadEdit(params);
    } else {
      callEditApi(params);
    }
  };

  return {
    show,
    onModal,
    showEdit,
    onModalEdit,
    onEditGoodplan,
    navigateToUserDetails,
    // togleShowModalFilter,
    // showFilter,
    onSubmit,

    addGoodplanLoading,
    onAddGoodplan,
    handleImageChange,
    selectedImage,
    register,
    handleSubmitForm,
    errors,
    country,
    city,
    handleCityChange,
    handleCountryChange,
  };
};
