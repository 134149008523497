import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import type { VOGSectionProps } from "./@types";
import styles from "./VOGSection.module.scss";
import { useLocale } from "../../../locales";
import { Button, Text } from "../../atoms";
import Image from "../../atoms/Image";
import dumyImg from "../../../assets/images/default.png";
import Input from "../../atoms/Input";
import { handleImagesPath } from "../../../utils/hooks/helper";
import { ControlInput } from "../../atoms/ControlledInput/Input";

const VOGSection = ({
  userDetails,
  handleSubmit,
  onSubmit,
  control,
  vogLoading,
  onClickDeclined,
  onClickVerified,
  declineLoading,
  verifyLoading,
  onPreviewFile=(filelink:string)=>{},
  ...props
}: VOGSectionProps) => {
  const {
    localeTitles,
    localeLinks,
    localeLabels,
    localePlaceholders,
    localeButtons,
  } = useLocale();
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div className={styles["VOGSection"]}>
      <Text className={styles["labelText"]}>
        {localeLabels.LABEL_VOG_REGISTRATION_MSG}
      </Text>

      <div className={styles["VOGSection-main"]}>
        <div className={styles["detail-field"]}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles["detail-field"]}
          >
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_SEND_EMAIL_TO_EMPLOYEE}:
            </Text>
            <ControlInput
              control={control}
              name="link"
              placeholder={
                localePlaceholders.PLACEHOLDER_SEND_GOVERNMENT_ISSUED_LINK
              }
              className={styles["inputField"]}
              wrapClassName={styles["inputField-wrap"]}
              type="text"
              // prefix={<EmailLoginIcon  />}
            />
            {/* <ControlInput
              control={control}
              autoFocus={false}
              width={"500px"}
              placeholder={
                localePlaceholders.PLACEHOLDER_SEND_GOVERNMENT_ISSUED_LINK
              }
            /> */}
            <Button
              loading={vogLoading}
              type="submit"
              className={styles["primaryBtn"]}
              onClick={handleSubmit(onSubmit)}
            >
              {localeButtons.BUTTON_SEND_EMAIL}
            </Button>
          </form>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_VOG_OF_EMPLOYEE}:
          </Text>
          {userDetails?.worker?.documents?.certificate?.length > 0 ? (
            <div className={styles["flex"]}>
              {userDetails?.worker?.documents?.certificate?.map(
                (pic, index) => (
                  <Image
                    onClick={() => onPreviewFile(pic)}
                    key={index}
                    className={styles["documentImg"]}
                    imagePath={handleImagesPath(pic)}
                  />
                )
              )}

              {userDetails?.worker?.verificationStatus === "verified" ? (
                <div className="flex space-x-2">
                  <Text className={styles["labelText"]}>
                    {localeLabels.LABEL_NOTE}:
                  </Text>
                  <Text className={styles["labelValue_response"]}>
                    {localeLabels.LABEL_VOG_IS_VALID_AND_APPROVED}
                  </Text>
                </div>
              ) : (
                <div className="flex space-x-2">
                  <Text className={styles["labelText"]}>
                    {localeLabels.LABEL_NOTE}:
                  </Text>
                  <Text className={styles["labelValue_response"]}>
                    {localeLabels.LABEL_VOG_IS_INVALID_AND_REJECTED}
                  </Text>
                </div>
              )}
            </div>
          ) : (
            <Text className={styles["labelValue"]}>
              {localeLabels.LABEL_CURRENTLY_NO_VOG_AVAILABLE}
            </Text>
          )}
        </div>

        <div className="flex justify-evenly">
          <Button
            disabled={userDetails?.worker?.verificationStatus === "verified"}
            loading={verifyLoading}
            type="button"
            className={
              styles[
                userDetails?.worker?.verificationStatus === "verified"
                  ? "disableBtn"
                  : "allowEmployeeBtn"
              ]
            }
            onClick={onClickVerified}
          >
            {localeButtons.BUTTON_ALLOW_EMPLOYEE_TO_WORK}
          </Button>
          <Button
            disabled={userDetails?.worker?.verificationStatus !== "verified"}
            loading={declineLoading}
            type="button"
            className={
              styles[
                userDetails?.worker?.verificationStatus !== "verified"
                  ? "disableBtn"
                  : "declineEmployeeBtn"
              ]
            }
            onClick={onClickDeclined}
          >
            {localeButtons.BUTTON_DECLINE_EMPLOYEE_CERTIFICATE}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VOGSection;
