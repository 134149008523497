import { useLocation, useNavigate } from "react-router-dom";
import type { JobsCompletedSectionProps } from "./@types";
import styles from "./JobsCompletedSection.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import JobsCompletedCard from "../JobsCompletedCard";
import { useState } from "react";

const JobsCompletedSection = ({ data, getData }: JobsCompletedSectionProps) => {
  const navigation = (link?: string) => {};
  const { localeBasics, localeTitles } = useLocale();
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  const itemsToShow = showAll ? data?.length : 2;

  return (
    <div className={styles["JobsCompletedSection"]}>
      <Text className={styles["title"]}>
        {localeTitles.TITLE_JOBS_COMPLETED}
      </Text>

      <div className={styles["JobsCompletedSection-main"]}>
        {data?.slice(0, itemsToShow).map((job, index) => (
          <JobsCompletedCard key={index} data={job} getData={getData} />
        ))}

        {!showAll && data?.length > 2 && (
          <Text className={styles["see-more"]} onClick={toggleShowAll}>
            {localeBasics.TEXT_SEE_MORE}
          </Text>
        )}
      </div>
    </div>
  );
};

export default JobsCompletedSection;
