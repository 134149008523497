import { useDashboardServices } from "./hook";
import DashboardPage from "../../pages/dashboard/DashboardPage";

export interface services {}

const DashboardServices = ({}: services) => {
  const { dashboardData, monthlyJoinedData, sortedNotifications } =
    useDashboardServices();
  return (
    <DashboardPage
      dashboardData={dashboardData}
      monthlyJoinedData={monthlyJoinedData}
      notifications={sortedNotifications}
    />
  );
};

export default DashboardServices;
