import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: string;
  onClick?: any;
};
function VisibileIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={
        window.innerWidth > breakPoints.sm - 1
          ? size
            ? size
            : "24"
          : size
          ? size
          : "15"
      }
      height={
        window.innerWidth > breakPoints.sm - 1
          ? size
            ? size
            : "24"
          : size
          ? size
          : "15"
      }
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
        fill={color ? color : "#0F1322"}
      />
      <path
        d="M11.9999 9.13965C10.4299 9.13965 9.1499 10.4196 9.1499 11.9996C9.1499 13.5696 10.4299 14.8496 11.9999 14.8496C13.5699 14.8496 14.8599 13.5696 14.8599 11.9996C14.8599 10.4296 13.5699 9.13965 11.9999 9.13965Z"
        fill={color ? color : "#0F1322"}
      />
    </svg>
  );
}

export default VisibileIcon;

// fill={white ? "#fffff" : "#0F1322"}
