export default {
   LINK_FORGOT_PW: "Wachtwoord vergeten",
   LINK_LOGIN: "Terug naar inlogpagina",
   LINK_DASHBOARD: "Dashboard",
   LINK_ORDERS: "Lijst met bestellingen",
   LINK_EDIT_ACCOUNT: "Account bewerken",
   LINK_LIST_OF_USERS: "Lijst met gebruikers",
   LINK_USER_DETAILS: "Gebruikersgegevens",
   LINK_LIST_OF_BIKES: "Lijst met fietsen",
   LINK_CHARTS_SCREEN: "Grafiekscherm",
   LINK_GOOD_PLAN: "Goed plan",
   LINK_APARTMENT_AND_VILLA: "Appartement en villa",
   LINK_HOSTEL_AND_CAMPING: "Hostel en camping",
   LINK_EXPERIENCE: "Ervaring",
   LINK_LIST_OF_GOODPLAN: "Lijst met goede plannen",
   LINK_APARTMENTS_AND_VILLA_ATTR: "Lijst met kenmerken van appartementen en villa's",
   LINK_HOSTEL_AND_CAMPING_ATTR: "Lijst met hostel- en campingkenmerken",
   LINK_EXPERIENCE_ATTR: "Lijst met ervaringsattributen",

  //
  LINK_EMPLOYEES_VIEW: "Werknemers",
  LINK_FREELANCERS_VIEW: "Freelancers",
  LINK_ORGANIZATIONS_VIEW: "Organisaties",
  LINK_APPLICATION_STATUS: "Applicatie status",
  LINK_EMPLOYEES_LIST: "medewerkers lijst totaal",
  LINK_FREELANCER_LIST: "Freelancer lijst totaal",
  LINK_ORGANIZATION_LIST: "Organisatie lijst totaal",
  LINK_ORGANIZATION_BRANCHES: "Branches",
  LINK_ORGANIZATION_CATEGORY: "Categorieen",
  LINK_EMPLOYEE_SKILLSET: "Vaardigheden ",
  LINK_REPORTS: "Rapporten",
  LINK_SEE_ALL_JOBS: "Bekijk alle diensten",

  LINK_EMPLOYEE: "Medewerker",
  LINK_FREELANCER: "Freelancer",
  LINK_ORGANIZATION: "Organisatie",
  LINK_CONTACT_INFO: "Contact info",
  LINK_DAYCARE: "Kinderdagverblijf",
  LINK_LOGISTICS: "Logistiek",
  LINK_ADMINISTRATION: "Administratie",
  LINK_CALLCENTER: "Callcenter",
  LINK_EDUCATION: "Onderwijs",
  LINK_HOW_IT_WORKS: "Hoe het werkt",
  LINK_FIND_SHIFTS: "Vind diensten",
  LINK_FAQ: "FAQ",
  LINK_CONTACT_US: "Neem contact met ons op",
  LINK_TERMS_AND_CONDITIONS: "Algemene voorwaarden",
  LINK_PRIVACY_AND_POLICY: "Privacybeleid",
  LINK_COOKIES_STATEMENT: "Cookiesbeleid",

  LINK_CREATE_ACCOUNT: " Account aanmaken",
  LINK_FORGOT_PASSWORD: "Wachtwoord vergeten?",
  LINK_RESEND: "Opnieuw versturen",
  LINK_FIND_JOBS: "Vind diensten",
  LINK_INBOX: "Inbox",
  LINK_MY_JOBS: "Mijn diensten",
  LINK_FINANCE: "Financiën",
  LINK_MY_STATS: "Mijn statistieken",
  LINK_PERSONAL_SCHEDULE: "Mijn rooster",
  LINK_FIND_WORKERS: "Vind werknemers",
  LINK_POST_A_JOB: "Plaats een dienst",
  LINK_HIRED_WORKERS: "Ingehuurde werknemers",
  LINK_SCHEDULE: "Rooster",
  LINK_NOTIFICATIONS: "Meldingen",
  LINK_ACCOUNT: "Profiel",
  LINK_SETTINGS: "Instellingen",
  LINK_FAQS: "Veelgestelde vragen",
  LINK_DELETE_ACCOUNT: "Account verwijderen",
  LINK_LOG_OUT: "Uitloggen",
  LINK_BLOGS: "Blogs",
  LINK_ABOUT_US: "Over ons",
  LINK_HEALTHCARE: "Gezondheidszorg",
  LINK_OTHER: "Overig",
  LINK_SALES: "Verkoop",

  // new One 
  LINK_CHANGE_PASSWORD:"Verander wachtwoord"
}