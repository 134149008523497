import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastConsumer } from "../../../store/ToastProvider";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useLocale } from "../../../locales";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  APIAddCategory,
  APIDeleteCategory,
  APIEditCategory,
  ApiTogglePriceCategory,
} from "../../../utils/globalAPI/applicationData";
import { useLocation } from "react-router-dom";

export type CategoryFormField = {
  en: String;
  du: String;
  price: boolean;
  branchId: String;
};

export const useCategoriesServices = () => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [categoryId, setCategoryId] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const {
    dropdowns,
    categories,
    appDataLoading,
    refetchAppAData,
    accessToken,
  } = UsersContextConsumer();
  const toast = ToastConsumer();
  const { localeSuccess, localeErrors } = useLocale();
  const location = useLocation();

  const validationSchema: any = yup.object().shape({
    en: yup.string().required(localeErrors.ERROR_INVALID_SKILLSET).min(2),
    du: yup.string().required(localeErrors.ERROR_INVALID_SKILLSET).min(2),
  });

  const selectedBranch = location?.state?.selectedBranch || null;

  const [dData, setDData] = useState(dropdowns.categories);

  useEffect(() => {
    refetchAppAData();
  }, []);

  useEffect(() => {
    if (selectedBranch) {
      setDData(
        dropdowns.categories.filter(
          (item: any) => item.branchId === selectedBranch._id
        )
      );
    }
  }, [selectedBranch,dropdowns?.categories]);

  useEffect(() => {
    if (!selectedBranch) {
      setDData(dropdowns.categories);
    }
  }, [dropdowns?.categories]);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<CategoryFormField>({
    resolver: yupResolver(validationSchema) as any,
  });

  const handleOpenAddModal = () => {
    setAddModal(true);
  };

  const handleCloseAddModal = () => {
    setAddModal(false);
  };

  const handleOpenEditModal = (data: any) => {
    console.log("handleOpenEditModal", data);
    // alert(data?.price)
    setEditModal(true);
    // setBranchData(data);
    setValue("en", data?.label.en);
    setValue("du", data?.label.du);
    setValue("branchId", data?.branchId);
    setValue("price", data?.price as any);

    setCategoryId(data?._id);
    setBranchId(data?.branchId);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    reset();
    setCategoryId(null);
  };

  const handleOpenDeleteModal = (id: string) => {
    // console.log("handleDeleteModal");
    setCategoryId(id);
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onSubmit: SubmitHandler<CategoryFormField> = async (data) => {
    const params = {
      en: data?.en,
      du: data?.du,
      branchId: data?.branchId,
      price: data?.price,
    };
    try {
      //
      setLoading(true);
      const response = await APIAddCategory(params, accessToken);
      toast.success(localeSuccess.SUCCESS_CATEGORY_ADDED, "top-right");
      refetchAppAData();
      reset();
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setLoading(false);
      handleCloseAddModal();
    }
  };

  const onSubmitEdit: SubmitHandler<CategoryFormField> = async (data) => {
    const params = {
      en: data?.en,
      du: data?.du,
      branchId: getValues("branchId"),
      price: data?.price,
    };
    try {
      //
      setEditLoading(true);
      const response = await APIEditCategory(categoryId, params, accessToken);
      toast.success(localeSuccess.SUCCESS_CATEGORY_UPDATED, "top-right");
      // refetchAppAData();

      setDData(
        dData.map((item) =>{
          if(item._id === categoryId){
            return{
              ...item,
              label:{
                en: data?.en,
                du: data?.du,
              },
              price: data?.price,
            }
          }
          return item;
        })
      )

      reset();
      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setEditLoading(false);
      handleCloseEditModal();
    }
  };
  const onToggleCategroryPrice = async (data) => {
    try {
      setEditLoading(true);
      console.log("datadata", data);

      const response = await ApiTogglePriceCategory(data, accessToken);
      toast.success(localeSuccess.SUCCESS_CATEGORY_UPDATED, "top-right");
      refetchAppAData();

      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setEditLoading(false);
      handleCloseEditModal();
    }
  };

  const onConfirmDelete = async () => {
    try {
      //
      setDeleteLoading(true);
      const response = await APIDeleteCategory(categoryId, accessToken);
      toast.success(localeSuccess.SUCCESS_CATEGORY_DELETED, "top-right");
      refetchAppAData();
      setCategoryId("");
      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteModal();
    }
  };

  return {
    handleOpenAddModal,
    handleCloseAddModal,
    addModal,
    reset,
    loading,
    appDataLoading,
    refetchAppAData,
    dropdowns,
    onSubmit,
    register,
    control,
    handleSubmit,
    deleteModal,
    handleOpenDeleteModal,
    onToggleCategroryPrice,
    handleCloseDeleteModal,
    deleteLoading,
    onConfirmDelete,
    handleOpenEditModal,
    handleCloseEditModal,
    editModal,
    editLoading,
    onSubmitEdit,
    errors,
    branchId,
    selectedBranch,
    categories:dData,
  };
};
