import type { tableProps } from "./@types";
import { useLocale } from "../../../locales";
import styles from "./Table.module.scss";
import { FcClearFilters, FcPlus } from "react-icons/fc";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import { Button } from "components/atoms/ButtonF";
import { Text } from "components/atoms/TextF";

const ITEMS_PER_PAGE = 10; // Adjust this value based on the desired items per page

const TableWrpper = ({
  title,
  children,
  search,
  more,
  setSearch,
  path = "/dashboard/list",
  className = " ",
  clearFilter,
  onClearfilter,
  clearFilterLoading,
  tableCalssName = "overflow-scroll",
  showTitle,
  showPagination,
  showAddNewButton,

  changeStausFilter,
  stausFilter,

  data,
  hasMore,

  currentPage,
  OnNextPage,
  onPreviousPage,
  tableType,
}: tableProps) => {
  const { localeButtons } = useLocale();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navigateToCreatePage = () => {
    let accountType = "";
    if (pathname == "/organizations") accountType = "organization";
    else if (pathname == "/employees") accountType = "employee";
    else if (pathname == "/freelancers") accountType = "freelancer";

    navigate(`/create?accountType=${accountType}`);
  };
  const Navigate = () => {
    navigate(path);
  };

  const totalItems = data?.length || 0;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  // ul can be all , active , inactive

  const onChangeUl = (status: "all" | "verified" | "unverified") => {
    changeStausFilter(status);
  };

  return (
    <div className={"md:px-10 w-full   " + className}>
      <div className="bg-white w-full   rounded-[16px] mb-10">
        <div
          className={
            showTitle
              ? "flex justify-between items-center"
              : "flex justify-end items-center"
          }
        >
          {showTitle && (
            <div className=" flex justify-between items-center px-8 py-3 font-bold">
              <div className="text-base font-bold">{title}</div>
              <div className="items-center justify-center flex ">
                {clearFilter ? (
                  <div
                    className={
                      clearFilterLoading
                        ? "flex items-center space-x-3  px-2 p-1 rounded-[5px] cursor-progress"
                        : "flex items-center space-x-3  px-2 p-1 rounded-[5px] cursor-pointer"
                    }
                    onClick={() => {
                      if (!clearFilterLoading && onClearfilter) onClearfilter();
                    }}
                  >
                    <div>{localeButtons.BUTTON_CLEAR_FILTER}</div>
                    <FcClearFilters />
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <div className="flex flex-row items-center">
            {(pathname == "/employees" || pathname == "/freelancers") && (
              <div className={styles["filter-wrapper"]}>
                <div
                  className={styles["filter-title"]}
                  onClick={() => onChangeUl("all")}
                >
                  <input
                    name="ul"
                    type="radio"
                    value="all"
                    className={styles["radio"]}
                    checked={stausFilter == "all"}
                  />
                  <label>{localeButtons.BUTTONS_ALL}</label>
                </div>
                <div
                  className={styles["filter-title"]}
                  onClick={() => onChangeUl("verified")}
                >
                  <input
                    name="ul"
                    type="radio"
                    value="active"
                    className={styles["radio"]}
                    checked={stausFilter == "verified"}
                    onChange={() => onChangeUl("verified")}
                  />
                  <label>{localeButtons.BUTTONS_ACTIVE}</label>
                </div>
                <div
                  className={styles["filter-title"]}
                  onClick={() => onChangeUl("unverified")}
                >
                  <input
                    name="ul"
                    type="radio"
                    value="inactive"
                    className={styles["radio"]}
                    checked={stausFilter == "unverified"}
                  />
                  <label>{localeButtons.BUTTONS_INACTIVE}</label>
                </div>
              </div>
            )}
            {(pathname == "/organizations" ||
              pathname == "/employees" ||
              pathname == "/freelancers") && (
              <Button className="primary" onClick={navigateToCreatePage}>
                {localeButtons.BUTTON_ADD_NEW}
                <FcPlus />
              </Button>
            )}

            {showPagination && (
              <Pagination
                hasMore={hasMore}
                currentPage={currentPage}
                totalPages={totalPages}
                // onPageChange={handlePageChange}
                increment={OnNextPage}
                decrement={onPreviousPage}
              />
            )}
          </div>
        </div>

        {/* {Show Pagination here } */}
        {/* {it should be like < 1-10 of 27 >} */}
        {/* {Show Pagination here } */}
        <div className={tableCalssName}>{children}</div>
        {more ? (
          <div
            className="flex items-center justify-center space-x-3 pt-6 px-2 p-2 rounded-[5px] cursor-pointer"
            onClick={Navigate}
          >
            <div className={styles["seeMoreButton"]}>{more}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TableWrpper;
