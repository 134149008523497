import React from "react";
import styles from "./FinancePage.module.scss";

import { useLocale } from "locales";
import { Text } from "components/atoms";
import { useFinancePage } from "./hook";
import DynamicTable from "./DynamicTable";
import dayjs from "dayjs";
import { getLables } from "utils/hooks/helper";
import Loader from "components/atoms/Loader";

const ShiftPage: React.FC = () => {
  const { jobs, job: jobData, loading } = useFinancePage();

  const { localeLabels, currentLocale } = useLocale();

  const workShiftHeader = [
    localeLabels.LABEL_DAY,
    localeLabels.LABEL_DATE,
    localeLabels.LABEL_WORKING_STATUS,
    localeLabels.LABEL_START,
    localeLabels.LABEL_END,
    localeLabels.LABEL_BREAK,

    localeLabels.LABEL_HOURS_WORKED,
    localeLabels.LABEL_RATE,
    // localeLabels.LABEL_TOTAL_TIME,
    localeLabels.LABEL_TOTAL_AMOUNT,
  ];

  let selectedHeader: string[] = [];
  selectedHeader = workShiftHeader;

  // there 2 dates start and end get difference in hours
  const getDifferenceInHours = (start: Date, end: Date, mins?: number) => {
    const diffInMs = dayjs(end).diff(start, "minute");
    const minBreakTIme = diffInMs - (mins || 0);
    const diffInHours = minBreakTIme / 60;

    // convert time in this format HH:mm
    const hours = Math.floor(diffInHours);
    const minutes = Math.floor((diffInHours - hours) * 60);

    return {
      workingsHors: hours + ":" + minutes,
      diffInHours,
    };
  };

  const job = jobs?.pages?.[0]?.jobs?.[0];
  const rows = job?.workingDates?.map((shift) => {
    const totalWorkingHours = getDifferenceInHours(
      new Date(shift?.startDateTime || shift?.date),
      new Date(shift?.endDateTime || shift?.endDate),
      shift.breakTimeInMin
    );

    const ammount = (totalWorkingHours.diffInHours * job?.decidedRate).toFixed(
      2
    );
    // add euro sign
    const euroSign = "\u20AC";
    return [
      dayjs(shift?.date).format("dddd"),
      dayjs(shift?.date).format("DD MMM YYYY"),
      shift?.status,
      dayjs(shift?.startDateTime || shift?.date).format("HH:mm"),
      dayjs(shift?.endDateTime || shift?.endDate).format("HH:mm"),
      shift?.breakTimeInMin || 0,
      totalWorkingHours?.workingsHors,
      job?.jobId?.wage,
      euroSign + ammount,
    ];
  });

  return (
    <div>
      {loading ? (
        <div className={"loadingScreen"}>
          <Loader />
        </div>
      ) : (
        <div className={styles["FinancePage-main"]}>
          <div className={"row flex justify-between"}>
            <div className={styles["FinancePage-main-heading"]}>
              <Text className={styles["title"]}>{jobData?.title}</Text>
              <Text className={styles["cateogory"]}>
                {getLables(
                  jobData?.branches?.[0] as any,
                  currentLocale,
                  "branches"
                )}
              </Text>
            </div>
          </div>

          <div className={styles["FinancePage-table"]}>
            <DynamicTable headers={selectedHeader} rows={rows as any} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftPage;
