import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastConsumer } from "../../../store/ToastProvider";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useLocale } from "../../../locales";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  genrateReport,
  getReports,
  downloadReports,
} from "../../../utils/globalAPI/Dashboard";
import { useSocketIO } from "../../../store/SocketConsumer";
import { useInfiniteQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { IReport } from "../../../types/Gloabal";

export type AddFormField = {
  worker: string;
  address: string;
  organization: string;
  reportGroup: string;
  reportType: string;
  dates: Date[];
};

export const useReportsServices = () => {
  const { appDataLoading, accessToken, fetchApi } = UsersContextConsumer();
  const toast = ToastConsumer();
  const { localeSuccess, localeErrors } = useLocale();

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AddFormField>({
    defaultValues:{
      worker:"",
      address:"",
      organization:"",
      reportGroup:"schedule",
      reportType:"",
      dates:[]
    }
  });

  const { sockets } = useSocketIO();

  const handleOrganizationFetch = async (input: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      sockets.emit("fetch-organization", input, (data) => {
        const formattedData = data.map((item) => ({
          label: item?.organization?.companyName,
          value: item?._id,
          address: item?.address,
        }));
        resolve(formattedData);
      });
    });
  };
  const handleAddressFetch = async (input: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      sockets.emit("fetch-address", input, watch("organization"), (data) => {
        const formattedData = data.map((item) => ({
          value: item?._id,
          label: item?.city,
        }));
        resolve(formattedData);
      });
    });
  };
  const handleWorkerFetch = async (input: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      sockets.emit("fetch-worker", input, (data) => {
        const formattedData = data.map((item) => ({
          value: item?._id,
          label: item?.firstName + " " + item?.lastName,
        }));
        resolve(formattedData);
      });
    });
  };

  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
    if (watch("organization")) {
      sockets.emit("fetch-address", "", watch("organization"), (data) => {
        const formattedData = data.map((item) => ({
          value: item?._id,
          label: item?.city,
        }));
        setAddressOptions(formattedData);
      });
    }
  }, [watch("organization")]);
  const [loading,setLoading] = useState(false)
  const onSubmit: SubmitHandler<AddFormField> = async (data) => {
    try {
      setLoading(true)
      const d = await genrateReport(data, accessToken);
      refetch()
      toast.success(localeSuccess.REPORT_GENRATED);
      const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const link = document.createElement('a');
      link.href = url;

      // Set the file name
      link.setAttribute('download', 'downloaded_file.xlsx');

      // Simulate a click on the anchor element to trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error: any) {
      toast.serverError(error,"top-right")
    }
    finally{
      setLoading(false)
    }
  };

  const Submit = handleSubmit(onSubmit);
  const [params, setSearchParams] = useSearchParams({ offset: "0" });
  const [offset, setOffset] = useState(params?.get("offset") || "0");

  useEffect(() => {
    if (offset) {
      setSearchParams({ offset: offset });
    }
  }, [offset]);

  const { data, hasNextPage, isLoading, fetchNextPage,refetch } = useInfiniteQuery(
    ["reports"],
    ({ pageParam }) => getReports(pageParam, accessToken),
    {
      enabled: !!fetchApi,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (data) => data.nextPage,
    }
  );

  const onNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setOffset(Number(offset) + 1 + "");
      setSearchParams({ offset: Number(offset) + 1 + "" });
    }
  };
  const onPreviouse = () => {
    if (Number(offset) > 0) {
      setOffset(Number(offset) - 1 + "");
      setSearchParams({ offset: Number(offset) - 1 + "" });
    }
  };

const onClickDownload=async(id:IReport)=>{
  try{

    const d=await downloadReports(id?._id,accessToken)
    console.log(d)
    // response is a file download that file and save it
    // Create a Blob object from the file response
    // let unit8Array = new Uint8Array(d.data);
   const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const link = document.createElement('a');
      link.href = url;

      // Set the file name
      link.setAttribute('download', 'downloaded_file.xlsx');

      // Simulate a click on the anchor element to trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

  }catch(err:any){}
}

  return {
    appDataLoading,
    control,
    watch,
    handleOrganizationFetch,
    handleAddressFetch,
    handleWorkerFetch,
    addressOptions,
    Submit,
    onPreviouse,
    onNextPage,
    isLoading,
    onClickDownload,
    reports:data?.pages?.[Number(offset)],
    loading,
  };
};
