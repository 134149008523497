import { IoArrowBackCircleOutline } from "react-icons/io5";
import styles from "./DayView.module.scss";
import { Button } from "components/atoms/ButtonF";
import TaskDecription from "assets/svgs/TaskDescription";
import ClanderIcon from "assets/svgs/TaskDescription copy";
import { Text } from "components/atoms/TextF";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IAvailabilties, ISchedule } from "../ScheduleModal/@types";
import { IHiredWokers, ITask, JobData } from "types/schema";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmptyScreen from "components/organisms/EmptyScreen";
import ModalComponent from "components/molecules/ModalF/Modal";
import ConfirmationModal from "components/cells/ConfirmationModal";
import TrashImgIcon from "assets/svgs/TrashImgIcon";
import AddSchedule from "components/cells/WorkingApprovalForm";
import EditAvailability from "components/cells/EditAvailability/EditAvailability";
import { useLocale } from "locales";
import { usePersonalSchedulePage } from "./panel.hook";
import EditPencilIcon from "assets/svgs/EditPencilIcon";
import TrashIcon from "assets/svgs/TrashIcon";
import { useTaskCard } from "components/organisms/TaskCard/hook";
import { getTaskStatus } from "utils/hooks/helper";

interface panelProps {
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPopoverOpen: boolean;
  selectedDate: Date;
  availability: IAvailabilties;
  jobs: {
    job: JobData;
  } & IHiredWokers[];
  addAvailability: () => void;
  refetchData: () => void;
}

export const Pannel = ({
  setPopoverOpen,
  isPopoverOpen,
  selectedDate,
  availability,
  jobs,
  addAvailability,
  refetchData,
}: panelProps) => {
  const [params, setParamas] = useSearchParams({ job: "", date: "" });

  const [view, setView] = useState<"availability" | "tasks" | "schedule">(
    params.get("job") ? "tasks" : "availability"
  );

  useEffect(() => {
    if (params.get("job")) {
      setView("tasks");
    }
  }, [params.get("job"), params.get("date")]);

  const { localeButtons, localeTitles, localeLabels } = useLocale();
  const {
    modals,
    toggleEditAvailabilityModal,
    onConfirmAvailabilityEdit,
    onDeleteAvailability,
    openDeleteAvailability,
    openAvailabiltySchedule,

    loading,

    toggleDeleteAvailabilityModal,
    clockColor,
  } = usePersonalSchedulePage(refetchData);

  const returnLabel = () => {
    if (availability?.availability === "available") {
      return localeLabels.LABEL_AVAILABLE;
    } else if (availability?.availability === "partial") {
      return localeLabels.LABEL_PARTIAL_AVAILABLE;
    } else if (availability?.availability === "unavailable") {
      return localeLabels.LABEL_NOT_AVAILABLE;
    }
  };

  const returnShift = () => {
    if (availability?.shift === "all_day") {
      return localeLabels.LABEL_ALL_DAY;
    } else if (availability?.shift === "day_shift") {
      return localeLabels.LABEL_DAY_SHIFT;
    } else if (availability?.shift === "night_shift") {
      return localeLabels.LABEL_NIGHT_SHIFT;
    }
  };

  return (
    <div className={styles["calander-detail"]}>
      <div className={styles["header-btn"]}>
        <div className={styles["header-back-btn"]}>
          <IoArrowBackCircleOutline
            size={30}
            onClick={() => setPopoverOpen(!isPopoverOpen)}
          />
          <Text className={styles["title"]}>
            {localeTitles.TITLE_DETAIL_AVAILABILITY}
          </Text>
        </div>

        <Text className={styles["title"]}>
          {dayjs(selectedDate).format("DD-MMM")}
        </Text>

        {view === "availability" && (
          <div className={styles["eventsContainer-body-head"]}>
            <div
              className={styles["action-button"]}
              onClick={() => openAvailabiltySchedule(availability)}
            >
              <EditPencilIcon
                size="16"
                color={clockColor(availability?.availability)}
              />
            </div>
            <div
              className={styles["action-button"]}
              onClick={() => openDeleteAvailability(availability)}
            >
              <TrashIcon
                size="16"
                color={clockColor(availability?.availability)}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles["btn-tabs"]}>
        <Button
          className={
            view === "availability"
              ? styles["active-btn"]
              : styles["inactive-btn"]
          }
          onClick={() => setView("availability")}
        >
          {localeButtons.BUTTON_AVAILABILITY}
        </Button>
        <Button
          className={
            view === "tasks" ? styles["active-btn"] : styles["inactive-btn"]
          }
          onClick={() => setView("tasks")}
        >
          {localeButtons.BUTTON_JOB_DETAILS}
        </Button>
      </div>

      <div className={styles["panelbody"]}>
        {view === "tasks" &&
          jobs?.map((item, index) => (
            <TasksItemCollapsible
              key={index}
              index={index}
              item={item?.job}
              hiredWorker={item}
              loading={loading}
              date={selectedDate}
              refetch={refetchData}
            />
          ))}
        {view === "tasks" && !jobs && (
          <EmptyScreen text={localeTitles.TITLE_NO_TASK} />
        )}

        {view === "availability" && !availability && (
          <EmptyScreen
            buttonText={localeButtons.BUTTON_ADD_AVAILABILITY}
            text={localeTitles.TITLE_NO_AVAILABILITY}
            onClick={() => {
              addAvailability();
            }}
          />
        )}

        {view === "availability" &&
          availability &&
          (availability?.availability ? (
            <div className={styles["panel-body"]}>
              <div className={styles["panel-item"]}>
                <Text className={`${styles["description"]} cursor-pointer`}>
                  {returnLabel()}
                </Text>
              </div>
              <div className={styles["panel-item"]}>
                <div className={styles["icon"]}>
                  <ClanderIcon />
                </div>
                <Text className={styles["description"]}>
                  {dayjs(selectedDate).format("D MMMM MM, YYYY")}
                </Text>
              </div>
              {availability.availability === "available" && (
                <div className={styles["panel-item"]}>
                  <div className={styles["icon"]}>
                    <TaskDecription />
                  </div>
                  <Text className={styles["description"]}>{returnShift()}</Text>
                </div>
              )}
              {availability?.reason && (
                <div className={styles["panel-item"]}>
                  <div className={styles["icon"]}>
                    <TaskDecription />
                  </div>
                  <Text className={styles["description"]}>
                    {availability?.reason}
                  </Text>
                </div>
              )}
              {availability?.availability === "partial" && (
                <div className={styles["panel-item"]}>
                  <div className={styles["icon"]}>
                    <TaskDecription />
                  </div>
                  <Text className={styles["description"]}>
                    {dayjs(availability?.timeFrame?.[0]).format("HH:mm")} -{" "}
                    {dayjs(availability?.timeFrame?.[1]).format("HH:mm")}
                  </Text>
                </div>
              )}
            </div>
          ) : null)}
      </div>

      {modals.editAvailability && (
        <ModalComponent
          open={modals.editAvailability}
          onClose={toggleEditAvailabilityModal}
        >
          <EditAvailability
            selectedEvent={availability}
            loading={loading}
            onConfirm={onConfirmAvailabilityEdit}
            closeModal={toggleEditAvailabilityModal}
          />
        </ModalComponent>
      )}

      <ModalComponent
        open={modals.deleteAvailability}
        onClose={toggleDeleteAvailabilityModal}
      >
        <ConfirmationModal
          cancelButtonText={localeButtons?.BUTTON_NO_CANCEL}
          confirmButtonText={localeButtons?.BUTTON_YES_DELETE}
          onConfirm={onDeleteAvailability}
          icon={<TrashImgIcon />}
          subTitle={localeTitles.TITLE_ARE_YoU_SURE_DELETE_THIS}
          closeModal={toggleDeleteAvailabilityModal}
          loading={loading}
        />
      </ModalComponent>
    </div>
  );
};

export const TasksItemCollapsible = ({
  item,
  hiredWorker,
  loading,
  date,
  refetch,
}: {
  item: JobData;
  index: number;
  loading: boolean;
  hiredWorker: IHiredWokers;
  date: Date;
  refetch: () => void;
}) => {
  const [params, setParamas] = useSearchParams({ job: "", date: "" });

  const [display, setDisplay] = useState(params.get("job") === item?._id);

  const { localeLabels } = useLocale();

  const isDateExist = hiredWorker?.workingDates?.find(
    (i) =>
      dayjs(i.date).format("YYYY-MM-DD") === dayjs(date).format("YYYY-MM-DD")
  );

  const { onConfirm, toggleModal, modal, modalLoading } = useTaskCard(
    isDateExist?._id as string,
    refetch
  );

  return (
    <div className={styles["panel-body"]} onClick={(e) => e.stopPropagation()}>
      <div
        className={styles["panel-item"]}
        onClick={() => setDisplay(!display)}
      >
        <Text className={`${styles["title"]} cursor-pointer`}>
          {item?.title}
        </Text>
        <div className={"row flex items-center"}>
          <Button
            className={`${styles[isDateExist?.status || ""]} ${
              loading && " cursor-progress"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              toggleModal(isDateExist?.status);
            }}
          >
            {getTaskStatus(isDateExist?.status, localeLabels)}
          </Button>
        </div>
      </div>
      {display && (
        <TasksItem item={item} hiredWorker={hiredWorker} date={date} />
      )}

      {modal && (
        <ModalComponent open={modal} onClose={() => toggleModal("close")}>
          <AddSchedule
            loading={modalLoading}
            onConfirm={onConfirm}
            closeModal={() => toggleModal("close")}
            defaultValues={{
              date: [
                dayjs(
                  isDateExist?.startDateTime || isDateExist?.date
                )?.toDate(),
                dayjs(
                  isDateExist?.endDateTime || isDateExist?.endDate
                )?.toDate(),
              ],
              breakTimeInMin: isDateExist?.breakTimeInMin as number,
              status: isDateExist?.status as any,
            }}
            shift={hiredWorker?.job?.shift}
            date={isDateExist?.date as Date}
          />
        </ModalComponent>
      )}
    </div>
  );
};
export const TasksItem = ({
  item,
  hiredWorker,
  date,
}: {
  item: JobData;
  hiredWorker: IHiredWokers;
  date: Date;
}) => {
  const navigate = useNavigate();
  const isDateExist = hiredWorker?.workingDates?.find(
    (i) =>
      dayjs(i.date).format("YYYY-MM-DD") === dayjs(date).format("YYYY-MM-DD")
  );

  return (
    <>
      <div
        className={`${styles["panel-item"]} underline`}
        onClick={() => {
          navigate(`/organization/${item?._id}/details`);
        }}
      >
        <div className={styles["icon"]}>
          <TaskDecription />
        </div>
        <Text className={styles["description"]}>{item?.title}</Text>
      </div>
      <div className={`${styles["panel-item"]} justify-between items-center`}>
        <div className={`${styles["panel-item"]} items-center`}>
          <div className={styles["icon"]}>
            <ClanderIcon />
          </div>
          <Text className={styles["description"]}>
            {dayjs(isDateExist?.startDateTime || item?.timeToStart).format(
              "hh:mm"
            )}
            -
            {dayjs(isDateExist?.endDateTime || item?.timeToEnd).format("hh:mm")}
          </Text>
        </div>
        {/* button to accept or decline */}
      </div>
      <div className={styles["panel-item"]}>
        <div className={styles["icon"]}>
          <TaskDecription />
        </div>
        <Text className={styles["description"]}>
          {dayjs(isDateExist?.startDateTime || isDateExist?.date).format(
            "D MMMM"
          )}
          {item.shift === "night" &&
            dayjs(isDateExist?.endDateTime || isDateExist?.endDate).format(
              "- D MMMM"
            )}
        </Text>
      </div>
    </>
  );
};
