import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { availabilityForm } from "../@types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UsersContextConsumer } from "store/AuthProvider";
import {
  getAlreadyAvailAvailability,
  getPreviosWeekAvailability,
} from "utils/globalAPI/ScheduleAndAvailability";
import { ToastConsumer } from "store/ToastProvider";
import { DateObject } from "react-multi-date-picker";
import { useLocale } from "locales";

interface DateInfo {
  date: Date;
  reason: string;
  availability: string;
}
export const useAvailablilitySchedule = (defaultValues?: availabilityForm) => {
  const [selectedWeek, setSelectedWeek] = useState<any>([
    dayjs().toDate(),
    dayjs().endOf("week").toDate(),
  ]);
  const [loadingAvailability, setLoading] = useState(false);
  const { accessToken } = UsersContextConsumer();
  const { serverError } = ToastConsumer();
  const { localeErrors } = useLocale();

  const validationSchema = yup.object().shape({
    availabilitySchedule: yup.array().of(
      yup.object().shape({
        date: yup.date().required(localeErrors.ERROR_DATE_REQUIRED),
        availability: yup
          .string()
          .oneOf(
            ["available", "unavailable", "partial", ""],
            localeErrors.ERROR_INVALID_AVAILABILITY
          ),
        shift: yup.string().when("availability", {
          is: "available",
          then: yup
            .string()
            .oneOf(
              ["day_shift", "night_shift"],
              localeErrors.ERROR_SHIFT_REQUIRED
            )
            .required(localeErrors.ERROR_SHIFT_REQUIRED),
          otherwise: yup.string().notRequired(),
        }),
        timeFrame: yup.array().when("availability", {
          is: "partial",
          then: yup
            .array()
            .of(yup.date())
            .min(2, localeErrors.ERROR_INVALID_TIMEFRAME)
            // add custom testing that 2nd element should be greater than first
            .test({
              test: (value) => {
                if (value && value.length > 1) {
                  return value?.[0] && value?.[1] && value?.[0] < value?.[1]
                    ? true
                    : false;
                }
                return true;
              },
              message: localeErrors.ERROR_INVALID_TIMEFRAME,
            })
            .required(localeErrors.ERROR_INVALID_TIMEFRAME),
          otherwise: yup.array(),
        }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<availabilityForm>({
    resolver: yupResolver(validationSchema) as any,
    defaultValues,
  });

  // useScrollToError(errors);
  const { fields, remove, insert, append } = useFieldArray({
    control,
    name: "availabilitySchedule",
  });

  console.log("errors", errors);

  // useEffect(() => {
  //   const getData = async () => {
  //     const dates = await getDatesBetween(
  //       dayjs(selectedWeek[0]).startOf("week").toDate(),
  //       dayjs(selectedWeek[1]).endOf("week").toDate()
  //     );
  //   };
  //   getData();
  // }, []);

  const getDatesBetween = async (startDate: Date, endDate: Date) => {
    setLoading(true);
    try {
      setValue("availabilitySchedule", []);
      let availability = await getAlreadyAvailAvailability(
        dayjs(startDate).format("MM-DD-YYYY"),
        dayjs(endDate).format("MM-DD-YYYY"),
        accessToken
      );
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        let isAvailable = availability.data.find(
          (item: any) => item.date === dayjs(currentDate).format("YYYY-MM-DD")
        );
        if (isAvailable) {
          append({
            date: dayjs(currentDate).format("YYYY-MM-DD"),
            availability: isAvailable.availability as
              | "available"
              | "unavailable"
              | "partial",
            reason: isAvailable.reason,
            timeFrame: isAvailable?.timeFrame,
            shift: isAvailable.shift as "day_shift" | "night_shift",
          });
        } else {
          append({
            date: dayjs(currentDate).format("YYYY-MM-DD"),
            reason: "",
            availability: "",
            timeFrame: [],
            shift: "day_shift",
          });
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }
    } catch (error: any) {
      serverError(error);
    } finally {
      setLoading(false);
    }
  };
  const copyPreviosWeeSchedule = async () => {
    setLoading(true);
    try {
      setValue("availabilitySchedule", []);
      let availability = await getPreviosWeekAvailability(
        dayjs(selectedWeek[0]).format("MM-DD-YYYY"),
        accessToken
      );
      let currentDate = new Date(selectedWeek[0]);

      while (currentDate <= selectedWeek[1]) {
        // let isAvailable =availability.data.data.find(
        //   (item: any) => item.date === dayjs(currentDate).format("YYYY-MM-DD")
        // )
        // now we have to check on base of day if day is saturday and day at currentDate is saturaday then we have to copy availability of saturday
        // if day is sunday and day at currentDate is sunday then we have to copy availability of sunday

        let isAvailable = availability.data.find(
          (item: any) =>
            dayjs(currentDate).format("dddd").toLowerCase() ===
            dayjs(item.date).format("dddd").toLowerCase()
        );
        if (isAvailable) {
          append({
            date: dayjs(currentDate).format("YYYY-MM-DD"),
            availability: isAvailable.availability as
              | "available"
              | "unavailable"
              | "partial",
            reason: isAvailable.reason,
            shift: isAvailable.shift as "day_shift" | "night_shift",
            timeFrame: isAvailable?.timeFrame,
          });
        } else {
          append({
            date: dayjs(currentDate).format("YYYY-MM-DD"),
            reason: "",
            availability: "available",
            timeFrame: [],
            shift: "day_shift",
          });
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }
    } catch (error: any) {
      serverError(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeDates = async (data: Date[]) => {
    setSelectedWeek(data);
    await getDatesBetween(dayjs(data[0]).toDate(), dayjs(data[1]).toDate());
  };

  return {
    fields,
    control,
    getDatesBetween,

    selectedWeek,
    setSelectedWeek,

    errors,

    watch,

    handleSubmit,

    copyPreviosWeeSchedule,

    onChangeDates,
    loadingAvailability,
  };
};
