import { Text } from "components/atoms/TextF";
import styles from "./NotificationCard.module.scss";
import { NotificationCardProps } from "./@types";
import DirectMessageIcon from "assets/svgs/DirectMessageIcon";
import CloseCircleIcon from "assets/svgs/CloseCircleIcon";
import dayjs from "dayjs";

const NotificationCard = ({
  data,
  deleteNotification,
  navigateToLink,
}: NotificationCardProps) => {
  return (
    <div
      className={styles["NotificationCard"]}
      onClick={(event) => {
        event.stopPropagation();
        navigateToLink(data?.link,data?._id);
      }}
    >
      <div className={styles["NotificationCard-left"]}>
        <DirectMessageIcon />
        <div>
          <Text className={styles["title"]}>{data?.content}</Text>
          <Text className={styles["date"]}>
            {dayjs(data?.createdAt).format("MM/DD/YYYY")}
          </Text>
        </div>
      </div>
      <CloseCircleIcon
        className="cursor-pointer"
        onClick={(event) => {
          event?.stopPropagation();
          deleteNotification(data?._id);
        }}
      />
    </div>
  );
};

export default NotificationCard;
