import type { EventCardProps } from "./@types";
import styles from "./EventCard.module.scss";
import { Text } from "components/atoms/TextF";
import ClockIconCalendar from "assets/svgs/ClockIconCalendar";
import VisibileIcon from "assets/svgs/VisibileIcon";
import { useLocale } from "locales";
import { availabilityTypes } from "components/cells/ScheduleModal/@types";

const EventCard = ({
  startTime,
  endTime,
  left,
  width,
  availability,
  shift,
  isTask,
}: EventCardProps) => {
  const { localeLabels } = useLocale();
  // "completed" | "rejected" | "inProgress" | "declined" | "approved"
  const clockColor = (
    status?:
      | availabilityTypes
      | "completed"
      | "inProgress"
      | "rejected"
      | "approved"
      | "declined"
  ) => {
    if (status === "available") {
      return "#16911b";
    } else if (status === "unavailable") {
      return "#da011c";
    } else if (status === "partial") {
      return "#181203";
    } else if (status === "completed") {
      return "#C9D5FF";
    } else if (status === "inProgress") {
      return "#181203";
    } else if (status === "approved") return "#819bf7";
    else if (status === "declined") {
      return "rgb(228, 174, 38)";
    } else if (status === "rejected") {
      return "#DA011C";
    }
  };

  return (
    <>
      <div
        className={`${styles["eventsContainer"]}`}
        style={{
          width: width,
          left: left,
          position: left ? "absolute" : "relative",
        }}
      >
        <div
          className={`${styles["eventsContainer-body"]} ${
            styles[`eventsContainer-body-${availability}`]
          } `}
        >
          <div className={"w-full"}>
            <Text
              className={`${styles[`eventTitle`]} ${
                styles[`eventTitle-${availability}`]
              } `}
            >
              {isTask ? shift : shift + " " + localeLabels.LABEL_SHIFT}
            </Text>

            <Text
              className={`${styles[`eventTime`]} ${
                styles[`eventTime-${availability}`]
              } `}
            >
              <ClockIconCalendar color={clockColor(availability as any)} />
              {availability === "available" ? (
                shift
              ) : (
                <>
                  {startTime}-{endTime}
                </>
              )}
            </Text>
          </div>

          <div className={styles["eventDiv-right"]}>
            <VisibileIcon color={clockColor(availability as any)} size="15" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
