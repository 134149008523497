import { useEffect, useRef, useState } from "react";
import { useLocale } from "../../../locales";
import Text from "../../../components/atoms/Text";
import type { ChartProps } from "./@types";
import styles from "./Chart.module.scss";
import { useWidth } from "../../../utils/responsiveHook";
import Loader from "../../../components/molecules/Loading";
import ReactApexChart from "react-apexcharts";

const Chart = ({
  data,
  loading,
  type = "bar",
  dataType,
  options,
}: ChartProps) => {
  const { localeLabels } = useLocale();
  const ref = useRef<any>(null);
  const { width } = useWidth();


  return (
    <div ref={ref} className="">
      {loading ? (
        <div className="flex justify-center items-center h-full w-full">
          <Loader />
        </div>
      ) : (
        <ReactApexChart
          options={options}
          
          series={data?.series}
          type={type}
          // height={350}
        />
      )}
    </div>
  );
};

export default Chart;
