import React from "react";
import { ErrorMessage } from "../ErrorMessage";
import { Control, Controller, FieldValues } from "react-hook-form";

export type TextAreaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    wrapClassName: string;
    control: Control<any>;
    className: string;
    name: string;
    placeholder: string;
    errors: string[] | any;
    onChange: Function;
    register: any;
    prefix: React.ReactNode;
  }>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      wrapClassName = "",
      className = "",
      name = "",
      placeholder = "",
      children,
      onChange,
      prefix,
      errors,
      register,
      control,
      ...restProps
    },
    ref
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      if (onChange) onChange(e?.target?.value);
    };

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <div className="flex flex-col">
            <div
              className={`${wrapClassName} 
      `}
            >
              {!!prefix && prefix}
              <textarea
                // {...restProps}
                // {...register}
                // name={name}
                ref={ref}
                value={value}
                className={`${className}  border-0`}
                placeholder={placeholder}
                onChange={onChange}
                // onChange={handleChange}
                {...restProps}
              />

              {children}
              {!!errors && (
                <ErrorMessage errors={errors?.[name]?.message as string} />
              )}
            </div>
          </div>
        )}
      />
    );
  }
);

export { TextArea };
