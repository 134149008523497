import type { SortDropDownProps } from "./@types";
import styles from "./SortDropDown.module.scss";
import { useLocale } from "../../../locales";

import { Text } from "components/atoms/TextF";
import { BiSort } from "react-icons/bi";

import { useSortDropDown } from "./hook";
import { BsArrowDownCircle, BsArrowUp, BsArrowUpCircle } from "react-icons/bs";

const SortDropDown = ({ changeFilter,value }: SortDropDownProps) => {
  const { localeDropdowns } = useLocale();

  const { toggle, ref, isComponentVisible, selectSortFilter } =
    useSortDropDown(changeFilter);
  return (
    <div ref={ref}>
      <div className={styles["actionButton-postListing-icon"]} onClick={toggle}>
      <BiSort className={styles["sort-filter"]} size={20} color="#afafaf" />
        {/* {width > breakPoints.md && <ChevronDownIcon />} */}
      </div>

      {isComponentVisible ? (
        <div className="font-bold relative">
          <div
            className="bg-white p-2 mt-3 bg-white-A700 space-y-3 flex flex-col rounded-lg font-bold  top-13 right-[3%] absolute border-2 border-black w-32 z-20"
            style={{ boxShadow: "0px 6px 32px 0px rgba(44, 50, 169, 0.1)" }}
          >
            <div className={styles["dropdown-option-top"]}>
              <div
                className={styles["dropdown-option"]}
                onClick={() => selectSortFilter("A_Z")}
              >
                <Text className={styles["dropdown-menu"]}>
                  {localeDropdowns.DROPDOWN_SORT_A_Z}
                </Text>
              </div>
              <div
                className={styles["dropdown-option"]}
                onClick={() => selectSortFilter("Z_A")}
              >
                <Text className={styles["dropdown-menu"]}>
                  {localeDropdowns.DROPDOWN_SORT_Z_A}
                </Text>
              </div>
              <div
                className={styles["dropdown-option"]}
                onClick={() => selectSortFilter("popularity")}
              >
                <Text className={styles["dropdown-menu"]}>
                  {localeDropdowns.DROPDOWN_SORT_BY_POPULARITY}<BsArrowUpCircle color="#afafaf" size={15} style={{marginLeft:10}} />
                </Text>
              </div>
              <div
                className={styles["dropdown-option"]}
                onClick={() => selectSortFilter("popularity_d")}
              >
                <Text className={styles["dropdown-menu"]}>
                  {localeDropdowns.DROPDOWN_SORT_BY_POPULARITY} <BsArrowDownCircle color="#afafaf" size={15} style={{marginLeft:10}} />
                </Text>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SortDropDown;
