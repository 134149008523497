const awsExports = {
  Auth: {
    // identityPoolId: process.env.USER_POOL_ID ? process.env.USER_POOL_ID : 'eu-central-1_L31SDqUOR',
    identityPoolRegion:'eu-central-1_L31SDqUOR',

    // userPoolId:  process.env.USER_POOL_ID ? process.env.USER_POOL_ID : 'eu-central-1_L31SDqUOR',
    userPoolId: 'eu-central-1_L31SDqUOR',

    // userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID? process.env.USER_POOL_WEB_CLIENT_ID : '4st72a380s80ieev27emp6uc65',
    userPoolWebClientId: '1jfdmm3na56nkdugviimt3mv2p',
    
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      // domain: "app.motofellow.com",
      domain:
        process.env.NODE_ENV === "development"
          ? process.env.LOCOLHOST
          : process.env.COGNITO_DOMAIN_NAME,

      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 30,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      // sameSite: "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
  },
};
// console.log(
//   "AWS URL",
//   process.env.USER_POOL_WEB_CLIENT_ID,
//   process.env.COGNITO_DOMAIN_NAME,
//   process.env.REACT_APP_API_URL
// );

export default awsExports;
