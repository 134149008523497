import React, { useEffect, useRef, useState } from "react";
import { useLocale } from "../../../locales";
import styles from "./CommonTable.module.scss";
import { CommonTableProps } from "./@types";
import TableTemplate from "../../organisms/Table/Table";
import ConfirmationModal from "../../organisms/Confirmation Modal";
import TableItems from "../../molecules/Table Items";
import TableHeader from "../../../components/molecules/Table Header";
import TableHeaderMobile from "../../../components/molecules/Table Header/TableHeaderMobile";
import { useWidth } from "../../../utils/responsiveHook";
import FilterModal from "../../organisms/FilterModal";

const CommonTable = ({
  loading,
  data = [],
  tableType = "Application_status",
  showConfimationModal = false,
  onClickCanelBtnModal = () => {},
  onCLickConfirmBtnModal = () => Promise.resolve(),
  openSearchModal,
  onClickClearFilter,
  showFilter,
  navigateTo = () => {},
  onSubmit = () => {},
  filterLoading,
  nextFunction,
  previousFunction,
  onModalEdit,
  showTitle,
  onClickDelete,
  onClickEdit,
  showPagination,
  hasMore,
  onToggleCategroryPrice,
  allowedBranches,
  filter,
  changeStausFilter,
  stausFilter,
  ...props
}: CommonTableProps) => {
  const ref = useRef<any>(null);
  const { localeButtons } = useLocale();
  const { width } = useWidth();

  return (
    <div ref={ref}>
      <TableTemplate
        hasMore={hasMore}
        showPagination={showPagination}
        data={data}
        showTitle={showTitle}
        tableType={tableType}
        openSearchModal={openSearchModal}
        searchbtnShow={true}
        loading={loading}
        nextFunction={nextFunction}
        previousFunction={previousFunction}
        tableFooter={props.tableFooter}
        OnNextPage={props.OnNextPage}
        onPreviousPage={props.onPreviousPage}
        currentPage={props.currentPage}
        changeStausFilter={changeStausFilter}
        stausFilter={stausFilter}
        {...props}
        // tableHeading={<TableHeader tableType={tableType} />}
        tableHeading={<TableHeader tableType={tableType} filter={filter} />}
        tableBody={data?.map((iterator, index) => (
          <TableItems
            onClickDelete={onClickDelete}
            onClickEdit={onClickEdit}
            number={index}
            key={index}
            itemData={iterator}
            tableType={tableType}
            navigateTo={navigateTo}
            onModalEdit={onModalEdit}
            onToggleCategroryPrice={onToggleCategroryPrice}
            isAllowed={allowedBranches?.includes(iterator._id)}
          />
        ))}
      />
      <ConfirmationModal
        show={showConfimationModal}
        onHide={onClickCanelBtnModal}
        onConfirm={onCLickConfirmBtnModal}
        // statusChangeModalLading={statusChangeModalLading}
      />
      <FilterModal
        filterType={tableType == "Application_status" ? "goodplan" : "bikes"}
        loading={filterLoading}
        onSubmit={onSubmit}
        show={showFilter}
        onClickClearFilter={onClickClearFilter}
      />
    </div>
  );
};

export default CommonTable;
