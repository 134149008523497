import { Text } from "components/atoms/TextF";
import styles from "./AddSchedule.module.scss";
import { workingDatesFormProps } from "./@types";
import { useLocale } from "locales";

import { Button } from "components/atoms/ButtonF";
import CloseCircleIcon from "assets/svgs/CloseCircleIcon";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import { useAddSchedule } from "./hook";
import { ControlInput } from "components/atoms/ControlledInput/Input";

import { ControlTimeSelector } from "components/atoms/TimeSelector/TimeSelector";
import ControlSelect from "components/molecules/ControlSelect";

import {
  workingDatesStatusOrg
} from "constants/constantData";

import dayjs from "dayjs";
import { useWidth } from "utils/responsiveHook";

const AddSchedule = ({
  closeModal,
  onConfirm,
  loading,
  defaultValues,
  shift = "day",
  date,
}: workingDatesFormProps) => {
  const { control, handleSubmit, watch } = useAddSchedule(defaultValues);
  const { width } = useWidth();

  const {
    localeBasics,
    localeTitles,
    localePlaceholders,
    localeButtons,
    localeLabels,
  } = useLocale();

  return (
    <div className={styles["AddSchedule"]}>
      <div className={styles["AddSchedule-header"]}>
        <div></div>
        <Text className={styles["title"]}>{localeTitles.TITLE_JOB_STATUS}</Text>
        <div onClick={closeModal} className={"cursor-pointer"}>
          <CloseCircleIcon color="#8BA3F5" size="33" />
        </div>
      </div>

      <div className={styles["AddSchedule-main-body"]}>
        <div className={styles["AddSchedule-main"]}>
          <Text className={styles["heading"]}>
            {localeTitles.TITLE_JOB_STATUS}
          </Text>
          <div className={styles["selectDiv"]}>
            <ControlSelect
              name={`status`}
              control={control}
              list={workingDatesStatusOrg(localeLabels)}
              className={styles["inputField-wrap"]}
            />
          </div>
        </div>

        {watch("status") === "rejected" && (
          <Text className={styles["subTitle"]}>
            {localeBasics.TEXT_REJECT_JOB_DONE_BY_WORKER}
          </Text>
        )}
        {watch("status") === "declined" && (
          <Text className={styles["subTitle"]}>
            {localeBasics.TEXT_DECLINE_JOB_NOTE}
          </Text>
        )}

        {(watch("status") === "completed" ||
          watch("status") === "approved") && (
          <>
            <div className={styles["eachSection-dates-availability"]}>
              <Text className={styles["heading"]}>
                {localeLabels.LABEL_TIME_FRAME}
              </Text>
              <div className={styles["selectDiv-timeFrame"]}>
                {/* <Text className={styles["label"]}>
                  {localeLabels.LABEL_FROM}:
                </Text> */}

                <ControlTimeSelector
                  type="range"
                  name={`date`}
                  control={control}
                  maxDate={
                    shift === "night"
                      ? dayjs(date).add(1, "day").endOf("day").toDate()
                      : dayjs(date).endOf("day").toDate()
                  }
                  minDate={dayjs(date).startOf("day").toDate()}
                />

                {/* <Text className={styles["label"]}>
                  {localeLabels.LABEL_TO}: 
                </Text> 

                <div className={styles["timeDiv"]}>
                  <ControlTimeSelector name={`endDateTime`} control={control} />

                </div> */}
              </div>
            </div>

            <div className={styles["eachSection-dates-availability"]}>
              <Text className={styles["heading"]}>
                {localeLabels.LABEL_BREAK}
              </Text>
              <div className={styles["selectDiv"]}>
                <ControlInput
                  control={control}
                  name="breakTimeInMin"
                  placeholder={localePlaceholders.PLACEHOLDERS_BREAK_TIME}
                  className={styles["inputField"]}
                  wrapClassName={styles["inputField-wrap"]}
                  type="number"
                  max={60 * 24}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className={styles["AddSchedule-buttons"]}>
        <Button
          loading={loading}
          className={styles["saveButton"]}
          onClick={handleSubmit(onConfirm)}
        >
          {localeButtons.BUTTON_SAVE}
        </Button>
        {width > breakPoints.md && (
          <Button className={styles["cancelButton"]} onClick={closeModal}>
            {localeButtons.BUTTON_CANCEL}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddSchedule;
