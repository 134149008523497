import { Text } from "components/atoms/TextF";
import styles from "./AddEducationModal.module.scss";
import { AddEducationModalProps } from "./@types";
import { useLocale } from "locales";
import { useNavigate } from "react-router-dom";
import { ControlInput } from "components/atoms/ControlledInput/Input";
import { Button } from "components/atoms/ButtonF";
import { useForm } from "react-hook-form";
import { Education } from "types/forms";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

const AddEducationModal = ({
  closeModal,
  onEdit,
  onConfirm,
  data,
}: AddEducationModalProps) => {
  const { localeErrors } = useLocale();
  const validationSchema: any = yup.object().shape({
    degreeName: yup
      .string()
      .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    school: yup.string().required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    startDate: yup
      .date()
      .typeError(localeErrors.ERROR_STARTING_DATE_REQUIRED)
      .required(localeErrors.ERROR_STARTING_DATE_REQUIRED),
    endDate: yup
      .date()
      .typeError(localeErrors.ERROR_ENDING_DATE_REQUIRED)
      .required(localeErrors.ERROR_ENDING_DATE_REQUIRED)
      .min(
        yup.ref("startDate"),
        localeErrors.ERROR_ENDING_DATE_BEFORE_STARTING_DATE
      ),
  });
  const { handleSubmit, control } = useForm<Education>({
    defaultValues: {
      ...data,
      startDate: dayjs(data?.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(data?.endDate).format("YYYY-MM-DD"),
    },
    resolver: yupResolver(validationSchema) as any,
  });

  const { localeTitles, localePlaceholders, localeLabels } = useLocale();

  return (
    <div className={styles["AddEducationModal"]}>
      <div className={styles["AddEducationModal-main"]}>
        <div className={styles["AddEducationModal-head"]} onClick={closeModal}>
          <Text className={styles["backText"]}>
            {data
              ? localeTitles.TITLE_EDIT_EDUCATION
              : localeTitles.TITLE_ADD_EDUCATION}
          </Text>
        </div>
        <div className={styles["AddEducationModal-sections"]}>
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_DEGREE_TITLE}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                borderClass=""
                name="degreeName"
                placeholder={localePlaceholders.PLACEHOLDERS_ENTER_DEGREE_TITLE}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="text"
              />
            </div>
          </div>
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_INSTITUE_NAME}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                borderClass=""
                name="school"
                placeholder={
                  localePlaceholders.PLACEHOLDERS_ENTER_INSTITUTE_NAME
                }
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="text"
              />
            </div>
          </div>
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_START_DATE}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                borderClass=""
                name="startDate"
                placeholder={localePlaceholders.PLACEHOLDERS_STARTING_DATE}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="date"
              />
            </div>
          </div>
          <div className={styles["eachSection"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_END_DATE}
            </Text>
            <div className={styles["each div"]}>
              <ControlInput
                control={control}
                borderClass=""
                name="endDate"
                placeholder={localePlaceholders.PLACEHOLDERS_ENDING_DATE}
                className={styles["inputField"]}
                wrapClassName={styles["inputField-wrap"]}
                type="date"
              />
            </div>
          </div>
        </div>

        <div className={styles["submitButton"]}>
          <Button
            className={"secondary-active"}
            onClick={data ? handleSubmit(onEdit) : handleSubmit(onConfirm)}
          >
            {data
              ? localeTitles.TITLE_EDIT_EDUCATION
              : localeTitles.TITLE_ADD_EDUCATION}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddEducationModal;
