import apiRequest from "../axios";
import { AxiosResponse } from "axios";

import dayjs from "dayjs";
import { useEffect, useState } from "react";

import DefaultImage from "../../assets/images/default.png";
import { UsersContextConsumer } from "../../store/AuthProvider";

// import * as fileType from 'file-type';

// interface FileType {
//   ext: string;
//   mime: string;
// }
// const useFileTypeFromUrl = (url: string) => {
//   const [fileTypeResult, setFileTypeResult] = useState<FileType | null>(null);

//   useEffect(() => {
//     const fetchFileAndGetType = async () => {
//       try {
//         const response = await fetch(url);
//         const arrayBuffer = await response.arrayBuffer();
//         const result = await fileType.fileTypeFromBuffer(arrayBuffer);

//         if (result) {
//           setFileTypeResult({
//             ext: result.ext,
//             mime: result.mime,
//           });
//         } else {
//           console.error('Unable to determine file type.');
//         }
//       } catch (error) {
//         console.error('Error fetching file:', error.message);
//       }
//     };

//     if (url) {
//       fetchFileAndGetType();
//     }
//   }, [url]);

//   return fileTypeResult;
// };

// export default useFileTypeFromUrl;


export const handleImagesPath = (path: string | null | undefined) => {
  if (path) {
    return getImageURL(path);
  } else {
    return DefaultImage;
  }
};
export const getTimeDuration = (createdAt: string) => {
  const currentTime = dayjs();
  const createdAtTime = dayjs(createdAt);

  const hoursAgo = currentTime.diff(createdAtTime, "hour");
  return hoursAgo;
};
export const getImageURL = (path: string | null | undefined) => {
  return `https://bear-document.s3.eu-central-1.amazonaws.com/${path}`;
};


export const useCustomScrollBehavior = (
  elementId = "root",
  addScroll = false
) => {
  useEffect(() => {
    const element = document.getElementById(elementId);

    if (!element) {
      console.error(`Element with ID ${elementId} not found.`);
      return;
    }

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        if (addScroll) element.style.overflowY = "scroll";
      } else {
        element.style.overflowY = "hidden";
      }
    };

    // Initial setup
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      // and also set the overflow back to default
      if (addScroll) element.style.overflowY = "auto";
    };
  }, [elementId]);

  return null;
};

export const getLables = (
  data: string,
  currentLocale: "en" | "du",
  type: "skills" | "categories" | "branches"
) => {
  const { skills, categories, branches } = UsersContextConsumer();
  switch (type) {
    case "skills":
      return skills?.find((skill) => skill._id === data)?.label?.[
        currentLocale
      ];
    case "categories":
      return categories?.find((category) => category._id === data)?.label?.[
        currentLocale
      ];
    case "branches":
      return branches?.find((branch) => branch._id === data)?.label?.[
        currentLocale
      ];
    default:
      return "";
  }
};


export const getTaskStatus = (
  data: string | undefined,
  // currentLocale: "en" | "du",
  localeLabels: any
) => {
  // status: "assigned" | "completed" | "inProgress" | "rejected";
  if (data === "assigned") {
    return localeLabels.LABEL_ASSIGNED.toUpperCase();
  } else if (data === "inProgress") {
    return localeLabels.LABEL_INPROGRESS.toUpperCase();
  } else if (data === "rejected") {
    return localeLabels.LABEL_REJECTED.toUpperCase();
  } else if (data === "approved") {
    return localeLabels.LABEL_APPROVED.toUpperCase();
  } else if (data === "declined") {
    return localeLabels.LABEL_DECLINED.toUpperCase();
  }  else if (data === "paused") {
    return localeLabels.LABEL_PAUSED.toUpperCase();
  } else {
    return localeLabels.LABEL_COMPLETED.toUpperCase();
  }
};

export interface ISSignedUrlResponse {
  status: string;
  message: string;
  signedUrl: string;
}


const ApiGetSignedUrls = async (
  fileKey: string
): Promise<ISSignedUrlResponse> => {
  try {
    const res = await apiRequest({
      method: "GET",
      url: `/get_pre_signed_url?fileKey=${fileKey}`,
    });
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};


export const uploadFile = async (file: File, fileKey: string) => {
  try {
    const res = await ApiGetSignedUrls(fileKey);
    console.log("res file", res);

    const response = await fetch(res?.signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
      redirect: "follow",
    });
    console.log("response file", response);
    return Promise.resolve({
      fileKey,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};
