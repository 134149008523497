import { useLocale } from "../../../../locales";

export const useFilterForm = () => {


  const StatusList = [];
  return {
    StatusList,
  };
};
