import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UsersContextConsumer } from "store/AuthProvider";
import { getHiredWorker } from "utils/globalAPI/OrganizationWorkers";

export const useHiredWorkers = () => {
  const { accessToken, fetchApi,appDataLoading, } = UsersContextConsumer();

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeCategory, setActiveCategory] = useState(
    searchParams.get("h") || "hired"
  );

  const hanCategoryChange = (value: string) => {
    setActiveCategory(value);
    setSearchParams({ h: value });
  };
  useEffect(() => {
    hanCategoryChange(searchParams.get("h") || "hired");
  }, [searchParams]);

  const params = useParams();
  const organization = params?.organization;

  const { data, isLoading,  } = useQuery(
    [
      "jobsDetails",
      {
        activeCategory,
        organization,
      },
    ],

    async () =>
      getHiredWorker(activeCategory as any, organization, accessToken),
    {
      enabled: fetchApi,

      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const navigate = useNavigate();
  const onClickCard = (hiredWorkerId: string) => {
    navigate(`/hired-workers/task/${hiredWorkerId}`);
  };

  return {
    hiredWorkersList: data?.hiredWorkersList || [],
    onClickCard,
    activeCategory,
    hanCategoryChange,
    isLoading,
    appDataLoading
  };
};
