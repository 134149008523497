export default {
  "Job Invitation": "Job Invitation",
  "Profile Verification": "Profile Verification",
  "Job Status Changed": "Your job status is changed",
  "Task Request": "Task Request",
  job_completed: "Job Completed",
  job_contract_cancelled: "Job Contract Cancelled",
  job_contract_paused: "Job Contract Paused",
  job_contract_resumed: "Job Contract Resumed",
  job_contract_completed: "Job Contract Completed",
  "Organization has given you rating and review":
    "Organization has given you rating and review",
  "You are rated on a job": "You are rated on a job",
  "Job Completed": "Job Completed",
  "Job Cancelled": "Job Cancelled",
  "Job Paused": "Job Paused",
  "Job Resumed": "Job Resumed",
  "Hourly Rate Change": "Hourly Rate Change",
  "Rpeated a contract": "Rpeated a contract",
  "Organization has completed the job and given you rating and review":
    "Organization has completed the job and given you rating and review",
  "Organization has cancelled the job": "Organization has cancelled the job",
  "Organization has paused the job": "Organization has paused the job",
  "Organization has resumed the job": "Organization has resumed the job",
  "Organization change your hourly rate":
    "Organization change your hourly rate",
  "Organization has started a new job contract.":
    "Organization has started a new job contract.",
  "Job Proposal Received": "Job Proposal Received.",
  "Job Proposal": "Job Proposal",
  "Invitation Accepted": "Invitation Accepted",
  "Invitation Rejected": "Invitation Rejected",
  Hired: "Hired",
  genralNotificaitionMessageJobPosting:
    "New Job posted by user in a  branch where job posting check is enabled. Click here to see the job details.",
  "Hired For A Job": "Congrats! You have been hired.",
};
