import { AxiosResponse } from "axios";
import apiRequest from "../../axios";
import { getApplicationDataResponse } from "../../../types/ApiResponse";
import { AddFormField } from "../../../services/BranchesServices/hook";
import { CategoryFormField } from "../../../services/CategoriesServices/hook";

export const getApplicationData =
  async (): Promise<getApplicationDataResponse> => {
    const response = await apiRequest({
      method: "get",
      url: `/admin/application/data`,
    });
    return response;
  };

// Branch API
export const APIAddBranch = async (
  data: AddFormField,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "post",
    url: `/admin/appdata/branch`,
    data,
    token
  });
  return response;
};


export const getOrganizartionAllowedBaranches = async (
  organizationId: string,
  token: string
) => {
  const response = await apiRequest({
    method: "get",
    url: `/admin/appdata/organization/branches/${organizationId}`,
    token
  });
  return response;
};

export const APIDeleteBranch = async (
  id: string,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "delete",
    url: `/admin/appdata/branch/${id}`,
    token
  });
  return response;
};

export const APIEditBranch = async (
  id: string,
  data: AddFormField,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "put",
    url: `/admin/appdata/branch/${id}`,
    data,
    token
  });
  return response;
};
// Branch API

// Skill API
export const APIAddSkill = async (
  data: AddFormField,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "post",
    url: `/admin/appdata/skill`,
    data,
    token
  });
  return response;
};

export const APIDeleteSkill = async (
  id: string,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "delete",
    url: `/admin/appdata/skill/${id}`,
    token
  });
  return response;
};

export const APIEditSkill = async (
  id: string,
  data: AddFormField |any,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "put",
    url: `/admin/appdata/skill/${id}`,
    data,
    token
  });
  return response;
};

// Skill API

// Category API
export const APIAddCategory = async (
  data: CategoryFormField,
  token: string
): Promise<getApplicationDataResponse> => {
  console.log("APIAddCategory", data);
  const response = await apiRequest({
    method: "post",
    url: `/admin/appdata/category`,
    data,
    token
  });
  return response;
};

export const APIDeleteCategory = async (
  id: string,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "delete",
    url: `/admin/appdata/category/${id}`,
    token
  });
  return response;
};

export const APIEditCategory = async (
  id: string,
  data: CategoryFormField,
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "put",
    url: `/admin/appdata/category/${id}`,
    data,
    token
  });
  return response;
};
export const ApiTogglePriceCategory = async (
  data: {
    price: boolean;
    categoryId:string
  },
  token: string
): Promise<getApplicationDataResponse> => {
  const response = await apiRequest({
    method: "put",
    url: `/admin/appdata/category/price/toggle`,
    data,
    token
  });
  return response;
};


export const ApiToggleOrganizationJobPOsting = async (
  data: any,
  organization:string,
  token: string
) => {
  const response = await apiRequest({
    method: "put",
    url: `/Admin/appdata/jobPostingCheck/${organization}`,
    token,
    data,
  });

  return response;
};
