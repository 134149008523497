import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

import React, { MouseEventHandler } from "react";
type Props = {
  sameSize?: boolean;
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function DocumentTextIcon({ color, size, onClick, sameSize }: Props) {

  return (
    <svg
      width={window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"}
      height={
        window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"
      }
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 13H13"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 17H11"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DocumentTextIcon;

// fill={white ? "#fffff" : "#0F1322"}
