import type { AvatarWithIconProps } from "./@types";
import styles from "./AvatarWithIcon.module.scss";
import { Img } from "components/atoms/Img";
import { Controller } from "react-hook-form";
import Dropzone from "react-dropzone";
import EditPencilProfileIcon from "assets/svgs/EditPencilProfileIcon";
import { ErrorMessage } from "components/atoms/ErrorMessage";
import { handleImagesPath } from "utils/hooks/helper";

const AvatarWithIcon = ({
  control,
  ImageData,
  profileImage,
}: AvatarWithIconProps) => {

  return (
    <Controller
      name={"profileImage"}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <>
          <Dropzone
            multiple={false}
            onDrop={(acceptedFiles) => {
              //  
              onChange(acceptedFiles[0]);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles["uploadCard"]}>
                <input {...getInputProps()} />

                <div className={styles["drop-zone"]}>

                    <div className={styles["uploadImg-div"]}>
                      <Img
                        src={typeof value ==="string"?handleImagesPath(value):URL.createObjectURL(
                          ImageData as Blob | MediaSource
                        )}
                        className={styles["uploadImg"]}
                      />
                      <div className={styles["editIcon"]}>
                        <EditPencilProfileIcon />
                      </div>
                    </div>

                </div>
                {!!errors && (
                  <ErrorMessage
                    errors={errors?.["profileImage"]?.message as string}
                  />
                )}
              </div>
            )}
          </Dropzone>
        </>
      )}
    />
  );
};

export default AvatarWithIcon;
