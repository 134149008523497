import { UsersContextConsumer } from "../../../../store/AuthProvider";
import React from "react";
import { useForm } from "react-hook-form";

export const useLayout = () => {
  const { reset, control, handleSubmit } = useForm<{
    search: string;
    by: string;
  }>({
    defaultValues: {
      search: "",
      by: "name",
    },
  });

  const [show, setShow] = React.useState(false);
  const [editAccountModal, setEditAccountModal] = React.useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = React.useState(false);

  const dashboardPath = "/";

  const onEditAccount = () => {
    setEditAccountModal(!editAccountModal);
  };
  const onUpdatePassword = () => {
    setUpdatePasswordModal(!updatePasswordModal);
  };

  const { notifications, onSubmitSearch } = UsersContextConsumer();

  // console.log("adminData", adminData);
  return {
    setShow,
    dashboardPath,
    onEditAccount,
    onUpdatePassword,
    notifications,
    handleSubmit,
    control,
    onSubmitSearch,
  };
};
