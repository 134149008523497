import { ReactNode, useEffect, useState } from "react";
import { createCtx } from "../utils";
import { adminAuthContext } from "./@types";
import { useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useQuery } from "react-query";
import { getApplicationData } from "../../utils/globalAPI/applicationData";
import {
  ApiGetAccessToken,
  ApiGetNotifications,
} from "../../utils/globalAPI/Dashboard";
import { searchFormField } from "../../types/FormFields";
import { useLocale } from "locales";
import { IAddress } from "types/schema";

export const [useAuth, SetAuthProvider] = createCtx<adminAuthContext>();

const useAuthCtx = () => {
  const [searchParams, setSerachParams] = useSearchParams({
    keyword: "",
    searchBy: "",
  });

  const [searchKeyword, setSearchKeyword] = useState<string>(
    searchParams.get("keyword") || ""
  );
  const [searchBy, setSearchBy] = useState<string>(
    searchParams.get("searchBy") || "name"
  );

  const [organizationAddress, setOrganizationAddress] = useState<
    IAddress[] | null
  >(null);

  const setAddress = (addres: IAddress[]) => {
    // save locally 
    setOrganizationAddress(addres);
    localStorage.setItem("organizationAddress", JSON.stringify(addres));
  };

  const getAddress = () => {
    // get from local storage
    const address = localStorage.getItem("organizationAddress");
    if (address) {
      setOrganizationAddress(JSON.parse(address));
    }
  };

  useEffect(()=>{
    if(!organizationAddress)
      getAddress()
  },[])

  const [adminData, setAdminData] = useState<string | null>(null);

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const { data: { notifications } = ({} = {}) } = useQuery(
    [
      "notifications",
      {
        accessToken: adminData,
      },
    ],
    async () => ApiGetNotifications(adminData),
    {
      enabled: !!adminData,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const {
    data: { branches, categories, skills, dropdowns } = {
      branches: [],
      categories: [],
      skills: [],
    },
    isLoading: appDataLoading,
    refetch: refetchAppAData,
  } = useQuery("application", getApplicationData, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const onSubmitSearch = async (data: searchFormField) => {
    setSerachParams({
      keyword: data?.search,
      searchBy: data?.by,
    });
    setSearchKeyword(data?.search);
    setSearchBy(data?.by);
  };

  const { localeLabels } = useLocale();

  const days = [
    {
      value: "Monday",
      label: {
        en: localeLabels.LABEL_MONDAY,
        du: localeLabels.LABEL_MONDAY,
      },
      _id: "Monday",
    },
    {
      value: "Tuesday",
      label: {
        en: localeLabels.LABEL_TUESDAY,
        du: localeLabels.LABEL_TUESDAY,
      },
      _id: "Tuesday",
    },
    {
      value: "Wednesday",
      label: {
        en: localeLabels.LABEL_WEDNESDAY,
        du: localeLabels.LABEL_WEDNESDAY,
      },

      _id: "Wednesday",
    },
    {
      value: "Thursday",
      label: {
        en: localeLabels.LABEL_THURSDAY,
        du: localeLabels.LABEL_THURSDAY,
      },
      _id: "Thursday",
    },
    {
      value: "Friday",
      label: {
        en: localeLabels.LABEL_FRIDAY,
        du: localeLabels.LABEL_FRIDAY,
      },
      _id: "Friday",
    },
    {
      value: "Saturday",
      label: {
        en: localeLabels.LABEL_SATURDAY,
        du: localeLabels.LABEL_SATURDAY,
      },
      _id: "Saturday",
    },
    {
      value: "Sunday",
      label: {
        en: localeLabels.LABEL_SUNDAY,
        du: localeLabels.LABEL_SUNDAY,
      },
      _id: "Sunday",
    },
  ];

  return {
    adminData,
    setAdminData,

    signOut,
    branches,
    skills,
    categories,
    notifications,
    dropdowns,
    appDataLoading,
    refetchAppAData,
    onSubmitSearch,
    accessToken: adminData,
    fetchApi: adminData?.length > 0,

    searchBy,
    searchKeyword,

    days,

    organizationAddress,
    setAddress
  };
};

const AuthAdminProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuthCtx();
  return <SetAuthProvider value={auth}>{children}</SetAuthProvider>;
};

export const UsersContextConsumer = () => {
  const auth = useAuth();
  return auth;
};

export default AuthAdminProvider;
