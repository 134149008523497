import { useEffect, useRef, useState } from "react";
import { Text } from "components/atoms/TextF";
import styles from "./CompanyWorkersView.module.scss";
import { CompanyWorkersViewProps } from "./@types";
import { useLocale } from "locales";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Img } from "components/atoms/Img";
// import  {GantChart}  from "./Gantt.Chart";
import { handleImagesPath } from "utils/hooks/helper";
import { UserId } from "types/schema";
import { Loader } from "components/molecules/Loader/Loader";
import { Button } from "components/atoms/ButtonF";
import { BsFullscreenExit } from "react-icons/bs";

const CompanyWorkersView = ({
  tasksList,
  workerLoading,
  workers,
  refetchTasks,
  view,
  haveTasks,
  fullScreen,
  setFullScreen,
}: CompanyWorkersViewProps) => {
  const chartContainer = useRef<HTMLDivElement>(null);

  const { hiredWorkerId } = useParams();

  const [taskDetail, setShowTaskDetail] = useState<boolean>(false);
  const [selectedWorker, setSelectedWorker] = useState<UserId | null>(null);
  useEffect(() => {
    if (!selectedWorker) {
      const selWo =
        workers?.find((worker) => worker?._id === hiredWorkerId) || null;
      if (selWo) setSelectedWorker(selWo);
    }
  }, [hiredWorkerId, workers]);

  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (chartContainer.current) {
      setHeight(chartContainer.current.clientHeight - 140);
    }
  }, [chartContainer, fullScreen]);

  return (
    <div className={styles["month-view"]}>
      <WorkerPannel
        className={styles["pc-left"]}
        workers={workers}
        hiredWorkerId={hiredWorkerId}
        workerLoading={workerLoading}
        setSelectedWorker={setSelectedWorker}
      />
      <div
        ref={chartContainer}
        className={`${styles["datesContainer-right"]} ${
          hiredWorkerId && fullScreen ? styles["full-screen"] : ""
        }`}
      >
        {fullScreen && (
          <div className="flex flex-row justify-between w-full">
            <div
              className={`${styles["workerContainer"]} ${
                hiredWorkerId === selectedWorker?._id &&
                styles["focused-worker"]
              }`}
            >
              <Img
                src={handleImagesPath(selectedWorker?.profileImage)}
                alt={selectedWorker?.firstName}
                className={styles["worker-image"]}
              />
              <Text
                className={`${styles["worker-name"]} ${
                  hiredWorkerId === selectedWorker?._id &&
                  styles["focused-worker-name"]
                }`}
              >{`${selectedWorker?.firstName} ${selectedWorker?.lastName}`}</Text>
            </div>
            <Button
              className={
                taskDetail ? styles["primaryBtn"] : styles["secondaryBtn"]
              }
              onClick={() => setShowTaskDetail(!taskDetail)}
            >
              ShowTask Detail
            </Button>
            <Button
              className={
                fullScreen ? styles["primaryBtn"] : styles["secondaryBtn"]
              }
              onClick={() => setFullScreen(!fullScreen)}
              leftIcon={
                <BsFullscreenExit
                  size={25}
                  color={fullScreen ? "#fff" : "#000"}
                />
              }
            ></Button>
          </div>
        )}
        <Outlet
          context={{
            height: height,
            tasksList,
            workers,
            hiredWorkerId,
            workerLoading,
            refetchTasks,
            haveTasks,
            view,
            fullScreen,
            selectedWorker,
            setSelectedWorker,
            taskDetail: taskDetail && fullScreen,
          }}
        />
      </div>
    </div>
  );
};

export const WorkerPannel = ({
  workers,
  hiredWorkerId,
  className,
  workerLoading,
  fullScreen,
  setSelectedWorker,
}: {
  workers?: UserId[];
  hiredWorkerId?: string;
  className?: string;
  workerLoading?: boolean;
  fullScreen?: boolean;
  setSelectedWorker: React.Dispatch<React.SetStateAction<UserId | null>>;
}) => {
  const { localeLabels, localeBasics } = useLocale();
  const navigate = useNavigate();
  const handleOnClickNavigate = (path: string) => {
    navigate(path);
  };

  if (workerLoading) {
    return (
      <div className={`${className} ${styles["datesContainer-left-loading"]}`}>
        <Loader />
      </div>
    );
  }

  const organization = useParams()?.organization

  return (
    <div className={`${className} ${styles["datesContainer-left"]}`}>
      {/* <div className={styles["weekLabel"]}> */}
      <Text className={styles["workerHeading"]}>
        {localeLabels.LABEL_WORKERS}
      </Text>
      <div className={styles["workers-col"]}>
        {/* Hire SOmeone */}
        {workers?.length == 0 && (
          <Text
            className={`${styles["worker-name"]} ${
              hiredWorkerId === "-1" && styles["focused-worker-name"]
            }`}
          >{`${localeBasics.BASIC_HIRE_SOMEONE}`}</Text>
        )}

        {workers?.map((worker, index) => (
          <div
            onClick={() => {
              setSelectedWorker(worker);
              handleOnClickNavigate(`/schedule/${organization}/${worker?._id}`);
            }}
            key={index}
            className={`${styles["workerContainer"]} ${
              hiredWorkerId === worker?._id && styles["focused-worker"]
            }`}
          >
            <Img
              src={handleImagesPath(worker?.profileImage)}
              alt={worker?.firstName}
              className={styles["worker-image"]}
            />
            <Text
              className={`${styles["worker-name"]} ${
                hiredWorkerId === worker?._id && styles["focused-worker-name"]
              }`}
            >{`${worker.firstName} ${worker.lastName}`}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyWorkersView;
