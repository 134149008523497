import { workerTypeOption } from "types/Gloabal";
import apiRequest from "utils/axios";
import { GetOrganizationWorkerResponse, IWorkerResponse } from "./types";
import { INVITE_FORM } from "types/forms";
import dayjs from "dayjs";

export const getHiredWorker = async (
  workerStatus: "hired" | "completed" | "cancelled",
  organization: string,
  token: string
) => {
  const response = await apiRequest({
    method: "put",
    url: `/organization/worker/admin?workerStatus=${workerStatus}&organization=${organization}`,
    token,
  });
  return response;
};

export const getFindWorkersAPI = async (
  data: {
    alreadyHired?: boolean;
    organizationId?: string;
    query: string;
    jobId: string;
    workerType?: workerTypeOption;
  },
  offset: number,
  token: string
): Promise<GetOrganizationWorkerResponse> => {
  console.log("getFindWorkersAPI", data, "token", token);
   ;
  let url = `/organization/worker/find/admin?`;
  // let url = "/organization/worker/find/worker?";
  if (data.query) url += `query=${data.query}&`;
  if (data.workerType) url += `workerType=${data.workerType}&`;
  if (data.alreadyHired) url += `organizationId=${data.organizationId}&`;
  if (data.jobId) url += `jobId=${data.jobId}&`;

  url += `offset=${offset}&`;
  //  
  const response = await apiRequest({
    method: "get",
    url: url,
    token,
  });
  //
  return response;
};

export const HireWorkerApi = async (
  jobId: string | undefined,
  workerId: string | undefined,
  organizationId: string,
  token: string
): Promise<any> => {
  console.log("ge applicants", jobId, "token", token);

  const response = await apiRequest({
    method: "post",
    url: `organization/job/${jobId}/hire/${workerId}/admin?organizationId=${organizationId}`,

    token,
  });
  return response;
};

export const InviteWorkerAPI = async (
  data: INVITE_FORM,
  token: string
): Promise<IWorkerResponse> => {
  const response = await apiRequest({
    method: "post",
    url: `/organization/Job/Invite/admin`,
    token,
    data,
  });
  return response;
};

// /getWorkerDetailsAPI

export const getWorkerDetailsAPI = async (
  worker: string,
  organization: string,
  token: string
): Promise<IWorkerResponse> => {
  const response = await apiRequest({
    method: "get",
    url: `/organization/worker/${worker}/admin?date=${dayjs().format("YYYY-MM-DD")}&organization=${organization}`,
    token,
  });
  return response;
};
