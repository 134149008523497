import { Text } from "components/atoms/TextF";
import styles from "./ConfirmationModal.module.scss";
import { ConfirmationModalProps } from "./@types";

import { Button } from "components/atoms/ButtonF";

const ConfirmationModal = ({
  subTitle,
  icon,
  closeModal,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  loading,
  title,
}: ConfirmationModalProps) => {
  return (
    <div
      className={styles["ConfirmationModal"]}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {title && <Text className={styles["title"]}>{title}</Text>}

      {icon && <div className={styles["ConfirmationModal-icon"]}>{icon}</div>}

      <Text className={styles["sub-title"]}>{subTitle}</Text>

      <div className={styles["ConfirmationModal-buttons"]}>
        <Button
          loading={loading}
          className={"secondary-active"}
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
        <Button className={"primary"} onClick={closeModal}>
          {cancelButtonText}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
