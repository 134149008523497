import React, { useEffect, useRef, useState } from "react";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import styles from "./JobPostingSection.module.scss";
import { JobPostingSectionProps } from "./@types";
import { useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import DocumentTextIcon from "assets/svgs/DocumentTextIcon";
import {
  availabilityScheduleSowOptions,
  jobTypeOptions,
  postJobOptionsFeatures,
  repeatWeekOptions,
} from "constants/constantData";
import { MdOutlineViewWeek } from "react-icons/md";
import { TextArea } from "components/atoms/TextAreaF";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import CreditCardIcon from "assets/svgs/CreditCardIcon";

import CalendarAddIcon from "assets/svgs/CalendarAddIcon";
import TimerIcon from "assets/svgs/TimerIcon";
import GasStationIcon from "assets/svgs/GasStationIcon";
import ProfileIcon from "assets/svgs/ProfileIcon";
import HangerIcon from "assets/svgs/HangerIcon";
import LocationIcon from "assets/svgs/LocationIcon";
import { ControlInput } from "components/atoms/ControlledInput/Input";
import { useJobPosting } from "./hook";
import ControlSelect from "components/molecules/ControlSelect/Select";
import PencilIcon from "assets/svgs/PencilIcon";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import { ErrorMessage } from "components/atoms/ErrorMessage";
import { ControlAutoCompleteInput } from "components/atoms/ControlledAutCompleteGoogle/Input";
import { UsersContextConsumer } from "store/AuthProvider";
import ThreeDCubeIcon from "assets/svgs/ThreeDCubeIcon";

import CategorySelect from "components/molecules/CategorySelect";
import { WiNightCloudyHigh } from "react-icons/wi";
import { BsArrowRepeat } from "react-icons/bs";

import ShirtFoldIcon from "assets/svgs/ShirtFoldIcon";
import ParkingIcon from "assets/svgs/ParkingIcon";
import { ControlTimeSelector } from "components/atoms/TimeSelector/TimeSelector";
import { AddNewLocation } from "./AddNewLocation";
import ReactSelect from "react-select";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import dayjs from "dayjs";
import { useWidth } from "utils/responsiveHook";

const JobForm = (props: JobPostingSectionProps) => {
  // const { register } = useFormContext();

  const navigate = useNavigate();
  const {
    localeBasics,
    localeTitles,
    localePlaceholders,
    localeButtons: localeButtons,
    localeLabels,
  } = useLocale();
  const {
    control,
    handleSubmit,
    setValue,
    errors,
    watch,
    address,
    addressDefaulValues,
    isLoading,
  } = useJobPosting(props?.defaulValues);
  const { dropdowns, days } = UsersContextConsumer();

  // we need to get address of organization

  const { width } = useWidth();

  const onSubmitConfirm = (data: any) => {
    console.log("data", data);
    props.onSubmit({
      ...data,
      repeatedDays: data?.repeatedDays?.map((item: any) => item?.value),
    });
  };

  useEffect(() => {
    if (
      watch("branches")
        ?.map((item) => item?._id)
        .includes("666c1907fe69134bb470c490")
    ) {
      setValue("shift", "day");
    }
  }, [watch("branches")]);

  return (
    <div className={styles["JobPostingSection"]}>
      <div className={styles["JobPostingSection-head"]}>
        <Text className={styles["title"]}>
          {!props.isEdit
            ? localeTitles.TITLE_POST_A_NEW_JOB
            : localeTitles.TITLE_EDIT_JOB}
        </Text>

        <div className={styles["JobPostingSection-head-right"]}>
          <Button className="primary" onClick={() => navigate(-1)}>
            {localeButtons.BUTTON_CANCEL}
          </Button>
          <Button
            className={
              Object.keys(errors).length > 0
                ? "delete-button"
                : "secondary-active"
            }
            type="submit"
            onClick={handleSubmit(onSubmitConfirm)}
            loading={props.loading}
          >
            {props.isEdit
              ? localeButtons.BUTTON_EDIT_NOW
              : localeButtons.BUTTON_POST_NOW}
          </Button>
        </div>
      </div>
      <div className={styles["JobPostingSection-main"]}>
        {/* <div className={styles["JobPostingSection-left"]}>
          <div
            className={styles["left-item"]}
            // onClick={() => scrollToRecentSection()}
            // onClick={() => handleItemClick("ENTER_DETAILS")}
          >
            <DocumentTextIcon color={"#101828"} />
            <Text className={`${styles["selected"]}`}>
              {localeTitles.TITLE_ENTER_DETAILS}
            </Text>
          </div>
        </div> */}
        <div className={styles["JobPostingSection-right"]}>
          <form
            onSubmit={handleSubmit(onSubmitConfirm)}
            className={styles["formDiv"]}
          >
            <div className={styles["right-section-recent"]}>
              <Text className={styles["section-title"]}>
                {localeTitles.TITLE_WORKER_DETAILS}
              </Text>
              <div className={styles["JobPostingSection-right-section"]}>
                <div className={styles["section-list"]}>
                  <Controller
                    name={"workerType"}
                    control={control}
                    render={({
                      field: { onChange, value, ref },
                      formState: { errors },
                    }) => (
                      <>
                        {postJobOptionsFeatures(localeTitles).map((option) => (
                          <div
                            className={styles["preferenceDiv"]}
                            onClick={() => onChange(option.value)}
                          >
                            <Img
                              src={option?.icon}
                              className={styles["optionIcon"]}
                            />
                            <Text
                              key={option.value}
                              className={styles["select-field"]}
                            >
                              {option.value === value ? (
                                <AiFillCheckCircle size={24} type="radio" />
                              ) : (
                                <BsCircle size={24} type="radio" />
                              )}

                              {option.label}
                            </Text>
                          </div>
                        ))}
                        {!!errors && (
                          <ErrorMessage
                            errors={errors?.["workerType"]?.message as string}
                          />
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>

            <div
              className={styles["right-section-each"]}
              // ref={branchesAndCategoriesSectionRef}
            >
              <Text className={styles["section-title"]}>
                {localeTitles.TITLE_BRANCHES_AND_CATEGORIES}
              </Text>
              <div className={styles["JobPostingSection-right-section"]}>
                <CategorySelect
                  multiSelect={true}
                  name="branches"
                  control={control}
                  list={dropdowns?.branches}
                  // maxLimit={3}
                  Icon={<ThreeDCubeIcon />}
                />
                <CategorySelect
                  multiSelect={true}
                  name="categories"
                  control={control}
                  list={
                    dropdowns?.categories?.filter((item) =>
                      watch("branches")?.find(
                        (branch) => branch._id === item.branchId
                      )
                        ? true
                        : false
                    ) || []
                  }
                  Icon={<ThreeDCubeIcon />}
                />
              </div>
            </div>

            <div className={styles["right-section-recent"]}>
              <Text className={styles["section-title"]}>
                {localeTitles.TITLE_ENTER_DETAILS}
              </Text>
              <div className={styles["JobPostingSection-right-section"]}>
                <div className={styles["profile-fields"]}>
                  {/* <div className={styles["ReactSelectDiv"]}> */}
                  <ControlSelect
                    name={"hiringUrgencyLevel"}
                    control={control}
                    list={jobTypeOptions(localeLabels)}
                    Icon={<PencilIcon />}
                  />
                  {/* </div> */}
                  <ControlInput
                    control={control}
                    name={"title"}
                    placeholder={localePlaceholders.PLACEHOLDERS_JOB_NAME}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    borderClass=""
                    prefix={<DocumentTextIcon color={"#858D98"} sameSize />}
                  />
                </div>

                <div className={styles["warning-div"]}>
                  {watch("hiringUrgencyLevel") === "soon"
                    ? localeBasics?.BASIC_SOON_WARNING
                    : watch("hiringUrgencyLevel") === "urgent"
                    ? localeBasics?.BASIC_URGENT_WARNING
                    : ""}
                </div>

                <TextArea
                  control={control}
                  name="jobDescription"
                  placeholder={localePlaceholders.PLACEHOLDERS_JOB_DESCRIPTION}
                  wrapClassName={styles["textArea"]}
                  className={styles["inputField-textArea"]}
                  prefix={<DocumentTextIcon color={"#858D98"} sameSize />}
                  // wrapClassName={styles["inputField-wrap"]}
                ></TextArea>

                <div className={styles["profile-fields"]}>
                  <Controller
                    name={"shift"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      formState: { errors },
                    }) => (
                      <div className={styles["parking"]}>
                        <div className={styles["parkingDiv"]}>
                          <div className={styles["parkingDiv-left"]}>
                            <WiNightCloudyHigh color={"#858D98"} size={20} />
                            <Text className={styles["labelText1"]}>
                              {localeTitles.TITLE_WORKING_SHIFT}
                            </Text>
                          </div>
                          <div className={styles["optionsSection"]}>
                            <div
                              className={styles["select-field-parking"]}
                              onClick={() => {
                                if (
                                  !watch("branches")
                                    ?.map((item) => item?._id)
                                    .includes("666c1907fe69134bb470c490")
                                )
                                  onChange("night");
                              }}
                            >
                              {value === "night" ? (
                                <AiFillCheckCircle size={24} type="radio" />
                              ) : (
                                <BsCircle size={24} type="radio" />
                              )}
                              <label
                                className={styles["labelText1"]}
                                htmlFor="yes"
                              >
                                {localeButtons.BUTTON_NIGHT_SHIFT}
                              </label>
                            </div>
                            <div
                              className={styles["select-field-parking"]}
                              onClick={() => onChange("day")}
                            >
                              {value === "day" ? (
                                <AiFillCheckCircle size={24} type="radio" />
                              ) : (
                                <BsCircle size={24} type="radio" />
                              )}
                              <label
                                className={styles["labelText1"]}
                                htmlFor="no"
                              >
                                {localeButtons.BUTTON_DAY_SHIFT}
                              </label>
                            </div>
                          </div>
                        </div>
                        {!!errors && (
                          <ErrorMessage
                            errors={errors?.shift?.message as string}
                          />
                        )}
                      </div>
                    )}
                  />

                  <ControlInput
                    control={control}
                    name="startDate"
                    borderClass=""
                    placeholder={localePlaceholders.PLACEHOLDERS_STARTING_DATE}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="date"
                    prefix={<CalendarAddIcon color={"#858D98"} sameSize />}
                  />

                  {watch("shift") === "night" && (
                    <ControlInput
                      control={control}
                      name="endDate"
                      borderClass=""
                      placeholder={localePlaceholders.PLACEHOLDERS_ENDING_DATE}
                      preDefinedClassName="inputField"
                      preDefinedWrapClassName="inputField-wrap"
                      type="date"
                      // max date must be greater than start date and its only one day after
                      max={dayjs(watch("startDate"))
                        .add(1, "day")
                        .format("YYYY-MM-DD")}
                      min={dayjs(watch("startDate")).format("YYYY-MM-DD")}
                      prefix={<CalendarAddIcon color={"#858D98"} sameSize />}
                    />
                  )}
                  <ControlTimeSelector
                    name="timeToStart"
                    control={control}
                    timeFormate="dd-MM-YYYY HH:mm"
                    // prefix={<CalendarAddIcon color={"#858D98"} sameSize />}
                    placeholder={localePlaceholders.PLACEHOLDERS_START_TIME}
                  />
                  <ControlTimeSelector
                    name="timeToEnd"
                    control={control}
                    timeFormate="dd-MM-YYYY HH:mm"
                    // prefix={<CalendarAddIcon color={"#858D98"} sameSize />}
                    placeholder={localePlaceholders.PLACEHOLDERS_END_TIME}
                  />

                  <ControlInput
                    control={control}
                    name="breakTime"
                    borderClass=""
                    placeholder={localePlaceholders.PLACEHOLDERS_BREAK_TIME}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="number"
                    prefix={<TimerIcon color={"#858D98"} sameSize />}
                  />

                  <Controller
                    name={"parkingOptions"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      formState: { errors },
                    }) => (
                      <div className={styles["parking"]}>
                        <div className={styles["parkingDiv"]}>
                          <div className={styles["parkingDiv-left"]}>
                            <ParkingIcon />
                            <Text className={styles["labelText"]}>
                              {localeTitles.TITLE_FREE_PARKING}
                            </Text>
                          </div>
                          <div className={styles["optionsSection"]}>
                            <div
                              className={styles["select-field-parking"]}
                              onClick={() => onChange("free")}
                            >
                              {value === "free" ? (
                                <AiFillCheckCircle size={24} type="radio" />
                              ) : (
                                <BsCircle size={24} type="radio" />
                              )}
                              <label
                                className={styles["labelText"]}
                                htmlFor="yes"
                              >
                                {localeButtons.BUTTON_YES}
                              </label>
                            </div>
                            <div
                              className={styles["select-field-parking"]}
                              onClick={() => onChange("paid")}
                            >
                              {value === "paid" ? (
                                <AiFillCheckCircle size={24} type="radio" />
                              ) : (
                                <BsCircle size={24} type="radio" />
                              )}
                              <label
                                className={styles["labelText"]}
                                htmlFor="no"
                              >
                                {localeButtons.BUTTON_NO}
                              </label>
                            </div>
                          </div>
                        </div>
                        {!!errors && (
                          <ErrorMessage
                            errors={errors?.parkingOptions?.message as string}
                          />
                        )}
                      </div>
                    )}
                  />

                  <ControlSelect
                    name={`workerAvailability`}
                    control={control}
                    list={availabilityScheduleSowOptions(localeLabels)}
                    Icon={<PencilIcon />}
                  />

                  {watch("workerType") !== "employee" &&
                    watch("categories")?.filter((item) => !item.price).length === 0 && 
                    (
                      <ControlInput
                        control={control}
                        name="wage"
                        borderClass=""
                        placeholder={
                          localePlaceholders.PLACEHOLDERS_MONTHLY_SALARY_PRICE_PER_HOUR
                        }
                        preDefinedClassName="inputField"
                        preDefinedWrapClassName="inputField-wrap"
                        type="text"
                        prefix={<CreditCardIcon color={"#858D98"} sameSize />}
                      />
                    )}
                  <ControlInput
                    control={control}
                    borderClass=""
                    name="travelExpenses"
                    placeholder={localePlaceholders.PLACEHOLDERS_TRAVEL_EXPENSE}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<GasStationIcon color={"#858D98"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    name="workerNeeded"
                    borderClass=""
                    placeholder={localePlaceholders.PLACEHOLDERS_WORKERS_NEEDED}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="number"
                    prefix={<ProfileIcon color={"#858D98"} />}
                  />

                  <ControlInput
                    control={control}
                    name="cloths"
                    borderClass=""
                    placeholder={localePlaceholders.PLACEHOLDERS_CLOTHES}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<HangerIcon color={"#858D98"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""
                    name="clothsDescription"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_CLOTHES_DESCRIPTION
                    }
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<ShirtFoldIcon color={"#858D98"} sameSize />}
                  />

                  <div className="flex flex-col justify-start items-start">
                    {watch("newLocation") ? (
                      <ControlAutoCompleteInput
                        control={control}
                        name="address"
                        placeholder={localePlaceholders.PLACEHOLDERS_ADDRESS}
                        preDefinedClassName="inputField"
                        preDefinedWrapClassName="inputField-wrap"
                        type="text"
                        prefix={<LocationIcon color={"#858D98"} size={16} />}
                        onChangeCoordinates={(
                          coordinates,
                          formatedAddress,
                          postal_code,
                          street,
                          city,
                          country
                        ) => {
                          setValue(
                            `addNewAddress.location.coordinates`,
                            coordinates
                          );
                          setValue("addNewAddress.address", formatedAddress);
                          if (postal_code) {
                            setValue(`addNewAddress.zip`, postal_code);
                          }
                          if (street) {
                            setValue(`addNewAddress.street`, street);
                          }
                          if (city) {
                            setValue(`addNewAddress.city`, city);
                          }
                          if (country) {
                            setValue(`addNewAddress.country`, country);
                          }
                        }}
                      />
                    ) : isLoading ? null : (
                      <>
                        <ReactSelect
                          defaultValue={addressDefaulValues}
                          options={address?.map((item: any) => ({
                            label: item?.addressTitle
                              ? item?.addressTitle
                              : item?.street + " " + item?.city,
                            value: item?._id,
                          }))}
                          onChange={(value: any) => {
                            setValue("address", value?.value + "");
                          }}
                          styles={{
                            indicatorsContainer: (provided) => ({
                              ...provided,
                            }),
                            control: (provided, state) => ({
                              // only update border-radius to 40px
                              ...provided,
                              borderRadius: 40,
                              borderWidth: 0,
                              height: "100%",
                            }),
                            menu: (base) => ({
                              ...base,
                              zIndex: 1000,
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#fff",
                              color: "black",
                            }),
                          }}
                          className={`${styles["inputField-wrap"]}`}
                        />
                        {errors?.address?.message && (
                          <ErrorMessage
                            errors={errors?.address?.message as string}
                          />
                        )}
                      </>
                    )}

                    <AddNewLocation
                      label={
                        watch("newLocation")
                          ? localeButtons.BUTTON_SELECT_EXISTING_LOCATION
                          : localeButtons.BUTTON_ADD_NEW_LOCATION
                      }
                      onClick={() => {
                        if (watch("newLocation")) {
                          setValue("newLocation", false);
                          setValue("address", "");
                        } else {
                          setValue("newLocation", true);
                          setValue("addNewAddress", {});
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Branches and Categories  */}
            <div
              className={styles["right-section-each"]}
              // ref={branchesAndCategoriesSectionRef}
            >
              <Text className={styles["section-title"]}>
                {localeTitles.TITLE_REPEATED_JOB}
              </Text>
              <div className={styles["JobPostingSection-right-section"]}>
                <div className={styles["profile-fields"]}>
                  <Controller
                    name={"repeated"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      formState: { errors },
                    }) => (
                      <div className={styles["parking"]}>
                        <div className={styles["parkingDiv"]}>
                          <div className={styles["parkingDiv-left"]}>
                            <BsArrowRepeat size={24} color={"#858D98"} />
                            <Text className={styles["labelText"]}>
                              {localeTitles.TITLE_REPEATED_JOBS}
                            </Text>
                          </div>
                          <div className={styles["optionsSection"]}>
                            <div
                              className={styles["select-field-parking"]}
                              onClick={() =>
                                onChange(value === true ? false : true)
                              }
                            >
                              {value ? (
                                <AiFillCheckCircle size={24} type="radio" />
                              ) : (
                                <BsCircle size={24} type="radio" />
                              )}
                              <label
                                className={styles["labelText"]}
                                htmlFor="yes"
                              >
                                {localeButtons.BUTTON_YES}
                              </label>
                            </div>
                            <div
                              className={styles["select-field-parking"]}
                              onClick={() =>
                                onChange(value === true ? false : true)
                              }
                            >
                              {!value ? (
                                <AiFillCheckCircle size={24} type="radio" />
                              ) : (
                                <BsCircle size={24} type="radio" />
                              )}
                              <label
                                className={styles["labelText"]}
                                htmlFor="no"
                              >
                                {localeButtons.BUTTON_NO}
                              </label>
                            </div>
                          </div>
                        </div>
                        {!!errors && (
                          <ErrorMessage
                            errors={errors?.repeated?.message as string}
                          />
                        )}
                      </div>
                    )}
                  />
                  {watch("repeated") && (
                    <>
                      <ControlSelect
                        name={"repeatWeek"}
                        disabled={!watch("repeated")}
                        control={control}
                        list={repeatWeekOptions(localeLabels)}
                        Icon={<MdOutlineViewWeek size={24} color={"#858D98"} />}
                      />

                      <CategorySelect
                        multiSelect={true}
                        name="repeatedDays"
                        disabled={!watch("repeated")}
                        control={control}
                        list={days}
                        disablesorting={true}
                        Icon={
                          <HiOutlineCalendarDays size={24} color={"#858D98"} />
                        }
                      />

                      <ControlInput
                        control={control}
                        name="repeatEndingDate"
                        disabled={!watch("repeated")}
                        placeholder={
                          localePlaceholders.PLACEHOLDERS_REPEAT_ENDING_DATE
                        }
                        preDefinedClassName="inputField"
                        preDefinedWrapClassName="inputField-wrap"
                        type="text"
                        prefix={<CalendarAddIcon color={"#858D98"} sameSize />}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Skills  */}
            <div
              className={styles["right-section-each"]}
              // ref={branchesAndCategoriesSectionRef}
            >
              <Text className={styles["section-title"]}>
                {localeTitles.TITLE_SKILLS_AND_EXPERTISE}
              </Text>
              <div className={styles["JobPostingSection-right-section"]}>
                <CategorySelect
                  multiSelect={true}
                  name="skills"
                  control={control}
                  list={dropdowns?.skills}
                  Icon={<ThreeDCubeIcon />}
                />
              </div>
            </div>
            {/* Skills  */}
          </form>
          {width > breakPoints.md && (
            <div
              className={`${styles["JobPostingSection-head-right"]} justify-end`}
            >
              <Button className="primary" onClick={() => navigate(-1)}>
                {localeButtons.BUTTON_CANCEL}
              </Button>
              <Button
                className={
                  Object.keys(errors).length > 0
                    ? "delete-button"
                    : "secondary-active"
                }
                type="submit"
                onClick={handleSubmit(onSubmitConfirm)}
                loading={props.loading}
              >
                {props.isEdit
                  ? localeButtons.BUTTON_EDIT_NOW
                  : localeButtons.BUTTON_POST_NOW}
              </Button>
            </div>
          )}
        </div>
        {width < breakPoints.md && (
          <div className={styles["JobPostingSection-mobile-button"]}>
            <Button
              className={"inactive-fullWidth"}
              type="button"
              onClick={() => navigate(-1)}
              // loading={loading}
            >
              {localeButtons.BUTTON_CANCEL}
            </Button>
            <Button
              className={"secondary-active-fullWidth"}
              type="submit"
              onClick={handleSubmit(onSubmitConfirm)}
              loading={props.loading}
            >
              {localeButtons.BUTTON_POST_NOW}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobForm;
