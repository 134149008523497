import { useNavigate } from "react-router-dom";
import type { HiringManagerCardProps } from "./@types";
import styles from "./HiringManagerCard.module.scss";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import LoadingTimerIcon from "./LoadingTimerIcon";
import { handleImagesPath } from "utils/hooks/helper";
import { BsArrowRepeat } from "react-icons/bs";


import { MdOutlineViewWeek } from "react-icons/md";
import dayjs from "dayjs";
import ClockIcon from "pages/OrganizationJobsPage/Icon/ClockIcon";
import TimerIcon from "pages/FinanceJobs/HiredWorkerCard/Icon/TimerIcon";
import GasStationIcon from "pages/FinanceJobs/HiredWorkerCard/Icon/GasStationIcon";

const HiringManagerCard = ({ data }: HiringManagerCardProps) => {
  const { localeTitles, localeLabels,currentLocale } = useLocale();
  const days = [
    {
      value:"Monday",
      label:{
        en:localeLabels.LABEL_MONDAY,
        du:localeLabels.LABEL_MONDAY
      },
      _id:"Monday"
    },
    {
      value:"Tuesday",
      label:{
        en:localeLabels.LABEL_TUESDAY,
        du:localeLabels.LABEL_TUESDAY
      },
      _id:"Tuesday"
    },
    {
      value:"Wednesday",
      label:{
        en:localeLabels.LABEL_WEDNESDAY,
        du:localeLabels.LABEL_WEDNESDAY
      },

      _id:"Wednesday"
    },
    {
      value:"Thursday",
      label:{
        en:localeLabels.LABEL_THURSDAY,
        du:localeLabels.LABEL_THURSDAY
      },
      _id:"Thursday"
    },
    {
      value:"Friday",
      label:{
        en:localeLabels.LABEL_FRIDAY,
        du:localeLabels.LABEL_FRIDAY
      },
      _id:"Friday"
    },
    {
      value:"Saturday",
      label:{
        en:localeLabels.LABEL_SATURDAY,
        du:localeLabels.LABEL_SATURDAY
      },
      _id:"Saturday"
    },
    {
      value:"Sunday",
      label:{
        en:localeLabels.LABEL_SUNDAY,
        du:localeLabels.LABEL_SUNDAY
      },
      _id:"Sunday"
    }
    
  ]

  const repeatWeekOptions = (localeLabel: typeof localeLabels) => [
    { value: "", label: localeLabel.LABEL_PLEASE_SELECT },
    {
      value: "everyWeek",
      label: localeLabel.LABEL_EVERY_WEEK,
    },
    {
      value: "everySecondWeek",
      label: localeLabel.LABEL_EVERY_SECOND_WEEK,
    },
    {
      value: "everyThirdWeek",
      label: localeLabel.LABEL_EVERY_THIRD_WEEK,
    },
    {
      value: "everyFourthWeek",
      label: localeLabel.LABEL_EVERY_FOURTH_WEEK,
    },
  
  ];
  return (
    <div className={styles["HiringManagerCard"]}>
      {data?.hiringUrgencyLevel === "urgent" ? (
        <Text className={styles["urgentHiring"]}>
          {localeLabels.LABEL_URGENT_HIRING}
        </Text>
      ) : data?.hiringUrgencyLevel === "flexible" ? (
        <Text className={styles["normalHiring"]}>
          {localeLabels.LABEL_FLEXIBLE}
        </Text>
      ) : data?.hiringUrgencyLevel === "soon" ? (
        <Text className={styles["normalHiring"]}>
          {localeLabels.LABEL_SOON}
        </Text>
      ) : null}

      <div className={styles["HiringManagerCard-main"]}>
        <Text className={styles["description"]}>
          {localeTitles.TITLE_HIRING_MANAGER}
        </Text>

        <div className={styles["manager"]}>
          <div className={styles["manager-details"]}>
            <Img
              src={handleImagesPath(data?.user?.profileImage)}
              className={styles["image"]}
            />
            <Text
              className={styles["name"]}
            >{`${data?.user?.firstName} ${data?.user?.lastName}`}</Text>
          </div>

        </div>

        <div className={styles["details-div"]}>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_BEGIN_TIME}
            </Text>
            <Text className={styles["description-value"]}>
              <ClockIcon size={20} />
              {dayjs(data?.timeToStart).isValid()?dayjs(data?.timeToStart).format("HH:mm"):data?.timeToStart}

            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_END_TIME}
            </Text>
            <Text className={styles["description-value"]}>
              <ClockIcon size={20} />
              {dayjs(data?.timeToEnd).isValid()?dayjs(data?.timeToEnd).format("HH:mm"):data?.timeToEnd}
            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_BREAK_TIME}
            </Text>
            <Text className={styles["description-value"]}>
              <TimerIcon color="#101828" size={20} />
              {data?.breakTime}
            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_TRAVEL_EXPENSE}
            </Text>
            <Text className={styles["description-value"]}>
              <GasStationIcon size={20} />
              €{data?.travelExpenses}
            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_JOB_REPETATION}
            </Text>
            <Text className={styles["description-value"]}>
              <BsArrowRepeat size={20} color="#101828" />
              {data?.repeated ? "Repeated" : "Once"}
            </Text>
          </div>
          {data?.repeated && (
            <div className={styles["item"]}>
              <Text className={styles["description"]}>
                {localeTitles.TITLE_JOB_REPEAT_WHEN}
              </Text>
              <Text className={styles["description-value"]}>
                <MdOutlineViewWeek size={20} color="#101828" />
                {repeatWeekOptions(localeLabels)?.find(item=>(item.value===data?.repeatWeek))?.label}
              </Text>
            </div>
          )}
          {data?.repeated && (
            <div className={styles["item"]}>
              <Text className={styles["description"]}>
                {localeTitles.TITLE_JOB_REPEAT_END}
              </Text>
              <Text className={styles["description-value"]}>
                <MdOutlineViewWeek size={20} color="#101828" />
                {dayjs(data?.repeatEndingDate).format("DD-MM-YYYY")}
              </Text>
            </div>
          )}
          {data?.repeated && (
            <div className={styles["item"]}>
              <Text className={styles["description"]}>
                {localeTitles.TITLE_JOB_REPEAT_WHEN}
              </Text>
              <Text className={styles["description-value-grid"]}>
                {
                  data?.repeatedDays?.map((d:any)=>days?.find(item=>(item._id===d))?.label?.[currentLocale])?.map((item:string)=>(
                    <Text className={styles["days"]}>{item?.slice(0,3)}</Text>
                  ))
                }
              </Text>
            </div>
          )}
        </div>
        <div className={`${styles["item"]} ${styles["lastItem"]}`}>
          <Text className={styles["description"]}>
            {localeTitles.TITLE_JOB_TYPE}
          </Text>
          <Text className={styles["description-value"]}>
            <LoadingTimerIcon size={20} />
            {data?.workerType}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default HiringManagerCard;
