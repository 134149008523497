import AccountPage from "pages/AccountPage/AccountPage";
import AccountPageOrganization from "pages/AccountPageOrganization/AccountPageOrganization";
import { useAccountService } from "./hook";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const AccountServiceComponent = () => {
  const [q] = useSearchParams();

  const accountType = q.get("accountType");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      ["organization", "employee", "freelancer"].includes(accountType) === false
    )
      navigate("/");
  }, [accountType]);

  const { onSubmit, loading } = useAccountService(q.get("accountType"));
  return (
    <>
      {accountType === "organization" ? (
        <AccountPageOrganization onSubmit={onSubmit} loading={loading} />
      ) : (
        <AccountPage
          onSubmit={onSubmit}
          loading={loading}
          employee={accountType === "employee" ? true : false}
          freelancer={accountType === "freelancer" ? true : false}
          organization={false}
        />
      )}
    </>
  );
};

// export default AccountService;

export default function AccountService() {
  return <AccountServiceComponent />;
}
