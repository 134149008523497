import styles from "./AccountPageOrganization.module.scss";



import { useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";
import { Text } from "components/atoms/TextF";
import { TextArea } from "components/atoms/TextAreaF";

import { profileShowOptions } from "constants/constantData";

import { useAccountPageOrganization } from "./hook";

import ThreeDCubeIcon from "assets/svgs/ThreeDCubeIcon";

import profileImg from "assets/images/account/profile.png";

import GhostIcon from "assets/svgs/GhostIcon";

import MessageIcon from "assets/svgs/MessageIcon";

import BuildingIcon from "assets/svgs/BuildingIcon";

import UserTagIcon from "assets/svgs/UserTagIcon";
import HashTagIcon from "assets/svgs/HashTagIcon";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

import { AccountPageOrganizationProps } from "./@types";
import PeopleIcon from "assets/svgs/PeopleIcon";
import ControlSelect from "components/molecules/ControlSelect/Select";
import { ErrorMessage } from "components/atoms/ErrorMessage";
import { ControlInput } from "components/atoms/ControlledInput/Input";
import AvatarWithIcon from "components/molecules/AvatarWithIcon";


import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import GoogleMap from "components/cells/GoogleMap";

import UserEditIcon from "assets/svgs/UserEditIcon";
import PhoneInput from "components/atoms/PhoneInput/src";
import "components/atoms/PhoneInput/src/style/style.scss";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  SubmitHandler,
  UseFormSetValue,
} from "react-hook-form";
import { account_title_list } from "constants/forms.data";
import PencilIcon from "assets/svgs/PencilIcon";
import CategorySelect from "components/molecules/CategorySelect";
import { UsersContextConsumer } from "store/AuthProvider";

import ZipIcon from "assets/svgs/ZipIcon";
import ArrowSquareIcon from "assets/svgs/ArrowSquareIcon";
import { LiaCitySolid } from "react-icons/lia";
import MapIcon from "assets/svgs/MapIcon";
import { ControlAutoCompleteInput } from "components/atoms/ControlledAutCompleteGoogle/Input";

import { ORG_ACCOUNT_FORMS } from "types/forms";

import { AddNewLocation } from "./AddNewLocation";

import { TbReceiptTax } from "react-icons/tb";
import { useWidth } from "utils/responsiveHook";

const AccountPageOrganization = ({
  loading,
  onSubmit,
}: AccountPageOrganizationProps) => {
  const {
    watch,

    handleSubmit,
    control,
    setValue,
    errors,
    handleIbanChange,
    fields,
    sliderRef,
    userData,
    insertAddress,
    removeAddress,
  } = useAccountPageOrganization();

  const {
    localeTitles,
    localeBasics,
    localePlaceholders,
    localeLabels,
    localeButtons,
  } = useLocale();

  const { dropdowns } = UsersContextConsumer();
  const { width } = useWidth();

  const SubmitFortm: SubmitHandler<ORG_ACCOUNT_FORMS> = (data) => {
    const skillSet = data.skills?.map((skill) => skill._id);
    const branchSet = data.branches?.map((branch) => branch._id);
    const categorySet = data.categories?.map((category) => category._id);

    const formData = {
      ...data,
      skills: skillSet,
      branches: branchSet,
      categories: categorySet,
    };
    onSubmit(formData);
  };

  return (
    <div className={styles["AccountPageOrganization"]}>
      <div className={styles["AccountPageOrganization-heading"]}>
        <Text className={styles["title"]}>{localeTitles.TITLE_ACCOUNT}</Text>

        {width > breakPoints.md && (
          <Button
            className={"secondary-active"}
            onClick={handleSubmit(SubmitFortm)}
            loading={loading}
          >
            {localeButtons.BUTTON_SAVE_CHANGES}
          </Button>
        )}
      </div>
      <div className={styles["AccountPageOrganization-main"]}>
        <div className={styles["AccountPageOrganization-right"]}>
          <form
            onSubmit={handleSubmit(SubmitFortm)}
            className={styles["AccountPage-form"]}
          >
            <div className={styles["right-section-recent"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_BUSINESS_INFORMATION}
                </Text>
              )}
              <div
                className={
                  styles["AccountPageOrganization-right-section-profile"]
                }
              >
                <div className={styles["section-profile-left"]}>
                  <AvatarWithIcon
                    control={control}
                    profileImage={profileImg}
                    ImageData={watch("profileImage")}
                  />
                  <div>
                    <Text className={styles["avatar"]}>
                      {localeTitles.TITLE_AVATAR}
                    </Text>
                    <Text className={styles["avatarSubtitle"]}>
                      {localeBasics.TEXT_AVATAR_REQUIREMENT}
                    </Text>
                  </div>
                </div>
                <div className={styles["section-profile-right"]}>
                  <div className={styles["selectDiv"]}>
                    <ControlSelect
                      name="availabilityStatus"
                      control={control}
                      list={profileShowOptions(localeLabels)}
                      Icon={<GhostIcon />}
                    />
                  </div>
                </div>
              </div>

              <div className={styles["AccountPageOrganization-right-section"]}>
                <div className={styles["profile-fields"]}>
                  <div className={styles["selectDiv"]}>
                    <ControlSelect
                      name="title"
                      control={control}
                      list={account_title_list(localeLabels)}
                      Icon={<PencilIcon />}
                    />
                  </div>

                  <Controller
                    name="phone"
                    control={control}
                    render={({
                      field: { onChange, value, name, ref },
                      formState: { errors },
                    }) => (
                      <div className=" flex flex-col">
                        <PhoneInput
                          enableSearch={true}
                          value={value}
                          onChange={(phone: any) => onChange(phone)}
                        />
                        {!!errors && (
                          <ErrorMessage
                            errors={errors?.phone?.message as string}
                          />
                        )}
                      </div>
                    )}
                  />
                  <ControlInput
                    control={control}
                    name="firstName"
                    borderClass=""

                    placeholder={localePlaceholders.PLACEHOLDERS_FIRST_NAME}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<UserEditIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    name="lastName"
                    borderClass=""

                    placeholder={localePlaceholders.PLACEHOLDERS_LAST_NAME}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<UserEditIcon color={"#101828"} sameSize />}
                  />

                  {/* <ControlInput
                    control={control}
                    // register={register("birthday")}
                    name="dob"
                    placeholder={localePlaceholders.PLACEHOLDERS_BIRTHDAY}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="date"
                    prefix={<CakeIcon color={"#101828"} sameSize />}
                  /> */}

                  <ControlInput
                    control={control}
                    name="email"
                    borderClass=""

                    placeholder={localePlaceholders.PLACEHOLDER_EMAIL}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<MessageIcon color={"#101828"} />}
                  />

                  <ControlInput
                    control={control}
                    // register={register("companyName")}
                    borderClass=""

                    name="companyName"
                    placeholder={localePlaceholders.PLACEHOLDERS_COMPANY_NAME}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<BuildingIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""

                    name="employee"
                    placeholder={localePlaceholders.PLACEHOLDERS_EMPLOYEES}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="number"
                    prefix={<PeopleIcon color={"#101828"} sameSize />}
                  />
                  <ControlInput
                    control={control}
                    borderClass=""

                    name="position"
                    placeholder={localePlaceholders.PLACEHOLDERS_EMPLOYEES}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<PeopleIcon color={"#101828"} sameSize />}
                  />
                </div>
                <div className={styles["largeDiv"]}>
                  <ControlInput
                    control={control}
                    borderClass=""

                    name="heading"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_ACCOUNT_HEAD_LINE
                    }
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                  />

                  <TextArea
                    control={control}
                    name="bio"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_TELL_SOMETHING_ABOUT_YOURSELF
                    }
                    className={styles["textArea"]}
                  />
                </div>
              </div>
            </div>
            {/*Personal Information  */}

            {/*Location  */}
            <div className={styles["right-section-location"]}>
              {/* {width > breakPoints.md && ( */}
              <div className="flex justify-between items-center">
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_LOCATION}
                </Text>

                <AddNewLocation
                  onClick={() => {
                    //  ;
                    insertAddress(fields?.length);
                  }}
                />
              </div>

              <div className={styles["AccountPage-right-section-map"]}>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  slidesPerView={1}
                  navigation={true}
                  pagination={{ clickable: true }}
                  //  scrollbar={{ draggable: false ,hide:true}}
                  onSwiper={(swiper: any) => (sliderRef.current = swiper)}
                  onSlideChange={() => console.log("slide change")}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  shortSwipes={false}
                  // onSwiper={(swiper) => console.log(swiper)}
                >
                  {fields?.map((address, index) => (
                    <SwiperSlide>
                      <AddressComponent
                        index={index}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        removeAddress={removeAddress}
                        fields={fields}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>

            <>
              <div className={styles["right-section-location"]}>
                {width > breakPoints.md && (
                  <Text className={styles["section-title"]}>
                    {localeTitles.TITLE_KAMER_VAN_KOOPHANDEL}
                  </Text>
                )}
              </div>

              <div className={styles["AccountPage-right-section-map"]}>
                <ControlInput
                  control={control}
                  borderClass=""

                  name="kvkNumber"
                  placeholder={
                    localePlaceholders.PLACEHOLDERS_KVK_NUMBER_OPTIONAL
                  }
                  preDefinedWrapClassName="inputField-wrap"
                  preDefinedClassName="inputField"
                  type="text"
                  prefix={<ArrowSquareIcon color={"#101828"} />}
                />

                <ControlInput
                  control={control}
                  borderClass=""

                  name="companyName"
                  placeholder={
                    localePlaceholders.PLACEHOLDERS_COMPANY_NAME_OPTIONAL
                  }
                  preDefinedWrapClassName="inputField-wrap"
                  preDefinedClassName="inputField"
                  type="text"
                  prefix={<BuildingIcon color={"#101828"} sameSize />}
                />
              </div>
            </>

            <div className={styles["right-section-branchesAndCategories"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_BRANCHES_AND_CATEGORIES}
                </Text>
              )}
              <div
                className={
                  styles[
                    "AccountPageOrganization-right-section-branchAndCategory"
                  ]
                }
              >
                <CategorySelect
                  multiSelect={true}
                  name="branches"
                  control={control}
                  list={dropdowns?.branches}
                  // maxLimit={3}
                  Icon={<ThreeDCubeIcon />}
                />
                <CategorySelect
                  multiSelect={true}
                  name="categories"
                  control={control}
                  list={
                    dropdowns?.categories?.filter((item) =>
                      watch("branches")?.find(
                        (branch) => branch._id === item.branchId
                      )
                        ? true
                        : false
                    ) || []
                  }
                  Icon={<ThreeDCubeIcon />}
                />
              </div>
            </div>

            <div className={styles["right-section-branchesAndCategories"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_SKILLS_AND_EXPERTISE}
                </Text>
              )}
              <div
                className={
                  styles[
                    "AccountPageOrganization-right-section-branchAndCategory"
                  ]
                }
              >
                <CategorySelect
                  multiSelect={true}
                  name="skills"
                  control={control}
                  list={dropdowns?.skills}
                  Icon={<ThreeDCubeIcon />}
                />
              </div>
            </div>
            {/* Branches and Categories  */}

            {/*bank Information  */}
            <div className={styles["right-section-bank"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_BANK_DETAILS}
                </Text>
              )}

              <div className={styles["AccountPageOrganization-right-section"]}>
                <div className={styles["profile-fields"]}>
                  <ControlInput
                    control={control}
                    borderClass=""

                    name="accountHolderName"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_ACCOUNT_HOLDER_NAME
                    }
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<UserTagIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""

                    name="IBAN"
                    placeholder={localePlaceholders.PLACEHOLDERS_IBAN}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    handleChangeCustomly={handleIbanChange}
                    type="text"
                    prefix={<HashTagIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""

                    name="btw_nr"
                    placeholder={localePlaceholders.PLACEHOLDERS_BTW_NUMBER}
                    preDefinedClassName="inputField"
                    preDefinedWrapClassName="inputField-wrap"
                    type="text"
                    prefix={<TbReceiptTax size={20} color="#101828" />}
                  />
                </div>
              </div>
            </div>
            {/*bank Information  */}

            {width < breakPoints.md ? (
              <div className={styles["save-mobile"]}>
                <Button className={"inactive-fullWidth"}>
                  {localeButtons.BUTTON_SAVE_CHANGES}
                </Button>
              </div>
            ) : (
              <div className={styles["save-desktop"]}>
                <Button
                  className={"secondary-active"}
                  onClick={handleSubmit(SubmitFortm)}
                  loading={loading}
                >
                  {localeButtons.BUTTON_SAVE_CHANGES}
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export const AddressComponent = ({
  index,
  control,
  errors,
  setValue,
  removeAddress,
  fields,
  disabled=false
}: {
  index: number;
  control: Control<ORG_ACCOUNT_FORMS, any>;
  errors: FieldErrors<ORG_ACCOUNT_FORMS>;
  setValue: UseFormSetValue<ORG_ACCOUNT_FORMS>;
  removeAddress?: (index: number) => void;
  fields?: FieldArrayWithId<ORG_ACCOUNT_FORMS, "address", "id">[];
  disabled?:boolean
}) => {
  const { localeTitles, localeBasics, localePlaceholders } = useLocale();
  const slider = useSwiper();
  const { width } = useWidth();

  return (
    <>
      <div className={`${styles["profile-fields"]} mt-2`}>
        <ControlInput
        disabled={disabled}
          control={control}
          name={`address.${index}.addressTitle`}
          placeholder={localePlaceholders.PLACEHOLDERS_ADDRESS_TITLE}
          preDefinedClassName="inputField"
          preDefinedWrapClassName="inputField-wrap"
          customError={errors.address?.[index]?.addressTitle?.message}
          type="text"
          borderClass=""

          prefix={<ArrowSquareIcon />}
        />
        <ControlAutoCompleteInput
        disabled={disabled}

          control={control}
          name={`address.${index}.address`}
          placeholder={localePlaceholders.PLACEHOLDERS_ADDRESS}
          type="text"
          preDefinedClassName="inputField"
          preDefinedWrapClassName="inputField-wrap"
          prefix={<MapIcon />}
          defaultChecked={true}
          onChangeCoordinates={(
            coordinates,
            formatedAddress,
            postal_code,
            street,
            city,
            country
          ) => {
            setValue(`address.${index}.location.coordinates`, coordinates);
            if (postal_code) {
              setValue(`address.${index}.zip`, postal_code);
            }
            if (street) {
              setValue(`address.${index}.street`, street);
            }
            if (city) {
              setValue(`address.${index}.city`, city);
            }
            if (country) {
              setValue(`address.${index}.country`, country);
            }
          }}
        />
      </div>
      <Controller
        name={`address.${index}.location.coordinates`}
        control={control}
        disabled={disabled}
    

        render={({
          field: { onChange, value, name, ref },
          formState: { errors },
        }) => (
          <>
            <GoogleMap
              setValue={setValue as any}
              lat={value?.[1]}
              lng={value?.[0]}
              index={index}
            />

            {/* {!!errors && (
      <ErrorMessage
        errors={
          errors?.place?.message ||
          errors?.street?.message ||
          (errors?.zip?.message as string)
        }
      />
    )} */}
          </>
        )}
      />
      <div className={`${styles["profile-fields"]} mt-2`}>
        <ControlInput
        disabled={disabled}

          control={control}
          name={`address.${index}.street`}
          placeholder={localePlaceholders.PLACEHOLDERS_STREET}
          preDefinedClassName="inputField"
          preDefinedWrapClassName="inputField-wrap"
          customError={errors.address?.[index]?.street?.message}
          type="text"
          borderClass=""
          prefix={<ArrowSquareIcon />}
        />

        <ControlInput
        disabled={disabled}

          control={control}
          name={`address.${index}.zip`}
          borderClass=""

          placeholder={localePlaceholders.PLACEHOLDERS_ZIP}
          preDefinedClassName="inputField"
          preDefinedWrapClassName="inputField-wrap"
          customError={errors.address?.[index]?.zip?.message}
          type="text"


          prefix={<ZipIcon />}
        />

        <ControlInput
          control={control}
          name={`address.${index}.city`}
          borderClass=""
        disabled={disabled}


          placeholder={localePlaceholders.PLACEHOLDERS_CITY}
          preDefinedClassName="inputField"
          preDefinedWrapClassName="inputField-wrap"
          customError={errors.address?.[index]?.city?.message}
          type="text"
          prefix={
            <LiaCitySolid
              size={width > breakPoints.sm ? 35 : 16}
              color="#858D98"
            />
          }
        />

        <ControlInput
          control={control}
          name={`address.${index}.country`}
          placeholder={localePlaceholders.PLACEHOLDERS_COUNTRY}
          preDefinedWrapClassName="inputField-wrap"
          preDefinedClassName="inputField"
          borderClass=""

          customError={errors.address?.[index]?.country?.message}
          type="text"
          prefix={<MapIcon />}
        />
      </div>
      <div className="flex justify-between items-center mt-2">
        {removeAddress && fields && fields?.length > 0 && (
          <div
            onClick={() => {
              removeAddress?.(index);
              // slider?.slideNext();
            }}
            className={styles["btn-remove-action"]}
          >
            {localeTitles.TITLE_REMOVE_LOCATION}
          </div>
        )}

        <Text className={styles["location-tip"]}>
          {localeBasics.TEXT_LOCATION_TIP}
        </Text>
      </div>
    </>
  );
};

export default AccountPageOrganization;
