import RatingStar from "./RatingStar";
import type { RatingCardProps } from "./@types";
import styles from "./RatingCard.module.scss";
import { Text } from "components/atoms/TextF";
import googleImg from "./GoogleImg.png";
import { Img } from "components/atoms/Img";
import { useLocale } from "locales";

const RatingCard = ({
  rating,
  className,
  googleText,
  ratingText,
  logoClass,
  starClass,
  subTitleClass,
  reviews
}: RatingCardProps) => {
  const maxRating = 5;
  const { localeBasics } = useLocale();

  return (
    <div className={`${styles["RatingCard"]} ${className}`}>
      <div className={`${styles["RatingCard-top"]} `}>
        <Text className={`${styles["ratingText"]}  ${ratingText}`}>
          {rating?.toFixed(1)}
        </Text>
        {Array.from({ length: maxRating }, (_, index) => (
          <RatingStar
            key={index}
            filled={index < rating}
            rating={rating}
            starClass={starClass}
          />
        ))}
      </div>
      <div className={`${styles["RatingCard-bottom"]} cursor-pointer`} onClick={()=>{
        // https://g.co/kgs/ZLxT2Ts
        // open new tab with google
        window.open("https://g.co/kgs/ZLxT2Ts", "_blank");
      }}>
        <Img
          className={`${styles["ratingCard-img"]} ${logoClass}`}
          src={googleImg}
        />
        <div>
          <Text className={`${styles["ratingText-title"]}  ${googleText}`}>
            Google
          </Text>
          <Text className={`${styles["ratingText-subTitle"]} ${subTitleClass}`}>
            {localeBasics.TEXT_TOTAL_REVIEWS}:{reviews}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default RatingCard;
