import { useDetailsServices } from "./hook";
import UserDetailsPage from "../../pages/UserDetailsPage";
import Loader from "../../components/atoms/Loader";

export interface services {}

const DetailsServices = ({}: services) => {
  const {
    userDetails,
    onToggleAccount,
    onClickHide,
    onConfirmAccountToggle,
    showConfirmation,
    toggleLoading,
    userDetailsListLoading,
    onClickVerified,
    onClickDeclined,
    onSubmitForm,
    verifyLoading,
    vogLoading,
    declineLoading,
    onUserUpdate,
    organization,
    latestContract
  } = useDetailsServices();
  if (userDetailsListLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <UserDetailsPage
      userDetails={userDetails}
      onToggleAccount={onToggleAccount}
      onUserUpdate={onUserUpdate}
      onClickHide={onClickHide}
      onConfirmAccountToggle={onConfirmAccountToggle}
      showConfirmation={showConfirmation}
      toggleLoading={toggleLoading}
      onClickVerified={onClickVerified}
      onClickDeclined={onClickDeclined}
      onSubmitForm={onSubmitForm}
      verifyLoading={verifyLoading}
      vogLoading={vogLoading}
      declineLoading={declineLoading}
      organization={organization}
      latestContract={latestContract}
    />
  );
};

export default DetailsServices;
