import styles from "./OrganizationJobsPage.module.scss";

import { localeButtons, useLocale } from "locales";

import JobCard from "./JobCard/JobCard";

import { Button } from "components/atoms/ButtonF";
import { Loader } from "@aws-amplify/ui-react";
import ConfirmationModal from "components/organisms/Confirmation Modal";
import { useAllJobs } from "./hook/useAllJobs";

export const jobCategoryTypes = (localeButton: typeof localeButtons) => [
  { label: localeButton.BUTTON_UNDER_REVIEW, value: "under_review" },
  { label: localeButton.BUTTON_FILLED_BY_ADMIN, value: "admin" },
  { label: localeButton.BUTTON_FILLED_BY_USERS, value: "users" },
];

const AllJobs = () => {
  const {
    activeCategory,
    jobsData,
    jobsDataLoading,
    getCategoryName,
    onConfirmDeleteJob,
    loading,
    modal,
    toggleModal,
    onDeleteJob,
    onChangeFulfilledBy,
  } = useAllJobs();

  const { localeButtons, localeLabels } = useLocale();

  return (
    <div className={styles["OrganizationJobsPage"]}>
      <div className={styles["OrganizationJobsPage-main"]}>
        <div className={styles["OrganizationJobsPage-top"]}>
          <div className={styles["OrganizationJobsPage-top-left"]}>
            {jobCategoryTypes(localeButtons)?.map(
              (category: { label: string; value: string }, index) => (
                <Button
                  key={index}
                  className={
                    activeCategory === category?.value
                      ? styles["primaryBtn"]
                      : styles["secondaryBtn"]
                  }
                  onClick={() => getCategoryName(category?.value as any)}
                >
                  {category?.label}
                </Button>
              )
            )}
          </div>
        </div>

        {jobsDataLoading ? (
          <div className={"loadingScreen"}>
            <Loader />
          </div>
        ) : (
          <div className={styles["OrganizationJobsPage-jobs"]}>
            {(jobsData || [])?.map((job, jobIndex: number) => (
              <JobCard
                cardType="organization"
                data={job}
                key={jobIndex}
                onDeleteJob={onDeleteJob}
                onChangeFulfilledBy={onChangeFulfilledBy}
                organizationId={job?.organization?.user}
              />
            ))}
          </div>
        )}
      </div>

      <ConfirmationModal
        show={modal}
        title={localeLabels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JOB}
        onHide={toggleModal}
        onConfirm={onConfirmDeleteJob}
        loading={loading}
      />
    </div>
  );
};

export default AllJobs;
