import { useLocale } from "locales";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery, useInfiniteQuery } from "react-query";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";
import { INVITE_FORM, accountType } from "types/forms";

import {
  HireWorkerApi,
  InviteWorkerAPI,
  getFindWorkersAPI,
} from "utils/globalAPI/OrganizationWorkers";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const useInviteWorkerPage = () => {
  const { localeSuccess, localeErrors } = useLocale();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { adminData: accessToken, fetchApi } = UsersContextConsumer();

  const { handleSubmit, control, watch } = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const toast = ToastConsumer();

  const query = watch("search");

  const { jobId, organization } = useParams();

  const navigate = useNavigate();

  const [modal, setModal] = useState({
    invite: false,
    hire: false,
  });
  const [inviteLoading, setInviteLoading] = useState(false);
  const [hireLoading, setHireLoding] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    "employee" | "freelancer"
  >("employee");
  const [workerId, setWorkerId] = useState<string>("");
  const [accountTyp, setaccountTyp] = useState<accountType>("employee");

  const handleClose = (type: "invite" | "hire") => {
    setModal({
      ...modal,
      [type]: false,
    });
  };
  const handleOpen = (type: "invite" | "hire") => {
    setModal({
      ...modal,
      [type]: true,
    });
  };

  const onClickInvite = (id: string, at: accountType) => {
    setWorkerId(id);
    handleOpen("invite");
    setaccountTyp(at);
  };
  const onClickHire = (id: string) => {
    setWorkerId(id);
    handleOpen("hire");
  };

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery(
      [
        "findWorkers",
        {
          tabValue,
          selectedOption,
          query,
          jobId,
        },
      ],

      async ({ pageParam = 0 }) =>
        getFindWorkersAPI(
          {
            query: query,
            workerType: selectedOption,
            alreadyHired: tabValue === 0 ? false : true,
            jobId,
          },
          pageParam,
          accessToken
        ),
      {
        getNextPageParam: (data) => (data?.hasMore ? data.nextPage : null),
        enabled: fetchApi,
      }
    );
  const onConfirmInvite: SubmitHandler<INVITE_FORM> = async (data) => {
    console.log("submitData", data);
    const params = {
      worker: workerId,
      jobId: data?.jobId,
      perHourRate: data?.perHourRate,
      content: data?.content,
      organizationId: organization,
    };
    // InviteWorkerAPI(params, accessToken);

    try {
      setInviteLoading(true);
      const response = await InviteWorkerAPI(params, accessToken);
      toast.success(localeSuccess.SUCESS_INVITIATION_SENT, "top-right");

      refetch();
      handleClose("invite");
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setInviteLoading(false);
    }
  };

  const onHireWorker = async () => {
    try {
      setHireLoding(true);
      const response = await HireWorkerApi(
        jobId,
        workerId,
        organization,
        accessToken
      );
      toast.success(localeSuccess.SUCESS_HIRE, "top-right");
      // setTokenInCookies(response?.data.data);
      refetch();
      handleClose("hire");
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setHireLoding(false);
    }
  };

  const closeInviteModal = () => {
    handleClose("invite");
  };
  const onCloseHireModal = () => {
    handleClose("hire");
  };

  const handleOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: "employee" | "freelancer" | null
  ) => {
    if (newOption) {
      setSelectedOption(newOption);
    }
  };

  const navigateToWorkerDetails = (workerId: string) => {
    navigate(`/organization/${organization}/worker/${workerId}/${jobId}`);
  };

  return {
    control,
    selectedOption,
    handleOptionChange,
    data,

    hasMore: hasNextPage,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,

    tabValue,
    handleTabChange,
    onClickHire,
    hireModal: modal.hire,

    handleSubmit,
    inviteModal: modal.invite,
    closeInviteModal,
    onConfirmInvite,
    onClickInvite,
    inviteLoading,
    hireLoading,
    jobId,
    workerId,
    accountTyp,
    onCloseHireModal,
    onHireWorker,
    navigateToWorkerDetails,
  };
};
