import { Text } from "components/atoms/TextF";
import styles from "./EditAvailability.module.scss";
import {
  EditAvailabilityProps,
} from "./@types";
import {  useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";

import CloseCircleIcon from "assets/svgs/CloseCircleIcon";


import dayjs from "dayjs";
import {
  availabilityScheduleSowOptions,
  shiftOPtions,
} from "constants/constantData";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import { useEditAvailability } from "./hook";
import { ControlInput } from "components/atoms/ControlledInput/Input";
import ControlSelect from "components/molecules/ControlSelect/Select";
import { ControlTimeSelector } from "components/atoms/TimeSelector/TimeSelector";
import { useWidth } from "utils/responsiveHook";

const EditAvailability = ({
  closeModal,
  onConfirm,
  loading,
  selectedEvent,
}: EditAvailabilityProps) => {
  const {
    control,
    watch,
    handleSubmit,
    errors,
  } = useEditAvailability(selectedEvent);

  const {
    localeBasics,
    localeTitles,
    localePlaceholders,
    localeButtons,
    localeLabels,
  } = useLocale();

  const { width } = useWidth();

  return (
    <div className={styles["AvailabilitySchedule"]}>
      <div className={styles["AvailabilitySchedule-header"]}>
        <div></div>
        <Text className={styles["title"]}>
          {localeTitles.TITLE_AVAILABILITY_SCHEDULE}
        </Text>
        <div onClick={closeModal} className="cursor-pointer">
          <CloseCircleIcon color="#8BA3F5" size="33" />
        </div>
      </div>

      <Text className={styles["subTitle"]}>
        {width > breakPoints.md
          ? localeBasics.TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT
          : localeBasics.TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT_MOBILE}
      </Text>

      <div className={styles["AvailabilitySchedule-main-body"]}>
        {width < breakPoints.md && <div className={styles["border"]}></div>}


        {/* {loadingAvailability?(<Loader />):
        ( */}
        <div className={styles["AvailabilitySchedule-dates"]}>
          <div className={styles["AvailabilitySchedule-eachSection"]}>
            <div className={styles["eachSection-main"]}>
              <div className={styles["avaiability-div"]}>
                <Text className={styles["heading"]}>
                  {dayjs(watch(`date`)).format("dddd, DD MMM")}
                </Text>
                <ControlSelect
                  name={`availability`}
                  control={control}
                  list={availabilityScheduleSowOptions(localeLabels)}
                  className={styles["selectInputAvailability"]}
                  selectTextClass={styles["selct-input"]}
                />

                {watch(`availability`) === "partial" &&
                  watch(`availability`) && (
                    <ControlTimeSelector
                      name={`timeFrame`}
                      control={control}
                      customError={errors?.timeFrame?.message}
                      type="range"
                      format="HH:mm"
                      // className={styles["timeRangeClassName"]}
                    />
                  )}
                {watch(`availability`) === "available" &&
                  watch(`availability`) && (
                    <ControlSelect
                      control={control}
                      name={`shift`}
                      placeholder={localePlaceholders.PLACEHOLDERS_ENTER_SHIFT}
                      className={styles["selectInputAvailability"]}
                      list={shiftOPtions(localeLabels)}
                      selectTextClass={styles["selct-input"]}
                    />
                  )}
              </div>
              <div className={styles["eachSection-dates"]}>
                {/* {watch(`availability`) ===
                    "partial" && (
                    <TimeFrame
                      control={control}
                      index={index}
                      errors={errors}
                    />
                  )} */}

                {watch(`availability`) && (
                  <ControlInput
                    control={control}
                    name={`reason`}
                    placeholder={localePlaceholders.PLACEHOLDERS_ADD_REASON}
                    customError={errors?.reason?.message}
                    className={styles["inputField"]}
                    wrapClassName={styles["inputField-wrap"]}
                    type="text"
                  />
                )}
              </div>
            </div>
            {watch(`availability`) && <div className={styles["border"]}></div>}
          </div>
        </div>
      </div>

      <div className={styles["AvailabilitySchedule-buttons"]}>
        <Button
          loading={loading}
          // type="button"
          className={styles["saveButton"]}
          onClick={handleSubmit(onConfirm)}
        >
          {localeButtons.BUTTON_EDIT_NOW}
        </Button>
        {width > breakPoints.md && (
          <Button
            className={styles["cancelButton"]}
            onClick={(e) => {
              e?.stopPropagation();
              closeModal();
            }}
          >
            {localeButtons.BUTTON_CANCEL}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditAvailability;
