import { useLocale } from "locales";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UsersContextConsumer } from "store/AuthProvider";
import { ToastConsumer } from "store/ToastProvider";
import { IJobCompleted } from "types/schema";
import { HireWorkerApi } from "utils/globalAPI/OrganizationWorkers";

export const useWorkerDetailsPage = (refetch: () => void) => {
  const [selectedCompanyData, setSelectedCompanyData] =
    useState<IJobCompleted>();

  const getData = (data: IJobCompleted) => {
    setSelectedCompanyData(data);
  };

  const navigate = useNavigate();

  const { userId, organization } = useParams();

  const onNavigate = () => {
    navigate(`/organization/${organization}/schedule/${userId}`);
  };

  const [modal, setModal] = useState({
    invite: false,
    hire: false,
  });
  const [hireLoading, setHireLoding] = useState(false);
  const toast = ToastConsumer();
  const { localeSuccess } = useLocale();
  const { accessToken } = UsersContextConsumer();

  const [workerId, setWorkerId] = useState<string>("");

  const handleClose = (type: "invite" | "hire") => {
    setModal({
      ...modal,
      [type]: false,
    });
  };
  const handleOpen = (type: "invite" | "hire") => {
    setModal({
      ...modal,
      [type]: true,
    });
  };

  const onClickHire = (id: string) => {
    setWorkerId(id);
    handleOpen("hire");
  };
  const { jobId } = useParams();

  const onHireWorker = async () => {
    try {
      setHireLoding(true);
      const response = await HireWorkerApi(
        jobId,
        workerId,
        organization,
        accessToken
      );
      toast.success(localeSuccess.SUCESS_HIRE, "top-right");
      await refetch()
      handleClose("hire");
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setHireLoding(false);
    }
  };

  const onCloseHireModal = () => {
    handleClose("hire");
  };

  return {
    getData,
    selectedCompanyData,
    onNavigate,
    onCloseHireModal,
    onHireWorker,
    onClickHire,
    hireLoading,
    hireModal:modal.hire
  };
};
