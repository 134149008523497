import type { CardWithActionButtonsProps } from "./@types";
import styles from "./CardWithActionButtons.module.scss";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";
import HeartIcon from "./HeartIcon";
import LocationIcon from "./LocationIcon";
import ProfileIcon from "./ProfileIcon";
import BriefCaseIcon2 from "./BriefCaseIcon2";
import dayjs from "dayjs";

import { handleImagesPath } from "utils/hooks/helper";
import { BsTrash } from "react-icons/bs";
import { useEffect, useState } from "react";
import EditPencilIcon from "assets/svgs/EditPencilIcon";

const CardWithActionButtons = ({
  data,
  onDeleteJob,
  OnEditJob,
  onChangeJobPosting,
}: CardWithActionButtonsProps) => {
  const {
    localeBasics,
    localeLabels,
    localeButtons: localeButtons,
  } = useLocale();

  return (
    <div className={styles["CardWithActionButtons"]}>
      <div className={styles["CardWithActionButtons-left"]}>
        <div 
        className="flex flex-row justify-between w-full p-4"
        >
          <div
          className="flex flex-row"
          >
            <div className={styles["left-image"]}>
              <Img
                className={styles["companyLogo"]}
                src={handleImagesPath(data?.organization?.logo)}
              />
            </div>
            <div className={`${styles["left-details"]} ml-2`}>
              <div className={styles["left-details-top"]}>
                <Text className={styles["title"]}>{data?.title}</Text>
                <Text className={styles["companyName"]}>
                  {data?.organization?.companyName}
                </Text>
              </div>
            </div>
          </div>
          <Button
            leftIcon={<EditPencilIcon />}
            className="primary"
            onClick={(event) => {
              event?.stopPropagation();
              OnEditJob && OnEditJob(data);
            }}
          >
            {localeButtons.BUTTON_EDIT_DETAILS}
          </Button>
        </div>
      </div>
      <div className={`${styles["pc-row"]}`}>
        <div className={`${styles["pc-mobile"]}`}>
          <div className={styles["left-details-iconText"]}>
            <LocationIcon size={16} />
            <Text className={styles["description"]}>
              {data?.address?.city} {data?.address?.country}
            </Text>
          </div>
          <div className={styles["left-details-iconText"]}>
            <ProfileIcon size={16} />
            <Text className={styles["description"]}>
              {data?.totalHiredWorkers} / {data?.workerNeeded}{" "}
              {localeLabels.LABEL_PERSON}
            </Text>
          </div>
        </div>
        <div className={styles["CardWithActionButtons-right"]}>
          <Text className={styles["description"]}>{`${
            localeBasics.TEXT_POSTED_ON
          } ${dayjs(data?.createdAt).format("DD MMM YYYY")}`}</Text>

          <div className={styles["button-div"]}>
            <Button
              leftIcon={<BsTrash />}
              className="primary"
              onClick={(event) => {
                event?.stopPropagation();
                onDeleteJob && onDeleteJob(data);
              }}
            >
              {localeButtons.BUTTON_DELETE}
            </Button>

            <SelectFullfilledBy
              onChange={(value) => {
                onChangeJobPosting && onChangeJobPosting(value);
              }}
              defaultValue={data?.fullfilledBy}
            />
            {/* <Button
              leftIcon={<BriefCaseIcon2 />}
              className="primary"
              onClick={(event) => {
                event?.stopPropagation();
                onChangeJobPosting && onChangeJobPosting(data);
              }}
            >
              {data?.jobPostingCheck
                ? localeButtons.BUTTONS_JOB_FULLFILLED_BY_USERS
                : localeButtons.BUTTONS_JOB_FULLFILLED_BY_ADMIN}
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectFullfilledBy = ({
  onChange,
  defaultValue,
}: {
  onChange: (value: "under_review" | "admin" | "users") => void;
  defaultValue: "under_review" | "admin" | "users";
}) => {
  const localeButtons = useLocale().localeButtons;

  const [fullfilledBy, setFullfilledBy] = useState<
    "under_review" | "admin" | "users"
  >(defaultValue || "under_review");

  const handleChange = (e) => {
    setFullfilledBy(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setFullfilledBy(defaultValue);
  }, [defaultValue]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <select
        onChange={handleChange}
        // stopPropagation is used to prevent the click event from bubbling up to the parent element

        value={fullfilledBy}
        className={`${styles["select-primary"]} ${styles[fullfilledBy]}`}
      >
        <option value="under_review">
          {localeButtons.BUTTONS_JOB_IN_REVIEW}
        </option>
        <option value="admin">
          {localeButtons.BUTTONS_JOB_FULLFILLED_BY_ADMIN}
        </option>
        <option value="users">
          {localeButtons.BUTTONS_JOB_FULLFILLED_BY_USERS}
        </option>
      </select>
    </div>
  );
};

export default CardWithActionButtons;
