import React from "react";
type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function ChevronRightIcon({ white, size }: Props) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 15.7266L9.02367 10L0 4.27344V0L15.7576 10L0 20V15.7266Z"
        fill="#9FB5FF"
      />
    </svg>
  );
}

export default ChevronRightIcon;
