import React from "react";
import type { tableItemsProps } from "./@types";
import OrderTableItemsMobile from "../TableItems/OrderTableItemsMobile";
import { useWidth } from "../../../utils/responsiveHook";
import TableItemsList from "../TableItems";

const TableItems = ({
  itemData,
  openModal,
  onClickDetails,
  tableType = "Application_status",
  navigateTo,
  onModalEdit,
  number,
  onClickDelete,
  onClickEdit,
  onToggleCategroryPrice
  ,isAllowed
}: tableItemsProps) => {
  const { width } = useWidth();

  // switch (tableType) {
  //   case "Application_status":
  //     return width <= 767 ? (
  //       <OrderTableItemsMobile
  //         tableType={tableType}
  //         itemData={itemData}
  //         navigateTo={navigateTo}
  //       />
  //     ) : (
  return (
    <TableItemsList
      onClickEdit={onClickEdit}
      onClickDelete={onClickDelete}
      number={number}
      tableType={tableType}
      itemData={itemData}
      navigateTo={navigateTo}
      onModalEdit={onModalEdit}
      onToggleCategroryPrice={onToggleCategroryPrice}
      isAllowed={isAllowed}
    />
  );
  // );

  //   default:
  //     return null;
  // }
};

export default TableItems;
