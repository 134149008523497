import { AxiosResponse } from "axios";
import apiRequest from "../../axios";
import {
  GetNotificationResponse,
  getDashboardApiResponse,
  accessTokenApiResponse,
  GenReportApiResponse,
  getReportResponse,
} from "../../../types/ApiResponse";
import dayjs from "dayjs";

export const ApiGetDashboard = async (
  token: string
): Promise<getDashboardApiResponse> => {
  // console.log("ApiGetAllUsers", type, "offset", offset);

  const response = await apiRequest({
    method: "get",
    url: `/Admin/dashboard`,
    token,
  });

  return response;
};

export const ApiGetNotifications = async (
  token: string
): Promise<GetNotificationResponse> => {
  const response = await apiRequest({
    method: "get",
    url: `/Admin/getNotification`,
    token,
  });

  return response;
};
export const ApiGetAccessToken = async (
  token: string
): Promise<accessTokenApiResponse> => {
  const response = await apiRequest({
    method: "post",
    url: `/Admin/login`,
    token,
  });

  return response;
};
export const genrateReport = async (
  data: {
    worker: string;
    address: string;
    organization: string;
    reportGroup: string;
    reportType: string;
    dates: Date[];
  },
  token: string
) => {
  let url = `/Admin/genrateReports?`;

  if (data?.worker) url += `worker=${data?.worker}&`;
  if (data?.address) url += `address=${data?.address}&`;
  if (data?.organization) url += `organization=${data?.organization}&`;
  if (data?.reportGroup) url += `reportGroup=${data?.reportGroup}&`;
  if (data?.reportType) url += `reportType=${data?.reportType}&`;
  if (data?.dates)
    url += `startDate=${
      data?.dates?.[0] ? dayjs(data?.dates?.[0]).toDate() : undefined
    }&endDate=${
      data?.dates?.[1] ? dayjs(data?.dates?.[1]).toDate() : undefined
    }`;

  const response = await apiRequest({
    method: "get",
    url: url,
    token,
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "blob",
    },
  });

  return response;
};
export const downloadReports = async (reportId: string, token: string) => {
  let url = `/Admin/downloadReport?reportId=${reportId}`;

  const response = await apiRequest({
    method: "get",
    url: url,
    token,
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "blob",
    },
  });

  return response;
};
export const getReports = async (
  offset: string,
  token: string
): Promise<getReportResponse> => {
  let url = `/Admin/reports?offset=${offset}`;

  const response = await apiRequest({
    method: "get",
    url: url,
    token,
  });

  return response;
};

export type seeAllJobStatusApi = "under_review" | "admin" | "users" | "deleted" | "rejected" | "cancelled"

export const getAllJobAPI = async (
  fullfilledBy:seeAllJobStatusApi,
  token: string
) => {
   
  const response = await apiRequest({
    method: "post",
    url: `/organization/Job/admin/alljobs`,
    data:{
      fullfilledBy
    },
    token,
  });
   
  return response;
};
export const getOrganizationJobAPI = async (
  status: string,
  organizationId:string,
  address:string[],
  token: string
) => {
   
  const response = await apiRequest({
    method: "get",
    url: `/organization/Job/admin/${organizationId}?jobStatus=${status}&address=${address?.join(",")}`,
    token,
  });
   
  return response;
};


export const getOrganizationJobByIdAPI = async (
  id: string,
  token: string
) => {

  const response = await apiRequest({
    method: "get",
    url: `/organization/Job/${id}/admin`,
    token,
  });

  return response;
};
export const deleteJobApi = async (
  id: string,
  token: string
) => {
  const response = await apiRequest({
    method: "delete",
    url: `/organization/Job/${id}/admin`,
    token,
  });

  return response;
};

export const onChecngeJobPostingCheck = async (
  id: string,
  fullfilledBy: "under_review" | "admin" | "users",
  token: string
) => {
  const response = await apiRequest({
    method: "post",
    url: `/Admin/job/change`,
    token,
    data:{
      fullfilledBy,
      jobId:id
    }
  });

  return response;
};