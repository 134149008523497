import type { EmptyScreenProps } from "./@types";
import styles from "./EmptyInbox.module.scss";

import { Text } from "components/atoms/TextF";

import EmptyMessagesIcon from "assets/svgs/EmptyMessagesIcon";
import { useLocale } from "locales";

import { useNavigate } from "react-router-dom";

const EmptyInbox = ({ title, text }: EmptyScreenProps) => {
  const { localeBasics } = useLocale();

  const navigate = useNavigate();
  return (
    <div className={styles["noDataDiv"]}>
      <EmptyMessagesIcon size={"48"} />
      <Text className={styles["noData-title"]}>
        {title || localeBasics.TEXT_NO_CHAT_TITLE}
      </Text>
      <Text className={styles["noData-text"]}>
        {text || localeBasics.TEXT_NO_CHAT_MESSAGES}
      </Text>
    </div>
  );
};

export default EmptyInbox;
