import { useEmployeeServices } from "./hook";
import ListPage from "../../pages/ListPage";
import Loader from "../../components/atoms/Loader";
import { on } from "events";

export interface services {}

const EmployeeServices = ({}: services) => {
  const {
    listLoading,
    usersList,
    hasMore,
    totalUsers,
    onNextPage,
    onPreviousPage,
    currentPage,
    order,
    onChangeFilter,

    
    changeStausFilter,
    stausFilter


  } = useEmployeeServices();
  if (listLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <ListPage
      type={"Employee"}
      list={usersList}
      hasMore={hasMore}
      totalUsers={totalUsers}
      loading={listLoading} 
    
      showPagination={true}
      OnNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      currentPage={currentPage}
      
    changeStausFilter={changeStausFilter}
    stausFilter={stausFilter}
      filter={{
        order: order.order,
        column: order.column,
        onChange: onChangeFilter,
      }}
    />
  );
};

export default EmployeeServices;

// export default function AccountService() {
//   return (
//     <ProtectedRoute>
//       <AccountServiceComponent />
//     </ProtectedRoute>
//   );
// }
