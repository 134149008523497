import { UsersContextConsumer } from "../../AuthProvider";
import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
let newSocket: any = null;
const useSocket = (serverURL: string) => {
  const { accessToken , fetchApi } = UsersContextConsumer();
  const [socket, setSocket] =
    useState<Socket<DefaultEventsMap, DefaultEventsMap>>();
  const [connected, setConnected] = useState(false);
  console.log("serverURL", serverURL);
  useEffect(() => {
    if (fetchApi && accessToken && !connected) {
      newSocket = io(serverURL,{
        auth: {
          token: accessToken,
        },
        path:"/admin",
        reconnectionDelay: 1000,
        reconnection:true,
        forceNew: true,
        timeout: 20000,
        autoConnect: true,
      });
      newSocket.on("connect", () => {
        setSocket(newSocket);
        setConnected(true);
      })
      newSocket?.on("disconnect", () => {
        setConnected(false);
        setSocket(undefined);
      });
    }
    return () => {
     newSocket?.disconnect?.();
    };
  }, [serverURL, fetchApi,accessToken]);

  useEffect(() => {
    // emit disconnect event  when user logout
    if (!(fetchApi && accessToken) && socket) {
      socket?.disconnect();
      setConnected(false);
    }
  }, [socket,fetchApi]);
 return { socket, connected };
};
export default useSocket;
