import { Img } from "components/atoms/Img";
import type { UploadCardProps } from "./@types";
import styles from "./UploadCard.module.scss";
import { Text } from "components/atoms/TextF";
import Dropzone from "react-dropzone";
import { useLocale } from "locales";
import { Controller } from "react-hook-form";

import FileViewerModal from "../FileViewerModal";
import { useState } from "react";

const UploadCard = ({
  Icon,
  title,
  text,
  className,
  inputClass,
  name = "file",
  control,
  maxFiles = 1,
  accept,
  imageClass,
  disabled,
  maxSize = 1000000,
}: UploadCardProps) => {
  const { localeLabels } = useLocale();

  const [showFileViewer, setShowFileViewer] = useState(false);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <>
          <Dropzone
            maxFiles={maxFiles}
            maxSize={maxSize}
            onDrop={(acceptedFiles) => {
              console.log(acceptedFiles);
              onChange([...value, ...acceptedFiles]);
              console.log("uploadCard", value, "acceptedFiles", acceptedFiles);
            }}
            multiple
            accept={accept}
            disabled={value?.length == maxFiles || disabled}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={`${errors?.[name]?.message?.toString()? styles["error-border"]: "" } ${styles["uploadCard"]} ${className}`}
                // onClick={open}
              >
                
                {<input {...getInputProps()} />}
                {/* <div className={styles['uploadCard-main']}> */}
                <div className={`${styles["drop-zone"]} ${inputClass}`}>
                  {/* {value?.length > 0 && (
                    <Img
                      className={`${styles["image"]} ${imageClass}`}
                      src={URL?.createObjectURL(value[0] as Blob | MediaSource)}
                    />
                  )} */}
                  <button
                    className={styles["text-icon"]}
                    onClick={(event) => {
                      if (value.length == 0) return;
                      setShowFileViewer(!showFileViewer);
                      // alert("");
                      event.stopPropagation();
                    }}
                    role="button"
                    type="button"
                  >
                    {Icon && Icon}
                    <div className=" align-start justify-start flex flex-col ">
                      <Text className={`${styles["text"]} ${errors?.[name]?.message?.toString()? styles["error-title"]: "" }`}>{text}</Text>
                      <Text className={styles["text"]}>
                        {value?.length > 0 && `selected ${value?.length} files`}
                      </Text>
                    </div>
                  </button>

                  <div className={"flex justify-between"}>
                    {value?.length < maxFiles  && !disabled && (
                      <Text className={styles["title"]}>{title}</Text>
                    )}

                    {value?.length > 0 && !disabled && (
                      <Text
                        className={styles["deleteText"]}
                        onClick={(event) => {
                          if(disabled)
                          return;
                          onChange([]);
                          const target = event.stopPropagation();
                        }}
                      >
                        {localeLabels.LABEL_DELETE}
                      </Text>
                    )}
                  </div>
                </div>
                <FileViewerModal
                  open={showFileViewer}
                  onClose={() => {
                    setShowFileViewer(false);
                  }}
                  files={value}
                />
              </div>
            )}
          </Dropzone>
          {/* {!!errors && (
            <ErrorMessage errors={errors?.[name]?.message as string} />
          )} */}
        </>
      )}
    />
  );
};

export default UploadCard;
