import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSocketIO } from "store/SocketConsumer";
import { ToastConsumer } from "store/ToastProvider";

import { INotificaiton } from "types/schema";

let notification: {
  recent: INotificaiton[];
  earlier: INotificaiton[];
} = {
  recent: [],
  earlier: [],
};

export const useNotificationService = () => {

  const { serverError } = ToastConsumer();

  const { sockets, connected } = useSocketIO();
  const [notifications, setNotification] = useState(notification);

  const deleteNotification = async (notificationId: string) => {
    try {
      // await deleteNotifications(notificationId,accessToken);
      // debugger
      sockets?.emit("delete-notifications", notificationId);
      setNotification((item) => ({
        recent:
          item?.recent.filter((item) => item?._id !== notificationId) || [],
        earlier:
          item?.earlier.filter((item) => item?._id !== notificationId) || [],
      }));
    } catch (err: any) {
      serverError(err);
    }
  };

  useEffect(() => {
    if (connected) {
      sockets?.emit("get-notifications", (data: any) => {
        setNotification(data);
        console.log("notification", data);
      }); 
    }

    return () => {
      sockets?.off("refresh-notification");
      sockets?.off("get-notification");
    };
  }, [, sockets, connected]);

  const updateNotificationReadAbility = async (notificationId: string) => {
    sockets?.emit("read-notification", notificationId);
  };

  return {
    notifications,
    deleteNotification,
    updateNotificationReadAbility,
  };
};
