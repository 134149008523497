import { Text } from "components/atoms/TextF";
import styles from "./MonthView.module.scss";
import { MonthViewProps } from "./@types";
import { useLocale } from "locales";
import dayjs, { Dayjs } from "dayjs";
import LabelIndicator from "assets/svgs/LabelIndicator";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import { useState } from "react";
import { Pannel } from "../DayView/Panel";
import { TableCell } from "../WeekView/WeekView";
import { BsPlus } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { useWidth } from "utils/responsiveHook";

const generateMonthViewData = (selectedDate: dayjs.Dayjs) => {
  const weeks = [];
  const firstDayOfMonth = selectedDate.startOf("month");
  const lastDayOfMonth = selectedDate.endOf("month");
  let currentWeekStart = firstDayOfMonth.startOf("week").add(1, "day");
  let currentDay = currentWeekStart;

  while (
    currentDay.isBefore(lastDayOfMonth) ||
    currentDay.isSame(lastDayOfMonth)
  ) {
    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(currentDay);
      currentDay = currentDay.add(1, "day");
    }
    weeks.push(week);
  }

  return weeks;
};

const MonthView = ({
  selectedDate,
  eventsData,
  addAvailability,
  refetchData,
}: MonthViewProps) => {
  const weeks = generateMonthViewData(selectedDate);
  const { localeLabels } = useLocale();

  function getWeekNumber(dateString: string): number {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(
      ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );
    return weekNumber;
  }

  const { width } = useWidth();

  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const [currentSelectedDate, setCurrentSelectedDate] = useState(
    selectedDate.toDate()
  );

  const [params, setParamas] = useSearchParams();

  const openPopover = (date: dayjs.Dayjs, jobId?: string) => {
    if (jobId) {
      setParamas({ job: jobId, date: date.format("YYYY-MM-DD") });
    } else {
      setParamas({ date: date.format("YYYY-MM-DD") });
    }

    setCurrentSelectedDate(date.toDate());
    setPopoverOpen(true);
  };

  return (
    <>
      <div className={styles["month-view-main"]}>
        <table
          className={
            isPopoverOpen ? styles["month-view"] : styles["month-view-full"]
          }
        >
          <thead className={styles["datesContainer-head"]}>
            <tr className={styles["datesContainer-1"]}>
              <td
                className={`${styles["dayLabel-day"]} ${styles["dayLabel-day-none"]}`}
              >
                <Text className={styles["dayLabel"]}>
                  {localeLabels.LABEL_DAY}
                </Text>
              </td>
              <td className={styles["dayLabel-day"]}>
                {localeLabels.LABEL_MON.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={styles["dayLabel-day"]}>
                {localeLabels.LABEL_TUE.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={styles["dayLabel-day"]}>
                {localeLabels.LABEL_WED.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={styles["dayLabel-day"]}>
                {localeLabels.LABEL_THU.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={styles["dayLabel-day"]}>
                {localeLabels.LABEL_FRI.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={styles["dayLabel-day"]}>
                {localeLabels.LABEL_SAT.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={styles["dayLabel-day"]}>
                {localeLabels.LABEL_SUN.slice(
                  0,
                  width > breakPoints.lg ? 3 : 1
                )}
              </td>
              <td className={styles["dayLabel-day"]}></td>
            </tr>
          </thead>
          <tbody>
            {weeks?.map((week, weekIndex: number) => (
              <tr className={styles["datesContainer"]}>
                <td className={styles["dateContainer-week"]}>
                  <Text className={styles["dayLabel"]}>
                    {`${localeLabels.LABEL_WEEK} ${getWeekNumber(
                      week[0].format("YYYY-MM-DD")
                    )}`}
                  </Text>
                </td>
                {week?.map((date, dateIndex: number) => {
                  const availabilityEvent =
                    eventsData?.[dayjs(date).format("YYYY-MM-DD")]
                      ?.availability;
                  const jobs =
                    eventsData?.[dayjs(date).format("YYYY-MM-DD")]?.hiredWorker;

                  return (
                    <TableCell
                      availabilityEvent={availabilityEvent}
                      jobs={jobs}
                      dateIndex={dateIndex}
                      openPopover={openPopover}
                      date={date}
                      refetchData={refetchData}
                      isClander={true}
                    />
                  );
                })}

                <td className={styles["dateContainer"]}>
                  <Text className={styles["addAvailability"]}>
                    <BsPlus
                      color={"white"}
                      onClick={() =>
                        addAvailability([
                          dayjs(week[0]).startOf("week").toDate(),
                          dayjs(week[0]).endOf("week").toDate(),
                        ])
                      }
                      size={width > breakPoints.lg ? 40 : 40}
                    />
                  </Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isPopoverOpen && (
          <Pannel
            setPopoverOpen={setPopoverOpen}
            isPopoverOpen={isPopoverOpen}
            selectedDate={currentSelectedDate}
            jobs={
              eventsData?.[dayjs(currentSelectedDate).format("YYYY-MM-DD")]
                ?.hiredWorker
            }
            availability={
              eventsData?.[dayjs(currentSelectedDate).format("YYYY-MM-DD")]
                ?.availability
            }
            addAvailability={() =>
              addAvailability([
                dayjs(currentSelectedDate).startOf("week").toDate(),
                dayjs(currentSelectedDate).endOf("week").toDate(),
              ])
            }
            refetchData={refetchData}
          />
        )}
      </div>
      <div className={styles["datesContainer-bottom"]}>
        <div className={styles["indicators"]}>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="#181203" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_HIRED}
            </Text>
          </div>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="#DA011C" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_REJECTED}
            </Text>
          </div>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="rgb(228, 174, 38)" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_DECLINED}
            </Text>
          </div>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="#819bf7" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_APPROVED}
            </Text>
          </div>
          <div className={styles["each-indicator"]}>
            <LabelIndicator color="#C9D5FF" />
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_COMPLETED}
            </Text>
          </div>
        </div>
        {width < breakPoints.md && (
          <div className={styles["indicators"]}>
            <div className={styles["each-indicator"]}>
              <LabelIndicator color="#009633" />
              <Text className={styles["labelText"]}>
                {localeLabels.LABEL_AVAILABLE}
              </Text>
            </div>
            <div className={styles["each-indicator"]}>
              <LabelIndicator color="#DA011C" />
              <Text className={styles["labelText"]}>
                {localeLabels.LABEL_UNAVAILABLE}
              </Text>
            </div>
            <div className={styles["each-indicator"]}>
              <LabelIndicator color="#B5B8C3" />
              <Text className={styles["labelText"]}>
                {localeLabels.LABEL_PARTIAL_AVAILABLE}
              </Text>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MonthView;
