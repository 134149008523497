import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function BroomIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.87031 5.66912L6.45031 7.74914L4.89031 5.18914C4.32031 4.24914 4.62031 3.00914 5.56031 2.43914C6.50031 1.86914 7.74031 2.16913 8.31031 3.10913L9.87031 5.66912Z"
        stroke={color ? color : "#101828"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8191 9.1596L8.65914 11.0796C6.81914 12.1996 6.25913 14.4596 7.14913 16.2596L9.19913 20.4396C9.85913 21.7896 11.4591 22.2596 12.7391 21.4696L19.1691 17.5596C20.4591 16.7796 20.7691 15.1496 19.8791 13.9396L17.1091 10.1996C15.9091 8.57964 13.6591 8.0396 11.8191 9.1596Z"
        stroke={color ? color : "#101828"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7574 5.09815L5.63281 8.21875L7.71321 11.6351L12.8378 8.51455L10.7574 5.09815Z"
        stroke={color ? color : "#101828"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3105 16.8105L15.9606 19.5206"
        stroke={color ? color : "#101828"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.75 18.3691L13.4 21.0792"
        stroke={color ? color : "#101828"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.8691 15.25L18.5191 17.96"
        stroke={color ? color : "#101828"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BroomIcon;

// fill={white ? "#fffff" : "#0F1322"}
