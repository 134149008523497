import React from "react";
import { Text } from "components/atoms/TextF";
import styles from "./AvailabilitySchedule.module.scss";
import { AvailabilityScheduleProps } from "./@types";
import { useLocale } from "locales";

import Input from "components/atoms/Input";
import { Button } from "components/atoms/ButtonF";

import CloseCircleIcon from "assets/svgs/CloseCircleIcon";

import "./index.scss";

import DatePicker, { DateObject } from "react-multi-date-picker";

import InputIcon from "react-multi-date-picker/components/input_icon";
import dayjs from "dayjs";

import {
  availabilityScheduleSowOptions,
  shiftOPtions,
} from "constants/constantData";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import { useAvailablilitySchedule } from "./hook";
import { ControlInput } from "components/atoms/ControlledInput/Input";
import ControlSelect from "components/molecules/ControlSelect/Select";

// import { useHeight, useWidth } from "Common/hooks/responsiveHook";
import { ControlTimeSelector } from "components/atoms/TimeSelector/TimeSelector";
import { useWidth } from "utils/responsiveHook";

const AvailabilitySchedule = ({
  closeModal,
  onConfirm,
  loading,
  defaultValues,
}: AvailabilityScheduleProps) => {
  const {
    fields,
    control,
    selectedWeek,
    watch,
    handleSubmit,
    onChangeDates,
    errors,
    copyPreviosWeeSchedule,
  } = useAvailablilitySchedule(defaultValues);

  const {
    localeBasics,
    localeTitles,
    localePlaceholders,
    localeButtons,
    localeLabels,
  } = useLocale();

  const { width } = useWidth();

  const headerRef = React.useRef<HTMLDivElement>(null);
  const subHeaderRef = React.useRef<HTMLDivElement>(null);
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);
  const modal = React.useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   // adjust height of body by subtracting height of header,subHeader and footer from modal
  //   if (
  //     headerRef.current &&
  //     subHeaderRef.current &&
  //     bodyRef.current &&
  //     footerRef.current &&
  //     modal.current
  //   ) {
  //     const headerHeight = headerRef.current.getBoundingClientRect().height;
  //     const subHeaderHeight =
  //       subHeaderRef.current.getBoundingClientRect().height;
  //     const footerHeight = footerRef.current.getBoundingClientRect().height;
  //     const modalHeight = modal.current.getBoundingClientRect().height;
  //     const bodyHeight =
  //       modalHeight - (headerHeight + subHeaderHeight + footerHeight);
  //     bodyRef.current.style.height = `${bodyHeight}px`;
  //   }
  // }, [height, width]);

  return (
    <div ref={modal} className={styles["AvailabilitySchedule"]}>
      <div ref={headerRef} className={styles["AvailabilitySchedule-header"]}>
        <div></div>
        <Text className={styles["title"]}>
          {localeTitles.TITLE_AVAILABILITY_SCHEDULE}
        </Text>
        <div onClick={closeModal} className="cursor-pointer">
          <CloseCircleIcon color="#8BA3F5" size="33" />
        </div>
      </div>

      <Text ref={subHeaderRef} className={styles["subTitle"]}>
        {width > breakPoints.md
          ? localeBasics.TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT
          : localeBasics.TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT_MOBILE}
      </Text>

      <div ref={bodyRef} className={styles["AvailabilitySchedule-main-body"]}>
        {width < breakPoints.md && <div className={styles["border"]}></div>}
        <div className={styles["AvailabilitySchedule-main"]}>
          <Text className={styles["heading"]}>
            {localeTitles.TITLE_DATE_SELECTION}
          </Text>
          <div className={styles["datePicker-field"]}>
            <DatePicker
              value={selectedWeek}
              onChange={(newDates: DateObject[]) => {
                const selectDates = [
                  dayjs(newDates[0].format("YYYY-MM-DD"))
                    .startOf("week")
                    .toDate(),
                  dayjs(newDates[0].format("YYYY-MM-DD"))
                    .endOf("week")
                    .toDate(),
                ];
                onChangeDates(selectDates);
              }}
              range
              minDate={new DateObject().subtract(1, "days")}
              render={<InputIcon />}
            />
          </div>
        </div>
        {/* <div className={styles["AvailabilitySchedule-selectField"]}>
          <Text className={styles["select-field"]}>
            <Input
              type="checkbox"
              name="filters"
              value={"previous values"}
              className={styles["input-checkbox"]}
              onChange={copyPreviosWeeSchedule}
            />
            {localeLabels.LABEL_COPY_THE_PREVIOUS_WEEK_SCHEDULE}
          </Text>
        </div> */}

        {/* {loadingAvailability?(<Loader />):
        ( */}
        <div className={styles["AvailabilitySchedule-dates"]}>
          {fields?.map((data, index) => (
            <div
              className={styles["AvailabilitySchedule-eachSection"]}
              key={index}
            >
              <div className={styles["eachSection-main"]}>
                <div className={styles["avaiability-div"]}>
                  <Text className={styles["heading"]}>
                    {dayjs(watch(`availabilitySchedule.${index}.date`)).format(
                      "dddd, DD MMM"
                    )}
                  </Text>
                  <ControlSelect
                    name={`availabilitySchedule.${index}.availability`}
                    control={control}
                    list={availabilityScheduleSowOptions(localeLabels)}
                    className={styles["selectInputAvailability"]}
                    selectTextClass={styles["selct-input"]}
                  />

                  {watch(`availabilitySchedule.${index}.availability`) ===
                    "partial" &&
                    watch(`availabilitySchedule.${index}.availability`) && (
                      <ControlTimeSelector
                    name={`availabilitySchedule.${index}.timeFrame`}
                    control={control}
                    customError={
                      errors?.availabilitySchedule?.[index]?.timeFrame?.message
                    }
                    type="range"
                    format="HH:mm"
                    // className={styles["timeRangeClassName"]}
                    
                  />
                    )}
                  {watch(`availabilitySchedule.${index}.availability`) ===
                    "available" &&
                    watch(`availabilitySchedule.${index}.availability`) && (
                      <ControlSelect
                        control={control}
                        name={`availabilitySchedule.${index}.shift`}
                        placeholder={
                          localePlaceholders.PLACEHOLDERS_ENTER_SHIFT
                        }
                        className={styles["selectInputAvailability"]}
                        list={shiftOPtions(localeLabels)}
                        selectTextClass={styles["selct-input"]}
                        customError={
                          errors?.availabilitySchedule?.[index]?.shift?.message
                        }
                      />
                    )}
                </div>
                <div className={styles["eachSection-dates"]}>
                  {/* {watch(`availabilitySchedule.${index}.availability`) ===
                    "partial" && (
                    <TimeFrame
                      control={control}
                      index={index}
                      errors={errors}
                    />
                  )} */}

                  {watch(`availabilitySchedule.${index}.availability`) && (
                    <ControlInput
                      control={control}
                      name={`availabilitySchedule.${index}.reason`}
                      placeholder={localePlaceholders.PLACEHOLDERS_ADD_REASON}
                      customError={
                        errors?.availabilitySchedule?.[index]?.reason?.message
                      }
                      className={styles["inputField"]}
                      wrapClassName={styles["inputField-wrap"]}
                      type="text"
                    />
                  )}
                </div>
              </div>
              {watch(`availabilitySchedule.${index}.availability`)  && <div className={styles["border"]}></div>}
            </div>
          ))}
        </div>
      </div>

      <div ref={footerRef} className={styles["AvailabilitySchedule-buttons"]}>
        <Button
          loading={loading}
          // type="button"
          className={styles["saveButton"]}
          onClick={handleSubmit(onConfirm)}
        >
          {localeButtons.BUTTON_SAVE}
        </Button>
        {width > breakPoints.md && (
          <Button className={styles["cancelButton"]} onClick={closeModal}>
            {localeButtons.BUTTON_CANCEL}
          </Button>
        )}
      </div>
    </div>
  );
};

// export const TimeFrame = ({
//   index,
//   control,
//   errors,
// }: {
//   index: number;
//   control: Control<availabilityForm, any>;
//   errors: FieldErrors<availabilityForm>;
// }) => {
//   const { fields, remove, append } = useFieldArray({
//     control,
//     name: `availabilitySchedule.${index}.timeFrame`,
//   });

//   const { localeLabels, localePlaceholders, localeButtons } = useLocale();

//   useEffect(() => {
//     if (fields.length === 0) {
//       append({
//         ...fields,
//       });
//     }
//   }, [append, fields]);

//   return (
//     <div className={styles["partial-section"]}>
//       <div>
//         {fields?.length >= 1 && (
//           <Button
//             className={styles["partial-actionBtn"]}
//             leftIcon={<AddCircleIcon size="18" color="#101828" />}
//             onClick={() =>
//               append({
//                 startTime: new DateObject(),
//                 endTime: new DateObject(),
//                 reasonP: "",
//               })
//             }
//           >
//             {localeButtons.BUTTON_ADD_MORE_SHIFT}
//           </Button>
//         )}
//       </div>
//       {fields?.map((timeFrame, timeFrameIndex) => (
//         <>
//           <div className={styles["partial-section-main"]}>
//             <div className={styles["eachSection-dates-availability"]}>
//               <div className={styles["selectDiv-timeFrame"]}>
//                 <ControlTimeSelector
//                   name={`availabilitySchedule.${index}.timeFrame.${timeFrameIndex}.startTime`}
//                   control={control}
//                   customError={
//                     errors?.availabilitySchedule?.[index]?.timeFrame?.[
//                       timeFrameIndex
//                     ]?.startTime?.message
//                   }
//                   type="range"
//                 />

//                 <Text className={styles["label"]}>
//                   {localeLabels.LABEL_TO}:
//                 </Text>
//                 <div className={styles["timeDiv"]}>
//                   <ControlTimeSelector
//                     name={`availabilitySchedule.${index}.timeFrame.${timeFrameIndex}.endTime`}
//                     control={control}
//                     customError={
//                       errors?.availabilitySchedule?.[index]?.timeFrame?.[
//                         timeFrameIndex
//                       ]?.endTime?.message
//                     }
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className={styles["eachSection-dates-availability"]}>
//               <Text className={styles["heading"]}>
//                 {localeLabels.LABEL_REASON}
//               </Text>
//               <div className={styles["selectDiv"]}>
//                 <ControlInput
//                   control={control}
//                   name={`availabilitySchedule.${index}.timeFrame[${timeFrameIndex}].reasonP`}
//                   placeholder={localePlaceholders.PLACEHOLDERS_ADD_REASON}
//                   className={styles["inputField"]}
//                   wrapClassName={styles["inputField-wrap"]}
//                   customError={
//                     errors?.availabilitySchedule?.[index]?.timeFrame?.[
//                       timeFrameIndex
//                     ]?.reasonP?.message
//                   }
//                   type="text"
//                 />
//               </div>
//             </div>
//           </div>

//           <div className={styles["partial-action"]}>
//             <Button
//               className={styles["partial-actionBtn"]}
//               leftIcon={<TrashIcon size="18" />}
//               onClick={() => remove(timeFrameIndex)}
//             >
//               {localeButtons.BUTTON_DELETE_SHIFT}
//             </Button>
//             {fields?.length < 1 && (
//               <Button
//                 className={styles["partial-actionBtn"]}
//                 leftIcon={<AddCircleIcon size="18" color="#101828" />}
//                 onClick={() =>
//                   append({
//                     startTime: new DateObject(),
//                     endTime: new DateObject(),
//                     reasonP: "",
//                   })
//                 }
//               >
//                 {localeButtons.BUTTON_ADD_MORE_SHIFT}
//               </Button>
//             )}
//           </div>
//         </>
//       ))}
//     </div>
//   );
// };

export default AvailabilitySchedule;
