import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function LabelIndicator({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <circle cx="6" cy="6" r="6" fill={color ? color : "#C9D5FF"} />
    </svg>
  );
}

export default LabelIndicator;

// fill={white ? "#fffff" : "#0F1322"}
