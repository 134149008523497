import { useNavigate } from "react-router-dom";

import type { dropDownProps } from "./@types";
import styles from "./DropDown.module.scss";
import useComponentVisible from "../../../utils/useComposite";
import { useLocale } from "../../../locales";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Text } from "../../atoms";
import { useWidth } from "../../../utils/responsiveHook";
import Image from "../../atoms/Image";
import { handleImagesPath } from "../../../utils/hooks/helper";
import dummyImg from "../../../assets/images/default.png";


const DropDown = ({
  isAdmin = false,
  onEditAccount = () => {},
  onUpdatePassword = () => {},
  signOut,
}: dropDownProps) => {
  const { localeDropdowns } = useLocale();
  const navigate = useNavigate();
  const { width } = useWidth();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const toggle = () => {
    if (isComponentVisible == true) {
      setIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
    }
  };

  // const editAccount = () => {
  //   setIsComponentVisible(false);
  //   onEditAccount();
  // };
  // const updatePassword = () => {
  //   setIsComponentVisible(false);
  //   onUpdatePassword();
  // };


  const logout = () => {
    signOut();
  };

  return (
    <div ref={ref}>
      <div
        className="flex items-center space-x-3 cursor-pointer"
        onClick={toggle}
      >
        <Image imagePath={dummyImg} className={styles["userImg"]} />
        <div className=" font-normal">
          <Text
            color={width <= 991 ? "white" : "black"}
            size={"s"}
            weight="600"
          >
            user name
          </Text>
          <Text color={width <= 991 ? "white" : "gray"} size={"xs"}>
            Admin
          </Text>
        </div>
        <MdOutlineKeyboardArrowDown size={26} />
      </div>
      {isComponentVisible ? (
        <div className="font-bold">
          <div className="bg-white p-2 mt-3 bg-white-A700 space-y-3 flex flex-col rounded-lg font-bold  top-20 right-15 absolute border-2 border-black w-[195px] z-20 ">
            {/* <div className="cursor-pointer" onClick={editAccount}>
              <Text size={"xs"} weight="700">
                {localeDropdowns.DROPDOWN_EDIT_ACCOUNT}
              </Text>
            </div>
            <div className="cursor-pointer" onClick={updatePassword}>
              <Text size={"xs"} weight="700">
                {localeDropdowns.DROPDOWN_UPDATE_PW}
              </Text>
            </div> */}
            <div className="cursor-pointer" onClick={logout}>
              <Text size={"xs"} weight="700">
                {localeDropdowns.DROPDOWN_LOGOUT}
              </Text>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DropDown;
