import { NavLink, useLocation, useNavigate } from "react-router-dom";

import type { sidebarProps } from "./@types";

import { useLocale } from "../../../locales";
import { Text } from "../../../components/atoms";
import Image from "../../atoms/Image";
import logo from "../../../assets/images/logo.png";

const Sidebar = ({ dashboard = "/" }: sidebarProps) => {
  const { localeLinks } = useLocale();

  const location = useLocation();

  return (
    <div className="lg:w-[230px]">
      <div className="w-[100%] bg-white min-h-screen text-white  hidden lg:block">
        <NavLink to={dashboard?.length != 0 ? dashboard : "/"}>
          <Image imagePath={logo} />
          {/* <div
            style={{ fontSize: "1.5rem" }}
            className="font-bold leading-6  align-middle  pt-3 pl-4 pb-3  cursor-pointer"
          >
            <Text color="white" size={"m"} weight="700">
              {localeTitles.TITLE_SAFR}
            </Text>
            <div className="text-[14px] font-bold">
              <Text color="white" weight="700" size={"xs"}>
                {titleType()}
              </Text>
            </div>
          </div> */}
        </NavLink>

        <div className="mt-20 flex flex-col">
          <>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2  h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer  pl-8 w-[100%] text-left  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={location.pathname === "/" ? "black" : "white"}
                weight={location.pathname === "/" ? "700" : "400"}
                size={"xs"}
              >
                {localeLinks.LINK_DASHBOARD}
              </Text>
            </NavLink>

            <NavLink
              to="/employees"
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/employees") ? "black" : "white"
                }
                weight={
                  location.pathname.includes("/employees") ? "700" : "400"
                }
                size={"xs"}
              >
                {localeLinks.LINK_EMPLOYEES_VIEW}
              </Text>
            </NavLink>

            <NavLink
              to={"/freelancers"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/freelancers") ? "black" : "white"
                }
                weight={
                  location.pathname.includes("/freelancers") ? "700" : "400"
                }
                size={"xs"}
              >
                {localeLinks.LINK_FREELANCERS_VIEW}
              </Text>
            </NavLink>

            <NavLink
              to={"/organizations"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/organizations")
                    ? "black"
                    : "white"
                }
                weight={
                  location.pathname.includes("/organizations") ? "700" : "400"
                }
                size={"xs"}
              >
                {localeLinks.LINK_ORGANIZATIONS_VIEW}
              </Text>
            </NavLink>

            <NavLink
              to={"/branches"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8   font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/branches") ? "black" : "white"
                }
                weight={location.pathname.includes("/branches") ? "700" : "400"}
                size={"xs"}
              >
                {localeLinks.LINK_ORGANIZATION_BRANCHES}
              </Text>
            </NavLink>

            <NavLink
              to={"/categories"}
              className={({ isActive }) =>
                isActive ||
                location.pathname.includes("/categories") ||
                location.pathname.includes("/branch/categories/")
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/categories") ||
                  location.pathname.includes("/branch/categories/")
                    ? "black"
                    : "white"
                }
                weight={
                  location.pathname.includes("/categories") ||
                  location.pathname.includes("/branch/categories/")
                    ? "700"
                    : "400"
                }
                size={"xs"}
              >
                {localeLinks.LINK_ORGANIZATION_CATEGORY}
              </Text>
            </NavLink>

            <NavLink
              to={"/skillset"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/skillset") ? "black" : "white"
                }
                weight={location.pathname.includes("/skillset") ? "700" : "400"}
                size={"xs"}
              >
                {localeLinks.LINK_EMPLOYEE_SKILLSET}
              </Text>
            </NavLink>

            <NavLink
              to={"/reports"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/reports") ? "black" : "white"
                }
                weight={location.pathname.includes("/reports") ? "700" : "400"}
                size={"xs"}
              >
                {localeLinks.LINK_REPORTS}
              </Text>
            </NavLink>
            <NavLink
              to={"/organization/jobs/all"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#C9D5FF] py-2 leading-5 cursor-pointer text-left pl-8  font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer bg-black text-left "
              }
            >
              <Text
                color={
                  location.pathname.includes("/organization/jobs/all") ? "black" : "white"
                }
                weight={location.pathname.includes("/organization/jobs/all") ? "700" : "400"}
                size={"xs"}
              >
                {localeLinks.LINK_SEE_ALL_JOBS}
              </Text>
            </NavLink>
          </>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
