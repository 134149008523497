import AWS from "aws-sdk";

export const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
export const REGION = process.env.REACT_APP_AWS_REGION;
window.Buffer = window.Buffer || require("buffer").Buffer;

export const myBucket = new AWS.S3({
  endpoint: "fra1.digitaloceanspaces.com",
  accessKeyId: "",
  secretAccessKey: "",
});
