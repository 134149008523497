import { useLocation, useNavigate } from "react-router-dom";

import type { LanguageDropDownProps } from "./@types";
import styles from "./LanguageDropDown.module.scss";
import { useLocale } from "../../../locales";
// import useComponentVisibleDropdown from "Common/hooks/useCompositeDropdown";
// import GlobalIcon from "assets/svgs/GlobalIcon";
import { MdArrowDownward } from "react-icons/md";
// import ChevronDownIcon from "assets/svgs/ChevronDownIcon";
import { Text } from "../../atoms";
import useComponentVisibleDropdown from "../../../utils/useCompositeDropdown";
import Image from "../../atoms/Image";
import usFlag from "../../../assets/images/usaFlag.png";
import duFlag from "../../../assets/images/dutchFlag.png";
import ChevronDownIcon from "../../../assets/Jsx/ChevronDownIcon";

const LanguageDropDown = ({ isAdmin = false }: LanguageDropDownProps) => {
  const { localeDropdowns, changeLocale, currentLocale } = useLocale();
  const location = useLocation();
  const navigate = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisibleDropdown(false);
  const toggle = () => {
    if (isComponentVisible == true) {
      setIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
    }
  };

  const navigation = (link: string) => {
    navigate(link);
    setIsComponentVisible(false);
  };

  const changeLanguage = (lng: any) => {
    changeLocale(lng);
    toggle();
  };

  return (
    <div ref={ref}>
      <div className={styles["actionButton-postListing-icon"]} onClick={toggle}>
        {/* <GlobalIcon /> */}
        <Image
          imagePath={currentLocale === "du" ? duFlag : usFlag}
          className={styles["flagImg"]}
        />
        <Text className={styles["dropdownTxt"]}>
          {/* {localeDropdowns.DROPDOWN_ENGLISH} */}
          {currentLocale === "du"
            ? localeDropdowns.DROPDOWN_DUTCH
            : localeDropdowns.DROPDOWN_ENGLISH}
        </Text>

        <ChevronDownIcon />
        {/* {window.innerWidth > breakPosints.md && <ChevronDownIcon />} */}
      </div>

      {isComponentVisible ? (
        <div className="font-bold relative">
          <div
            className="bg-white p-2 mt-3 bg-white-A700 space-y-3 flex flex-col rounded-lg font-bold  top-13 right-[3%] absolute border-2 border-black w-32 z-20"
            style={{ boxShadow: "0px 6px 32px 0px rgba(44, 50, 169, 0.1)" }}
          >
            <div className={styles["dropdown-option-top"]}>
              <div
                className={styles["dropdown-option"]}
                onClick={() => changeLanguage("en")}
              >
                <Text className={styles["dropdown-menu"]}>
                  {localeDropdowns.DROPDOWN_ENGLISH}
                </Text>
              </div>
              <div
                className={styles["dropdown-option"]}
                onClick={() => changeLanguage("du")}
              >
                <Text className={styles["dropdown-menu"]}>
                  {localeDropdowns.DROPDOWN_DUTCH}
                </Text>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LanguageDropDown;
