import dayjs from "dayjs";
import {
  localeBasicsTypes,
  localeLabelsTypes,
} from "./../locales/@types/index";
import { REGISTRATION_FORMS, accountTitle } from "./../types/forms.d";

export const formDefaultData: REGISTRATION_FORMS = {
  // location: ["", ""],
  coordinates: [0, 0],
  token: "",
  firstName: "",
  lastName: "",
  password: "",
  title: "",
  dob: dayjs().format("YYYY-MM-DD"),
  street: "",
  city: "",
  country: "",
  zip: "",
  address: "",
  kvkNumber: "",
  branches: [],
  accountType: "employee",
  phone: "+31",
  locale: "en",
  // countryCode: "+47",
  accountHolderName: "",
  IBAN: "",
  btw_nr: "",

  idFront: [],
  idBack: [],
  certificate: [],
  diplomas: [],
  insuranceBlockDocuments: [],
  scannedImages: [],
  companyName: "",
  employee: "",
  position: "",

};

export const account_title_list = (localLabels: localeLabelsTypes) => [
  { value: "", label: localLabels.LABEL_PLEASE_SELECT },
  { value: "Mr", label: localLabels?.LABEL_MR },
  { value: "Mrs", label: localLabels?.LABEL_MRS },
];

// cleaning
// hospitality
// construction
// IT

export const categories_list = (localeBasics: localeBasicsTypes) => [
  {
    title: localeBasics.TEXT_CHILD_DAY_CARE,
    value: "childDayCare",
  },
  {
    title: localeBasics.TEXT_HEALTH_CARE,
    value: "healthCare",
  },
  {
    title: localeBasics.TEXT_OFFICES,
    value: "office",
  },
  {
    title: localeBasics.TEXT_LOGISTICS,
    value: "logistics",
  },
  {
    title: localeBasics.TEXT_SALES,
    value: "sales",
  },
  {
    title: localeBasics.TEXT_CALL_CENTER,
    value: "callcenter",
  },
  {
    title: localeBasics.TEXT_EDUCATION,
    value: "education",
  },
  {
    title: localeBasics.TEXT_OTHERS,
    value: "other",
  },
];

const BRANCHES = [
  {
    _id: "dayCare",
    label: {
      en: "Day care",
      du: "Kinderopvang",
    },
  },
  {
    _id: "logistic",
    label: {
      en: "Logistic",
      du: "Logistiek",
    },
  },
  {
    _id: "office",
    label: {
      en: "Office",
      du: "Kantoor",
    },
  },
  {
    _id: "healthcare",
    label: {
      en: "Healthcare",
      du: "Gezondheidszorg",
    },
  },
  {
    _id: "hospitality",
    label: {
      en: "Hospitality",
      du: "Hospitality",
    },
  },
  {
    _id: "sales",
    label: {
      en: "Sales",
      du: "Verkoop",
    },
  },
  {
    _id: "education",
    label: {
      en: "Education",
      du: "Onderwijs",
    },
  },
  {
    _id: "other",
    label: {
      en: "Other",
      du: "Andere",
    },
  },
];

const dayCareCategories = [
  {
    _id: "kdv",
    branchId: "dayCare",
    label: {
      en: "Childcare center (KDV)",
      du: "Kinderdagverblijf (KDV)",
    },
  },
  {
    _id: "bso",
    branchId: "dayCare",
    label: {
      en: "After-school care (BSO)",
      du: "Buitenschoolse opvang (BSO)",
    },
  },
  {
    _id: "vso",
    branchId: "dayCare",
    label: {
      en: "Before-school care (VSO)",
      du: "Voorschoolse opvang (VSO)",
    },
  },
  {
    _id: "tso",
    branchId: "dayCare",
    label: {
      en: "Midday school care (TSO)",
      du: "Tussenschoolse opvang (TSO)",
    },
  },
  {
    _id: "vve",
    branchId: "dayCare",
    label: {
      en: "Preschool and early childhood education (VVE)",
      du: "Voor- en vroegschoolse educatie (VVE)",
    },
  },
  {
    _id: "peuterspeelzaal",
    branchId: "dayCare",
    label: {
      en: "Preschool playgroup",
      du: "Peuterspeelzaal",
    },
  },
  {
    _id: "pedagogischCoach",
    branchId: "dayCare",
    label: {
      en: "Pedagogical coach",
      du: "Pedagogisch coach",
    },
  },
  {
    _id: "overig",
    branchId: "dayCare",
    label: {
      en: "Other",
      du: "Overig",
    },
  },
];

const logisticsCategories = [
  {
    _id: "vervoer",
    branchId: "logistics",
    label: {
      en: "Transport",
      du: "Vervoer",
    },
  },
  {
    _id: "opslag",
    branchId: "logistics",
    label: {
      en: "Storage",
      du: "Opslag",
    },
  },
  {
    _id: "productie",
    branchId: "logistics",
    label: {
      en: "Production",
      du: "Productie",
    },
  },
  {
    _id: "overig",
    branchId: "logistics",
    label: {
      en: "Other",
      du: "Overig",
    },
  },
];

const officeCategories = [
  {
    _id: "finance",
    branchId: "office",
    label: {
      en: "Finance",
      du: "Financiën",
    },
  },
  {
    _id: "inkoop",
    branchId: "office",
    label: {
      en: "Procurement",
      du: "Inkoop",
    },
  },
  {
    _id: "hr",
    branchId: "office",
    label: {
      en: "Human Resources",
      du: "Personeelszaken",
    },
  },
  {
    _id: "it",
    branchId: "office",
    label: {
      en: "Information Technology",
      du: "Informatietechnologie",
    },
  },
  {
    _id: "overig",
    branchId: "office",
    label: {
      en: "Other",
      du: "Overig",
    },
  },
];

const healthcareCategories = [
  {
    _id: "geestelijkeGezondheidszorg",
    branchId: "healthcare",
    label: {
      en: "Mental Healthcare (GGZ)",
      du: "Geestelijke gezondheidszorg (GGZ)",
    },
  },
  {
    _id: "gehandicaptenzorg",
    branchId: "healthcare",
    label: {
      en: "Disability Care",
      du: "Gehandicaptenzorg",
    },
  },
  {
    _id: "jeugdzorg",
    branchId: "healthcare",
    label: {
      en: "Youth Care",
      du: "Jeugdzorg",
    },
  },
  {
    _id: "ouderenzorg",
    branchId: "healthcare",
    label: {
      en: "Elderly Care",
      du: "Ouderenzorg",
    },
  },
  {
    _id: "thuiszorg",
    branchId: "healthcare",
    label: {
      en: "Home Care",
      du: "Thuiszorg",
    },
  },
  {
    _id: "verpleging",
    branchId: "healthcare",
    label: {
      en: "Nursing",
      du: "Verpleging",
    },
  },
  {
    _id: "overig",
    branchId: "healthcare",
    label: {
      en: "Other",
      du: "Overig",
    },
  },
];

const hospitalityCategories = [
  {
    _id: "hostess",
    branchId: "hospitality",
    label: {
      en: "Hostess",
      du: "Hostess",
    },
  },
  {
    _id: "bartender",
    branchId: "hospitality",
    label: {
      en: "Bartender",
      du: "Bartender",
    },
  },
  {
    _id: "ober",
    branchId: "hospitality",
    label: {
      en: "Waiter/Waitress",
      du: "Ober",
    },
  },
  {
    _id: "overig",
    branchId: "hospitality",
    label: {
      en: "Other",
      du: "Overig",
    },
  },
];

const salesCategories = [
  {
    _id: "callcenter",
    branchId: "sales",
    label: {
      en: "Call Center",
      du: "Callcenter",
    },
  },
  {
    _id: "klantenservice",
    branchId: "sales",
    label: {
      en: "Customer Service",
      du: "Klantenservice",
    },
  },
  {
    _id: "retail",
    branchId: "sales",
    label: {
      en: "Retail",
      du: "Retail",
    },
  },
  {
    _id: "overig",
    branchId: "sales",
    label: {
      en: "Other",
      du: "Overig",
    },
  },
];

const educationCategories = [
  {
    _id: "primairOnderwijs",
    branchId: "education",
    label: {
      en: "Primary Education (PO)",
      du: "Primair onderwijs (PO)",
    },
  },
  {
    _id: "voortgezetOnderwijs",
    branchId: "education",
    label: {
      en: "Secondary Education (VO)",
      du: "Voortgezet onderwijs (VO)",
    },
  },
  {
    _id: "middelbaarBeroepsonderwijs",
    branchId: "education",
    label: {
      en: "Intermediate Vocational Education (MBO)",
      du: "Middelbaar beroepsonderwijs (MBO)",
    },
  },
  {
    _id: "hogerBeroepsonderwijs",
    branchId: "education",
    label: {
      en: "Higher Vocational Education (HBO)",
      du: "Hoger beroepsonderwijs (HBO)",
    },
  },
  {
    _id: "overig",
    branchId: "education",
    label: {
      en: "Other",
      du: "Overig",
    },
  },
];

const otherCategory = [
  {
    _id: "bouw",
    branchId: "other",
    label: {
      en: "Construction",
      du: "Bouw",
    },
  },
];
