import { useListPage } from "./hook";
import CommonTable from "../../components/templates/CommonTable";
import { useLocale } from "../../locales";

import { ListPageProps } from "./@types";

const ListPage = ({
  totalUsers,
  loading,
  type,
  onClickDelete,
  onClickEdit,
  onToggleCategroryPrice,
  showPagination,
  list,
  currentPage,
  OnNextPage,
  onPreviousPage,
  hasMore,
  allowedBranches,
  filter,
  stausFilter,
  changeStausFilter
}: ListPageProps) => {
  const { navigateToUserDetails, onSubmit, onModalEdit } = useListPage(type);
  const { localeTitles } = useLocale();

  return (
    <div className="mt-4  md:mt-10">
      {/* <Text
        className={styles["heading"]}
      >{`${localeTitles.TITLE_TOTAL} ${type} : ${totalUsers}`}</Text> */}
      <CommonTable
        showPagination={showPagination}
        title={`${localeTitles.TITLE_TOTAL} ${type} : ${totalUsers}`}
        tableType={type}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
        loading={loading}
        data={list || []}
        showTitle
        hasMore={hasMore}
        onModalEdit={onModalEdit}
        onSubmit={onSubmit}
        navigateTo={navigateToUserDetails}
        onToggleCategroryPrice={onToggleCategroryPrice}
        currentPage={currentPage}
        OnNextPage={OnNextPage}
        onPreviousPage={onPreviousPage}
        allowedBranches={allowedBranches}
        filter={filter}
        changeStausFilter={changeStausFilter}
        stausFilter={stausFilter}
      />
    </div>
  );
};

export default ListPage;
