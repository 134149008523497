import { useEffect, useState } from "react";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useInfiniteQuery } from "react-query";
import { ApiGetAllUsers, ApiToggleOrganizationJobPOsting } from "../../../utils/globalAPI/Users";
import { useLocale } from "locales";
import { ToastConsumer } from "store/ToastProvider";
import dayjs from "dayjs";

export const useOrganizationServices = () => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const { searchBy, searchKeyword } = UsersContextConsumer();
  const [offset, setOffset] = useState(0);

  const{localeSuccess}= useLocale();

  useEffect(() => {
    setOffset(0);
  }, [searchBy, searchKeyword]);
  const { accessToken,fetchApi } = UsersContextConsumer();
  const toast = ToastConsumer();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: listLoading,
    refetch
  } = useInfiniteQuery(
    [
      "organizationList",
      {
        searchBy,
        keyword: searchKeyword,
      },
    ],
    async ({ pageParam = 0 }) =>
      ApiGetAllUsers("organization", pageParam, searchKeyword, searchBy,accessToken),
    {
      enabled: !!fetchApi,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (data) => data.nextPage,
    }
  );

  const handleOpenFilter = () => {
    setOpenFilterModal(true);
  };

  const handleCloseFilter = () => {
    setOpenFilterModal(false);
  };

  const onNextPage = () => {
    if (!hasNextPage) return;
    fetchNextPage();
    setOffset((prev) => prev + 1);
  };

  const onPreviousPage = () => {
    if (offset > 0) {
      setOffset((prev) => prev - 1);
    }
  };


  const [order, setOrder] = useState<{
    column: "r-date" | "r-name" | "r-number" | "r-status";
    order: "asc" | "desc";
  }>({
    column: "r-date",
    order: "asc",
  });
  const onChangeFilter = (
    v: "asc" | "desc",
    column: "r-date" | "r-name" | "r-number" | "r-status"
  ) => {
    setOrder({ column, order: v });
  };

  const handleFilter = (offset:number) => {
    if (order.column === "r-date") {
      if (order.order === "asc") {
        // sort by date asc
        return data?.pages?.[offset]?.usersList.sort(
          (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix()
        );
      } else {
        // sort by date desc
        return data?.pages?.[offset]?.usersList.sort(
          (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()
        );
      }
    } else return data?.pages?.[offset]?.usersList;
  };

 
  return {
    listLoading,
    openFilterModal,
    handleOpenFilter,
    handleCloseFilter,
    usersList: handleFilter(offset),
    hasMore: data?.pages?.[offset]?.hasMore,
    totalUsers: data?.pages?.[offset]?.totalUsers || 0,
    onNextPage,
    onPreviousPage,
    currentPage: offset + 1,

    order,onChangeFilter

  };
};
