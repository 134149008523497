import { useState } from "react";
import { useLocale } from "../../../locales";
import Modal from "../../molecules/Modal";
import Confirmation from "../Confirmation";
import type { confirmationModalProps } from "./@types";
import styles from "./ConfirmationModal.module.scss";

const ConfirmationModal = ({
  show,
  onHide = () => {},
  onConfirm,
  title,
  loading,
}: confirmationModalProps) => {
  const { localeTitles } = useLocale();

  return (
    <div className={styles["auth-form"]}>
      <Modal show={show}>
        <Confirmation title={title} onConfirm={onConfirm} onHide={onHide} loading={loading}/>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
