import type { DynamicTableProps } from "./@types";
import styles from "./DynamicTable.module.scss";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const DynamicTable = ({ rows, headers }: DynamicTableProps) => {

  return (
    <>
      <Table>
        <TableHead className={styles["table-head"]}>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index} className={styles["headerCell"]}>
                <div
                  className={styles["headerCell-value"]}
                  // style={{ margin: "0 10px" }}
                >
                  {header}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles["table-body"]}>
          {rows?.map((cell, cellIndex) => (<TableRow
          >
            {cell?.map((data, cellIndex) => (
              <TableCell key={cellIndex} className={styles["headerCell"]}>
                <div
                  className={styles["headerCell-bodyValue"]}
                  // style={{ margin: "0 10px" }}
                >
                  {data}
                </div>
              </TableCell>
            ))}
          </TableRow>))}
        </TableBody>
      </Table>
    </>
  );
};

export default DynamicTable;
