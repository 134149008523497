import React, { MouseEventHandler } from "react";
type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: () => void;
};
function ChevronDownIcon({ color, size, onClick }: Props) {
  return (
    <svg
      onClick={onClick}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 7.5L9 10.5L12.75 7.5"
        stroke={color ? color : "#101828"}
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ChevronDownIcon;

// fill={white ? "#fffff" : "#0F1322"}
