
import useComponentVisible from "utils/useComposite";



export const useSortDropDown = (changeFilter:(d:"A_Z" | "Z_A" | "popularity_d" | "popularity")=>void) => {

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const toggle = (e?: any) => {
    e?.stopPropagation();
    if (isComponentVisible === true) {
      setIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
    }
  };

  const selectSortFilter= (name:"A_Z" | "Z_A" | "popularity_d" | "popularity")=>{
    changeFilter(name)
    setIsComponentVisible(false)
  }


  return {
 
    toggle,
    ref,
    isComponentVisible,
    selectSortFilter
  };
};
