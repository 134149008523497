import React, { useState } from "react";
import styles from "./NotificationPage.module.scss";

import { useNavigate } from "react-router-dom";
import { useLocale } from "locales";
import { Text } from "components/atoms/TextF";
import TimerIcon from "assets/svgs/TimerIcon";
import CalendarTickIcon from "assets/svgs/CalendarTickIcon";
import NotificationCard from "components/cells/NotificationCard/NotificationCard";
import { useIntreactionObserver } from "utils/hooks/IntreactionObserver";
import { INotificaiton } from "types/schema";

interface NotificationProps {
  notification?: {
    earlier: INotificaiton[];
    recent: INotificaiton[];
  };
  deleteNotification: (notificationId: string) => Promise<void>;
  markNotificaitonAsRead: (notificationId: string) => void;
  earliarLength:number;
  recentLength:number;
}

const NotificationPage: React.FC<NotificationProps> = (props) => {
  const navigate = useNavigate();
  const { localeTitles } = useLocale();
  

  

  const navigateToLink = (link: string,notificationId:string) => {
    props.markNotificaitonAsRead(notificationId)
    navigate(link);
  };

  return (
    <div className={styles["NotificationPage"]}>
      <Text className={styles["title"]}>
        {localeTitles.TITLE_NOTIFICATIONS}
      </Text>

      <div className={styles["NotificationPage-main"]}>
        
        <div className={styles["NotificationPage-right"]}>
          {props.recentLength > 0 && (
              <div
                className={styles["right-section-recent"]}
              >
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_MOST_RECENT}
                </Text>
                <div className={styles["NotificationPage-right-section"]}>
                  {props.notification?.recent?.map((item, index) => (
                    <NotificationCard
                      data={item}
                      key={index}
                      deleteNotification={props.deleteNotification}
                      navigateToLink={navigateToLink}
                    />
                  ))}
                </div>
              </div>
            )}

          {
            props?.earliarLength>0 &&
            <div
            className={styles["right-section-earlier"]}
          >
            <Text className={styles["section-title"]}>
              {localeTitles.TITLE_EARLIER}
            </Text>
            <div className={styles["NotificationPage-right-section"]}>
              {props?.notification?.earlier?.map((item, index) => (
                <NotificationCard
                  data={item}
                  key={index}
                  deleteNotification={props.deleteNotification}
                  navigateToLink={navigateToLink}
                />
              ))}
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
