import React from "react";
type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function OptionIcon({ white, size }: Props) {
  return (
    <svg
      width="39"
      height="34"
      viewBox="0 0 39 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3147_25365)">
        <rect
          x="3.62256"
          y="2.93115"
          width="31.6444"
          height="26.5751"
          rx="8"
          fill="white"
        />
        <rect
          x="3.62256"
          y="2.93115"
          width="31.6444"
          height="26.5751"
          rx="8"
          stroke="#EBEEF2"
        />
        <path
          d="M14.445 14.5522C13.5283 14.5522 12.7783 15.3022 12.7783 16.2189C12.7783 17.1356 13.5283 17.8856 14.445 17.8856C15.3617 17.8856 16.1117 17.1356 16.1117 16.2189C16.1117 15.3022 15.3617 14.5522 14.445 14.5522ZM24.445 14.5522C23.5283 14.5522 22.7783 15.3022 22.7783 16.2189C22.7783 17.1356 23.5283 17.8856 24.445 17.8856C25.3617 17.8856 26.1117 17.1356 26.1117 16.2189C26.1117 15.3022 25.3617 14.5522 24.445 14.5522ZM19.445 14.5522C18.5283 14.5522 17.7783 15.3022 17.7783 16.2189C17.7783 17.1356 18.5283 17.8856 19.445 17.8856C20.3617 17.8856 21.1117 17.1356 21.1117 16.2189C21.1117 15.3022 20.3617 14.5522 19.445 14.5522Z"
          fill="#B8BFCC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3147_25365"
          x="0.122559"
          y="0.431152"
          width="38.6445"
          height="33.5752"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.376471 0 0 0 0 0.423529 0 0 0 0 0.501961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3147_25365"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3147_25365"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default OptionIcon;
