import { useEffect } from "react";

import Header from "../../organisms/Header";
import Sidebar from "../../organisms/Sidebar";
import { adminLayoutProps } from "./@types";
import styles from "./Layout.module.scss";

import { useLayout } from "./hook";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { Amplify } from "aws-amplify";

import {
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// import { currentConfig } from "@/Configuration";
import awsExports from "../../../utils/hooks/Configuration";

Amplify.configure(awsExports);

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

export default function AdminLayout({
  children,
  dashboard,
  searchBar = true,
  isProtected = true,
  isAdmin = false,

  headerPath = [],
  backArrow = false,
}: adminLayoutProps) {
  const {
    setShow,
    dashboardPath,
    onEditAccount,
    onUpdatePassword,
    notifications,
    handleSubmit,
    control,
    onSubmitSearch,
  } = useLayout();

  const { signOut } = UsersContextConsumer();



  return (
    <div className={styles["layout"]} onClick={() => setShow(false)}>
      <Sidebar dashboard={dashboardPath} />

      <div
        className={styles["layout-body"]}
        //  style={{height:height}}
        id="layout"
      >
        <Header
          onSubmitSearch={onSubmitSearch}
          signOut={signOut}
          dashboard={dashboardPath}
          headerPath={headerPath}
          searchBar={searchBar}
          backArrow={backArrow}
          isAdmin={isAdmin}
          onEditAccount={onEditAccount}
          onUpdatePassword={onUpdatePassword}
          notifications={notifications}
          handleSubmit={handleSubmit}
          control={control}
        />
        {children}
      </div>

      {/* <UpdatePasswordModal
        show={updatePasswordModal}
        onSubmit={onSubmitPasswordChangeForm}
        onHide={onUpdatePassword}
        loading={loading}
      /> */}

      <div
        style={
          {
            // height:"200px"
          }
        }
      ></div>
    </div>
  );
}



// export default AdminLayout;
