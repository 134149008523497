import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function ArrowSquareIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={window.innerWidth > breakPoints.sm - 1 ? "24" : "15"}
      height={window.innerWidth > breakPoints.sm - 1 ? "24" : "15"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1396 15.0691V13.1091C12.1396 10.5891 14.1797 8.53906 16.7097 8.53906H18.6696"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.62012 8.55078H7.58014C10.1001 8.55078 12.1501 10.5908 12.1501 13.1208V13.7708V17.2508"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.13983 6.75L5.33984 8.55L7.13983 10.35"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.8594 6.75L18.6594 8.55L16.8594 10.35"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ArrowSquareIcon;

// fill={white ? "#fffff" : "#0F1322"}
