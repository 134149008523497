import React from "react";
interface Props {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
}
function TaskDecription({ color = "#000", size = 24, style, onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
      style={style}
    >
      <path
        fill={color}
        d="M11.768.002a11.964 11.964 0 00-8.256 3.512c-4.683 4.683-4.683 12.29 0 16.974 4.684 4.683 12.292 4.683 16.976 0 4.683-4.683 4.683-12.291 0-16.974a.5.5 0 00-.754.053L10.946 15.24 5.352 9.647a.5.5 0 10-.707.707l6.001 6.001a.5.5 0 00.753-.053l8.752-11.627c3.887 4.323 3.789 10.946-.37 15.106a10.996 10.996 0 01-15.561 0 10.994 10.994 0 010-15.56c3.578-3.578 9-4.175 13.194-1.802a.5.5 0 10.492-.87A11.985 11.985 0 0011.768.002z"
      ></path>
    </svg>
  );
}

export default TaskDecription;
