import styles from "./AccountPage.module.scss";

import { useLocale } from "locales";
// import { Button, Text, TextArea } from "components";
import { Button } from "components/atoms/ButtonF";
import { Text } from "components/atoms/TextF";
import { TextArea } from "components/atoms/TextAreaF";
import { profileShowOptions } from "constants/constantData";
import { useAccountPage } from "./hook";
import ModalComponent from "components/molecules/ModalF/Modal";

import ThreeDCubeIcon from "assets/svgs/ThreeDCubeIcon";

import profileImg from "assets/images/account/profile.png";

import GhostIcon from "assets/svgs/GhostIcon";

import CakeIcon from "assets/svgs/CakeIcon";

import UserEditIcon from "assets/svgs/UserEditIcon";
import MessageIcon from "assets/svgs/MessageIcon";

import CreditCardIcon from "assets/svgs/CreditCardIcon";
import BroomIcon from "assets/svgs/BroomIcon";
import BuildingIcon from "assets/svgs/BuildingIcon";

import UserTagIcon from "assets/svgs/UserTagIcon";
import HashTagIcon from "assets/svgs/HashTagIcon";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import UploadCard from "components/cells/UploadCard";
import PersonalCardIcon from "assets/svgs/PersonalCardIcon";
import CertificateIcon from "assets/svgs/CertificateIcon";
import DiplomaIcon from "assets/svgs/DiplomaIcon";
import CameraIcon from "assets/svgs/CameraIcon";

import { AccountPageProps } from "./@types";
import { ControlInput } from "components/atoms/ControlledInput/Input";

import UploadFileIcon from "assets/svgs/UploadFileIcon";
import ControlSelect from "components/molecules/ControlSelect/Select";
import PencilIcon from "assets/svgs/PencilIcon";
import { account_title_list } from "constants/forms.data";
import AddCircleIcon from "assets/svgs/AddCircleIcon";
import { ErrorMessage } from "components/atoms/ErrorMessage";
import AddJobModal from "components/cells/AddJobModal";
import { Education, Job } from "types/forms";
import AddEducationModal from "components/cells/AddEducationModal";
import AvatarWithIcon from "components/molecules/AvatarWithIcon";

import ExpandableField from "components/molecules/ExpandableField";

import LinkedInIcon from "assets/svgs/LinkedInIcon";
import SkypeIcon from "assets/svgs/SkypeIcon";

import PhoneInput from "components/atoms/PhoneInput/src";
import "components/atoms/PhoneInput/src/style/style.scss";
import ArrowSquareIcon from "assets/svgs/ArrowSquareIcon";

import CategorySelect from "components/molecules/CategorySelect";

import { Navigation, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Swiper, SwiperSlide } from "swiper/react";
import { AddressComponent } from "../AccountPageOrganization/AccountPageOrganization";
import { TbReceiptTax } from "react-icons/tb";
import { UsersContextConsumer } from "store/AuthProvider";
import { useWidth } from "utils/responsiveHook";

const AccountPage = ({
  employee,
  freelancer,

  onSubmit,
  loading,
}: AccountPageProps) => {
  const {
    // register,
    Controller,
    control,
    setValue,
    watch,
    fields,

    handleSubmit,

    jobModal,
    closeJobModal,
    onConfirmJob,
    onConfirmEducation,
    openJobModal,
    openEducationModal,
    closeEducationModal,
    educationModal,
    removeJob,
    removeEducation,
    userData,
    editJobFeild,
    editEducationFeild,
    getEducationField,
    getJobField,
    onConfirmEducationEdit,
    onConfirmJobEdit,
    handleIbanChange,
    errors,
  } = useAccountPage();

  const {
    localeTitles,
    localeBasics,
    localePlaceholders,
    localeLabels,
    localeButtons,
  } = useLocale();

  const { width } = useWidth();
  const { dropdowns } = UsersContextConsumer();

  return (
    <div className={styles["AccountPage"]}>
      <div className={styles["AccountPage-heading"]}>
        <Text className={styles["title"]}>{localeTitles.TITLE_ACCOUNT}</Text>

        {width > breakPoints.md && (
          <Button
            className={"secondary-active"}
            // type="submit"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          >
            {localeButtons.BUTTON_SAVE_CHANGES}
          </Button>
        )}
      </div>
      <div className={styles["AccountPage-main"]}>
        <div className={styles["AccountPage-right"]}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles["AccountPage-form"]}
          >
            <div className={styles["right-section-recent"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_PERSONAL_INFORMATION}
                </Text>
              )}
              <div className={styles["AccountPage-right-section-profile"]}>
                <div className={styles["section-profile-left"]}>
                  <AvatarWithIcon
                    control={control}
                    profileImage={profileImg}
                    ImageData={watch("profileImage")}
                  />
                  <div>
                    <Text className={styles["avatar"]}>
                      {localeTitles.TITLE_AVATAR}
                    </Text>
                    <Text className={styles["avatarSubtitle"]}>
                      {localeBasics.TEXT_AVATAR_REQUIREMENT}
                    </Text>
                  </div>
                </div>
                <div className={styles["section-profile-right"]}>
                  <div className={styles["selectDiv"]}>
                    <ControlSelect
                      name="availabilityStatus"
                      control={control}
                      list={profileShowOptions(localeLabels)}
                      Icon={<GhostIcon />}
                    />
                  </div>
                </div>
              </div>

              <div className={styles["AccountPage-right-section"]}>
                <div className={styles["profile-fields"]}>
                  <div className={styles["selectDiv"]}>
                    <ControlSelect
                      name="title"
                      control={control}
                      list={account_title_list(localeLabels)}
                      Icon={<PencilIcon />}
                    />
                  </div>
                  <ControlInput
                    control={control}

                    // register={register("birthday")}
                    name="dob"
                    placeholder={localePlaceholders.PLACEHOLDERS_BIRTHDAY}
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    borderClass=""
                    type="date"
                    prefix={<CakeIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""

                    // register={register("firstName")}
                    name="firstName"
                    placeholder={localePlaceholders.PLACEHOLDERS_FIRST_NAME}
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    prefix={<UserEditIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    name="lastName"
                    borderClass=""

                    placeholder={localePlaceholders.PLACEHOLDERS_LAST_NAME}
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    prefix={<UserEditIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""
                    name="email"
                    placeholder={localePlaceholders.PLACEHOLDER_EMAIL}
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    prefix={<MessageIcon color={"#101828"} />}
                  />
                </div>
                <div className={styles["largeDiv"]}>
                  <ControlInput
                    control={control}
                    name="heading"
                    borderClass=""

                    placeholder={
                      localePlaceholders.PLACEHOLDERS_ACCOUNT_HEAD_LINE
                    }
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                  />
                  <TextArea
                    control={control}
                    name="bio"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_TELL_SOMETHING_ABOUT_YOURSELF
                    }
                    className={styles["textArea"]}
                  ></TextArea>
                </div>
                <div className={styles["profile-fields"]}>
                  <ControlInput
                    borderClass=""

                    control={control}
                    name="idNumber"
                    placeholder={localePlaceholders.PLACEHOLDERS_ID_NUMBER}
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    prefix={<UserEditIcon color={"#101828"} sameSize />}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({
                      field: { onChange, value, name, ref },
                      formState: { errors },
                    }) => (
                      <div className="flex flex-col">
                        <PhoneInput
                          // country={"eg"}
                          enableSearch={true}
                          value={value}
                          onChange={(phone: any) => onChange(phone)}
                        />
                        {!!errors && (
                          <ErrorMessage
                            errors={errors?.phone?.message as string}
                          />
                        )}
                      </div>
                    )}
                  />

                  {!employee && (
                    <ControlInput
                    borderClass=""

                      disabled={freelancer ? false : true}
                      control={control}
                      name="hourlyRate"
                      placeholder={
                        localePlaceholders.PLACEHOLDERS_SALARY_HOURLY_RATE
                      }
                      className={styles["inputField"]}
                      wrapClassName={
                        styles[
                          freelancer
                            ? "inputField-wrap"
                            : "inputField-wrap-disabled"
                        ]
                      }
                      type="number"
                      prefix={<CreditCardIcon color={"#101828"} />}
                    />
                  )}
                </div>

                <div className={styles["profile-fields"]}>
                  <ControlInput
                    borderClass=""

                    control={control}
                    name="linkedin"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_ADD_LINKEDIN_URL
                    }
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    prefix={<LinkedInIcon />}
                  />
                  <ControlInput
                    borderClass=""

                    control={control}
                    name="skype"
                    placeholder={localePlaceholders.PLACEHOLDERS_ADD_SKYPE_URL}
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    prefix={<SkypeIcon />}
                  />
                </div>
              </div>
            </div>
            {/*Personal Information  */}

            {/*KVK Info  */}
            {userData?.accountType === "freelancer" && (
              <>
                <div className={styles["right-section-location"]}>
                  {width > breakPoints.md && (
                    <Text className={styles["section-title"]}>
                      {localeTitles.TITLE_KAMER_VAN_KOOPHANDEL}
                    </Text>
                  )}
                </div>

                <div className={styles["AccountPage-right-section-map"]}>
                  <ControlInput
                    control={control}
                    borderClass=""

                    name="kvkNumber"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_KVK_NUMBER_OPTIONAL
                    }
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    prefix={<ArrowSquareIcon color={"#101828"} />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""

                    name="companyName"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_COMPANY_NAME_OPTIONAL
                    }
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    prefix={<ArrowSquareIcon color={"#101828"} />}
                  />
                </div>
              </>
            )}
            {/*KVK Info  */}

            {/*Location  */}
            <div className={styles["right-section-location"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_LOCATION}
                </Text>
              )}
              <div className={styles["AccountPage-right-section-map"]}>
                <Swiper
                  modules={[Navigation, A11y]}
                  slidesPerView={1}
                  navigation={true}
                  pagination={{ clickable: true }}
                  //  scrollbar={{ draggable: false ,hide:true}}
                  onSlideChange={() => console.log("slide change")}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  shortSwipes={false}
                  // onSwiper={(swiper) => console.log(swiper)}
                >
                  {fields?.map((address, index) => (
                    <SwiperSlide>
                      <AddressComponent
                        index={index}
                        control={control as any}
                        errors={errors}
                        setValue={setValue as any}
                        fields={fields as any}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            {/*Location  */}

            {/* Job   */}
            <div className={styles["right-section-jobAndEducation"]}>
              {width > breakPoints.md && (
                <div className={styles["section-heading"]}>
                  <Text className={styles["section-title"]}>
                    {localeTitles.TITLE_JOB}
                  </Text>
                  <AddCircleIcon color={"#101828"} onClick={openJobModal} />
                </div>
              )}
              <div className={styles["AccountPage-right-section-job"]}>
                <div className={styles["eachFieldWithCategory"]}>
                  {watch("job")?.map((job, index: number) => (
                    <div>
                      <ExpandableField
                        removeJobField={removeJob}
                        editJobField={getJobField}
                        index={index}
                        name={job?.positionName}
                        key={index}
                        control={control}
                        prefix={<BroomIcon color={"#101828"} />}
                        placeHolderText={
                          localePlaceholders.PLACEHOLDERS_JOB_TTTLE
                        }
                        fieldDataJob={job}
                        category="JOB"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Job   */}

            {/* Education  */}
            <div className={styles["right-section-jobAndEducation"]}>
              {width > breakPoints.md && (
                <div className={styles["section-heading"]}>
                  <Text className={styles["section-title"]}>
                    {localeTitles.TITLE_EDUCATION}
                  </Text>
                  <AddCircleIcon
                    color={"#101828"}
                    onClick={openEducationModal}
                  />
                </div>
              )}
              <div className={styles["AccountPage-right-section-job"]}>
                <div className={styles["eachFieldWithCategory"]}>
                  {watch("education")?.map((edu, index: number) => (
                    <div>
                      <ExpandableField
                        removeEducationField={removeEducation}
                        index={index}
                        editEducationField={getEducationField}
                        name={edu?.degreeName}
                        key={index}
                        control={control}
                        prefix={<BuildingIcon color={"#101828"} />}
                        placeHolderText={
                          localePlaceholders.PLACEHOLDERS_EDUCATION_TITLE
                        }
                        fieldDataEducation={edu}
                        category="EDUCATION"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/*  Education  */}

            <div className={styles["right-section-branchesAndCategories"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_BRANCHES_AND_CATEGORIES}
                </Text>
              )}
              <div
                className={
                  styles[
                    "AccountPageOrganization-right-section-branchAndCategory"
                  ]
                }
              >
                <CategorySelect
                  multiSelect={true}
                  name="branches"
                  control={control}
                  list={dropdowns?.branches}
                  // maxLimit={3}
                  Icon={<ThreeDCubeIcon />}
                />
                <div className="mt-2 mb-2"></div>
                <CategorySelect
                  multiSelect={true}
                  name="categories"
                  control={control}
                  list={
                    dropdowns?.categories?.filter((item) =>
                      watch("branches")?.find(
                        (branch) => branch._id === item.branchId
                      )
                        ? true
                        : false
                    ) || []
                  }
                  Icon={<ThreeDCubeIcon />}
                />
              </div>
            </div>

            <div className={styles["right-section-branchesAndCategories"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_SKILLS_AND_EXPERTISE}
                </Text>
              )}
              <div
                className={
                  styles[
                    "AccountPageOrganization-right-section-branchAndCategory"
                  ]
                }
              >
                <CategorySelect
                  multiSelect={true}
                  name="skills"
                  control={control}
                  list={dropdowns?.skills}
                  Icon={<ThreeDCubeIcon />}
                />
              </div>
            </div>

            {/*bank Information  */}
            <div className={styles["right-section-bank"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_BANK_DETAILS}
                </Text>
              )}

              <div className={styles["AccountPage-right-section"]}>
                <div className={styles["profile-fields"]}>
                  <ControlInput
                    control={control}
                    borderClass=""

                    // register={register("accountHolderName")}
                    name="accountHolderName"
                    placeholder={
                      localePlaceholders.PLACEHOLDERS_ACCOUNT_HOLDER_NAME
                    }
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    prefix={<UserTagIcon color={"#101828"} sameSize />}
                  />

                  <ControlInput
                    control={control}
                    borderClass=""

                    // register={register("iban")}
                    name="IBAN"
                    placeholder={localePlaceholders.PLACEHOLDERS_IBAN}
                    preDefinedWrapClassName="inputField-wrap"
                    preDefinedClassName="inputField"
                    type="text"
                    handleChangeCustomly={handleIbanChange}
                    prefix={<HashTagIcon color={"#101828"} sameSize />}
                  />

                  {userData?.accountType !== "employee" && (
                    <ControlInput
                      control={control}
                    borderClass=""

                      name="btw_nr"
                      placeholder={localePlaceholders.PLACEHOLDERS_BTW_NUMBER}
                      preDefinedClassName="inputField"
                      preDefinedWrapClassName="inputField-wrap"
                      type="text"
                      prefix={<TbReceiptTax size={20} color="#101828" />}
                    />
                  )}
                </div>
              </div>
            </div>
            {/*bank Information  */}

            {/*document Information  */}
            <div className={styles["right-section"]}>
              {width > breakPoints.md && (
                <Text className={styles["section-title"]}>
                  {localeTitles.TITLE_DOCUMENTS}
                </Text>
              )}

              <div className={styles["AccountPage-right-section"]}>
                <div className={styles["formDiv"]}>
                  <UploadCard
                    imageClass={styles["uploadCard_image"]}
                    className={styles["uploadCardDiv"]}
                    inputClass={styles["uploadCardDiv-inputDiv"]}
                    Icon={<PersonalCardIcon />}
                    text={localeBasics.TEXT_ID_MANDATORY}
                    title={localeTitles.TITLE_UPLOAD}
                    control={control}
                    name="idFront"
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                    }}
                    maxFiles={1}
                  />

                  <UploadCard
                    imageClass={styles["uploadCard_image"]}
                    className={styles["uploadCardDiv"]}
                    inputClass={styles["uploadCardDiv-inputDiv"]}
                    Icon={<PersonalCardIcon />}
                    text={localeBasics.TEXT_ID_BACK_MANDATORY}
                    title={localeTitles.TITLE_UPLOAD}
                    control={control}
                    name="idBack"
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                    }}
                    maxFiles={1}
                  />

                  <UploadCard
                    imageClass={styles["uploadCard_image"]}
                    className={styles["uploadCardDiv"]}
                    inputClass={styles["uploadCardDiv-inputDiv"]}
                    Icon={<CertificateIcon />}
                    text={localeBasics.TEXT_CERTIFICATES_OPTIONAL}
                    title={localeTitles.TITLE_UPLOAD}
                    control={control}
                    name="certificate"
                    disabled={
                      userData?.worker?.verificationStatus === "verified"
                    }
                    maxFiles={10}
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                      "application/*": [
                        ".png",
                        ".jpeg",
                        ".jpg",
                        ".pdf",
                        ".doc",
                        ".docx",
                      ],
                    }}
                  />
                  <UploadCard
                    imageClass={styles["uploadCard_image"]}
                    className={styles["uploadCardDiv"]}
                    inputClass={styles["uploadCardDiv-inputDiv"]}
                    Icon={<DiplomaIcon />}
                    text={localeBasics.TEXT_DIPLOMA_OPTIONAL}
                    title={localeTitles.TITLE_UPLOAD}
                    control={control}
                    name="diplomas"
                    maxFiles={10}
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                      "application/*": [
                        ".png",
                        ".jpeg",
                        ".jpg",
                        ".pdf",
                        ".doc",
                        ".docx",
                      ],
                    }}
                  />

                  <UploadCard
                    imageClass={styles["uploadCard_image"]}
                    className={styles["uploadCardDiv"]}
                    inputClass={styles["uploadCardDiv-inputDiv"]}
                    Icon={<CameraIcon />}
                    text={localeBasics.TEXT_SCAN_BY_CAMERA}
                    title={localeTitles.TITLE_TURN_ON}
                    control={control}
                    name="scannedImages"
                    maxFiles={1}
                  />
                  {freelancer && (
                    <UploadCard
                      imageClass={styles["uploadCard_image"]}
                      className={styles["uploadCardDiv"]}
                      inputClass={styles["uploadCardDiv-inputDiv"]}
                      Icon={<UploadFileIcon />}
                      text={localeBasics.TEXT_INSURANCE_BLOCK_OPTIONAL}
                      title={localeTitles.TITLE_UPLOAD}
                      control={control}
                      name="insurance"
                      maxFiles={10}
                      accept={{
                        "image/*": [".png", ".jpeg", ".jpg"],
                        "application/*": [
                          ".png",
                          ".jpeg",
                          ".jpg",
                          ".pdf",
                          ".doc",
                          ".docx",
                        ],
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {width < breakPoints.md ? (
              <div className={styles["save-mobile"]}>
                <Button className={"inactive-fullWidth"}>
                  {localeButtons.BUTTON_SAVE_CHANGES}
                </Button>
              </div>
            ) : (
              <div className={styles["save-desktop"]}>
                <Button
                  className={"secondary-active"}
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                >
                  {localeButtons.BUTTON_SAVE_CHANGES}
                </Button>
              </div>
            )}
          </form>
          <ModalComponent open={jobModal} onClose={closeJobModal}>
            <AddJobModal
              onEdit={onConfirmJobEdit}
              onConfirm={onConfirmJob}
              closeModal={closeJobModal}
              data={editJobFeild as Job}
            />
          </ModalComponent>
          <ModalComponent open={educationModal} onClose={closeEducationModal}>
            <AddEducationModal
              onEdit={onConfirmEducationEdit}
              onConfirm={onConfirmEducation}
              closeModal={closeEducationModal}
              data={editEducationFeild as Education}
            />
          </ModalComponent>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
