import { Text } from "components/atoms/TextF";
import styles from "./TaskDetailModal.module.scss";
import { AddScheduleOrganizationProps } from "./@types";
import { useLocale } from "locales";
import CloseCircleIcon from "assets/svgs/CloseCircleIcon";

import dayjs from "dayjs";
import ModalComponent from "components/molecules/ModalF/Modal";

const TaskDetailModal = ({
  closeModal,
  task,
  show,
}: AddScheduleOrganizationProps) => {
  const { localeBasics, localeTitles, localeLabels } = useLocale();

  return (
    <ModalComponent open={show} onClose={closeModal}>
      <div className={styles["AddScheduleOrganization"]}>
        <div className={styles["AddScheduleOrganization-header"]}>
          <div></div>
          <Text className={styles["title"]}>
            {localeTitles.TITLE_ORGANIZATION_SCHEDULE_DETAILE}
          </Text>
          <div onClick={closeModal}>
            <CloseCircleIcon color="#8BA3F5" size="33" />
          </div>
        </div>

        <Text className={styles["subTitle"]}>
          {localeBasics.TEXT_STAY_ORGANIZED_ADD_YOUR_SCHEDULE}
        </Text>

        <div className={styles["AddScheduleOrganization-main-body"]}>
          <div className={styles["eachSection-dates-availability"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_TASK_NAME}
            </Text>
            <div className={styles["selectDiv-timeFrame"]}>{task?.name}</div>
          </div>

          {/* <div className={styles["eachSection-dates-availability"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_ORGANIZATION_BRANCH}
            </Text>
            <div className={styles["selectDiv-timeFrame"]}>
              {task?.address?.addressTitle ||
                task?.address?.street + " " + task?.address?.city}
            </div>
          </div> */}

          <div className={styles["eachSection-dates-availability"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_EMPLOYEE_NAME}
            </Text>

            <div className={styles["selectDiv-timeFrame"]}>
              {task?.firstName + " " + task?.lastName}
            </div>
          </div>

          <div className={styles["eachSection-dates-availability"]}>
            <Text className={styles["heading"]}>
              {localeLabels.LABEL_TIME_FRAME}
            </Text>
            <div className={styles["selectDiv-timeFrame"]}>
              {/* formate like this  start date & time and endDate and time  */}
              {dayjs(task?.start).format("DD MMM YYYY hh:mm A")} -{" "}
              {dayjs(task?.end).format("DD MMM YYYY hh:mm A")}
            </div>
          </div>
        </div>

        {/* <div className={styles["AddScheduleOrganization-buttons"]}>
          <Button
            className={styles["saveButton"]}
            onClick={onEditTask}
          >
            {localeButtons.BUTTON_EDIT_TASK}
          </Button>
          {width > breakPoints.md && (
            <Button className={styles["cancelButton"]} onClick={onDeleteTask}>
              {localeButtons.BUTTON_DELETE_TASK}
            </Button>
          )}
        </div> */}
      </div>
    </ModalComponent>
  );
};

export default TaskDetailModal;
