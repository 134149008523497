import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function EditPencilProfileIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
    width={window.innerWidth > breakPoints.lg - 1 ? "18" : "9.3"}
    height={window.innerWidth > breakPoints.lg - 1 ? "18" : "9.3"}
   
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1237 15.806H2.8737C2.59467 15.806 2.36328 15.5746 2.36328 15.2956C2.36328 15.0165 2.59467 14.7852 2.8737 14.7852H15.1237C15.4027 14.7852 15.6341 15.0165 15.6341 15.2956C15.6341 15.5746 15.4027 15.806 15.1237 15.806Z"
        fill="#101828"
      />
      <path
        d="M13.7758 3.20223C12.4556 1.88195 11.1625 1.84792 9.80821 3.20223L8.98474 4.0257C8.91668 4.09376 8.88946 4.20265 8.91668 4.29792C9.4339 6.1014 10.8767 7.54417 12.6802 8.0614C12.7074 8.0682 12.7346 8.07501 12.7618 8.07501C12.8367 8.07501 12.9047 8.04778 12.9592 7.99334L13.7758 7.16987C14.4496 6.50292 14.7763 5.8564 14.7763 5.20306C14.7831 4.52931 14.4564 3.87598 13.7758 3.20223Z"
        fill="#101828"
      />
      <path
        d="M11.4567 8.68062C11.2593 8.58534 11.0688 8.49006 10.885 8.38117C10.7353 8.2927 10.5924 8.19742 10.4494 8.09534C10.3338 8.02048 10.1976 7.91159 10.0683 7.8027C10.0547 7.79589 10.0071 7.75506 9.95264 7.70062C9.72806 7.51006 9.47625 7.26506 9.25167 6.99284C9.23125 6.97923 9.19723 6.93159 9.14959 6.87034C9.08153 6.78867 8.96584 6.65256 8.86375 6.49603C8.78209 6.39395 8.68681 6.24423 8.59834 6.09451C8.48945 5.91076 8.39417 5.72701 8.29889 5.53645C8.28448 5.50556 8.27053 5.47482 8.257 5.44426C8.15657 5.21742 7.86077 5.1511 7.68535 5.32652L3.78681 9.22506C3.69834 9.31353 3.61667 9.48367 3.59625 9.59937L3.22875 12.2059C3.1607 12.6687 3.29 13.1042 3.57584 13.3969C3.82084 13.6351 4.16111 13.7644 4.52861 13.7644C4.61028 13.7644 4.69195 13.7576 4.77361 13.7439L7.38695 13.3765C7.50945 13.356 7.67959 13.2744 7.76125 13.1859L11.6549 9.29222C11.8316 9.11558 11.7649 8.81242 11.5352 8.71438C11.5093 8.70335 11.4832 8.6921 11.4567 8.68062Z"
        fill="#101828"
      />
    </svg>
  );
}

export default EditPencilProfileIcon;

// fill={white ? "#fffff" : "#0F1322"}
