import React from "react";
type Props = { white?: boolean; size?: string; onClick?: any };
function SearchIcon({ white, size, onClick }: Props) {
  return (
    <svg
      className="flex-shrink-0"
      onClick={onClick}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8637 24.586L22.7867 19.509C23.8357 17.928 24.4497 16.035 24.4497 14C24.4497 8.486 19.9637 4 14.4497 4C8.93571 4 4.44971 8.486 4.44971 14C4.44971 19.514 8.93571 24 14.4497 24C16.4847 24 18.3777 23.386 19.9587 22.337L25.0357 27.414C25.8157 28.195 27.0837 28.195 27.8637 27.414C28.6447 26.633 28.6447 25.367 27.8637 24.586ZM7.44971 14C7.44971 10.14 10.5897 7 14.4497 7C18.3097 7 21.4497 10.14 21.4497 14C21.4497 17.86 18.3097 21 14.4497 21C10.5897 21 7.44971 17.86 7.44971 14Z"
        fill="#9FB5FF"
      />
    </svg>
  );
}

export default SearchIcon;
