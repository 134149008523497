import { useOutletContext } from "react-router-dom";
import { UserId } from "types/schema";
import { WorkerPannel } from "./CompanyWorkersView";

export default function WorkerPannelPage() {
  const {
    workers,
    hiredWorkerId,
    workerLoading,
    setSelectedWorker,
  }: {
    workers?: UserId[];
    hiredWorkerId?: string;
    workerLoading?: boolean;
    setSelectedWorker: React.Dispatch<React.SetStateAction<UserId | null>>;
  } = useOutletContext();

  return (
    <WorkerPannel
      workers={workers}
      hiredWorkerId={hiredWorkerId}
      workerLoading={workerLoading}
      setSelectedWorker={setSelectedWorker}
    />
  );
}
