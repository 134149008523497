import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: boolean;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function ZipIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={window.innerWidth > breakPoints.sm - 1 ? "24" : "15"}
      height={window.innerWidth > breakPoints.sm - 1 ? "24" : "15"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.35 9.05077L15.01 16.5908C14.45 18.3808 11.94 18.4108 11.35 16.6308L10.65 14.5608C10.46 13.9908 10.01 13.5308 9.43997 13.3508L7.35997 12.6508C5.58997 12.0608 5.61997 9.53077 7.40997 8.99077L14.95 6.64077C16.43 6.19077 17.82 7.58077 17.35 9.05077Z"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ZipIcon;

// fill={white ? "#fffff" : "#0F1322"}
