import dayjs from "dayjs";
import { props } from "./@types";
import styles from "./WeeklyAvailability.module.scss";
import { Text } from "components/atoms/TextF";
import WeekView from "components/cells/WeekView";

import { useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";

const WeeklyAvailability = ({
  data,
  title,
  showDetaile = false,
  onNavigate,
}: props) => {
  const { localeButtons } = useLocale();

  return (
    <div className={styles["SkillAndExpertiseCard"]}>
      <div className={styles["header"]}>
        <Text className={styles["title"]}>{title}</Text>

        {showDetaile && (
          <Button className="primary" onClick={onNavigate}>{localeButtons.BUTTON_SEE_ALL_AVAILABILITY}</Button>
        )}
      </div>

      <div className={styles["SkillAndExpertiseCard-skills"]}>
        <WeekView
          eventsData={data}
          addAvailability={() => {}}
          refetchData={() => {}}
          selectedDate={dayjs()}
          isClander={false}
        />
      </div>
    </div>
  );
};

export default WeeklyAvailability;
