

type Props = {
  color?: string;
  size?: number;
  onClick?: any;
};
function LocationIcon({ color, size = 24, onClick }: Props) {

  return (
    <svg
      width={size}
      height={size}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 13.4295C13.723 13.4295 15.1199 12.0326 15.1199 10.3095C15.1199 8.58633 13.723 7.18945 11.9999 7.18945C10.2768 7.18945 8.87988 8.58633 8.87988 10.3095C8.87988 12.0326 10.2768 13.4295 11.9999 13.4295Z"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
      />
      <path
        d="M3.6202 8.49C5.5902 -0.169998 18.4202 -0.159997 20.3802 8.5C21.5302 13.58 18.3702 17.88 15.6002 20.54C13.5902 22.48 10.4102 22.48 8.3902 20.54C5.6302 17.88 2.4702 13.57 3.6202 8.49Z"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
      />
    </svg>
  );
}

export default LocationIcon;

// fill={white ? "#fffff" : "#0F1322"}
