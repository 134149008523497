import styles from "./OrganizationJobsPage.module.scss";

import { useNavigate } from "react-router-dom";

import { useHiredWorkers } from "./hook";
import HiredWorkersPage from "./HiredWorkersPage/HiredWorkersPage";
import Loader from "components/atoms/Loader";

type OrganizationJobsPage = {};
const FinanceJobs = ({}: OrganizationJobsPage) => {

  const {
    hiredWorkersList,
    isLoading: loading,
    activeCategory,
    hanCategoryChange,
    appDataLoading
  } = useHiredWorkers();

  const navigate = useNavigate();



  const onClickCard = (hiredWorkerId: string) => {
    navigate(`/organization/${hiredWorkerId}/shift`);
  };

  return (
    <div className={styles["OrganizationJobsPage"]}>
      <div className={styles["OrganizationJobsPage-main"]}>
        {appDataLoading ? (
          <div className={styles["OrganizationJobsPage-jobs"]}>
          <Loader />
        </div>
        ) :  (
          <div className={styles["OrganizationJobsPage-jobs"]}>
              <HiredWorkersPage
                isLoading={loading}
                activeCategory={activeCategory}
                hanCategoryChange={hanCategoryChange}
                isPage={true}
                onClickCard={onClickCard}
                hiredWorkersList={hiredWorkersList}
              />
          </div>
        )}
      </div>
    </div>
  );
};

export default FinanceJobs;
