import React from "react";
interface Props {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
}
function ClanderIcon({ color = "#000", size = 24, style, onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
      style={style}
    >
      <path fill="url(#pattern0)" d="M0 0H24V24H0z"></path>
      <defs>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.01563)" xlinkHref="#image0_1777_22167"></use>
        </pattern>
        <image
          id="image0_1777_22167"
          width="64"
          height="64"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB60lEQVR4nO2abU7DMAyG3wkxTsDGGQb85+N8Y3AD2G7CT6oB4xDwh+6DCyAQIiiSkVDp1i510nR7H8lS0nW24zhOpAYghBBCCCFr0wZwBWAKIAUwkGd12J9KO6R9XAIwGbmo2b4NQjBSMXoK4EzaaU32T6Q9C2gfv1Ff1g9t/0v6O9sagE/p725rAD6kv+fLYBfACMAiU3iWOTTOKVJVJVlh7/3PM+vjUHxW4QDAS45Ddysc0h58UcBvc959BtDRCMBIFD4C6AFo1bAkyuq3vh0CmMjvNxrG30RZT8FBV9bVfyS/zzWMf4uyvJnP7sv2THAu7VcN4476W/KO9b0ypsRsDnLWYF/DeAX9alloSihqi5OpzExf+Wzuoj9oAGKEAUDNGTB2OOBU0VN0dnDGOCrScrLK4O8dx6wagBDveMUwAGAGGE+ZwyUA1gA0tgiOK25RIeTBZwBMQ8R7AGItggwAmAHgEoAChjUALIJmRWbEIEkdRTCJYOB5H2mCBaCIjT8HFMEAgBkALgGEWSaa//sHawD8F8Ey4sO+V0XJkv05+47rPu/Lb6fP47Gh+nl8IcrszYumoHpBYijKJqI45kywvh0DeBKfrzWUduXCkWmYWJ/3oURHLhzNIxhYkcxk5tUGTwghhBCCTeMH3M7W7atewJQAAAAASUVORK5CYII="
        ></image>
      </defs>
    </svg>
  );
}

export default ClanderIcon;
