import { Text } from "components/atoms/TextF";
import styles from "./InviteWorkerModal.module.scss";
import { InviteWorkerModalProps } from "./@types";
import { useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";

import { ControlInput } from "components/atoms/ControlledInput/Input";
import DocumentTextIcon from "assets/svgs/DocumentTextIcon";
import { useInviteWorkerModal } from "./hook";

const InviteWorkerModal = ({
  closeModal,
  onConfirm,
  loading,
  isEmployee = false,
  defaultValues = {
    jobId: "",
    perHourRate: 0,
    content: "",
    worker: "",
  },
}: InviteWorkerModalProps) => {
  // const { register } = useFormContext();
  const {
    localeTitles,
    localePlaceholders,
    localeButtons: localeButtons,
  } = useLocale();

  const { control, handleSubmit } = useInviteWorkerModal(defaultValues);

  return (
    <div className={styles["InviteWorkerModal"]}>
      <div className={styles["InviteWorkerModal-main"]}>
        <div className={styles["InviteWorkerModal-head"]} onClick={closeModal}>
          <Text className={styles["backText"]}>
            {localeTitles.TITLE_INVITE_WORKER}
          </Text>
        </div>
        <div className={styles["workerData-sections"]}>
          {/* <form onSubmit={handleSubmit(onConfirm)}> */}

          {!isEmployee && (
            <ControlInput
              control={control}
              name={"perHourRate"}
              placeholder={localePlaceholders.PLACEHOLDERS_SALARY_HOURLY_RATE}
              preDefinedClassName={"inputField"}
              preDefinedWrapClassName={"inputField-wrap"}
              type="number"
              prefix={<DocumentTextIcon color={"#858D98"} sameSize />}
              borderClass=""
            />
          )}

          <ControlInput
            control={control}
            name={"content"}
            placeholder={localePlaceholders.PLACEHOLDERS_JOB_DESCRIPTION}
            preDefinedClassName={"inputField"}
            preDefinedWrapClassName={"inputField-wrap"}
            type="text"
            prefix={<DocumentTextIcon color={"#858D98"} sameSize />}
            borderClass=""

          />

          {/* </form> */}
        </div>

        <div className={styles["buttonDiv"]}>
          <Button
            loading={loading}
            className={"secondary-active"}
            onClick={handleSubmit(onConfirm)}
          >
            {localeButtons.BUTTON_INVITE}
          </Button>
          <Button className={"primary"} onClick={closeModal}>
            {localeButtons.BUTTON_CANCEL}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InviteWorkerModal;
