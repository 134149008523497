import type { EmptyScreenProps } from "./@types";
import styles from "./EmptyScreen.module.scss";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import noDataImg from "assets/images/jobs/noData.png";

import { Button } from "components/atoms/ButtonF";

// import { Table, TableHead, TableRow, TableCell, TableBody, TableEmptyScreen } from '@material-ui/core';

const EmptyScreen = ({
  image = noDataImg,
  onClick,
  text,
  buttonText,
}: EmptyScreenProps) => {
  return (
    <div className={styles["noDataDiv"]}>
      <Img className={styles["noData-Img"]} src={image} />
      <Text className={styles["noData-text"]}>{text}</Text>

      {onClick && buttonText && <Button className={"primary-active"} onClick={onClick}>
        {buttonText}
      </Button>}
    </div>
  );
};

export default EmptyScreen;
