export default {
  LABEL_FAVORITES_ONLY: "Alleen favorieten",
  LABEL_FILLED_SERVICES: "Gevulde diensten",
  LABEL_RECENTLY_VIEWED: "Recent bekeken",
  LABEL_SHOW_BY_NAME: "Toon op naam",
  LABEL_SHOW_BY_POPULARITY: "Toon op populariteit",
  LABEL_SHOW_BY_DATE: "Toon op datum",
  LABEL_NEWEST: "Nieuwste",
  LABEL_CLIENT_RATING: "Medewerker beoordeling",
  LABEL_DAYS_WITH_SCHEDULED_SERVICES: "Dagen met ingeplande diensten",
  LABEL_ONLY_WITHIN_MY_FEATURES: "Alleen binnen mijn functies",
  LABEL_URGENT_JOBS: "Spoed diensten",
  LABEL_FREELANCER: "Freelancer",
  LABEL_FACILITY: "Facilitair",
  LABEL_HOSPITALITY_INDUSTRY: "Horeca",
  LABEL_LOGISTIC: "Logistiek",
  LABEL_OTHER: "Overig",
  LABEL_PROMOTION: "Promotie",
  LABEL_ACTIVE_JOBS: "Actieve diensten",

  completed: "Voltooid",
  rejected: "Afgekeurd",
  approved: "Goedgekeurd",
  declined: "Afgekeurd",
  inProgress: "In behandeling",

  LABEL_RETAIL: "Promotie",
  LABEL_CARE: "Gezondheidszorg",
  LABEL_SHOW_ALL: "Toon alles",
  LABEL_SORT_BY_NAME: "Sorteer op Naam",
  LABEL_SORT_BY_POPULARITY: "Sorteer op Populariteit",
  LABEL_SORT_BY_DATE: "Sorteer op Datum",
  LABEL_PROMOTED: "BELANGRIJK",
  LABEL_URGENT_HIRING: "SPOED DIENST",
  LABEL_URGENT: "SPOED",
  LABEL_URGENT_LOWER: "Spoed",
  LABEL_REVIEWS: "Beoordelingen",
  LABEL_FIND_JOBS: "Vind diensten",
  LABEL_POSTED_JOBS: "Geplaatste diensten",
  LABEL_FIND_WORKERS: "Vind werknemers",
  LABEL_HIRED_WORKERS: "Ingehuurde werknemers",
  LABEL_TIMELINE: "Tijdlijn",

  LABEL_INBOX: "Inbox",
  LABEL_COMPLETE: "VOLTOOID",
  LABEL_COMPLETED: "VOLTOOID",
  LABEL_PERSON: "Persoon",
  LABEL_PROPOSAL_SUBMITTED: "Voorstel ingediend",
  LABEL_INVITED_BY: "Uitgenodigd door",
  LABEL_DAY: "Dag",
  LABEL_DATE: "Datum",
  LABEL_WORKING_STATUS: "Status",
  LABEL_JOB_INFO: "Dienst informatie",
  LABEL_JOB_CONTRACT: "Dienst informatie",
  LABEL_HOURS_WORKED: "Gewerkte uren",
  LABEL_RATE: "Tarief",
  LABEL_TOTAL_TIME: "Totale tijd",
  LABEL_TOTAL_AMOUNT: "Totaal bedrag",
  LABEL_FROM: "Van",
  LABEL_TO: "Naar",
  LABEL_INVOICE_NUMBER: "Factuurnummer",
  LABEL_DATE_OF_INVOICE: "Factuurdatum",
  LABEL_TOTAL_AMOUNT_EX_VAT: "Totaalbedrag (ex. BTW)",
  LABEL_VAT: "VAT",
  LABEL_TOTAL_AMOUNT_INCL_VAT: "Totaalbedrag (incl. BTW)",
  LABEL_DOWNLOAD: "Download",
  LABEL_PAYMENT: "Betalen",

  LABEL_SATISFIED: "Uitbetaald",
  LABEL_AVAILABLE: "Beschikbaar",
  LABEL_UNAVAILABLE: "Niet beschikbaar",
  LABEL_MR: "Dhr.",
  LABEL_DAY_SHIFT: "Dag",
  LABEL_NIGHT_SHIFT: "Nacht",
  LABEL_ALL_DAY: "Gehele dag",
  LABEL_MRS: "Mevr.",
  LABEL_ENGLISH: "Engels",
  LABEL_DUTCH: "Nederlands",
  LABEL_INVITATION_TO_SETTINGS: "Uitnodiging voor Instellingen",
  LABEL_INVITATION_OF_LIKED_SERVICES: "Uitnodiging van favoriete diensten",
  LABEL_REMIDER_OF_JOBS: "Herinnering voor diensten",
  LABEL_ALERT_OF_PROPOSAL:
    "Waarschuwing voor goedkeuring van dienstvoorstel en dienstaanvraag",
  LABEL_SUN: "Zo",
  LABEL_MON: "Ma",
  LABEL_TUE: "Di",
  LABEL_WED: "Wo",
  LABEL_THU: "Do",
  LABEL_FRI: "Vrij",
  LABEL_SAT: "Za",
  LABEL_WEEK: "Week",
  LABEL_WEEKLY: "Wekelijks",
  LABEL_MONTHLY: "Maandelijks",
  LABEL_COMPANY_NAME: "Bedrijfsnaam",
  LABEL_COMPANY_ADDRESS: "Bedrijfsadres",
  LABEL_ADDRESS: "Adres",
  LABEL_COPY_THE_PREVIOUS_WEEK_SCHEDULE:
    "Kopieer het rooster van de vorige week",
  LABEL_AVAILABILITY_SELECTION: "Beschikbaarheidsselectie",
  LABEL_REASON: "Reden",
  LABEL_TASK_NAME: "Opdrachtnaam",
  LABEL_TASKS: "Opdrachtnaam",
  LABEL_TASK_DESCRIPTION: "Taakomschrijving",
  LABEL_TIME_FRAME: "Tijd schema",
  LABEL_AVAILABLE_ALL_DAY: "De hele dag beschikbaar",
  LABEL_APPROVE: "Goedkeuren",
  LABEL_APPROVED: "Goedgekeurd",
  LABEL_NOT_AVAILABLE: "Niet beschikbaar",
  LABEL_PARTIAL_AVAILABLE: "Gedeeltelijk beschikbaar",
  LABEL_PARTIAL: "Gedeeltelijk",
  LABEL_ALREADY_HIRED: "Al aangenomen",
  LABEL_OPEN_HIRING: "Open dienst",
  LABEL_HIRED: "Aangenomen",
  LABEL_SHIFT_NAME: "Dienst naam:",
  LABEL_NEW_SHIFT: "Nieuwe Dienst",
  LABEL_SHIFT: "Dienst",
  LABEL_DECLINED: "Afgewezen",
  LABEL_PAUSED: "Gepauzeerd",
  LABEL_DELETE: "Verwijderen",
  LABEL_TOP_RATED: "Best Beoordeeld",
  LABEL_ORDINARY: "Normaal",
  LABEL_SOON: "Oproep plaatsen",
  LABEL_FLEXIBLE: "Flexibel",
  LABEL_JOINING_DATE: "Zo snel mogelijk",
  LABEL_WORKERS: "Werknemers",
  LABEL_ORGANIZATION_BRANCH: "Organisatie branche",
  LABEL_EMPLOYEE_NAME: "Naam",
  LABEL_SCROLL_DOWN: "Naar beneden scrollen",
  LABEL_JOB_COMPLETION_SCORE: "Diensten voltooid score",
  LABEL_TOTAL_EARNINGS: "Totaal bedrag verdiend",
  LABEL_RECEIVED_INVITATIONS: "Uitnodigingen ontvangen",
  LABEL_INVITE: "Uitnodiging",
  LABEL_JOB_SUCCESS: "Succesvolle diensten",
  LABEL_OVERALL_RATING_START: "Algemene Beoordeling",
  LABEL_ORDERS_COMPLETED: "Diensten voltooid",
  LABEL_ORDERS: "Diensten",
  LABEL_SUBMITTED_PROPOSAL: "Ingediende uitnodigingen",
  LABEL_TOTAL_PROPOSAL: "Totale uitnodigingen",
  LABEL_PLEASE_SELECT: "Selecteer alstublieft",
  LABEL_PLEASE_SELECT_AVAILABILITY: "Selecteer de beschikbaarheid van werknemers",

  LABEL_PLEASE_SELECT_SHIFT: "Selecteer alstublieft een dienst",
  LABEL_EVERY_WEEK: "Elke week",
  LABEL_EVERY_SECOND_WEEK: "Elke tweede week",
  LABEL_EVERY_THIRD_WEEK: "Elke derde week",
  LABEL_EVERY_FOURTH_WEEK: "Elke vierde week",
  LABEL_PLEASE_SELECT_A_JOB: "Selecteer alstublieft een dienst?",

  LABEL_SUNDAY: "Zondag",
  LABEL_MONDAY: "Maandag",
  LABEL_TUESDAY: "Dinsdag",
  LABEL_WEDNESDAY: "Woensdag",
  LABEL_THURSDAY: "Donderdag",
  LABEL_FRIDAY: "Vrijdag",
  LABEL_SATURDAY: "Zaterdag",

  LABEL_SEARCH: "Zoeken",
  LABEL_CHILD_DAY_CARE: "Kinderopvang",
  LABEL_HOSPITALITY: "Hospitality",
  LABEL_HEALTH_CARE: "Gezondheidszorg",
  LABEL_OFFICE: "Kantoor",
  LABEL_SALES: "Verkoop",
  LABEL_CLEANING: "Schoonmaak",
  LABEL_CALL_CENTER: "Callcenter",
  LABEL_CONSTRUCTION: "Bouw",
  LABEL_CONTACT_US: "Neem contact op",
  LABEL_EMAIL: "E-mail",
  LABEL_PHONE: "Telefoon",
  LABEL_WHATSAPP: "Whatsapp",
  LABEL_IT: "IT",
  LABEL_EDUCATION: "Onderwijs",
  LABEL_JOB_TITLE: "Functie",
  LABEL_START_DATE: "Begin datum",
  LABEL_END_DATE: "Eind datum",
  LABEL_DEGREE_TITLE: "Opleiding",
  LABEL_INSTITUE_NAME: "Instituut",
  LABEL_LINKEDIN: "LinkedIn",
  LABEL_SKYPE: "Instagram",
  LABEL_VIEW_PROFILE: "Profiel bekijken",
  LABEL_UNLINK: "ontkoppel",
  LABEL_FIVE_KM: "5 KM",
  LABEL_TEN_KM: "10 KM",
  LABEL_TWINTY_KM: "20 KM",
  LABEL_THIRITY_KM: "30 KM",
  LABEL_FOURTY_KM: "40 KM",
  LABEL_FIFTY_KM: "50 KM",
  LABEL_HOURLY_RATE: "Tarief per uur",
  LABEL_WRITE_DESCRIPTION: "Beschrijving",
  LABEL_WRITE_PERPOSAL: "Schrijf een voorstel",
  LABEL_ADD_PORTFOLIO_LINK: "Voeg portfolio link",
  LABEL_AVAILABLE_A: "Beschikbaar",
  LABEL_TASK: "Taak = T",
  LABEL_TASK_BOLD: "Toegewezen taak = T with bold border",
  LABEL_SCHEDULE_S: "Rooster = S",
  LABEL_CLICK_HERE_TO_ADD_INDUSTRY:
    "Klik hierop om een branche of meerdere branches toe te voegen.",
  LABEL_ASSIGNED: "Toegewezen",
  LABEL_ACCEPTED: "Geaccepteerd",

  LABEL_REJECT: "Afwijzen",
  LABEL_REJECTED: "Afgewezen",
  LABEL_INPROGRESS: "In behandeling",
  LABEL_LOADMORE: "Laad meer",

  LABEL_START: "Begin",
  LABEL_END: "Eind",
  LABEL_BREAK: "Pauze",

  LABEL_FULLNAME: "Volledige naam",

  LABEL_PW: "Wachtwoord",
  LABEL_CPW: "Bevestig wachtwoord",
  LABEL_NEW_PW: "Nieuw wachtwoord",
  LABEL_NEW_CPW: "Bevestig nieuw wachtwoord",
  LABEL_NO_DOT: "Nee.",
  LABEL_USER_NAME: "Gebruikersnaam",
  LABEL_WORKER_TYPE: "Type werknemer",
  LABEL_VERIFCATION_STATUS: "Verificatiestatus",
  LABEL_APPROVED_DATE: "Goedgekeurde datum",
  LABEL_VIEW_MORE: "Bekijk meer",

  LABEL_FREELANCER_NAME: "Naam",
  LABEL_ORGANIZATION_NAME: "Organisatie naam",
  LABEL_PHONE_NUMBER: "Telefoonnummer",
  LABEL_INDUSTRY_CATEGORY: "Industriecategorie",
  LABEL_DOB: "Geboortedatum",
  LABEL_NOTIFICATION: "Melding",
  LABEL_ALLOW_POST_JOB: "Toestaan om vacature te plaatsen",
  LABEL_SEE_MORE: "meer",
  LABEL_BUSINESS_BRANCH: "Zakelijke Branche",
  LABEL_INDUSTRY: "Bracnche",
  LABEL_IN_PROCESS: "In behandeling",
  LABEL_DATE_OF_BIRTH: "Geboortedatum",
  LABEL_ADDRESS_DETAILS: "Adresgegevens",
  LABEL_BANK_ACCOUNT_DETAILS: "Bankrekening gegevens",
  LABEL_KVK_NUMBER: "Kvk-nummer",
  LABEL_EMPLOYEE_ID: "Werknemer-ID",
  LABEL_CERTIFICATE: "Certificaat",
  LABEL_DIPLOMA: "Diploma",
  LABEL_SCAN_BY_CAMERA: "Scannen met camera",
  LABEL_INSURANCE_DOCUMENT: "Verzekeringsdocument",
  LABEL_SEND_EMAIL_TO_EMPLOYEE: "Verzend een e-mail naar werknemer",
  LABEL_VOG_OF_EMPLOYEE: "VOG van werknemer",
  LABEL_VOG_REGISTRATION_MSG:
     "Alle bovenstaande gegevens zijn geverifieerd. Wij gaan daarom over tot het sturen van de VOG-registratielink naar Medewerker",
  LABEL_CURRENTLY_NO_VOG_AVAILABLE: "Momenteel is hier geen VOG beschikbaar",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_ACCOUNT:
    "Weet u zeker dat u dit account wilt deactiveren?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_ACTIVATE_ACCOUNT:
    "Weet je zeker dat je dit account wilt activeren?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_BRANCH:
    "Weet u zeker dat u deze branche wilt verwijderen?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SKILL:
    "Weet je zeker dat je deze vaardigheid wilt verwijderen?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JOB:
    "Weet u zeker dat u deze dienst wilt verwijderen?",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_CATEGORY:
    "Weet je zeker dat je deze categorie wilt verwijderen?",
  LABEL_FRONT_SIDE: "Voorzijde",
  LABEL_BACK_SIDE: "Achterkant",
  LABEL_NOTE: "Opmerking",
  LABEL_VOG_IS_VALID_AND_APPROVED:
    "Medewerker VOG is geldig en goedgekeurd.",
  LABEL_VOG_IS_INVALID_AND_REJECTED:
    "Medewerker VOG is ongeldig en afgewezen.",
  LABEL_COMPANY_EMPLOYEES: "Aantal werknemers organisatie:",
  LABEL_COMPANY_JOBS: "aantal diensten organisatie:",
  LABEL_SEE_ALL: "Bekijk alles",
  LABEL_BRANCH_NAME: "Naam van vestiging",
  LABEL_OPTIONS: "Opties",
  LABEL_SEE_CATEGORIES: "Zie categorieën",
  LABEL_CATEGORY_NAME_DUTCH: "Categorienaam (Nederlands)",
  LABEL_CATEGORY_NAME_ENGLISH: "Categorienaam (Engels)",
  LABEL_SKILL_NAME_DUTCH: "Naam van de vaardigheid (Nederlands)",
  LABEL_SKILL_NAME_ENGLISH: "Naam van de vaardigheid (Engels)",
  LABEL_BRANCH_NAME_ENGLISH: "Naam van de branche (Engels)",
  LABEL_BRANCH_NAME_DUTCH: "Naam van de branche (Nederlands)",
  LABEL_SELECT_BRANCH: "Selecteer branche",
  LABEL_TURN_ON_PRICE_HIDE: "Prijs verbergen inschakelen",
  LABEL_TURN_ON_JOB_CHECK: "Zet vacature plaatsings controle aan",
  LABEL_TURN_OFF_JOB_CHECK: "Schakel vacature plaatsings controle uit",
  LABEL_TURN_OFF_PRICE_HIDE: "Prijs verbergen uitschakelen",
  LABEL_SEARCH_BY_NAME: "Zoeken op naam",
  LABEL_SEARCH_BY_EMAIL: "Zoeken via e-mail",
  LABEL_SEARCH_BY_CONTACT: "Zoeken op contact",

  LABEL_ISSUE_DATE: "Uitgiftedatum",
  LABEL_EXPIRE_DATE: "Vervaldatum",
  LABEL_REPORT_TYPE: "Rapport type",
  LABEL_REPORT_GROUP: "Rapport groep",
  LABEL_FREELANCER_PRICE: "Zet Freelancer-prijs aan/uit",

  CURRENT_LOCATION: "Huidige locatie",


  LABEL_REGISTRATION_DATE:"Registratiedatum",
  LABEL_EMPLOYEE_NUMBER:"wn",
  LABEL_FREELANCER_NUMBER:"fn",
  LABEL_ASSOCIATED_ORGANIZATION: "Geassocieerde organisatie",
  LABEL_LATEST_CONTRACT_START: "Laatste contract",



  
};
