// Pagination.tsx
import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange?: (newPage: number) => void;
  increment: () => void;
  decrement: () => void;
  hasMore: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  hasMore,
  increment,
  decrement,
}) => {




  return (
    <div className="flex justify-center items-center ">
      <div
        className={`mx-2 cursor-pointer ${
          currentPage === 1 ? "text-gray-300" : ""
        }`}
        onClick={() => currentPage !== 1 && decrement()}
      >
        <FaAngleLeft />
      </div>
        <div
          key={currentPage}
          className={`mx-2 cursor-pointer font-bold"`}
        >
          {currentPage}
        </div>
      <div
        className={`mx-2 cursor-pointer ${!hasMore ? "text-gray-300" : ""}`}
        onClick={() =>  increment()}
      >
        <FaAngleRight />
      </div>
    </div>
  );
};

export default Pagination;
