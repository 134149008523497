export default {
  DROPDOWN_EDIT_ACCOUNT: "Account bewerken",
  DROPDOWN_UPDATE_PW: "Wachtwoord bijwerken",
  DROPDOWN_ALL: "Alles",
  DROPDOWN_READ: "Lezen",
  DROPDOWN_UNREAD: "Ongelezen",
  DROPDOWN_SORT_A_Z: "A_Z",
  DROPDOWN_SORT_Z_A: "Z_A",
  DROPDOWN_SORT_BY_POPULARITY: "Populariteit",
  DROPDOWN_BLOGS: "Blogs",
  DROPDOWN_CONTACT_US: "Contact",
  DROPDOWN_ABOUT_US: "Over ons",
  DROPDOWN_DUTCH: "Nederlands",
  DROPDOWN_ENGLISH: "Engels",
  DROPDOWN_ACCOUNT: "Account",
  DROPDOWN_SETTINGS: "Instellingen",
  DROPDOWN_FAQS: "FAQ",
  DROPDOWN_FINANCE: "Financiën",
  DROPDOWN_DELETE_ACCOUNT: "Verwijder Account",
  DROPDOWN_STATUS_VERIFICATION: "Status van verificatie",
  DROPDOWN_STATUS_VERIFIED: "geverifieerd",
  DROPDOWN_LOGOUT: "Uitloggen",
  DROPDOWN_MUTE_NOTIFICATIONS: "Meldingen dempen",
  DROPDOWN_UNMUTE_NOTIFICATIONS: "Meldingen niet dempen",
  DROPDOWN_BLOCK: "Blokkeer",
  DROPDOWN_UNBLOCK: "Deblokkeer",
  DROPDOWN_DELETE_CHATE: "Verwijder Chat",
  DROPDOWN_REPORT: "Rapporteer",
  DROPDOWN_EDIT: "Wijzig",
  DROPDOWN_DELETE: "Verwijder",
  DROPDOWN_COMPLETE_CONTRACT: "Voltooidienst",
  DROPDOWN_CANCEL_CONTRACT: "Annuleer dienst",
  DROPDOWN_PAUSE_CONTRACT: "Pauzeerdienst",
  DROPDOWN_RESUME_CONTRACT: "Hervat dienst",
  DROPDOWN_VIEW_JOB_DETAIL: "Bekijk dienstbeschrijving",
  DROPDOWN_VIEW_WORKER_PROFILE: "Bekijk profiel",
  DROPDOWN_EXTEND_JOB_CONTRACT: "Verleng dienst",
  DROPDOWN_REPEAT_JOB_CONTRACT: "Herhaaldienst",
  DROPDOWN_CHANGE_HOURLY_RATE: "Wijzig Uurtarief",

  // new one
  DROPDOWN_CHANGE_PASSWORD:"Verander wachtwoord",
};
