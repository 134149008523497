import { ViewMode, Gantt } from "gantt-task-react";

import "gantt-task-react/dist/index.css";
import { useOutletContext } from "react-router-dom";
import { ITask, OrgHiredWorkerList } from "types/schema";

import { useGantt } from "./hook";
import { Loader } from "components/molecules/Loader/Loader";

import TaskDetailModal from "../TaskDetailModal";
import EmptyScreen from "components/organisms/EmptyScreen";
import { useLocale } from "locales";

function GanttComponent({
  height,
  tasksList,
  view,
  taskDetail
}: {
  height: number;
  tasksList: ITask[];
  workers?: OrgHiredWorkerList[];
  view: ViewMode;
  taskDetail:boolean;
  refetchTasks: () => Promise<void>;
}) {
  const {
    columnWidth,
    isChecked,
    tasks,
    handleDblClick,
    task,
    displayTaskDetaile,
    toggleDisplayDetaileModal,
  } = useGantt(tasksList, view);

  return (
    <>
      <Gantt
        tasks={tasks}
        viewMode={view}
        onDoubleClick={handleDblClick}
        listCellWidth={taskDetail ? "155px" : ""}
        columnWidth={columnWidth}
        ganttHeight={height}
        
      />

      {displayTaskDetaile && (
        <TaskDetailModal
          show={displayTaskDetaile}
          closeModal={toggleDisplayDetaileModal}
          task={task}
        />
      )}
    </>
  );
}

export default function GantChart() {
  const {
    height,
    tasksList,
    workers,
    refetchTasks,
    view,
    haveTasks,
    fullScreen,
    taskDetail
  }: {
    height: number;
    tasksList: ITask[];
    workers?: OrgHiredWorkerList[];
    refetchTasks: () => Promise<void>;
    view: ViewMode;
    haveTasks: boolean;
    fullScreen: boolean;
    taskDetail: boolean;
  } = useOutletContext();

  const { localeBasics } = useLocale();

  if (!tasksList)
    return (
      <div className=" flex justify-center items-center h-full w-full">
        <Loader />
      </div>
    );

  if (tasksList?.length == 0) {
    return (
      <EmptyScreen
        buttonText={localeBasics.TEXT_WORKER_TASKS_EMPTY}
        // buttonAction={toggleEditScheduleModal}
        text={localeBasics.TEXT_SELECT_WORKER_MESSAGE_EMPTY as any}
        // onClick={() => {}}
      />
    );
  }

  return (
    <GanttComponent
      height={height}
      tasksList={tasksList}
      workers={workers}
      refetchTasks={refetchTasks}
      view={view}
      taskDetail={taskDetail}
    />
  );
}
