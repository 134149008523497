import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastConsumer } from "../../../store/ToastProvider";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useLocale } from "../../../locales";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  APIAddBranch,
  APIDeleteBranch,
  APIEditBranch,
  ApiToggleOrganizationJobPOsting,
} from "../../../utils/globalAPI/applicationData";

export type AddFormField = {
  en: String;
  du: String;
};

export const useBranchesServices = () => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [branchId, setBranchId] = useState<string>("");

  const { dropdowns, appDataLoading, refetchAppAData, accessToken } =
    UsersContextConsumer();
  const toast = ToastConsumer();
  const { localeSuccess, localeErrors } = useLocale();

  const validationSchema: any = yup.object().shape({
    en: yup.string().required(localeErrors.ERROR_INVALID_BRANCH).min(2),
    du: yup.string().required(localeErrors.ERROR_INVALID_BRANCH).min(2),
  });

  const [dData, setDData] = useState(dropdowns.branches);

  useEffect(() => {
    refetchAppAData();
  }, []);

  useEffect(() => {
    setDData(dropdowns.branches);
  }, [dropdowns?.branches]);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddFormField>({
    resolver: yupResolver(validationSchema) as any,
  });

  const handleOpenAddModal = () => {
    setAddModal(true);
  };

  const handleCloseAddModal = () => {
    setAddModal(false);
  };

  const handleOpenEditModal = (data: any) => {
    setEditModal(true);
    // setBranchData(data);
    setValue("en", data?.label.en);
    setValue("du", data?.label.du);

    setBranchId(data?._id);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    reset();
    setBranchId(null);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log("handleDeleteModal");
    setBranchId(id);
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onSubmit: SubmitHandler<AddFormField> = async (data) => {
    const params = {
      en: data?.en,
      du: data?.du,
    };
    try {
      //
      setLoading(true);
      const response = await APIAddBranch(params, accessToken);
      toast.success(localeSuccess.SUCCESS_BRANCH_ADDED, "top-right");
      refetchAppAData();
      reset();
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setLoading(false);
      handleCloseAddModal();
    }
  };

  const onSubmitEdit: SubmitHandler<AddFormField> = async (data) => {
    const params = {
      en: data?.en,
      du: data?.du,
    };
    try {
      //
      setEditLoading(true);
      const response = await APIEditBranch(branchId, params, accessToken);
      toast.success(localeSuccess.SUCCESS_BRANCH_UPDATED, "top-right");
      // refetchAppAData();
      setDData(
        dData.map((item) =>{
          if(item._id === branchId){
            return{
              ...item,
              label:{
                en: data?.en,
                du: data?.du,
              }
            }
          }
          return item;
        })
      )
      reset();
      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setEditLoading(false);
      handleCloseEditModal();
    }
  };

  const onConfirmDelete = async () => {
    try {
      //
      setDeleteLoading(true);
      const response = await APIDeleteBranch(branchId, accessToken);
      toast.success(localeSuccess.SUCCESS_BRANCH_DELETED, "top-right");
      refetchAppAData();
      setBranchId("");
      console.log("response", response);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteModal();
    }
  };

  return {
    handleOpenAddModal,
    handleCloseAddModal,
    addModal,
    reset,
    loading,
    appDataLoading,
    refetchAppAData,
    dropdowns,
    onSubmit,
    register,
    control,
    handleSubmit,
    deleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    deleteLoading,
    onConfirmDelete,
    handleOpenEditModal,
    handleCloseEditModal,
    editModal,
    editLoading,
    onSubmitEdit,
    errors,
    branches:dData
  };
};
