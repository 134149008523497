import React from "react";
import type { InputProps } from "./@types";
import styles from "./Input.module.scss";
import { Controller } from "react-hook-form";

const Input = ({
  placeholder = ``,
  type = `text`,
  register,
  value,
  name,
  disabled = false,
  className,
  validation,
  width,
  defaultValue,
  control,
  ...props
}: InputProps) => {
  
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <input
            {...props}
            // {...register}
            autoFocus
            type={type}
            defaultValue={defaultValue}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            name={name}
            disabled={disabled}
            // style={props.inputStyle}
            className={
              className
                ? className
                : width
                ? `border-black border-2 py-2 px-3 rounded-md w-[${width}] md:w-[${width}] lg:w-[${width}] placeholder:text-black`
                : "border-black border-2 py-2 px-3 rounded-md w-full lg:w-full placeholder:text-[#969696]"
            }
          />
        );
      }}
    />
  );
};

export default Input;
