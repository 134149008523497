import dayjs from "dayjs";
import { localeBasics } from "locales";


export function formatLastSeen(lastSeen: Date, localeBasic: typeof localeBasics) {
  const now = dayjs();
  const lastSeenTime = dayjs(lastSeen);

  const diffInMinutes = now.diff(lastSeenTime, "minute");
  const diffInHours = now.diff(lastSeenTime, "hour");
  const diffInDays = now.diff(lastSeenTime, "day");

  if (diffInMinutes < 1) {
    return localeBasic.TEXT_LAST_SEEN_JUST_NOW;
  } else if (diffInMinutes < 60) {
    return `${localeBasic.TEXT_LAST_SEEN} ${diffInMinutes} ${
      localeBasic.TEXT_MINUTE
    }${diffInMinutes > 1 ? "s" : ""} ${localeBasic.TEXT_AGO}`;
  } else if (diffInHours < 24) {
    return `${localeBasic.TEXT_LAST_SEEN} ${diffInHours} ${
      diffInHours > 1 ? localeBasic.TEXT_HOURS : localeBasic.TEXT_HOUR
    } ${localeBasic.TEXT_AGO}`;
  } else if (diffInDays === 1) {
    return `${localeBasic.TEXT_LAST_SEEN} 1 ${localeBasic.TEXT_DAY} ${localeBasic.TEXT_AGO}`;
  } else {
    return `${localeBasic.TEXT_LAST_SEEN} ${diffInDays} ${localeBasic.TEXT_DAYS} ${localeBasic.TEXT_AGO}`;
  }
}

export function formatDatePosted(
  lastSeen: string,
  localeBasic: typeof localeBasics
) {
  const now = dayjs();
  const lastSeenTime = dayjs(lastSeen);

  const diffInMinutes = now.diff(lastSeenTime, "minute");
  const diffInHours = now.diff(lastSeenTime, "hour");
  const diffInDays = now.diff(lastSeenTime, "day");

  if (diffInMinutes < 1) {
    return localeBasic.TEXT_LAST_SEEN_JUST_NOW;
  } else if (diffInMinutes < 60) {
    return ` ${diffInMinutes} ${localeBasic.TEXT_MINUTE}${
      diffInMinutes > 1 ? "s" : ""
    } ${localeBasic.TEXT_AGO}`;
  } else if (diffInHours < 24) {
    return ` ${diffInHours} ${
      diffInHours > 1 ? localeBasic.TEXT_HOURS : localeBasic.TEXT_HOUR
    } ${localeBasic.TEXT_AGO}`;
  } else if (diffInDays === 1) {
    return ` 1 ${localeBasic.TEXT_DAY} ${localeBasic.TEXT_AGO}`;
  } else {
    return ` ${diffInDays} ${localeBasic.TEXT_DAYS} ${localeBasic.TEXT_AGO}`;
  }
}

export function formatTaskDatePosted(
  lastSeen: string | Date,
  localeBasic: typeof localeBasics
) {
  console.log("formatTaskDatePosted", lastSeen);
  const now = dayjs();
  const lastSeenTime = dayjs(lastSeen);

  const diffInMinutes = now.diff(lastSeenTime, "minute");
  const diffInHours = now.diff(lastSeenTime, "hour");
  const diffInDays = now.diff(lastSeenTime, "day");

  if (diffInMinutes < 1) {
    return "0";
  } else if (diffInMinutes < 60) {
    return ` ${diffInMinutes} ${localeBasic.TEXT_MINUTE}${
      diffInMinutes > 1 ? "s" : ""
    } ${localeBasic.TEXT_AGO}`;
  } else if (diffInHours < 24) {
    return ` ${diffInHours} ${
      diffInHours > 1 ? localeBasic.TEXT_HOURS : localeBasic.TEXT_HOUR
    } `;
  } else if (diffInDays === 1) {
    return ` 1 ${localeBasic.TEXT_DAY} `;
  } else {
    return ` ${diffInDays} ${localeBasic.TEXT_DAYS} `;
  }
}
export function getDifference(
  start: string | Date,
  endDate:Date,
  localeBasic: typeof localeBasics,
  brekInMin=0,
) {

  const now = dayjs(endDate)
  const lastSeenTime = dayjs(start).subtract(brekInMin, "minute");

  const diffInMinutes = now.diff(lastSeenTime, "minute");
  const diffInHours = now.diff(lastSeenTime, "hour");
  const diffInDays = now.diff(lastSeenTime, "day");



  if (diffInMinutes < 1) {
    return "0";
  } else if (diffInMinutes < 60) {
    return ` ${diffInMinutes} ${localeBasic.TEXT_MINUTE}${
      diffInMinutes > 1 ? "s" : ""
    } ${localeBasic.TEXT_AGO}`;
  } else if (diffInHours < 24) {
    return ` ${diffInHours} ${
      diffInHours > 1 ? localeBasic.TEXT_HOURS : localeBasic.TEXT_HOUR
    } `;
  } else if (diffInDays === 1) {
    return ` 1 ${localeBasic.TEXT_DAY} `;
  } else {
    return ` ${diffInDays} ${localeBasic.TEXT_DAYS} `;
  }
}
