import {  useNavigate } from "react-router-dom";
import type { InviteWorkerCardProps } from "./@types";
import styles from "./InviteWorkerCard.module.scss";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { Button } from "components/atoms/ButtonF";

import LocationIcon from "assets/svgs/LocationIcon";

import CrownIcon from "assets/svgs/CrownIcon";
// import SkypeIcon from "assets/svgs/SkypeIcon";
// import LinkedInIcon from "assets/svgs/LinkedInIcon";
import DeviceMessageIcon from "assets/svgs/DeviceMessageIcon";
import AvailableDotIcon from "assets/svgs/AvailableDotIcon";

import {  handleImagesPath } from "utils/hooks/helper";
import HeartIcon from "assets/svgs/HeartIcon";
import { useSocketIO } from "store/SocketConsumer";

const InviteWorkerCard = ({
  data,
  onClickInvite,
  navigateToWorkerDetails,
  perposal,
  cardType = "invitation-worker",
  onCLickHireDirectly,
  isHired,
  isInvited
}: InviteWorkerCardProps) => {
  const { currentLocale, localeButtons:localeButtons, localeLabels,localeTitles } = useLocale();
  const navigate = useNavigate();



  return (
    <div className={styles["InviteWorker-main-workers"]}>
      <div className={styles["InviteWorkerCard"]}>
        <div className={styles["InviteWorkerCard-top"]}>
          <div className={styles["InviteWorkerCard-left"]}>
            <div onClick={()=>navigateToWorkerDetails(data?._id)} className={styles["left-image"]}>
              <Img
                className={styles["workerImage"]}
                src={handleImagesPath(data?.profileImage)}
              />
              {/* <div className={styles["available-dot"]}>
                <AvailableDotIcon color={isOnline ? "#26C700" : "gray"} />
              </div> */}
            </div>
            <div className={styles["left-details"]}>
              <div className={styles["left-details-top"]}>
                <div onClick={()=>navigateToWorkerDetails(data?._id)} className={styles["left-details-top-main"]}>
                  <Text
                    className={styles["title"]}
                  >{`${data?.firstName} ${data?.lastName}`}</Text>
                </div>
                <Text className={styles["companyName"]}>{data?.heading}</Text>
              </div>

              <div className={styles["left-details-bottom"]}>
                <div className={styles["InviteWorkerCard-mid-below"]}>
                  {/* <div className={styles["socialIcon-div"]}>
                    <SkypeIcon />
                  </div>
                  <div className={styles["socialIcon-div"]}>
                    <LinkedInIcon />
                  </div> */}

                  {/* <div
                    onClick={handleStartConversation}
                    className={styles["socialIcon-div"]}
                  >
                    <DeviceMessageIcon color="#101828" />
                  </div>

                  <div
                    onClick={toggleFavorit}
                    className={styles["socialIcon-div"]}
                  >
                    <HeartIcon filled={isFavourite} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className={styles["InviteWorkerCard-left-mid"]}>
            {data.ranking > 100 && (
              <div className={styles["topRated"]}>
                <CrownIcon />
                <Text className={styles["topRated-text"]}>
                  {localeLabels.LABEL_TOP_RATED}
                </Text>
              </div>
            )}
            <div className={styles["availableDiv"]}>
              {data?.availabilityStatus && (
                <div className={styles["available"]}>
                  <Text className={styles["available-text"]}>
                    {localeLabels.LABEL_AVAILABLE}
                  </Text>
                </div>
              )}
            </div>
          </div>

          <div className={styles["InviteWorkerCard-left-right"]}>
            {cardType === "job-applicants" ? (
              <div className={styles["InviteWorkerCard-right-buttons"]}>
                <Button
                  className={styles["secondary-active"]}
                  disabled={isHired || isInvited}
                  onClick={() => onClickInvite && onClickInvite(data?._id)}
                >
                  {localeButtons.BUTTON_INVITE_WORKER}
                </Button>
                <Button
                  disabled={isHired}
                  className={styles["primary-active"]}
                  onClick={()=>onCLickHireDirectly?.(data?._id)}
                >
                  {localeButtons.BUTTON_HIRE_DIRECTLY}
                </Button>
                <Button
                  className={styles["primary-active"]}
                  onClick={()=>navigateToWorkerDetails(data?._id)}
                >
                  {localeButtons.BUTTON_WORKER_DETAIL}
                </Button>
              </div>
            ) : (
              <div className={styles["InviteWorkerCard-right-buttons"]}>
                <Button
                  className={styles["secondary-active"]}
                  onClick={() => onClickInvite && onClickInvite(data?._id)}
                >
                  {localeButtons.BUTTON_INVITE_WORKER}
                </Button>
                <Button
                  className={styles["primary-active"]}
                  onClick={navigateToWorkerDetails}
                >
                  {localeButtons.BUTTON_WORKER_DETAIL}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className={styles["InviteWorkerCard-mid"]}>
          <Text className={styles["userDescription"]}>
            {perposal || data?.bio}
          </Text>
        </div>
        <div className={styles["WorkerCard-mid-skills"]}>
          {data?.skills?.slice(0, 5)?.map((skill, index: number) => (
            <Text key={index} className={styles["skill"]}>
              {skill?.label?.[currentLocale]}
            </Text>
          ))}
        </div>
        <div className={styles["InviteWorkerCard-bottom"]}>
          <div className={styles["InviteWorkerCard-bottom-left"]}>
            {data.accountType === "freelancer" && data?.hourlyRate && (
              <Text
                className={styles["rate"]}
              >{`€${data?.hourlyRate} ${localeTitles.TITLE_PER_HOUR}`}</Text>
            )}
          </div>

          <div className={styles["InviteWorkerCard-bottom-right"]}>
            <div className={styles["left-details-iconText"]}>
              <LocationIcon size={16} />
              <Text className={styles["description"]}>
                {localeLabels.LABEL_FROM}
              </Text>
              <Text className={styles["city"]}>{data?.address?.city},</Text>
              <Text className={styles["description"]}>
                {data?.address?.country}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteWorkerCard;
