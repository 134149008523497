import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastConsumer } from "../../../store/ToastProvider";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { useQuery } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { S3_BUCKET, myBucket } from "../../../store/utils/Aws";
import { Progress } from "aws-sdk/lib/request";
import { useLocale } from "../../../locales";
import { MonthlyJoinedData } from "../../../types/ApiResponse";

interface ImageInputProps {
  onChange: (file: File | null) => void;
}

export const useDashboardPage = (monthlyJoinedData?: MonthlyJoinedData) => {
  const navigate = useNavigate();

  const [totalJoinedData, setTotalJoinedData] = useState<any>();

  const navigateToUserDetails = (userId: string, type?: string) => {
    navigate(`/${type.toLocaleLowerCase()}s/` + userId);
  };

  const monthNames = monthlyJoinedData
    ? Object?.keys(monthlyJoinedData)
    : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];

  console.log("monthNames", monthNames);

  const lineOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: monthNames,
    },
  };
  const barOptions = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    stroke: {
      width: [4, 0, 0],
    },
    xaxis: {
      categories: monthNames,
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    // yaxis: {
    //   tickAmount: 5,
    //   min: 0,
    //   max: 100,
    // },
  };

  const postData = {
    series:
      totalJoinedData !== undefined
        ? totalJoinedData
        : [
            {
              name: "Freelancers",
              data: [0, 0],

              color: "#0095FF",
            },
            {
              name: "Employee",
              data: [0, 0],

              color: "#00E096",
            },
            {
              name: "Organizations",
              data: [0, 0],

              color: "#9FB5FF",
            },
          ],
  };

  useEffect(() => {
    const keyToName = {
      totalUser: "Totoal user Joinedg",
      totalFreelancer: "Freelancers",
      totalEmployee: "Employees",
      totalOrganization: "Organizations",
    };
    if (monthlyJoinedData) {
      const keys = Array.from(
        new Set(
          Object?.keys(monthlyJoinedData)?.flatMap((month) =>
            Object?.keys(monthlyJoinedData[month])
          )
        )
      );

      const transformedData = keys?.map((key) => {
        const name = keyToName[key];
        return {
          name: name,
          data:
            Object?.keys(monthlyJoinedData)?.map(
              (month) =>
                (monthlyJoinedData[month] && monthlyJoinedData[month][key]) ?? 0
            ) ?? [],
          color: {
            totalFreelancer: "#009aaa",
            totalUser: "#0095FF",
            totalEmployee: "#00E096",
            totalOrganization: "#9FB5FF",
          }[key],
        };
      });

      setTotalJoinedData(transformedData);
    }
  }, [monthlyJoinedData]);

  // console.log("totalJoinedData", totalJoinedData);

  return {
    navigateToUserDetails,
    postData,
    barOptions,
    lineOptions,
  };
};
