import en from "./english";
import du from "./dutch";
export const SERVER_RESPONSE = {
  en,
  du,
};

export type responseType =
  | "brach_id_not_exist"
  | "scheduleId_Invalid"
  | "rating_Invalid"
  | "review_Invalid"
  | "shifts_not_found"
  | "worker_not_found"
  | "job_conract_already_completed"
  | "mark_all_shifts_as_completed_before_completing_job"
  | "not_authorised"
  | "working_status_invalid"
  | "working_status_invalid"
  | "blocked_user_account"
  | "financeReport_Invalid"
  | "TASK_CAN_NOT_BE_UPDATED"
  | "schedule_Invalid"
  | "invalid_hiredWorker"
  | "invalid_job_id"
  | "worker_Invalid"
  | "end_Invalid"
  | "name_Invalid"
  | "start_Invalid"
  | "hiredWorker_Invalid"
  | "already_hired"
  | "reporteduser_Invalid"
  | "invalid_wage"
  | "invalid_branch_id"
  | "invalid_worker_type"
  | "worker_not_found"
  | "invalid_city"
  | "invalid_country"
  | "password_wrong"
  | "invalid_email"
  | "ERROR_UNKOWN"
  | "wrong_password"
  | "invalid_Auth_with"
  | "create_a_new_account"
  | "reset_password"
  | "invalid_password"
  | "invalid_locale"
  | "link_expired"
  | "invalid_firstName"
  | "invalid_lastName"
  | "invalid_title"
  | "invalid_dob"
  | "invalid_street"
  | "invalid_zip"
  | "invalid_place"
  | "invalid_categories"
  | "invalid_accountType"
  | "invalid_phone"
  | "invalid_accountHolderName"
  | "invalid_accountNumber"
  | "invalid_bankName"
  | "invalid_certificate"
  | "invalid_nic"
  | "invalid_diplomas"
  | "invalid_scannedImages"
  | "invalid_employee"
  | "invalid_position"
  | "invalid_branch"
  | "invalid_token"
  | "user_not_authorized"
  | "invalid_request"
  | "invalid_availabilityStatus"
  | "invalid_skills"
  | "invalid_job"
  | "invalid_education"
  | "invalid_profileImage"
  | "invalid_socialHandle"
  | "invalid_hourlyRate"
  | "invalid_bio"
  | "invalid_IBAN"
  | "invalid_companyName"
  | "invalid_invitationToSetting"
  | "invalid_invitationOfLikedService"
  | "invalid_reminders"
  | "invalid_alerts"
  | "invalid_tokenType"
  | "userId_Invalid"
  | "reportedUserId_Invalid"
  | "reportReason_Invalid"
  | "reportDescription_Invalid"
  | "notificationId_Invalid"
  | "workerType_Invalid"
  | "urgencyLevel_Invalid"
  | "wage_Invalid"
  | "Address_Invalid"
  | "cloths_Invalid"
  | "travelExpenses_Invalid"
  | "workerNumber_Invalid"
  | "breakTime_Invalid"
  | "title_Invalid"
  | "jobDescription_Invalid"
  | "attachment_Invalid"
  | "timeToEnd_Invalid"
  | "timeToStart_Invalid"
  | "startDate_Invalid"
  | "endDate_Invalid"
  | "jobId_Invalid"
  | "workerId_Invalid"
  | "perHourRate_Invalid"
  | "content_required"
  | "jobStatus_Invalid"
  | "invalid_jobStatus"
  | "attachment_required"
  | "response_Invalid"
  | "invitationId_Invalid"
  | "email_not_found"
  | "email_already_exist"
  | "invalid_phone"
  | "email_already_exist"
  | "description_min_required_length_is_10"
  | "user_not_active"
  | "password_old_wrong"
  | "invalid_category_type"
  | "invalid_companyName"
  | "organization_not_found"
  | "job_not_found"
  | "not_allowed_to_access_job"
  | "invalid_worker_type"
  | "task_not_found"
  | "incorrect_start_date"
  | "incorrect_end_date"
  | "conversation_not_found"
  | "user_is_blocked"
  | "user_not_found"
  | "cannot_send_empty_message"
  | "invitation_not_found"
  | "already_invited";
