import { useLocale } from "locales";
import React from "react";
import WorkerDetails from "pages/WorkerDetails/WorkerDetails";
import { useWorkerDetails } from "./hook";

const WorkerDetailsService: React.FC = () => {
  const {
    worker,
    workerDetailsLoading,
    onClickInvite,
    inviteModal,
    closeInviteModal,
    onConfirmInvite,
    inviteLoading,
    refetch
  } = useWorkerDetails();


  return (
    <WorkerDetails
      workerData={worker}
      loading={workerDetailsLoading}
      onClickInvite={onClickInvite}
      refetch={refetch}
      inviteModal={inviteModal}
      closeInviteModal={closeInviteModal}
      onConfirmInvite={onConfirmInvite}
      inviteLoading={inviteLoading}

    />
  );
};

export default WorkerDetailsService;
