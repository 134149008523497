import { AxiosResponse } from "axios";

import apiRequest from "utils/axios";
import { GetOrganizationWorkerResponse,workerTaskResponse } from "./types";

export const getOrganizationWorkers = async (
  activeCategory: string[],
  workerType: string,
  organizationId:string,
  token: string
): Promise<GetOrganizationWorkerResponse> => {
  const response = await apiRequest({
    method: "get",
    url: `/organization/schedule/worker/list/admin?branch=${activeCategory.join(",")}&workerType=${workerType}&organizationId=${organizationId}`,
    token,
  });
  return response;
};






export const getWorkerTasks = async (
  hiredWorker: string | undefined,
  token: string,
  isSort=false
): Promise<workerTaskResponse> => {
   
  const response = await apiRequest({
    method: "get",
    url: `/organization/schedule/getTasks/admin?hiredWorker=${hiredWorker}&isSort=${isSort}`,
    token,
  });
  return response;
};
