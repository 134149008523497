export const breakPoints={
    'xs': 0,
  'sm': 640,
  'md': 769,
  'lg': 1024,
  'slg': 1100,
  'xlg': 1220,
  'xl': 1350,
  '2xl': 1536,
  '3xl': 1700,
}