export default {
   SUCCESS_LOGIN: "Succesvol ingelogd",
   SUCESS_HIRE: "Werknemer succesvol ingehuurd",

   SUCCESS_PW_RESET: "E-mail is succesvol verzonden",
   SUCCESS_CHANGE_PW: "Wachtwoord is succesvol gewijzigd",
   SUCCESS_EDIT_ACCOUNT: "Accountinformatie succesvol bijgewerkt",
   SUCCES: "Succes",

   SUCCESS_ORGANIZATION_UPDATED: "Organisatie succesvol bijgewerkt",

   SUCCESS_ATTRIBUTE_ADDED: "Kenmerk succesvol toegevoegd",
   SUCCESS_ATTRIBUTE_DELETED: "Kenmerk succesvol verwijderd",
   SUCCESS_ATTRIBUTE_UPDATED: "Kenmerk succesvol bijgewerkt",
   SUCCESS_SENT_LINK: "Link succesvol verzonden",
   SUCCESS_ACCOUNT_ACTIVE: "Account succesvol actief",
   SUCCESS_ACCOUNT_INACTIVE: "Account succesvol inactief",
   SUCCESS_BRANCH_ADDED: "Branch succesvol toegevoegd",
   SUCCESS_BRANCH_UPDATED: "Branch succesvol bijgewerkt",
   SUCCESS_BRANCH_DELETED: "Branch succesvol verwijderd",
   SUCCESS_CATEGORY_ADDED: "Categorie succesvol toegevoegd",
   SUCCESS_CATEGORY_UPDATED: "Categorie succesvol bijgewerkt",
   SUCCESS_SKILL_ADDED: "Vaardigheid succesvol toegevoegd",
   SUCCESS_SKILL_UPDATED: "Vaardigheid succesvol bijgewerkt",
   SUCCESS_SKILL_DELETED: "Vaardigheid succesvol verwijderd",
   SUCCESS_CATEGORY_DELETED: "Categorie succesvol verwijderd",
   SUCCESS_EMPLOYEE_DECLINED: "Medewerker succesvol geweigerd",
   SUCCESS_EMPLOYEE_VERIFIED: "Medewerker succesvol geverifieerd",
   REPORT_GENRATED: "Rapport succesvol gegenereerd",
   SUCCESS_DELETING_JOB: "Dienst succesvol verwijderd",
   SUCCESS_JOB_UPDATED: "Dienst succesvol bijgewerkt",
   ERROR_DELETING_JOB: "Fout bij het verwijderen van dienst",
   SUCESS_JOB_EDITED: "Dienst succesvol bijgewerkt",
   SUCESS_JOB_POSTED: "Succesvol een nieuwe dienst geplaatst",
   SUCESS_INVITIATION_SENT: "Medewerker uitgenodigd",

   SUCCESS_EMAIL_SENT: "Verzoek succesvol verzonden",
   JOB_SHIFT_UPDATED: "Dienst succesvol bijgewerkt",
   SUCESS_INVOICE_GENERATED: "Factuur succesvol betaald",
   SUCESS_INVOICE_PAID: "Factuur succesvol betaald",

   REGISTRATION_SUCCESSFULLY_COMPLETED: "Registratie succesvol afgerond",
   PASSWORD_UPDATED_SUCCESSFULLY: "Wachtwoord succesvol bijgewerkt",
   LOGIN_SUCESS: "Inloggen succesvol",

   SUCESS_JOB_DELETED: "Dienst succesvol verwijderd",
   SUCESS_PROFILE_UPDATED: "Profiel succesvol geupdate",
   SUCESS_SETTINGS_UPDATED: "Opties succesvol geupdate",
   SUCESS_ACCOUNT_DELTED: "Account succesvol verwijderd",
   SUCESS_HIRE_APPLICANTS: "Medewerker succesvol ingehuurd",
   SUCESS_APPLIED: "Succesvol aangevraagd",
   SUCESS_RATING: "Medewerker succesvol waardering gegeven",
   SUCESS_CHANGE_HOURLY_RATE: "Tarief per uur succesvol gewijzigd",
   SUCESS_CONTRACT_START_AGAIN: "Dienst succesvol opnieuw geplaatst",
   SUCESS_JOB_SAVED: "Dienst succesvol opgeslagen",
   SUCESS_SCHEDULE_ADDED: "Haan succesvol toegevoegd",
   SUCESS_SCHEDULE_UPDATED: "Rooster succesvol geupdate",
   SUCESS_TASK_UPDATED: "Taak succesvol geupdate",
   SUCESS_SCHEDULE_DELETED: "Rooster succesvol verwijderd",
   SUCESS_AVAILABILITY_ADDED: "Beschikbaarheid succesvol toegevoegd",
   SUCESS_AVAILABILITY_UPDATED: "Beschikbaarheid succesvol geupdate",
   SUCESS_AVAILABILITY_DELETED: "Beschikbaarheid succesvol verwijderd",
   SUCESS_COMPLETED_CONTRACT: "Dienst succesvol afgerond",
   SUCESS_CANCELED_CONTRACT: "Dienst succesvol geannuleerd",
   SUCESS_PAUSED_CONTRACT: "Dienst succesvol gepauzeerd",
   SUCESS_RESUMED_CONTRACT: "Dienst succesvol hervat",

   SUCESS_JOB_UPDATED: "Taak succesvol bijgewerkt",
   // new one
   SUCCESS:"Succes",

   SUCESS_ACCOUNT_CREATED:"Account aangemaakt",
}