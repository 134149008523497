import { useLocation, useNavigate } from "react-router-dom";
import type { CompanyCardProps } from "./@types";
import styles from "./CompanyCard.module.scss";
import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import ClockIcon from "assets/svgs/ClockIcon";
import TimerIcon from "assets/svgs/TimerIcon";
import GasStationIcon from "assets/svgs/GasStationIcon";
import LoadingTimerIcon from "assets/svgs/LoadingTimerIcon";
import { handleImagesPath } from "utils/hooks/helper";
import dayjs from "dayjs";

const CompanyCard = ({ data }: CompanyCardProps) => {

  const { localeTitles } =
    useLocale();

  return (
    <div className={styles["CompanyCard"]}>
      <div className={styles["CompanyCard-main"]}>
        <div className={styles["company"]}>
          <div className={styles["company-details"]}>
            <Img
              src={handleImagesPath(data?.organization?.logo)}
              className={styles["image"]}
            />
            <div className={styles["company-details-name"]}>
              <Text className={styles["name"]}>
                {data?.organization?.companyName}
              </Text>
              <Text className={styles["description"]}>
                {data?.organization?.user?.address?.address}
              </Text>
            </div>
          </div>
          {/* <div className="cursor-pointer">
            <DeviceMessageIcon />
          </div> */}
        </div>

        <div>
          <div className={`${styles["item"]} ${styles["lastItem"]}`}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_HEAD_COUNT}
            </Text>
            <Text className={styles["description-value"]}>
              <ClockIcon />
              {data?.jobId?.workerNeeded}
            </Text>
          </div>
          <div className={styles["details-div"]}>
            <div className={styles["item"]}>
              <Text className={styles["description"]}>
                {localeTitles.TITLE_BEGIN_TIME}
              </Text>
              <Text className={styles["description-value"]}>
                <ClockIcon />

                {dayjs(data?.jobId?.timeToStart).isValid()?dayjs(data?.jobId?.timeToStart).format("HH:mm"):data?.jobId?.timeToStart}

              </Text>
            </div>
            <div className={styles["item"]}>
              <Text className={styles["description"]}>
                {localeTitles.TITLE_END_TIME}
              </Text>
              <Text className={styles["description-value"]}>
                <ClockIcon />
                {dayjs(data?.jobId?.timeToEnd).isValid()?dayjs(data?.jobId?.timeToEnd).format("HH:mm"):data?.jobId?.timeToEnd}
              </Text>
            </div>
            <div className={styles["item"]}>
              <Text className={styles["description"]}>
                {localeTitles.TITLE_BREAK_TIME}
              </Text>
              <Text className={styles["description-value"]}>
                <TimerIcon color="#101828" />
                {data?.jobId?.breakTime}
              </Text>
            </div>
            <div className={styles["item"]}>
              <Text className={styles["description"]}>
                {localeTitles.TITLE_TRAVEL_EXPENSE}
              </Text>
              <Text className={styles["description-value"]}>
                <GasStationIcon />
                €{data?.jobId?.travelExpenses}
              </Text>
            </div>
          </div>
          <div className={`${styles["item"]} ${styles["lastItem"]}`}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_JOB_TYPE}
            </Text>
            <Text className={styles["description-value"]}>
              <LoadingTimerIcon />
              {data?.jobId?.workerType}
            </Text>
          </div>
        </div>

        <div className={styles["company-about"]}>
          <Text className={styles["title"]}>
            {localeTitles.TITLE_ABOUT_COMPANY}
          </Text>
          <Text className={styles["about"]}>{data?.organization?.user?.bio}</Text>
        </div>

     
      </div>
    </div>
  );
};

export default CompanyCard;
