import type { FileModalProps } from "./@types";
import styles from "./UploadCard.module.scss";

import { Navigation, Pagination,  A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Modal from "components/molecules/ModalF";

import {  BsImageFill } from "react-icons/bs";
import { FaRegFilePdf } from "react-icons/fa";
import { getImageURL } from "utils/hooks/helper";

const FileViewerModal = ({ files, open, onClose }: FileModalProps) => {


  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal open={open} onClose={onClose}>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          // spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          //  scrollbar={{ draggable: true }}
          onSwiper={(swiper: any) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          style={{
            // height: 200,
            width: 300,
          }}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {files?.map((file) => (
            <SwiperSlide
              style={{
                // height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: 100,
              }}
            >
              <Files file={file} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal>
    </div>
  );
};

export const Files = ({ file }: { file: File | string }) => {
  let extention =
    typeof file == "string"
      ? "pdf"
      : file.name.substring(file.name.lastIndexOf(".") + 1);

  const ReturnIcon = () => {
    switch (extention) {
      case "jpg":
      case "png":
      case "jpeg":
      case "gif":
      case "webp":
      case "heic":
      case "heif":
        return (
          // display image
          <img
            className={styles["Image-styling"]}
            src={
              typeof file == "string"
                ? getImageURL(file)
                : URL.createObjectURL(file)
            }
          />
        );

      case "pdf":
        return <FaRegFilePdf size={60} />;
      default: {
        return <BsImageFill size={60} />;
      }
    }
  };

  return (
    <div className={styles["slid-pdf"]}>
      {ReturnIcon()}
      <a
        target="_blank"
        href={
          typeof file == "string"
            ? getImageURL(file)
            : URL.createObjectURL(file)
        }
        className={styles["title"]}
      >
        download
      </a>
    </div>
  );
};

export default FileViewerModal;
