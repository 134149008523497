import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: boolean;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function MapIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={window.innerWidth > breakPoints.sm - 1 ? "24" : "15"}
      height={window.innerWidth > breakPoints.sm - 1 ? "24" : "15"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 8.99953V14.9995C22 17.4995 21.5 19.2495 20.38 20.3795L14 13.9995L21.73 6.26953C21.91 7.05953 22 7.95953 22 8.99953Z"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.73 6.27L6.26999 21.73C3.25999 21.04 2 18.96 2 15V9C2 4 4 2 9 2H15C18.96 2 21.04 3.26 21.73 6.27Z"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3795 20.38C19.2495 21.5 17.4995 22 14.9995 22H8.99954C7.95954 22 7.05953 21.91 6.26953 21.73L13.9995 14L20.3795 20.38Z"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.24026 7.9807C6.92026 5.0507 11.3203 5.0507 12.0003 7.9807C12.3903 9.7007 11.3103 11.1607 10.3603 12.0607C9.67026 12.7207 8.58028 12.7207 7.88028 12.0607C6.93028 11.1607 5.84026 9.7007 6.24026 7.9807Z"
        stroke="#858D98"
        stroke-width="1.5"
      />
      <path
        d="M9.09412 8.69922H9.1031"
        stroke="#858D98"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default MapIcon;

// fill={white ? "#fffff" : "#0F1322"}
