import { useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { IAvailabilties } from "components/cells/ScheduleModal/@types";
import { useLocale } from "locales";
import { availabilityTypes } from "../@types";

export const useEditAvailability = (selectedEvent: IAvailabilties) => {
  const [selectedWeek, setSelectedWeek] = useState<any>(
    dayjs().startOf("week").toDate()
  );

  const { localeErrors } = useLocale();

  const validationSchema = yup.object().shape({
    date: yup.date().required(localeErrors.ERROR_DATE_REQUIRED),
    availability: yup
      .string()
      .oneOf(
        ["available", "unavailable", "partial"],
        localeErrors.ERROR_INVALID_AVAILABILITY
      )
      .required(localeErrors.ERROR_AVAILABILITY_REQUIRED),
    shift: yup.string().when("availability", {
      is: "available",
      then: yup
        .string()
        .oneOf(["day_shift", "night_shift"], localeErrors.ERROR_SHIFT_REQUIRED)
        .required(localeErrors.ERROR_SHIFT_REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    timeFrame: yup.array().when("availability", {
      is: "partial",
      then: yup
        .array()
        .of(yup.date())
        .min(2, localeErrors.ERROR_INVALID_TIMEFRAME)
        // add custom testing that 2nd element should be greater than first
        .test({
          test: (value) => {
            if (value && value.length > 1) {
              return value?.[0] && value?.[1] && value?.[0] < value?.[1]
                ? true
                : false;
            }
            return true;
          },
          message: localeErrors.ERROR_INVALID_TIMEFRAME,
        })
        .required(localeErrors.ERROR_INVALID_TIMEFRAME),
      otherwise: yup.array(),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<availabilityTypes>({
    defaultValues: {
      ...selectedEvent,
      timeFrame: selectedEvent?.timeFrame || [],
    },
    resolver: yupResolver(validationSchema) as any,
  });

  const onChangeDates = (data: Date) => {
    setSelectedWeek(data);
  };

  console.log("errors", errors);
  return {
    control,

    selectedWeek,
    setSelectedWeek,

    watch,
    errors,

    handleSubmit,

    onChangeDates,
  };
};
