import { useLocation, useNavigate } from "react-router-dom";
import type { EmployeeRatingAndReviewCardProps } from "./@types";
import styles from "./EmployeeRatingAndReviewCard.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { useState } from "react";
import { Img } from "components/atoms/Img";
import RatingStartIcon from "assets/svgs/RatingStartIcon";
import { handleImagesPath } from "utils/hooks/helper";

const EmployeeRatingAndReviewCard = ({
  data,
}: EmployeeRatingAndReviewCardProps) => {
  const maxRating = 5;

  const renderStarRating = (rating: number) => {
    const wholeStars = Math.floor(rating);
    const hasHalfStar = rating - wholeStars >= 0.5;

    const stars = [];

    for (let i = 0; i < maxRating; i++) {
      if (i < wholeStars) {
        // Fill full star
        stars.push(<RatingStartIcon key={i} fill="#1C53F4" stroke="#1C53F4" />);
      } else if (i === wholeStars && hasHalfStar) {
        // Fill half star
        stars.push(<RatingStartIcon key={i} fill="#1C53F4" stroke="#1C53F4" />);
      } else {
        // Empty star
        stars.push(<RatingStartIcon key={i} fill="#fff" stroke="#1C53F4" />);
      }
    }

    return stars;
  };

  return (
    <div className={styles["EmployeeRatingAndReviewCard"]}>
      <div className={styles["EmployeeRatingAndReviewCard-main"]}>
        <div className={styles["EmployeeRatingAndReviewCard-left"]}>
          <Img
            className={styles["image"]}
            src={handleImagesPath(data?.organization?.logo)}
          />
        </div>

        <div className={styles["EmployeeRatingAndReviewCard-right"]}>
          <div className={styles["EmployeeRatingAndReviewCard-top"]}>
            <div className={styles["EmployeeRatingAndReviewCard-left-text"]}>
              <Text className={styles["name"]}>
                {data?.organization?.companyName}
              </Text>
              <Text className={styles["role"]}>
                {data?.organization?.position}
              </Text>
            </div>
            <div className={styles["ReviewCard-right-rating"]}>
              <Text className={styles["ratingText"]}>
                {parseInt(data?.rating)}/5.0
              </Text>
              {renderStarRating(parseInt(data?.rating) || 0)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles["EmployeeRatingAndReviewCard-bottom"]}>
        <Text className={styles["reviewMessage"]}>{data?.review}</Text>
      </div>
    </div>
  );
};

export default EmployeeRatingAndReviewCard;
