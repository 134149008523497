import { locale } from './../../../types/forms.d';

import { AxiosResponse } from "axios";

import apiRequest from "utils/axios";
import { getApplicationDataResponse } from "./types";
// import { CreateOrganizationJob } from "types/schema";

//  /Update Profile

export const getApplicationData = async (
): Promise<AxiosResponse<getApplicationDataResponse, any>> => {

  const response = await apiRequest({
    method: "get",
    url: `/admin/application/data`,
  });
  return response;
};
export const getKvkNumber = async (
  kvkNumber: string
): Promise<AxiosResponse<any, any>> => {

  const response = await apiRequest({
    method: "get",
    url: `/admin/appdata/kvk?kvkNumber=${kvkNumber}`,
  });
  return response;
};
export const companyRating = async (
  locale:"en" | "nl"
) => {

  const response = await apiRequest({
    method: "get",
    url: `/admin/appdata/ratings?locale=${locale}`,
  });
  return response?.data;
};

