import { AxiosResponse } from "axios";

import apiRequest from "utils/axios";
import { getHiredWorkerFinance } from "./types";

export const getWorkerJobsShiftApi = async (
  data: {
    hiredWorker: string;
  },
  pageParam: number,
  token: string
) => {
  let url = `/finance/shift/admin?`;
  url += `offset=${pageParam}&`;
  url += `hiredWorker=${data?.hiredWorker}&`;

  const response = await apiRequest({
    method: "get",
    url: url,
    token,
  });
  pageParam = pageParam + 1;
  return response;
};
