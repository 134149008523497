import { useEffect, useState } from "react";
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  Controller,
} from "react-hook-form";
import { UsersContextConsumer } from "store/AuthProvider";
import { ACCOUNT_FORMS, Education, Job } from "types/forms";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocale } from "locales";
import dayjs from "dayjs";
import {
  electronicFormatIBAN,
  friendlyFormatIBAN,
  isValidIBAN,
} from "ibantools";

import { getKvkNumber } from "utils/globalAPI/application";
import { useLocation } from "react-router-dom";

export interface UploadedImage {
  filePath: string;
  fileName: string;
  progress: number;
  status: string;
  file: File;
}

export const useAccountPage = () => {
  const { state } = useLocation();
  const userData = state?.userData;

  const { localeErrors, currentLocale } = useLocale();
  const validationSchema: any = yup.object().shape({
    // accountHolderName: yup
    //   .string()
    //   .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    // IBAN: yup
    //   .string()
    //   .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS)
    //   .test("iban", localeErrors.ERROR_IBAN_FORMAT, (value) => {
    //     return isValidIBAN(electronicFormatIBAN(value) as string);
    //   }),
    availabilityStatus: yup
      .string()
      .required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),
    title: yup.string().required(localeErrors.ERROR_TITLE_REQUIRED),
    firstName: yup
      .string()
      .required(localeErrors.ERROR_FIRSTNAME_REQUIRED)
      .max(20, localeErrors.ERROR_FIRSTNAME_INVALID)
      .matches(/^[a-zA-Z ]*$/, localeErrors.ERROR_FIRSTNAME_INVALID),
    lastName: yup
      .string()
      .required(localeErrors.ERROR_LASTNAME_REQUIRED)
      .max(20, localeErrors.ERROR_LASTNAME_INVALID)
      .matches(/^[a-zA-Z ]*$/, localeErrors.ERROR_LASTNAME_INVALID),

    // phone: yup.string().required(localeErrors.ERROR_FILL_REQUIRED_THE_FIELDS),

    // address: yup.array().of(
    //   yup.object().shape({
    //     street: yup.string().required(localeErrors.ERROR_STREET_REQUIRED),
    //     city: yup.string().required(localeErrors.ERROR_CITY_REQUIRED),
    //     zip: yup.string().required(localeErrors.ERROR_ZIP_REQUIRED),
    //     country: yup.string().required(localeErrors.ERROR_COUNTRY_REQUIRED),
    //   })
    // ),

    // branches: yup.array().min(1, localeErrors.ERROR_CATEGORY_REQUIRED),

    // idBack: yup
    //   .array()
    //   .required(localeErrors.ERRORS_ID_CARD_REQUIRED)
    //   .min(1)
    //   .max(1),
    // idFront: yup
    //   .array()
    //   .required(localeErrors.ERRORS_ID_CARD_REQUIRED)
    //   .min(1)
    //   .max(1),
  });

  const {
    setValue,
    watch,
    getValues,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ACCOUNT_FORMS>({
    defaultValues: {
      IBAN:
        (friendlyFormatIBAN(userData?.worker?.bankDetails?.IBAN) as string) ||
        "",
      accountHolderName: userData?.worker?.bankDetails?.accountHolderName || "",
      btw_nr: userData?.worker?.bankDetails?.btw_nr || "",

      idBack: userData?.worker?.documents?.idBack || [],
      idFront: userData?.worker?.documents?.idFront || [],
      certificate: userData?.worker?.documents?.certificate || [],
      diplomas: userData?.worker?.documents?.diplomas || [],
      scannedImages: userData?.worker?.documents?.scannedImages || [],

      insuranceBlockDocuments:
        userData?.worker?.documents?.insuranceBlockDocuments || [],

      availabilityStatus: userData?.availabilityStatus || "available",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      bio: userData?.bio || "",
      phone: userData?.phone || "",
      dob: dayjs(userData?.dob).format("YYYY-MM-DD"),
      address: userData?.address || [{}],

      password: "",
      title: userData?.accountTitle || "Mr",
      profileImage: userData?.profileImage || "",
      hourlyRate: userData?.hourlyRate || "",
      heading: userData?.heading || "",

      categories:
        userData?.categories?.map((category) => ({
          ...category,
          value: category._id,
          label: category.label?.[currentLocale] as string,
        })) || [],
      skills:
        userData?.skills?.map((skill) => ({
          ...skill,
          value: skill._id,
          label: skill.label?.[currentLocale] as string,
        })) || [],
      branches:
        userData?.branches?.map((branch) => ({
          ...branch,
          value: branch._id,
          label: branch.label?.[currentLocale] as string,
        })) || [],

      job: userData?.worker?.job || [],
      education: userData?.worker?.education || [],

      // coordinates: userData?.address?.[0]?.location?.coordinates || [],
      email: userData?.email || "",
      idNumber: userData?.worker?.idNumber || "",
    },
    resolver: yupResolver(validationSchema) as any,
  });

  const { fields, insert, remove } = useFieldArray({
    control: control,
    name: "address",
  });

  const [jobModal, setJobModal] = useState<boolean>(false);
  const [educationModal, setEducationModal] = useState<boolean>(false);
  const [editJobFeild, setEditJobField] = useState<Job | null>();
  const [editEducationFeild, setEditEducationField] =
    useState<Education | null>();

  const getEducationField = (data: Education, index: number) => {
    setEducationModal(true);
    if (data) {
      setEditEducationField({ ...data, index });
    }
  };
  const getJobField = (data: Job, index: number) => {
    setJobModal(true);
    if (data) {
      setEditJobField({ ...data, index });
    }
  };

  const openJobModal = () => {
    setJobModal(true);
  };
  const closeJobModal = () => {
    setJobModal(false);
  };

  const openEducationModal = () => {
    setEducationModal(true);
  };
  const closeEducationModal = () => {
    setEducationModal(false);
  };

  const onConfirmJob: SubmitHandler<Job> = (data) => {
    console.log("onConfirmData", data);
    if (data) {
      let currentJobs = watch("job") || [];
      const newJobs = data;

      setValue("job", [...currentJobs, newJobs]);
    }
    setEditJobField(null);
    closeJobModal();
  };
  const onConfirmJobEdit: SubmitHandler<Job> = (data) => {
    console.log("onConfirmData", data);
    if (data) {
      let currentJobs = watch("job") || [];
      currentJobs[editJobFeild?.index || 0] = data;
      setValue("job", currentJobs);
    }
    setEditEducationField(null);
    closeJobModal();
  };
  const onConfirmEducation: SubmitHandler<Education> = (data) => {
    if (data) {
      const currentEducation = watch("education") || [];
      const newJobsEducation = data;
      setValue("education", [...currentEducation, newJobsEducation]);
    }
    closeEducationModal();
  };
  const onConfirmEducationEdit: SubmitHandler<Education> = (data) => {
    if (data) {
      const currentEducation = watch("education") || [];
      currentEducation[editEducationFeild?.index || 0] = data;

      setValue("education", currentEducation);
    }
    closeEducationModal();
  };

  const lastSkill = watch("skills")?.[watch("skills")?.length - 1] || ""; // Get the last skill or an empty string if the skills array is empty

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [activeCategory, setActiveCategory] =
    useState<string>("Favourite Jobs");

  const handleOpenFilter = () => {
    setOpenFilterModal(true);
  };

  const handleCloseFilter = () => {
    setOpenFilterModal(false);
  };

  const removeJob = (index: number) => {
    const currentJobs = watch("job");

    if (index >= 0 && index < currentJobs?.length) {
      const updatedJobs = [
        ...currentJobs?.slice(0, index),
        ...currentJobs?.slice(index + 1),
      ];

      setValue("job", updatedJobs);
    }
  };

  const removeEducation = (index: number) => {
    const currentEducation = watch("education");

    if (currentEducation) {
      if (index >= 0 && index < currentEducation?.length) {
        const updatedEducation = [
          ...currentEducation?.slice(0, index),
          ...currentEducation?.slice(index + 1),
        ];

        setValue("education", updatedEducation);
      }
    }
  };

  const handleIbanChange = (value: string) => {
    const iban = friendlyFormatIBAN(value);
    //
    setValue("IBAN", iban + "");
  };

  const handlekvk = async (kvk: string) => {
    try {
      const data = await getKvkNumber(kvk);

      // console.log("data kvk " ,data);
      // set
      setValue("companyName", data?.data?.naam);

      setValue(
        "address.0.zip",
        data?.data?.["_embedded"]?.hoofdvestiging?.adressen?.[0]?.postcode
      );
      setValue(
        "address.0.city",
        data?.data?.["_embedded"]?.hoofdvestiging?.adressen?.[0]?.plaats
      );
      setValue(
        "address.0.country",
        data?.data?.["_embedded"]?.hoofdvestiging?.adressen?.[0]?.land
      );
      setValue(
        "address.0.street",
        data?.data?.["_embedded"]?.hoofdvestiging?.adressen?.[0]?.straatnaam
      );
      setValue(
        "address.0.address",
        data?.data?.["_embedded"]?.hoofdvestiging?.adressen?.[0]?.volledigAdres
      );
      setValue("address.0.location.coordinates", [
        data?.data?.["_embedded"]?.hoofdvestiging?.adressen?.[0]?.geoData
          ?.gpsLongitude,
        data?.data?.["_embedded"]?.hoofdvestiging?.adressen?.[0]?.geoData
          ?.gpsLatitude,
      ]);
    } catch (e) {}
  };

  useEffect(() => {
    if (watch("kvkNumber")?.length == 8) {
      handlekvk(watch("kvkNumber") as string);
    }
  }, [watch("kvkNumber")]);

  return {
    control,
    openFilterModal,
    handleOpenFilter,
    handleCloseFilter,
    reset,
    setActiveCategory,
    activeCategory,

    watch,
    lastSkill,
    handleSubmit,
    Controller,
    fields,
    insert,
    remove,

    jobModal,
    closeJobModal,
    onConfirmJob,
    onConfirmEducation,
    openJobModal,
    openEducationModal,
    closeEducationModal,
    educationModal,
    removeJob,
    removeEducation,
    userData,
    setValue,

    editJobFeild,
    editEducationFeild,
    getEducationField,
    getJobField,
    onConfirmEducationEdit,
    onConfirmJobEdit,
    handleIbanChange,
    errors,
  };
};
