import { useLocale } from "locales";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";
import { INVITE_FORM } from "types/forms";
import {
  HireWorkerApi,
  InviteWorkerAPI,
  getWorkerDetailsAPI,
} from "utils/globalAPI/OrganizationWorkers";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const useWorkerDetails = () => {
  const { accessToken, fetchApi } = UsersContextConsumer();
  const {
    user: { attributes },
  } = useAuthenticator();
  console.log("user detail", attributes);

  const { localeSuccess } = useLocale();
  const toast = ToastConsumer();
  const { userId, organization, jobId } = useParams();

  const [inviteModal, setInviteModal] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [workerId, setWorkerId] = useState<string>("");

  const onClickInvite = (id: string) => {
    setWorkerId(id);
    setInviteModal(true);
  };

  const onConfirmInvite: SubmitHandler<INVITE_FORM> = async (data) => {
    const params = {
      worker: workerId,
      jobId: jobId,
      perHourRate: data?.perHourRate,
      content: data?.content,
    };
    // InviteWorkerAPI(params, accessToken);

    try {
      setInviteLoading(true);
      const response = await InviteWorkerAPI(params, accessToken);
      toast.success(localeSuccess.SUCESS_INVITIATION_SENT, "top-right");
      // setTokenInCookies(response?.data.data);
      setInviteModal(false);
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toast.serverError(error, "top-right");
    } finally {
      setInviteLoading(false);
    }
  };

  const closeInviteModal = () => {
    setWorkerId("");
    setInviteModal(false);
  };

  // get userId from path

  const {
    data: {
      worker = {
        worker: null,
      },
    } = {},
    refetch,
    isLoading: workerDetailsLoading,
  } = useQuery(
    [
      "workerDetails",
      {
        userId,
      },
    ],

    async () =>
      getWorkerDetailsAPI(userId + "", organization || "", accessToken),
    { enabled: fetchApi, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  return {
    worker,
    workerDetailsLoading,
    onClickInvite,
    inviteModal,
    closeInviteModal,
    onConfirmInvite,
    inviteLoading,
    refetch,
  };
};
