
import NotificationPage from "pages/NotificationPage";

import { useNotificationService } from "./hook";
import EmptyScreen from "components/organisms/EmptyScreen";
import { useLocale } from "locales";


export default function NotificationService() {

  const { notifications, deleteNotification, updateNotificationReadAbility } =
    useNotificationService();
  let notNotification =
    notifications?.earlier?.length === 0 && notifications?.recent?.length === 0;

  const {
    localeBasics
  } =useLocale()
  return (
    <>
      {notNotification ? (
        <EmptyScreen
          text={localeBasics?.BASIC_EMPTY_NOTIFICATION}
        />
      ) : (
        <NotificationPage
          notification={notifications}
          deleteNotification={deleteNotification}
          markNotificaitonAsRead={updateNotificationReadAbility}
          earliarLength={notifications?.earlier?.length || 0}
          recentLength={notifications?.recent?.length || 0}
        />
      )}
    </>
  );
}
