
import {  useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocale } from "locales";


import { workingDatesFormTypes } from "../@types";

export const useAddSchedule = (defaultValues?: workingDatesFormTypes) => {
  const { localeErrors } = useLocale();
  const validationSchema: any = yup.object().shape({
    date: yup
      .array()
      .required(localeErrors.ERROR_DATE_REQUIRED),
    //   .of(
    //     yup.object().shape({
    //       date: yup.date().required(localeErrors.ERROR_DATE_REQUIRED),
    //     })
    //   )
      // .test(
      //   "date",
      //   localeErrors.ERROR_INVALID_START_TIMEFRAME,
      //   (value: any) => {
      //     // date 2nd element must be greater then first
      //     alert(JSON.stringify(value))
      //     if (value.length > 1) {
      //       return dayjs(value[1]).isBefore(dayjs(value[0]));
      //     }
      //     // if there is only one date then it is invalid
      //     return false;
      //   }
      // )
      // .required(localeErrors.ERROR_DATE_REQUIRED),

      status:
      yup
      .string()
      .required(localeErrors.ERROR_STATUS_REQUIRED),

    
  });

  const { control, handleSubmit, watch,setValue,formState:{errors} } = useForm<workingDatesFormTypes>({
    defaultValues: {
      ...defaultValues,
      date: defaultValues?.date,
      breakTimeInMin: defaultValues?.breakTimeInMin,
      status: defaultValues?.status,
    },
    resolver: yupResolver(validationSchema) as any,
  });

  console.log("errors",errors)

  return {
    control,
    handleSubmit,
    watch,
  };
};
