import { useState } from "react";

import { useQuery } from "react-query";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";

import { deleteJobApi, getAllJobAPI, getOrganizationJobAPI, onChecngeJobPostingCheck, seeAllJobStatusApi } from "utils/globalAPI/Dashboard";
import { useParams, useSearchParams } from "react-router-dom";
import { useLocale } from "locales";
import { JobData } from "types/schema";

export const useAllJobs = () => {
  const toast = ToastConsumer();
  const { accessToken, fetchApi } = UsersContextConsumer();
  const [activeCategory, setActiveCategory] = useState<seeAllJobStatusApi>("under_review");

  const getCategoryName = (name:seeAllJobStatusApi) => {
    setActiveCategory(name);
  };

  const { data: jobsData, isLoading: jobsDataLoading,refetch } = useQuery(
    [
      "jobsData",
      {
        accessToken,
        activeCategory,
      },
    ],
    async () =>
      getAllJobAPI(activeCategory, accessToken)
        .then((res: any) => res?.jobs)
        .catch((error) => toast.error(error.message, "top-right")),
    { enabled: fetchApi, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  const [quer, searchQuery] = useSearchParams({ jobId: "" });

  const onDeleteJob = (data: JobData) => {
    toggleModal();
    searchQuery({ jobId: data?._id + "" });
  };

  const { localeSuccess } = useLocale();

  const onConfirmDeleteJob = async () => {
    try {
      setLoading(true);
      await deleteJobApi(quer.get("jobId") as string, accessToken);
      toast.success(localeSuccess.SUCCESS_DELETING_JOB);
    } catch (error) {
      console.log("Error in deleting job", error);
      toast.error(localeSuccess.ERROR_DELETING_JOB);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  const onChangeFulfilledBy = async (fullfilledBy:"under_review" | "admin" | "users",jobId:string) => {
    try {
      setLoading(true);
      await onChecngeJobPostingCheck(jobId as string,fullfilledBy, accessToken);
      toast.success(localeSuccess.SUCCESS_JOB_UPDATED);
      // refetch();
    } catch (error) {
      console.log("Error in Updating job", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    jobsData,
    jobsDataLoading,
    getCategoryName,
    activeCategory,
    onChangeFulfilledBy,

    onConfirmDeleteJob,
    loading,
    modal,
    toggleModal,
    onDeleteJob,
  };
};
