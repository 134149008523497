import { useUserDetailsPage } from "./hook";
import { Text } from "components/atoms/TextF";
import { useLocale } from "../../locales";
import styles from "./UserDetailsPage.module.scss";

import { UserDetailsPageProps } from "./@types";
import WorkerDetailsCard from "../../components/organisms/WorkerDetailsCard";
import VOGSection from "../../components/organisms/VOGSection";
import DeactivateButton from "../../components/molecules/DeactivateButton";

import ConfirmationModal from "../../components/organisms/Confirmation Modal";
import OrganizationDetailsCard from "../../components/organisms/OrganizationDetailsCard";
import CancleCircleIcon from "../../assets/Jsx/CancleCircleIcon";
import { BsCheck } from "react-icons/bs";
import FileViewerModal from "../../components/organisms/FileViewerModal";
import { useNavigate } from "react-router-dom";
import CategorySelect from "components/molecules/CategorySelect";
import ThreeDCubeIcon from "assets/svgs/ThreeDCubeIcon";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { AddressComponent } from "pages/AccountPageOrganization/AccountPageOrganization";
import { UsersContextConsumer } from "store/AuthProvider";

const UserDetailsPage = ({
  userDetails,
  onToggleAccount,
  onUserUpdate,
  onClickHide,
  onConfirmAccountToggle,
  showConfirmation,
  toggleLoading,

  onSubmitForm,
  vogLoading,
  onClickDeclined,
  onClickVerified,
  declineLoading,
  verifyLoading,
  organization,
  latestContract
}: UserDetailsPageProps) => {
  const {
    handleSubmitForm,
    control,
    onPreviewFile,
    previewFile,
    fields,
    sliderRef,
    onClosePreviewFile,
  } = useUserDetailsPage(
    userDetails?.branches,
    userDetails?.address,
    organization
  );

  const { localeButtons, localeTitles, localeLabels } = useLocale();
  const navigate = useNavigate();

  const { setAddress } = UsersContextConsumer();

  return (
    <div className="mt-4 px-8  md:mt-10 space-y-4">
      <div className="flex flex-row justify-between">
        {userDetails?.accountType === "organization" && (
          <DeactivateButton
            title={localeButtons.BUTTON_ALLOWED_BRANCHES}
            icon={<BsCheck size={15} />}
            onClickDeactivateButton={() => {
              navigate(
                `/organization/${userDetails?.organization?._id}/allowed/branchs`
              );
            }}
          />
        )}

        {userDetails?.accountType === "organization" && (
          <DeactivateButton
            title={localeButtons.BUTTON_ORGANIZATION_SCHEDULE}
            icon={<BsCheck size={15} />}
            onClickDeactivateButton={() => {
              setAddress(userDetails?.address);
              navigate(`/schedule/${userDetails?.organization?._id}`);
            }}
          />
        )}
        {userDetails?.accountType === "organization" && (
          <DeactivateButton
            title={localeButtons.BUTTON_POST_JOB}
            icon={<BsCheck size={15} />}
            onClickDeactivateButton={() => {
              navigate(`/organization/${userDetails?.organization?._id}/post`, {
                state: userDetails.address,
              });
            }}
          />
        )}
        <DeactivateButton
          title={localeButtons.BUTTON_UPDATE_USER}
          icon={<BsCheck size={15} />}
          onClickDeactivateButton={onUserUpdate}
        />

        <DeactivateButton
          title={
            userDetails?.status === "active"
              ? localeButtons.BUTTON_DEACTIVATE_ACCOUNT
              : localeButtons.BUTTON_ACTIVATE_ACCOUNT
          }
          icon={
            userDetails?.status === "active" ? (
              <CancleCircleIcon size={15} />
            ) : (
              <BsCheck size={15} />
            )
          }
          onClickDeactivateButton={onToggleAccount}
        />
      </div>
      {userDetails?.accountType !== "organization" ? (
        <WorkerDetailsCard
          onPreviewFile={onPreviewFile}
          userDetails={userDetails}
          latestContract={latestContract}
        />
      ) : (
        <OrganizationDetailsCard userDetails={userDetails} />
      )}

      {userDetails?.accountType !== "organization" && (
        <div className={styles["WorkerDetailsCard"]}>
          <Text className={styles["section-title"]}>
            {localeLabels.LABEL_ASSOCIATED_ORGANIZATION}
          </Text>

          <CategorySelect
            multiSelect={true}
            name="organization"
            control={control}
            list={organization?.map((item) => {
              return {
                _id: item._id,
                value: item._id,
                label: {
                  en: item.companyName,
                  du: item.companyName,
                },
              };
            })}
            // maxLimit={3}
            // disabled
            Icon={<ThreeDCubeIcon />}
          />
        </div>
      )}
      <div className={styles["WorkerDetailsCard"]}>
        <Text className={styles["section-title"]}>
          {localeLabels.LABEL_INDUSTRY_CATEGORY}
        </Text>

        <CategorySelect
          multiSelect={true}
          name="branches"
          control={control}
          list={userDetails?.branches}
          // maxLimit={3}
          disabled
          Icon={<ThreeDCubeIcon />}
        />
      </div>
      <div className={styles["WorkerDetailsCard"]}>
        <Text className={styles["section-title"]}>
          {localeLabels.LABEL_INDUSTRY_CATEGORY}
        </Text>

        <CategorySelect
          multiSelect={true}
          name="branches"
          control={control}
          list={userDetails?.branches}
          // maxLimit={3}
          disabled
          Icon={<ThreeDCubeIcon />}
        />
      </div>

      <div className={styles["WorkerDetailsCard"]}>
        {/* {width > breakPoints.md && ( */}

        <Text className={styles["section-title"]}>
          {localeTitles.TITLE_LOCATION}
        </Text>

        <div className={styles["AccountPage-right-section-map"]}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={1}
            navigation={true}
            pagination={{ clickable: true }}
            //  scrollbar={{ draggable: false ,hide:true}}
            onSwiper={(swiper: any) => (sliderRef.current = swiper)}
            onSlideChange={() => console.log("slide change")}
            style={{
              height: "100%",
              width: "100%",
            }}
            shortSwipes={false}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {fields?.map((address, index) => (
              <SwiperSlide>
                <AddressComponent
                  disabled={true}
                  index={index}
                  control={control as any}
                  fields={fields as any}
                  errors={{}}
                  setValue={() => {}}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {userDetails?.accountType !== "organization" && (
        <VOGSection
          userDetails={userDetails}
          handleSubmit={handleSubmitForm}
          onSubmit={onSubmitForm}
          onClickVerified={onClickVerified}
          onClickDeclined={onClickDeclined}
          control={control}
          vogLoading={vogLoading}
          declineLoading={declineLoading}
          verifyLoading={verifyLoading}
          onPreviewFile={onPreviewFile}
        />
      )}

      <FileViewerModal
        files={previewFile?.file}
        open={previewFile?.show}
        onClose={onClosePreviewFile}
      />

      <ConfirmationModal
        show={showConfirmation}
        title={
          userDetails?.status === "active"
            ? localeLabels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_ACCOUNT
            : localeLabels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_ACTIVATE_ACCOUNT
        }
        onHide={onClickHide}
        onConfirm={onConfirmAccountToggle}
        loading={toggleLoading}
      />
    </div>
  );
};

const SwipperCommponent = ({
  index,
  control,
  fields,
}: {
  index: number;
  control: any;
  fields: any;
}) => {
  const swiper = useSwiper();

  return (
    <SwiperSlide>
      <AddressComponent
        disabled={true}
        index={index}
        control={control as any}
        fields={fields as any}
        errors={{}}
        setValue={() => {}}
      />
    </SwiperSlide>
  );
};

export default UserDetailsPage;
