import JobForm from "pages/JobPostingSection";
import styles from "./PostAJobPage.module.scss";

import { usePostAJobPage } from "./hook";
import ModalComponent from "components/molecules/ModalF/Modal";

import { useLocale } from "locales";
import CheckCircleIcon from "assets/svgs/CheckCircleIcon";
import ConfirmationModal from "components/molecules/ConfirmationModal";

const PostAJob = () => {
  const { onSubmit, loading, inviteModal, closeInviteModal, onClickYes } =
    usePostAJobPage();
  const { localeButtons: localeButtons, localeTitles } = useLocale();

  return (
    <div className={styles["PostAJobPage"]}>
      <JobForm onSubmit={onSubmit} loading={loading} isEdit={false} />

      <ModalComponent open={inviteModal} onClose={closeInviteModal}>
        <ConfirmationModal
          cancelButtonText={localeButtons?.BUTTON_NO_CANCEL}
          confirmButtonText={localeButtons?.BUTTON_YES}
          icon={<CheckCircleIcon />}
          onConfirm={onClickYes}
          subTitle={
            <>
              {localeTitles.TITLE_JOB_POSTED_SUCCESSFULLY}
              <br />
              {localeTitles.TITLE_WANT_TO_SEND_INVITES}
            </>
          }
          closeModal={closeInviteModal}
          loading={false}
        />
      </ModalComponent>
    </div>
  );
};

export default PostAJob;
