import { Img } from "components/atoms/Img";
import { Text } from "components/atoms/TextF";
import styles from "./HiredWorkerCard.module.scss";
import { useLocale } from "locales";
import { useNavigate } from "react-router-dom";

import DeviceMessageIcon from "./Icon/DeviceMessageIcon";
import ClockIcon from "./Icon/ClockIcon";
import TimerIcon from "./Icon/TimerIcon";
import GasStationIcon from "./Icon/GasStationIcon";
import { HiredWorkerCardProps } from "./@types";
import LoadingTimerIcon from "./Icon/LoadingTimerIcon";
import { LinearProgress } from "@mui/material";
import CalendarAddIcon from "./Icon/CalendarAddIcon";
import { handleImagesPath } from "utils/hooks/helper";
import dayjs from "dayjs";

const HiredWorkerCard = ({
  data,
  className,
  midSectionClass,
  onClickCard,
}: HiredWorkerCardProps) => {
  const { localeTitles, localeLabels } = useLocale();

  // const navigateToJobDetails = () => {
  //   navigate(`/hired-workers/task/${data._id}`);
  //   // navigate(`/find-workers/${data._id}`);
  // };
  const valueTotal = (data?.totalCompletedTasks / data?.totalTasks) * 100;

  return (
    <div
      className={`${styles["HiredWorkerCard"]} ${className}`}
      onClick={() => onClickCard?.(data?._id)}
    >
      <div className={styles["HiredWorkerCard-top"]}>
        <div className={styles["HiredWorkerCard-top-above"]}>
          <div className={styles["HiredWorkerCard-top-left"]}>
            <Img
              src={handleImagesPath(data?.worker?.profileImage)}
              className={styles["userLogo"]}
            />
            <div>
              <Text className={styles["companyName"]}>
                {data?.worker?.firstName} {data?.worker?.lastName}
              </Text>
              <Text className={styles["details"]}>{data?.worker?.heading}</Text>
            </div>
          </div>
          <div className={styles["HiredWorkerCard-top-right"]}>
            <Text className={styles["hired"]}>{localeTitles?.TITLE_HIRED}</Text>
          </div>
        </div>
      </div>
      <Text className={styles["title-job"]}>{data?.job?.title}</Text>
      <div className={`${styles["HiredWorkerCard-mid"]} ${midSectionClass}`}>
        <div className={styles["details-div"]}>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_BEGIN_TIME}
            </Text>
            <Text className={styles["description-value"]}>
              <ClockIcon size={18} />
              {dayjs(data?.job?.timeToStart).isValid()
                ? dayjs(data?.job?.timeToStart).format("HH:mm")
                : data?.job?.timeToStart}
            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_END_TIME}
            </Text>
            <Text className={styles["description-value"]}>
              <ClockIcon size={18} />
              {dayjs(data?.job?.timeToEnd).isValid()
                ? dayjs(data?.job?.timeToEnd).format("HH:mm")
                : data?.job?.timeToEnd}
            </Text>
          </div>

          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_BREAK_TIME}
            </Text>
            <Text className={styles["description-value"]}>
              <TimerIcon size={18} color="#101828" />
              {data?.job?.breakTime}
            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_TRAVEL_EXPENSE}
            </Text>
            <Text className={styles["description-value"]}>
              <GasStationIcon size={18} />€ {data?.job?.travelExpenses}
            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_BREAK_TIME}
            </Text>
            <Text className={styles["description-value"]}>
              <LoadingTimerIcon size={18} />
              {data?.job?.breakTime}
            </Text>
          </div>
          {/* <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_POSITION}
            </Text>
            <Text className={styles["description-value"]}>
              <MagicPenIcon />
              {data.}
            </Text>
          </div> */}

          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_CONTRACT_START_DATE}
            </Text>
            <Text className={styles["description-value"]}>
              <ClockIcon size={18} />
              {dayjs(data?.job?.startDate).format("DD MMM YYYY")}
            </Text>
          </div>
          <div className={styles["item"]}>
            <Text className={styles["description"]}>
              {localeTitles.TITLE_CONTRACT_END_DATE}
            </Text>
            <Text className={styles["description-value"]}>
              <ClockIcon size={18} />
              {dayjs(
                data?.job?.repeated
                  ? data?.job?.repeatEndingDate
                  : data?.job?.shift == "night"
                  ? data?.job?.endDate
                  : data?.job?.startDate
              ).format("DD MMM YYYY")}
            </Text>
          </div>
        </div>

        <div className={styles["details-task-div"]}>
          <div className={styles["details-task-head"]}>
            <Text className={styles["description"]}>
              {localeTitles.LABEL_TASKS}
            </Text>
            <Text
              className={styles["description"]}
            >{`${data?.totalCompletedTasks}/${data?.totalTasks}`}</Text>
          </div>
          <LinearProgress
            variant="determinate"
            value={valueTotal}
            color="warning"
            className={styles["linearProgress"]}
            // sx={{ width: "320px", color: "yellow" }}
          />
        </div>
      </div>
      <div className={styles["HiredWorkerCard-bottom"]}>
        <div className={styles["joiningDate"]}>
          <Text className={styles["description"]}>
            {localeLabels.LABEL_JOINING_DATE}
          </Text>
          <div className={styles["joiningDate-date"]}>
            <CalendarAddIcon size={20} />
            <Text className={styles["description-value"]}>
              {dayjs(data?.createdAt).format("DD/MM/YYYY")}
            </Text>
          </div>
        </div>

        <Text className={styles["rate"]}>
          €{`${data?.decidedRate} ${localeTitles.TITLE_PER_HOUR}`}
        </Text>
      </div>
    </div>
  );
};

export default HiredWorkerCard;
