import React from "react";
import { usePostJobDetailsService } from "./hook";
import JobDetailsOrganization from "./JobDetailsOrganization";
import ConfirmationModal from "components/organisms/Confirmation Modal";
import { useLocale } from "locales";

const JobDetail: React.FC = () => {
  const {
    job,
    jobDetailsLoading,
    loading,
    modal,
    toggleModal,
    onDeleteJob,
    OnEditJob,
    onConfirmDeleteJob,
    onChangeJobPosting
  } = usePostJobDetailsService();
  const { localeLabels } = useLocale();
  return (
    <>
      <JobDetailsOrganization jobData={job} onDeleteJob={onDeleteJob} onChangeJobPosting={onChangeJobPosting} OnEditJob={OnEditJob} />
      <ConfirmationModal
        show={modal}
        title={localeLabels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_JOB}
        onHide={toggleModal}
        onConfirm={onConfirmDeleteJob}
        loading={loading}
      />
    </>
  );
};

export default JobDetail;
