import JobForm from "pages/JobPostingSection";
import styles from "./PostAJobPage.module.scss";

import { useEditAJobPage } from "./hook";

const EditAJob = () => {
  const { onSubmit, loading, defaulValues } = useEditAJobPage();

  return (
    <JobForm
      onSubmit={onSubmit}
      loading={loading}
      isEdit={true}
      defaulValues={defaulValues}
    />
  );
};

export default EditAJob;
