import type { RatingStarProps } from "./@types";
import "./Loader.scss";

export const Loader = ({ className, color = "bg-black" }: RatingStarProps) => {
  return (
    <div className="spinner">
      <div className="spinner-container">
        <span className="dots-circle-spinner loading"></span>
      </div>
    </div>
  );
};
