import React, { useState } from "react";

import type { WorkerDetailsCardProps } from "./@types";
import styles from "./WorkerDetailsCard.module.scss";
import { useLocale } from "../../../locales";
import { Text } from "../../atoms";
import Image from "../../atoms/Image";
import { getLables, handleImagesPath } from "../../../utils/hooks/helper";
import dayjs from "dayjs";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { dayjsDutuch } from "locales/MultiDatePicker";
import { JobData } from "types/Gloabal";

const WorkerDetailsCard = ({
  userDetails,
  onPreviewFile = (filelink: string) => {},
  latestContract,
  ...props
}: WorkerDetailsCardProps) => {
  const { localeLabels, currentLocale } = useLocale();

  const Job: JobData = latestContract?.jobId as any;

  console.log("latestContract", latestContract);

  const endDat = Job?.repeated
    ? dayjs(Job?.repeatEndingDate).format("DD/MM/YYYY")
    : dayjs(Job?.endDate).format("DD/MM/YYYY");

  return (
    <div className={styles["WorkerDetailsCard"]}>
      <div className={styles["WorkerDetailsCard-top"]}>
        <div className={styles[""]}></div>
        <Image
          imagePath={handleImagesPath(userDetails?.profileImage)}
          className={styles["userImg"]}
          alt=""
        />
        {userDetails?.worker?.verificationStatus === "verified" ? (
          <Text className={styles["approved"]}>
            {localeLabels.LABEL_APPROVED}
          </Text>
        ) : userDetails?.worker?.verificationStatus === "inProcess" ? (
          <Text className={styles["inProcess"]}>
            {localeLabels.LABEL_IN_PROCESS}
          </Text>
        ) : (
          <Text className={styles["rejected"]}>
            {localeLabels.LABEL_REJECTED}
          </Text>
        )}
      </div>
      <div className={styles["WorkerDetailsCard-mid"]}>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_EMPLOYEE_NUMBER}:
          </Text>

          <Text className={styles["labelValue"]}>
            {`${userDetails?.worker?.employeeNumber || ""}`}
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          {userDetails && (
            <>
              {userDetails?.accountType === "employee" && (
                <Text className={styles["labelText"]}>
                  {localeLabels.LABEL_EMPLOYEE_NAME}:
                </Text>
              )}
              {userDetails?.accountType === "freelancer" && (
                <Text className={styles["labelText"]}>
                  {localeLabels.LABEL_FREELANCER_NAME}:
                </Text>
              )}
              {userDetails?.accountType === "organization" && (
                <Text className={styles["labelText"]}>
                  {localeLabels.LABEL_ORGANIZATION_NAME}:
                </Text>
              )}
            </>
          )}
          <Text className={styles["labelValue"]}>
            {`${userDetails?.firstName} ${userDetails?.lastName}`}
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_PHONE_NUMBER}:
          </Text>
          <Text className={styles["labelValue"]}>{userDetails?.phone}</Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_DATE_OF_BIRTH}:
          </Text>
          <Text className={styles["labelValue"]}>
            {dayjs(userDetails?.dob).format("DD/MM/YYYY")}
          </Text>
        </div>
        {Job && (
          <div className={styles["detail-field"]}>
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_LATEST_CONTRACT_START}:
            </Text>
            <Text className={styles["labelValue"]}>
              {dayjs(latestContract?.hireStartDate).format("DD/MM/YYYY")}
              {" - "}
              {endDat}
            </Text>
          </div>
        )}

        {/* <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_INDUSTRY_CATEGORY}:
          </Text>
          <select className={styles["labelValue"]}>
            {userDetails?.branches?.map((category, index) => (
              <option key={index}>
                {getLables(category, currentLocale, "branches")}

              </option>
            ))}
          </select>
        </div> */}
        {/* <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_ADDRESS_DETAILS}:
          </Text>
          <Text className={styles["labelValue-address"]}>
            {userDetails?.address?.length > 0 && (
              <>
                <span
                  className={`cursor-pointer ${styles["collapsible-label"]}`}
                  onClick={() => setCollapsed(!isCollapsed)}
                >
                  {userDetails.address[0].address}{" "}
                  {userDetails.address.length > 1 && (
                    <>{isCollapsed ? <FaCaretDown /> : <FaCaretUp />}</>
                  )}
                </span>
                <select>
                  {userDetails?.address
                    ?.slice(1, userDetails?.address?.length)
                    ?.map((val, index) => (
                      <option key={index}>{val.address}</option>
                    ))}
                </select>
              </>
            )}
          </Text>
        </div> */}
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_REGISTRATION_DATE}
          </Text>
          <Text className={styles["labelValue"]}>
            {dayjs(userDetails?.createdAt)
              .locale(currentLocale === "en" ? "en" : dayjsDutuch)
              .format("DD MMM YYYY")}
          </Text>
        </div>
        <div className={styles["detail-field"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_BANK_ACCOUNT_DETAILS}
          </Text>
          <Text className={styles["labelValue"]}>
            {userDetails?.worker?.bankDetails?.IBAN}
          </Text>
        </div>
        {userDetails?.accountType === "freelancer" && (
          <div className={styles["detail-field"]}>
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_KVK_NUMBER}
            </Text>
            <Text className={styles["labelValue"]}>
              {userDetails?.worker?.kvkNumber}
            </Text>
          </div>
        )}
      </div>

      <div className={styles["WorkerDetailsCard-bottom"]}>
        <div className={styles["detail-field-documents"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_EMPLOYEE_ID}:
          </Text>
          <div className={styles["detail-field-document"]}>
            <Text className={styles["labelValue"]}>
              {localeLabels.LABEL_FRONT_SIDE}
            </Text>
            <Image
              onClick={() =>
                onPreviewFile(userDetails?.worker?.documents?.idFront?.[0])
              }
              className={styles["documentImg"]}
              imagePath={handleImagesPath(
                userDetails?.worker?.documents?.idFront?.[0]
              )}
            />
            {userDetails?.worker?.documents?.idFront?.length === 0 && (
              <Text className={styles["labelValue"]}>
                {localeLabels.LABEL_NOT_AVAILABLE}
              </Text>
            )}
          </div>
          <div className={styles["detail-field-document"]}>
            <Text className={styles["labelValue"]}>
              {localeLabels.LABEL_BACK_SIDE}
            </Text>
            <Image
              onClick={() =>
                onPreviewFile(userDetails?.worker?.documents?.idBack?.[0])
              }
              className={styles["documentImg"]}
              imagePath={handleImagesPath(
                userDetails?.worker?.documents?.idBack?.[0]
              )}
            />
            {userDetails?.worker?.documents?.idBack?.length === 0 && (
              <Text className={styles["labelValue"]}>
                {localeLabels.LABEL_NOT_AVAILABLE}
              </Text>
            )}
          </div>
        </div>

        <div className={styles["detail-field-document"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_DIPLOMA}:
          </Text>
          {userDetails?.worker?.documents?.diplomas?.map((pic, index) => (
            <Image
              onClick={() =>
                onPreviewFile(userDetails?.worker?.documents?.diplomas?.[index])
              }
              key={index}
              className={styles["documentImg"]}
              imagePath={handleImagesPath(pic)}
            />
          ))}
          {userDetails?.worker?.documents?.diplomas?.length === 0 && (
            <Text className={styles["labelValue"]}>
              {localeLabels.LABEL_NOT_AVAILABLE}
            </Text>
          )}
        </div>
        <div className={styles["detail-field-document"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_CERTIFICATE}:
          </Text>
          {userDetails?.worker?.documents?.certificate?.map((pic, index) => (
            <Image
              onClick={() =>
                onPreviewFile(
                  userDetails?.worker?.documents?.certificate?.[index]
                )
              }
              key={index}
              className={styles["documentImg"]}
              imagePath={handleImagesPath(pic)}
            />
          ))}

          {userDetails?.worker?.documents?.certificate?.length === 0 && (
            <Text className={styles["labelValue"]}>
              {localeLabels.LABEL_NOT_AVAILABLE}
            </Text>
          )}
        </div>
        <div className={styles["detail-field-document"]}>
          <Text className={styles["labelText"]}>
            {localeLabels.LABEL_SCAN_BY_CAMERA}:
          </Text>
          {userDetails?.worker?.documents?.scannedImages?.map((pic, index) => (
            <Image
              onClick={() =>
                onPreviewFile(
                  userDetails?.worker?.documents?.scannedImages?.[index]
                )
              }
              key={index}
              className={styles["documentImg"]}
              imagePath={handleImagesPath(pic)}
            />
          ))}

          {userDetails?.worker?.documents?.scannedImages?.length === 0 && (
            <Text className={styles["labelValue"]}>
              {localeLabels.LABEL_NOT_AVAILABLE}
            </Text>
          )}
        </div>
        {userDetails?.accountType === "freelancer" && (
          <div className={styles["detail-field-document"]}>
            <Text className={styles["labelText"]}>
              {localeLabels.LABEL_INSURANCE_DOCUMENT}:
            </Text>
            {userDetails?.worker?.documents?.insuranceBlockDocuments?.map(
              (pic, index) => (
                <Image
                  onClick={() =>
                    onPreviewFile(
                      userDetails?.worker?.documents?.insuranceBlockDocuments?.[
                        index
                      ]
                    )
                  }
                  key={index}
                  className={styles["documentImg"]}
                  imagePath={handleImagesPath(pic)}
                />
              )
            )}

            {userDetails?.worker?.documents?.insuranceBlockDocuments?.length ===
              0 && (
              <Text className={styles["labelValue"]}>
                {localeLabels.LABEL_NOT_AVAILABLE}
              </Text>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkerDetailsCard;
