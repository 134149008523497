import React from "react";

type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function DeliverBtnIcon({ white, size }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size == "MOBILE" ? "15" : "21"}
      height={size == "MOBILE" ? "15" : "21"}
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        fill={white ? "white" : "#00A510"}
        d="M18.905 3.49L15.25 4.954l-9-3.6L8.44.476a4.2 4.2 0 013.12 0l6.908 2.764c.157.062.304.147.437.25zm-8.904 3.563L13.635 5.6l-9-3.6-3.103 1.242a1.799 1.799 0 00-.437.25L10 7.053H10zM.4 4.911c0-.132.014-.262.042-.388L9.4 8.106v4.04a6.576 6.576 0 00-.6 2.753c0 .984.216 1.916.6 2.754v1.126a4.177 4.177 0 01-.96-.257l-6.908-2.764A1.8 1.8 0 01.4 14.087V4.91zm19.2 0v4.896A6.573 6.573 0 0015.4 8.3a6.583 6.583 0 00-4.8 2.069V8.106l8.958-3.583c.027.126.042.256.042.388zm-4.2 15.39a5.4 5.4 0 100-10.8 5.4 5.4 0 000 10.8zm-1.2-4.45l3.175-3.174a.6.6 0 01.85.848l-3.6 3.6a.598.598 0 01-.85 0l-1.2-1.2a.6.6 0 01.85-.848l.775.775z"
      ></path>
    </svg>
  );
}

export default DeliverBtnIcon;
