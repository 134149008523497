export const english = {
    name: "dutuch",
    months: [
      ["january", "jan"],
      ["february", "feb"],
      ["march", "mar"],
      ["april", "apr"],
      ["may", "may"],
      ["june", "jun"],
      ["july", "jul"],
      ["august", "aug"],
      ["september", "sep"],
      ["october", "oct"],
      ["november", "nov"],
      ["december", "dec"],
    ],
    weekDays: [
      ["saturday", "sat"],
      ["sunday", "sun"],
      ["monday", "mon"],
      ["tuesday", "tue"],
      ["wednesday", "wed"],
      ["thursday", "thu"],
      ["friday", "fri"],
    //   ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"]
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "am"],
      ["PM", "pm"],
    ],
  };
  

  export const dutch = {
    name: "dutch",
    months: [
      ["januari", "jan"],
      ["februari", "feb"],
      ["maart", "mrt"],
      ["april", "apr"],
      ["mei", "mei"],
      ["juni", "jun"],
      ["juli", "jul"],
      ["augustus", "aug"],
      ["september", "sep"],
      ["oktober", "okt"],
      ["november", "nov"],
      ["december", "dec"],
    ],
    weekDays: [
      ["Maandag", "ma"],
      ["Dinsdag", "di"],
      ["Woensdag", "wo"],
      ["Donderdag", "do"],
      ["Vrijdag", "vr"],
      ["Zaterdag", "za"],
      ["Zondag", "zo"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "am"],
      ["PM", "pm"],
    ],
  };
  

  export const dayjsDutuch:ILocale   = {
    name: 'nl', // name String
    weekdays: 'zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag'.split('_'), // weekdays Array
    weekdaysShort: 'zo_ma_di_wo_do_vr_za'.split('_'), // short weekdays Array
    weekdaysMin: 'zo_ma_di_wo_do_vr_za'.split('_'), // min weekdays Array
    weekStart: 1, // set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday
    // yearStart: 4, // the week that contains Jan 4th is the first week of the year.
    months: 'januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december'.split('_'), // months Array
    monthsShort: 'jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec'.split('_'), // short months Array
    ordinal: n => `${n}e`, // ordinal Function (number) => return number + output
    formats: {
      // abbreviated format options allowing localization
      LTS: 'HH:mm:ss',
      LT: 'HH:mm',
      L: 'DD-MM-YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
      // lowercase/short, optional formats for localization
      // l: 'D-M-YYYY',
      // ll: 'D MMM YYYY',
      // lll: 'D MMM YYYY HH:mm',
      // llll: 'ddd D MMM YYYY HH:mm'
    },
    relativeTime: {
      // relative time format strings, keep %s %d as the same
      future: 'over %s', // e.g. in 2 hours, %s been replaced with 2hours
      past: '%s geleden',
      s: 'een paar seconden',
      m: 'een minuut',
      mm: '%d minuten',
      h: 'een uur',
      hh: '%d uur', // e.g. 2 hours, %d been replaced with 2
      d: 'een dag',
      dd: '%d dagen',
      M: 'een maand',
      MM: '%d maanden',
      y: 'een jaar',
      yy: '%d jaar'
    }
  };