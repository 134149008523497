import { Text } from "../../atoms";
import React from "react";
import { useLocale } from "../../../locales";
import type { tableHeaderProps } from "./@types";
import styles from "./TableHeader.module.scss";
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";

const TableHeader = ({
  tableType = "Application_status",
  filter,
}: tableHeaderProps) => {
  const { localeLabels, localeTitles } = useLocale();
  switch (tableType) {
    case "Application_status":
      return (
        <tr>
          <th className={styles["tableHeading-white"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_NO_DOT}
            </Text>
          </th>

          <th className={styles["tableHeading-white"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_USER_NAME}
            </Text>
          </th>
          <th className={styles["tableHeading-white"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_WORKER_TYPE}
            </Text>
          </th>
          <th className={styles["tableHeading-white"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_VERIFCATION_STATUS}
            </Text>
          </th>
          {/* <th className={styles["tableHeading-white"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_APPROVED_DATE}
            </Text>
          </th> */}

          <th className={styles["tableHeading-white"]} colSpan={1}></th>
        </tr>
      );

    case "Employee":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px] `}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_EMPLOYEE_NUMBER}
            </Text>
          </th>
          <th className={`${styles["tableHeading"]}   `}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_EMPLOYEE_NAME}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_EMAIL}
            </Text>
          </th>
          <HearderItem
            filter={filter}
            name="r-date"
            label={localeLabels.LABEL_REGISTRATION_DATE}
          />

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_PHONE_NUMBER}
            </Text>
          </th>
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_INDUSTRY_CATEGORY}
            </Text>
          </th>
          {/* <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_DOB}
            </Text>
          </th> */}
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_NOTIFICATION}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={1}
          >
            {" "}
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_SEE_MORE}
            </Text>
          </th>
        </tr>
      );

    case "Freelancer":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px] `}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_FREELANCER_NUMBER}
            </Text>
          </th>
          <th className={`${styles["tableHeading"]}`}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_FREELANCER_NAME}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_EMAIL}
            </Text>
          </th>
          <th
            className={`flex cursor-pointer ${styles["tableHeading"]}`}
            onClick={() =>
              filter.onChange(filter.order === "asc" ? "desc" : "asc", "r-date")
            }
          >
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_REGISTRATION_DATE}
            </Text>
            <div className="flex flex-col">
              <IoMdArrowDropup
                color={
                  filter.column === "r-date" && filter.order === "asc"
                    ? "#000000"
                    : "#999999"
                }
              />
              <IoMdArrowDropdown
                color={
                  filter.column === "r-date" && filter.order === "desc"
                    ? "#000000"
                    : "#999999"
                }
              />
            </div>
          </th>
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_PHONE_NUMBER}
            </Text>
          </th>
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_INDUSTRY_CATEGORY}
            </Text>
          </th>
          {/* <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_DOB}
            </Text>
          </th> */}
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_NOTIFICATION}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={1}
          >
            {" "}
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_SEE_MORE}
            </Text>
          </th>
        </tr>
      );

    case "Organization":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px]`}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_ORGANIZATION_NAME}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_EMAIL}
            </Text>
          </th>
          <th
            className={`flex cursor-pointer ${styles["tableHeading"]}`}
            onClick={() =>
              filter.onChange(filter.order === "asc" ? "desc" : "asc", "r-date")
            }
          >
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_REGISTRATION_DATE}
            </Text>
            <div className="flex flex-col">
              <IoMdArrowDropup
                color={
                  filter.column === "r-date" && filter.order === "asc"
                    ? "#000000"
                    : "#999999"
                }
              />
              <IoMdArrowDropdown
                color={
                  filter.column === "r-date" && filter.order === "desc"
                    ? "#000000"
                    : "#999999"
                }
              />
            </div>
          </th>
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_PHONE_NUMBER}
            </Text>
          </th>
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_BUSINESS_BRANCH}
            </Text>
          </th>
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_INDUSTRY}
            </Text>
          </th>
          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_NOTIFICATION}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={1}
          >
            {" "}
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_SEE_MORE}
            </Text>
          </th>
        </tr>
      );

    case "Branches":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px]`}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_BRANCH_NAME_DUTCH}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_BRANCH_NAME_ENGLISH}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_OPTIONS}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={1}
          >
            {" "}
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_SEE_CATEGORIES}
            </Text>
          </th>
        </tr>
      );
    case "Allowed Branches":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px]`}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_BRANCH_NAME_DUTCH}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_BRANCH_NAME_ENGLISH}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_ALLOW_POST_JOB}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={1}
          >
            {" "}
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_SEE_CATEGORIES}
            </Text>
          </th>
        </tr>
      );

    case "Categories":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px]`}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_CATEGORY_NAME_DUTCH}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_CATEGORY_NAME_ENGLISH}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_FREELANCER_PRICE}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={1}
          >
            {" "}
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_OPTIONS}
            </Text>
          </th>
        </tr>
      );

    case "SkillSet":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px]`}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_SKILL_NAME_DUTCH}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_SKILL_NAME_ENGLISH}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={1}
          >
            {" "}
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_OPTIONS}
            </Text>
          </th>
        </tr>
      );

    case "reports":
      return (
        <tr>
          <th className={`${styles["tableHeading"]} rounded-tl-[16px]`}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_REPORT_TYPE}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_ISSUE_DATE}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_EXPIRE_DATE}
            </Text>
          </th>

          <th className={styles["tableHeading"]}>
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_REPORT_GROUP}
            </Text>
          </th>

          <th
            className={`${styles["tableHeading"]} rounded-tr-[16px]`}
            colSpan={2}
          >
            <Text color="black" size={"xs"} weight="700">
              {localeLabels.LABEL_OPTIONS}
            </Text>
          </th>
        </tr>
      );

    default:
      return null;
  }
};

const HearderItem = ({ filter, label, name }) => {
  return (
    <th
      className={`flex cursor-pointer ${styles["tableHeading"]}`}
      onClick={() =>
        filter.onChange(filter.order === "asc" ? "desc" : "asc", name)
      }
    >
      <Text color="black" size={"xs"} weight="700">
        {label}
      </Text>
      <div className="flex flex-col">
        <IoMdArrowDropup
          color={
            filter.column === name && filter.order === "asc"
              ? "#000000"
              : "#999999"
          }
        />
        <IoMdArrowDropdown
          color={
            filter.column === name && filter.order === "desc"
              ? "#000000"
              : "#999999"
          }
        />
      </div>
    </th>
  );
};

//   }
// };

export default TableHeader;
