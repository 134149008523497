import { UsersContextConsumer } from "store/AuthProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { JOB_FORMS } from "../@types";
import { useLocale } from "locales";

import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { ApiGetAddress } from "utils/globalAPI/Users";

export const useJobPosting = (defaulValues?: JOB_FORMS) => {
  const { localeErrors } = useLocale();

  const { days, adminData: accessToken,fetchApi } = UsersContextConsumer();

  const { currentLocale } = useLocale();

  const validationSchema: any = yup.object().shape({
    title: yup.string().required(localeErrors.ERROR_TITLE_REQUIRED),
    jobDescription: yup
      .string()
      .required(localeErrors.ERROR_JOB_DESCRIPTION_REQUIRED),

    // validate wage if the worker type is not employee

    wage: yup.number().when("workerType", {
      is: "freelancer",
      then: yup.number().notRequired(),
      otherwise: yup.number().required(localeErrors.ERROR_WAGE_REQUIRED),
    }),

    // address is required when newLocation is false
    address: yup
      .string()
      .when("newLocation", {
        is: false,
        then: yup.string().required(localeErrors.ERROR_ADDRESS_REQUIRED),
      })
      .nullable(),

    cloths: yup.string().required(localeErrors.ERROR_CLOTHS_REQUIRED),
    clothsDescription: yup
      .string()
      .required(localeErrors.ERROR_CLOTHS_REQUIRED),
    travelExpenses: yup
      .number()
      .required(localeErrors.ERROR_TRAVEL_EXPENSE_REQUIRED),
    timeToStart: yup
      .string()
      // .matches(
      //   /^([01]\d|2[0-3]):([0-5]\d)$/,
      //   localeErrors.ERROR_TIME_TO_START_FORMAT
      // )
      .required(localeErrors.ERROR_TIME_TO_START_REQUIRED),

    timeToEnd: yup
      .string()
      // .matches(
      //   /^([01]\d|2[0-3]):([0-5]\d)$/,
      //   localeErrors.ERROR_TIME_TO_START_FORMAT
      // )
      .required(localeErrors.ERROR_TIME_TO_END_REQUIRED)
      .when("shift", {
        is: "night",
        then: yup
          .string()
          .test(
            "is-greater-than-timeToStart",
            localeErrors.ERROR_TIME_TO_END_NOT_GREATER,
            function (value) {
              const { timeToStart } = this.parent;
              if (!timeToStart || !value) {
                return true; // Allow empty values
              }
              return value < timeToStart;
            }
          ),
        otherwise: yup
          .string()
          .test(
            "is-greater-than-timeToStart",
            localeErrors.ERROR_TIME_TO_END_NOT_GREATER,
            function (value) {
              const { timeToStart } = this.parent;
              if (!timeToStart || !value) {
                return true; // Allow empty values
              }
              return value > timeToStart;
            }
          ),
      }),
    breakTime: yup.string().required(localeErrors.ERROR_BREAK_TIME_REQUIRED),
    startDate: yup
      .date()
      .typeError(localeErrors.ERROR_STARTING_DATE_REQUIRED)
      .required(localeErrors.ERROR_STARTING_DATE_REQUIRED)
      .min(dayjs().format("YYYY-MM-DD"), localeErrors.ERROR_STARTING_DATE_PAST),
    // endDate will be depend on shift and if shift is night then end Date is reqired
    endDate: yup
      .date()
      .typeError(localeErrors.ERROR_ENDING_DATE_REQUIRED)
      .when("shift", {
        is: "night",
        then: yup
          .date()
          .required(localeErrors.ERROR_ENDING_DATE_REQUIRED)
          .min(
            dayjs().format("YYYY-MM-DD"),
            localeErrors.ERROR_ENDING_DATE_PAST
          ),
        otherwise: yup.date().notRequired(),
      }),

    workerNeeded: yup
      .number()
      .required(localeErrors.ERROR_WORKER_REQUIRED)
      .min(1),
    hiringUrgencyLevel: yup
      .string()
      .required(localeErrors.ERROR_HIRING_URGENCY_REQUIRED),
    workerType: yup
      .string()
      .required(localeErrors.ERROR_WORKER_TYPE_REQUIRED)
      .min(1),

    categories: yup.array().required(localeErrors.ERROR_CATEGORY_REQUIRED),

    // repeated must be a boolean value
    repeated: yup.boolean().required(localeErrors.ERROR_REPEATED_REQUIRED),
    // repeatEndingDate must be an Date and depends on repeated

    repeatEndingDate: yup.date().when("repeated", {
      is: true,
      then: yup
        .date()
        .typeError(localeErrors.ERROR_ENDING_DATE_REQUIRED)
        .required(localeErrors.ERROR_ENDING_DATE_REQUIRED),
      otherwise: yup.date().notRequired(),
    }),
    repeatedDays: yup.array().when("repeated", {
      is: true,
      then: yup
        .array()
        .required(localeErrors.ERROR_REPEATED_DAYS_REQUIRED)
        .min(1, localeErrors.ERROR_REPEATED_DAYS_REQUIRED),
      otherwise: yup.array().notRequired(),
    }),
    repeatWeek: yup.string().when("repeated", {
      is: true,
      then: yup.string().required(localeErrors.ERROR_REPEATED_WEEK_REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
  });

  const organization = useParams().organization;

  const { data, isLoading } = useQuery(
    [
      "OrganizationAdress",
      {
        organization: organization,
      },
    ],
    () => ApiGetAddress(organization, accessToken),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled:fetchApi
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<JOB_FORMS>({
    resolver: yupResolver(validationSchema) as any,
    // defaultValues: locationData,
    defaultValues: {
      ...defaulValues,
      contractType: defaulValues?.contractType || "full-time",
      parkingOptions: defaulValues?.parkingOptions || "free",
      shift: defaulValues?.shift || "day",
      repeated: defaulValues?.repeated || false,
      address: defaulValues?.address?._id,
      repeatEndingDate: dayjs(defaulValues?.repeatEndingDate).format(
        "YYYY-MM-DD"
      ) as any,
      repeatedDays: (days
        ?.filter((item) =>
          defaulValues?.repeatedDays?.includes(item?._id as any)
        )
        .map((item) => ({
          ...item,
          label: item?.label?.[currentLocale] as any,
        })) || []) as any,
      categories: defaulValues?.categories || [],
      cloths: defaulValues?.cloths || "",
      coordinates: defaulValues?.coordinates || [0, 0],
      startDate: dayjs(defaulValues?.startDate).format("YYYY-MM-DD") as any,
      endDate: dayjs(defaulValues?.endDate).format("YYYY-MM-DD") as any,
      branches: defaulValues?.branches || [],
      skills: defaulValues?.skills || [],
      newLocation: false,
      timeToEnd: dayjs(defaulValues?.timeToEnd).toDate() as any,
      timeToStart: dayjs(defaulValues?.timeToStart).toDate() as any,
    },
  });
  console.log("address", watch("address"));

  return {
    control,
    errors,
    handleSubmit,
    setValue,
    address: data || [],
    watch,
    // startDate,
    // setStartDate,
    // endDate,
    // setEndDate,

    addressDefaulValues: {
      label:
        data?.find((item) => item?._id == watch("address"))?.addressTitle ||
        `${data?.find((item) => item?._id == watch("address"))?.street || ""} ${
          data?.find((item) => item?._id == watch("address"))?.city || ""
        } `,

      value: data?.find((item) => item?._id == watch("address")) || "",
    },
    isLoading,
  };
};
