import { useState } from "react";

import { useQuery } from "react-query";
import { ToastConsumer } from "store/ToastProvider";
import { UsersContextConsumer } from "store/AuthProvider";

import { deleteJobApi, getOrganizationJobAPI } from "utils/globalAPI/Dashboard";
import { useParams, useSearchParams } from "react-router-dom";
import { useLocale } from "locales";
import { JobData } from "types/schema";
import { ApiGetAddress } from "utils/globalAPI/Users";

export const useJobService = () => {
  const toast = ToastConsumer();
  const { accessToken, fetchApi } = UsersContextConsumer();
  const [activeCategory, setActiveCategory] = useState<string>("openForHiring");

  const [selectedAddress, setSelectedAddress] = useState<string[]>([]);

  const getCategoryName = (name: string) => {
    setActiveCategory(name);
  };

  const handleAdress = (data: string[]) => {
    console.log("data", data);
    setSelectedAddress(data);
  };
  const organizationId = useParams()?.organization;

  const { data: jobsData, isLoading: jobsDataLoading } = useQuery(
    [
      "jobsData",
      {
        accessToken,
        activeCategory,
        organizationId,

        selectedAddress,
      },
    ],
    async () =>
      getOrganizationJobAPI(
        activeCategory,
        organizationId,
        selectedAddress,
        accessToken
      )
        .then((res: any) => res?.jobs)
        .catch((error) => toast.error(error.message, "top-right")),
    { enabled: fetchApi, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const { data, isLoading } = useQuery(
    [
      "OrganizationAdress",
      {
        organization: organizationId,
      },
    ],
    () => ApiGetAddress(organizationId, accessToken),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: fetchApi,
    }
  );

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  const [quer, searchQuery] = useSearchParams({ jobId: "" });

  const onDeleteJob = (data: JobData | null) => {
    toggleModal();
    searchQuery({ jobId: data?._id + "" });
  };

  const { localeSuccess } = useLocale();

  const onConfirmDeleteJob = async () => {
    try {
      setLoading(true);
      await deleteJobApi(quer.get("jobId") as string, accessToken);
      toast.success(localeSuccess.SUCCESS_DELETING_JOB);
    } catch (error) {
      console.log("Error in deleting job", error);
      toast.error(localeSuccess.ERROR_DELETING_JOB);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  return {
    jobsData,
    jobsDataLoading,
    getCategoryName,
    activeCategory,
    address: data || [],
    onConfirmDeleteJob,
    loading,
    modal,
    toggleModal,
    onDeleteJob,
    organizationId,
    handleAdress
  };
};
