import ListPage from "../../pages/ListPage";
import { useSkillsetServices } from "./hook";
import AddButton from "../../components/molecules/AddButton/AddButton";
import { useLocale } from "../../locales";
import ModalComponent from "../../components/organisms/ModalComponent";
import AppDataForm from "../../components/organisms/AppDataForm/AppDataForm";
import ConfirmationModal from "../../components/organisms/Confirmation Modal";
import Loader from "../../components/atoms/Loader";

export interface services {}

const SkillsetServices = ({}: services) => {
  // const { userData } = UserAuthContext();
  const { localeButtons, localeTitles, localeLabels, localePlaceholders } =
    useLocale();
  const {
    appDataLoading,
    dropdowns,
    handleOpenAddModal,
    handleCloseAddModal,
    addModal,
    onSubmit,
    control,
    handleSubmit,
    loading,
    deleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    deleteLoading,
    onConfirmDelete,
    handleOpenEditModal,
    handleCloseEditModal,
    editModal,
    editLoading,
    onSubmitEdit,
    errors,
    skills
  } = useSkillsetServices();

  if (appDataLoading) {
    return (
      <div className="flex h-[80%] w-full justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className="pt-10 pr-10 flex justify-end">
        <AddButton
          text={localeButtons.BUTTON_ADD_SKILL_NAME}
          onClick={handleOpenAddModal}
        />
      </div>
      <ListPage
        onClickDelete={handleOpenDeleteModal}
        onClickEdit={handleOpenEditModal}
        type={"SkillSet"}
        list={skills}
        // hasMore={hasMore}
        loading={appDataLoading}
        totalUsers={skills?.length}
      />

      {/* Add Form */}
      <ModalComponent show={addModal}>
        <AppDataForm
          handleSubmit={handleSubmit}
          control={control}
          errors={errors}
          onSubmit={onSubmit}
          loading={loading}
          onHide={handleCloseAddModal}
          title={localeTitles.TITLE_ADD_SKILLS}
          primaryBtnText={localeButtons.BUTTON_ADD_SKILL_NAME}
          placeholder={localePlaceholders.PLACEHOLDER_ADD_SKILL_NAME}
          label1={localeLabels.LABEL_SKILL_NAME_ENGLISH}
          label2={localeLabels.LABEL_SKILL_NAME_DUTCH}
        />
      </ModalComponent>
      {/* Add Form */}

      {/* Edit Form */}
      <ModalComponent show={editModal}>
        <AppDataForm
          handleSubmit={handleSubmit}
          control={control}
          errors={errors}
          onSubmit={onSubmitEdit}
          loading={editLoading}
          onHide={handleCloseEditModal}
          title={localeTitles.TITLE_EDIT_SKILLS}
          primaryBtnText={localeButtons.BUTTON_EDIT}
          placeholder={localePlaceholders.PLACEHOLDER_ADD_SKILL_NAME}
          label1={localeLabels.LABEL_SKILL_NAME_ENGLISH}
          label2={localeLabels.LABEL_SKILL_NAME_DUTCH}
        />
      </ModalComponent>
      {/* Edit Form */}

      {/* Delete Branch */}
      <ConfirmationModal
        show={deleteModal}
        title={localeLabels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SKILL}
        onHide={handleCloseDeleteModal}
        onConfirm={onConfirmDelete}
        loading={deleteLoading}
      />
      {/* Delete Branch */}
    </>
  );
};

export default SkillsetServices;
