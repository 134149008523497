import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: boolean;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function CertificateIcon({ color, size, onClick }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.48014 15.4891C7.15014 14.4591 7.54011 13.1891 8.61011 12.8391C9.18011 12.6591 9.88012 12.8091 10.2701 13.3591C10.6401 12.7891 11.3701 12.6591 11.9301 12.8391C13.0101 13.1891 13.3901 14.4591 13.0701 15.4891C12.5601 17.1191 10.7701 17.9691 10.2701 17.9691C9.78012 17.9591 8.01014 17.1291 7.48014 15.4891Z"
        stroke="#858D98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CertificateIcon;

// fill={white ? "#fffff" : "#0F1322"}
