import React from "react";
import { ErrorMessage } from "../ErrorMessage";
import { Control, Controller, FieldValues } from "react-hook-form";

import styles from "./TimeSelector.module.scss";

// import DatePicker from "react-datepicker";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePickerRange from "@wojtekmaj/react-datetimerange-picker";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
// import 'react-calendar/dist/Calendar.css';
// import 'react-clock/dist/Clock.css'

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import "./index.scss";

import dayjs from "dayjs";

import { breakPoints } from "constants/ResponsiveDesignBreakPoints";
import { useWidth } from "utils/responsiveHook";

// Define a generic InputProps type that accepts the Control type and automatically extracts the name prop.
type InputProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "size" | "prefix" | "onChange"
> & {
  control: Control<any>;
  name: keyof TFieldValues;
  // preDefinedWrapClassName?: "inputField-wrap-password" | "inputField-wrap";
  // preDefinedClassName?: "inputField" | "inputField-date";
  // wrapClassName?: string;
  className?: string;
  // label?: string;
  prefix?: React.ReactNode;
  type?: "range" | "date";
  minDate?: Date;
  maxDate?: Date;
  customError?: string;
  format?: string;
  timeFormate?: string;
  error?: boolean;
};

const ControlTimeSelector = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      format = "dd-MM-yyyy HH:mm",
      timeFormate="HH:mm",
      prefix,
      maxDate,
      type = "date",
      minDate,
      customError,
      placeholder,
      control,
      name,
      error,
      // value,
      ...restProps
    },
    ref
  ) => {
    const { width } = useWidth();

    if (type === "range" && width > breakPoints.md) {
      return (
        // <div className={styles["inputField-wrap"]}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <div className={` flex flex-col ${className}`}>
              <DatePickerRange
                format={format}
                clockClassName={"none"}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(date: any) => {
                  console.log("date", date?.hour);
                  onChange(date);
                }}
                value={value}
              />
              {(errors?.[name]?.message + "" !== "undefined" || customError) &&
                (
                  (errors?.[name]?.message != "undefined" &&
                    errors?.[name]?.message + "") ||
                  customError ||
                  ""
                )?.length > 0 && (
                  <ErrorMessage
                    errors={
                      customError && customError?.length > 0
                        ? customError
                        : (errors?.[name]?.message as string)
                    }
                  />
                )}
            </div>
          )}
        />
        //  </div>
      );
    } else if (type === "range" && width <= breakPoints.md) {
      return (
        // <div className={styles["inputField-wrap"]}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <div className={` ${styles["time-mobile"]} ${className}`}>
              <DateTimePicker
                format={format}
                clockClassName={"none"}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(date) => {
                  onChange([date, value[1]]);
                }}
                value={value?.[0] || new Date()}
              />
              <DateTimePicker
                format={format}
                clockClassName={"none"}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(date) => {
                  onChange([value[0], date]);
                }}
                value={value?.[1] || new Date()}
              />
              {(errors?.[name]?.message + "" !== "undefined" || customError) &&
                (
                  (errors?.[name]?.message != "undefined" &&
                    errors?.[name]?.message + "") ||
                  customError ||
                  ""
                )?.length > 0 && (
                  <ErrorMessage
                    errors={
                      customError && customError?.length > 0
                        ? customError
                        : (errors?.[name]?.message as string)
                    }
                  />
                )}
            </div>
          )}
        />
        //  </div>
      );
    }

    return (
      // <div className={styles["inputField-wrap"]}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <div className={` flex flex-col ${className}`}>
            {!!prefix && <div className={styles["prefix"]}>{prefix}</div>}
            <DatePicker
              inputClass={`${
                styles[
                  error ? "inputField-wrap-time-error" : "inputField-wrap-time"
                ]
              } ${
                (errors?.[name]?.message + "" !== "undefined" || customError) &&
                (
                  (errors?.[name]?.message != "undefined" &&
                    errors?.[name]?.message + "") ||
                  customError ||
                  ""
                )?.length > 0 &&
                styles["prefix-input"]
              }`}
              placeholder={placeholder}
              maxDate={maxDate}
              minDate={minDate}
              format={timeFormate}
              onChange={(date: any) => {
                console.log("date", date?.hour);
                let DATE = dayjs(
                  `${date?.year}-${date?.month}-${date?.day} ${date?.hour}:${date?.minute}`
                ).toDate();
                onChange(DATE);
              }}
              value={value}
              disableDayPicker
              plugins={[<TimePicker hideSeconds />]}
            />
            {(errors?.[name]?.message + "" !== "undefined" || customError) &&
              (
                (errors?.[name]?.message != "undefined" &&
                  errors?.[name]?.message + "") ||
                customError ||
                ""
              )?.length > 0 && (
                <ErrorMessage
                  errors={
                    customError && customError?.length > 0
                      ? customError
                      : (errors?.[name]?.message as string)
                  }
                />
              )}
          </div>
        )}
      />
      //  </div>
    );
  }
);

export { ControlTimeSelector };
