import type { FileModalProps } from "./@types";
import styles from "./UploadCard.module.scss";

import { Navigation, Pagination, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Modal from "../../molecules/Modal";

import { BsImageFill } from "react-icons/bs";
import  { getImageURL } from "../../../utils/hooks/helper";
import { AiFillCloseCircle } from "react-icons/ai";


const FileViewerModal = ({ files, open, onClose }: FileModalProps) => {
  return (
    <div style={{
      position:"relative",
    }} onClick={(event) => event.stopPropagation()}>
      

      <Modal minHeight="auto" show={open} onHide={onClose}>
        {/* <CloseF */}
        <AiFillCloseCircle
        style={{
          position:"absolute",
          top:-10,
          right:-10
        }}
        size={30}
        onClick={onClose}
       />
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          // spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          //  scrollbar={{ draggable: true }}
          onSwiper={(swiper: any) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          style={{
            // height: 200,
            width: 300,
          }}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {files?.map((file) => (
            <SwiperSlide
              style={{
                // height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: 100,
              }}
            >

              <Files file={file} onClose={onClose} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal>
    </div>
  );
};

export const Files = ({ file,onClose }: { file: File | string,onClose:()=>void }) => {
  const fileTypeResult ="jpg"

  const ReturnIcon = () => {
    switch (fileTypeResult) {
      case "jpg":
        return (
          // display image
          <img
            className={styles["Image-styling"]}
            src={
              typeof file == "string"
                ? getImageURL(file)
                : URL.createObjectURL(file)
            }
          />
        );

      default: {
        return <BsImageFill size={60} />;
      }
    }
  };

  return (
    <div className={styles["slid-pdf"]}>
    
      {ReturnIcon()}
      <a
        target="_blank"
        href={
          typeof file == "string"
            ? getImageURL(file)
            : URL.createObjectURL(file)
        }
        className={styles["title"]}
      >
        download
      </a>
    </div>
  );
};

export default FileViewerModal;
