import { useLocation, useNavigate } from "react-router-dom";
import type { JobDescriptionCardProps } from "./@types";
import styles from "./JobDescriptionCard.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";

const JobDescriptionCard = ({ description,title }: JobDescriptionCardProps) => {
  const { localeTitles } = useLocale();

  const formattedDescription = description?.split(":").map((line, index) => (
    <Text className={styles["description"]} key={index}>
      {line.trim()}:
    </Text>
  ));

  return (
    <div className={styles["JobDescriptionCard"]}>
      <Text className={styles["title"]}>{title || localeTitles.TITLE_JOB_OVERVIEW}</Text>
      <Text className={styles["description"]}>
      {description}
    </Text>
    </div>
  );
};

export default JobDescriptionCard;
