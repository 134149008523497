import type { JobsCompletedCardProps } from "./@types";
import styles from "./JobsCompletedCard.module.scss";
import { Text } from "components/atoms/TextF";
import { useLocale } from "locales";
import { Img } from "components/atoms/Img";
import { handleImagesPath } from "utils/hooks/helper";

const JobsCompletedCard = ({ data, getData }: JobsCompletedCardProps) => {
  const { localeBasics } = useLocale();
  return (
    <div
      className={styles["JobsCompletedCard"]}
      onClick={() => getData && getData(data)}
    >
      <div className={styles["JobsCompletedCard-left"]}>
        <Img
          className={styles["image"]}
          src={handleImagesPath(data?.organization?.logo)}
        />
        <div className={styles["JobsCompletedCard-left-text"]}>
          <Text className={styles["jobName"]}>{data?.jobId?.title}</Text>
          <Text className={styles["companyName"]}>
            {data?.organization?.companyName}
          </Text>
        </div>
      </div>
      <div className={styles["JobsCompletedCard-right"]}>
        {data?.jobId?.workerType !== "employee" && (
          <Text className={styles["earn"]}>
            €{(data?.jobId?.wage && data?.jobId?.wage) || 0}
          </Text>
        )}
        {data?.jobId?.hoursWorked && (
          <Text className={styles["hoursWorked"]}>{`${
            data?.jobId?.hoursWorked && data?.jobId?.hoursWorked
          } ${localeBasics.TEXT_HOURS_WORKED}`}</Text>
        )}
      </div>
    </div>
  );
};

export default JobsCompletedCard;
