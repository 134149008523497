export default {
  already_invited: "Je hebt deze gebruiker al uitgenodigd.",
  invalid_email: "Ongeldig e-mailadres. Voer een geldig e-mailadres in.",
  wrong_password: "Onjuist wachtwoord. Controleer uw wachtwoord nogmaals.",
  invalid_Auth_with:
    "Authenticatie mislukt. Probeer het opnieuw met een andere methode.",
  create_a_new_account:
    "Account niet gevonden. Wilt u een nieuw account aanmaken?",
  reset_password: "Wachtwoord opnieuw instellen mislukt. Probeer het later opnieuw.",
  invalid_password:
    "Wachtwoord moet aan de beveiligingseisen voldoen.",
  invalid_locale: "Ongeldige landinstelling geselecteerd. Kies een geldige landinstelling.",
  link_expired: "De link is verlopen. Vraag een nieuwe aan.",
  invalid_firstName: "Ongeldige voornaam. Voer een geldige voornaam in.",
  invalid_lastName: "Ongeldige achternaam. Voer een geldige achternaam in.",
  invalid_title: "Ongeldige titel. Voer een geldige titel in.",
  invalid_dob: "Ongeldige geboortedatum. Voer een geldige geboortedatum in.",
  invalid_street:
    "Ongeldig adres. Voer een geldig adres in.",
  invalid_zip: "Ongeldige postcode. Voer een geldige postcode in.",
  invalid_place: "Ongeldige plaats. Voer een geldige plaats in.",
  invalid_categories:
    "Ongeldige categorieën geselecteerd. Kies geldige categorieën.",
  invalid_accountType:
    "Ongeldig accounttype. Selecteer een geldig accounttype.",
  invalid_phone: "Ongeldig telefoonnummer. Voer een geldig telefoonnummer in.",
  invalid_accountHolderName:
    "Ongeldige naam accounthouder. Voer een geldige naam in",
  invalid_accountNumber:
    "Ongeldig rekeningnummer. Voer een geldig rekeningnummer in..",
  invalid_bankName: "Ongeldige banknaam. Voer een geldige banknaam in.",
  invalid_certificate:
    "Ongeldig certificaat. Geef een geldig certificaat op.",
  invalid_nic:
    "Ongeldig ID-nummer (Identificatiekaart). Voer een geldige ID nummer in",
  invalid_diplomas: "Ongeldige diploma's. Geef geldige diploma's op.",
  invalid_scannedImages:
    "Ongeldige gescande afbeeldingen. Geef geldige gescande afbeeldingen op.",
  invalid_employee: "Ongeldige werknemer. Werknemer niet gevonden.",
  invalid_position: "Ongeldige positie. Selecteer een geldige positie.",
  invalid_branch: "Ongeldige branche. Selecteer een geldige branche.",
  invalid_token: "Ongeldig token. Authenticatie mislukt.",
  user_not_authorized: "Gebruiker is niet geautoriseerd om deze actie uit te voeren.",
  invalid_request:
    "Ongeldig verzoek. Controleer uw verzoek en probeer het opnieuw.",

  invalid_availabilityStatus: "Ongeldige beschikbaarheidsstatus.",
  invalid_skills: "Ongeldige vaardigheden.",
  invalid_job: "Ongeldige baan.",
  invalid_education: "Ongeldige opleiding.",
  invalid_profileImage: "Profielafbeelding is ongeldig.",
  invalid_socialHandle: "Ongeldig sociaal handvat.",
  invalid_hourlyRate: "Ongeldig uurtarief.",
  invalid_bio: "Ongeldige biografie.",
  invalid_IBAN: "Ongeldig IBAN.",
  invalid_companyName: "Ongeldige bedrijfsnaam.",
  worker_not_found: "Gebruiker niet gevonden.",

  invalid_invitationToSetting: "Ongeldige uitnodigingsinstelling.",
  invalid_invitationOfLikedService:
    "Ongeldige uitnodiging voor een favoriete dienst.",
  invalid_reminders: "Ongeldige herinneringen.",
  invalid_alerts: "Ongeldige waarschuwingen.",
  invalid_tokenType: "Ongeldig tokentype.",
  userId_Invalid: "Gebruikers-ID niet gevonden.",
  reportedUserId_Invalid: "Gerapporteerde gebruikers-ID is ongeldig.",
  reportReason_Invalid: "Reden voor rapportage is ongeldig.",
  reportDescription_Invalid: "Omschrijving van rapportage is ongeldig.",
  notificationId_Invalid: "Meldings-ID is ongeldig.",
  workerType_Invalid: "Type werknemer is ongeldig.",
  urgencyLevel_Invalid: "Niveau van urgentie is ongeldig.",
  wage_Invalid: "Loon is ongeldig.",
  Address_Invalid: "Adres is ongeldig.",
  cloths_Invalid: "Kledinginformatie is ongeldig.",
  travelExpenses_Invalid: "Reiskosten zijn ongeldig.",
  workerNumber_Invalid: "Werknemersnummer is ongeldig.",
  breakTime_Invalid: "Pauzetijd is ongeldig.",
  title_Invalid: "Titel is ongeldig.",
  jobDescription_Invalid: "Functieomschrijving is ongeldig.",
  attachment_Invalid: "Bijlage is ongeldig.",
  timeToEnd_Invalid: "Eindtijd is ongeldig.",
  timeToStart_Invalid: "Begintijd is ongeldig.",
  startDate_Invalid: "Startdatum is ongeldig",
  endDate_Invalid: "Einddatum is ongeldig.",
  jobId_Invalid: "Functie-ID is ongeldig.",
  workerId_Invalid: "Werknemer-ID is ongeldig.",
  perHourRate_Invalid: "Tarief per uur is ongeldig.",
  content_required: "Inhoud is vereist.",
  jobStatus_Invalid: "Status van de functie is ongeldig.",
  invalid_jobStatus: "Ongeldige functiestatus.",
  attachment_required: "Bijlage is vereist.",
  response_Invalid: "Reactie is ongeldig.",
  invitationId_Invalid: "Uitnodiging bestaat niet in de database.",
  ERROR_UNKOWN: "Er is iets misgegaan. Probeer het later opnieuw.",
  password_wrong: "Wachtwoord is verkeerd.",
  email_not_found: "E-mail niet gevonden.",
  email_already_exist: "E-mail bestaat al.",
  description_min_required_length_is_10:
    "Beschrijving moet minstens 10 tekens lang zijn.",
  user_not_active: "Gebruiker is niet actief.",
  password_old_wrong: "Oud wachtwoord is onjuist.",
  invalid_category_type: "Ongeldig categorie type.",
  organization_not_found: "Organisatie niet gevonden.",
  job_not_found: "Functie niet gevonden.",
  not_allowed_to_access_job: "Geen toegang tot deze functie.",
  invalid_worker_type: "Ongeldig werknemerstype.",
  task_not_found: "Taak niet gevonden.",
  incorrect_start_date: "Onjuiste begindatum.",
  incorrect_end_date: "Onjuiste einddatum.",
  conversation_not_found: "Gesprek niet gevonden.",
  user_is_blocked: "Gebruiker is geblokkeerd.",
  user_not_found: "Gebruiker niet gevonden.",
  cannot_send_empty_message: "Kan geen leeg bericht versturen.",
  invitation_not_found: "Uitnodiging niet gevonden.",

  invalid_city: "Voer een geldige stad in.",
  invalid_country: "Voer een geldig land in.",

  invalid_date_format: "Ongeldig datumformaat. Gebruik een geldig datumformaat.",
  Same_date_found: "Er bestaat al een planning voor dezelfde datum.",
  timeFrame_is_required: "Tijdsframe is vereist voor planning",
  invalid_startTime_or_endTime: "Ongeldige begin- of eindtijd",
  StartTime_cannot_be_same_as_ahead_of_endTime:
    "Begintijd kan niet hetzelfde zijn als of eerder zijn dan de eindtijd.",
  TimeFrames_overlap_found: "Overlappende tijdframes gevonden.",
  task_in_timeFrame_is_required: "Taak in tijdsbestek is vereist.",
  invalid_availability: "Ongeldige beschikbaarheidsselectie.",
  day_shift: "Dag shift is geselecteerd.",
  invalid_shift: "Ongeldige shift selectie.",
  timeFrame_is_not_required: "Tijdsframe is niet vereist.",
  reason_in_timeFrame_is_required: "Reden in tijdsbestek is vereist.",
  Please_insert_reason_only_in_timeFrame:
    "Voeg alleen de reden in het tijdsbestek in",
  reason_is_required: "Reden is vereist.",
  taskName_in_timeFrame_is_required: "Taaknaam in tijdsbestek is vereist",
  invalid_schedule_or_availability_Id: "Ongeldige planning of beschikbaarheid.",
  invalid_timeFrame_id: "Ongeldige tijdsframe.",
  invalid_endDate: "Ongeldige einddatum.",
  invalid_startDate: "Ongeldige startdatum.",
  invalid_date: "Ongeldige datum.",
  schedule_not_found_for_the_given_date:
    "Planning niet gevonden voor de opgegeven datum.",
  timeFrame_already_occupied_for_this_date:
    "Het tijdsbestek is al bezet voor deze datum.",
  availability_not_found_for_the_given_date:
    "Beschikbaarheid niet gevonden voor de opgegeven datum.",
  worker_is_not_available_on_this_date:
    "Werknemer is niet beschikbaar op deze datum.",
  worker_is_not_available_at_this_time:
    "Werknemer is momenteel niet beschikbaar.",

  brach_id_not_exist: "Branche is niet geldig",
  invalid_address: "Ongeldig adres",
  invalid_hiredWorker: "Ongeldige ingehuurde werknemer",
  reporteduser_Invalid: "Gerapporteerde gebruiker is ongeldig",
  organizatio_not_found: "Organisatie account niet gevonden",

  already_hired: "Al aangenomen",
  invalid_job_id: "Ongeldige dienst-ID",
  worker_Invalid: "Ongeldige werknemer",
  hiredWorker_Invalid: "Ongeldige ingehuurde werknemer",
  start_Invalid: "Ongeldige startdatum",
  end_Invalid: "Ongeldige einddatum",
  name_Invalid: "Taaknaam is ongeldig",
  task_Invalid: "Taak is ongeldig",
  invalid_wage: "Het tarief moet een getal zijn",
  invalid_branch_id: "Ongeldige branche id",
  schedule_Invalid: "Ongeldig rooster",
  scheduleId_Invalid: "Ongeldig rooster",
  blocked_user_account: "Je komt niet in aanmerking om deze actie uit te voeren.",
  TASK_CAN_NOT_BE_UPDATED:
    "Taak kan niet worden bijgewerkt omdat deze is voltooid of goedgekeurd.",
  financeReport_Invalid: "verzoek is ongeldig.",
  working_status_invalid: "Werkstatus is ongeldig.",
  not_authorised: "U bent niet bevoegd om deze actie uit te voeren.",
  shifts_not_found:
    "Geen diensten gevonden om facturen binnen een bepaald tijdsbestek te genereren.",

  job_conract_already_completed: "Dienst al voltooid.",
  mark_all_shifts_as_completed_before_completing_job:
    "Markeer alle diensten als voltooid voordat u de taak voltooid.",
  rating_Invalid: "Beoordeling is vereist.",
  review_Invalid: "Review is vereist.",

  branch_not_allowed:"Uw organisatie mag geen dienst plaatsen voor de door u geselecteerde vestiging.",
}