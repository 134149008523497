import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
  sameSize?: boolean;
};
function HashTagIcon({ color, size, onClick ,sameSize}: Props) {
  const { width } = useWindowSize();

  return (
    <svg
    width={window.innerWidth < breakPoints.md - 1 && !sameSize ?  "15":  "24"}
    height={window.innerWidth < breakPoints.md - 1 && !sameSize ?  "15":  "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 13V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 22V16L17 18"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 16L21 18"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.95039 6.25977L8.90039 15.7298"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1096 6.25977L12.0596 15.7298"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.53027 9.41992H16.0003"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 12.5801H15.47"
        stroke={color ? color : "#858D98"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default HashTagIcon;

// fill={white ? "#fffff" : "#0F1322"}
