import { useWindowSize } from "usehooks-ts";
import { breakPoints } from "constants/ResponsiveDesignBreakPoints";

type Props = {
  sameSize?: boolean;
  color?: string;
  size?: "MOBILE" | "TABLET" | "LARGE";
  onClick?: any;
};
function HangerIcon({ color, size, onClick, sameSize }: Props) {
  const { width } = useWindowSize();

  return (
    <svg
      width={window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"}
      height={
        window.innerWidth < breakPoints.md - 1 && !sameSize ? "15" : "24"
      }
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6C14 6.39815 13.8837 6.76913 13.6831 7.08079C13.0854 8.00967 12 8.89543 12 10V10M12 10L20.9215 16.3725C22.5048 17.5034 21.7047 20 19.7591 20H4.24093C2.29525 20 1.49519 17.5034 3.07845 16.3725L12 10Z"
        stroke={color ? color : "#75808F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default HangerIcon;

// fill={white ? "#fffff" : "#0F1322"}
